import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';
import {
  GET_USERS,
  DELETE_USER,
  ADD_USER,
  EDIT_USER
} from "./actionTypes";

import {
  getUsersSuccess,
  getUsers,
  getUsersFail,
  deleteUserModal
} from "./actions";

import {
  getUsers as getUsersAPI,
  deleteUser as deleteUserAPI,
  addUser as addUserAPI,
  editUser as editUserAPI,
} from "api/v1";


function* fetchUsers({ payload: { params } }) {
  try {
    const response = yield call(getUsersAPI, params);
    yield put(getUsersSuccess(response));
  } catch (error) {
    yield put(getUsersFail("Something went wrong!"))
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* addUser({ payload: { user, actions } }) {
  try {
    yield call(addUserAPI, user)
    toast.success("User Created!");
    yield put(getUsers(true));
    actions.resetForm()

  } catch ({ errors }) {
    yield put(getUsersFail("Creation failed!"))
    if (errors) {
      actions.setErrors(errors)

    }
    toast.error("Something went wrong!");
  }
}

function* editUser({ payload: { id, user, actions } }) {
  try {
    yield call(editUserAPI, id, user)
    toast.success("User Updated!");
    yield put(getUsers(true));

  } catch ({ errors }) {
    yield put(getUsersFail("Creation failed!"))
    if (errors && actions) {
      actions.setErrors(errors)

    }
    toast.error("Something went wrong!");
  }
}

function* deleteUser({ payload: { user } }) {
  try {
    yield call(deleteUserAPI, user.id);
    put(deleteUserModal())
    yield put(getUsers(true));
    toast.warn("User Deleted!");
  } catch (error) {
    toast.error("Something went wrong!");
  }
}

function* userSaga() {
  yield takeEvery(GET_USERS, fetchUsers);
  yield takeEvery(DELETE_USER, deleteUser);
  yield takeEvery(ADD_USER, addUser);
  yield takeEvery(EDIT_USER, editUser);
}

export default userSaga;
