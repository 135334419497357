import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';
import {
  GET_PRODUCTS,
  DELETE_PRODUCT,
  ADD_PRODUCT,
  EDIT_PRODUCT
} from "./actionTypes";

import {
  getProductsSuccess,
  getProducts,
  getProductsFail,
  deleteProductModal
} from "./actions";

import {
  getProducts as getProductsAPI,
  deleteProduct as deleteProductAPI,
  addProduct as addProductAPI,
  editProduct as editProductAPI,
} from "api/v1";


function* fetchProducts({ payload: { params } }) {
  try {
    const response = yield call(getProductsAPI, params);
    yield put(getProductsSuccess(response));
  } catch (error) {
    yield put(getProductsFail("Something went wrong!"))
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* addProduct({ payload: { product, actions } }) {
  try {
    yield call(addProductAPI, product)
    toast.success("Product Created!");
    yield put(getProducts(true));
    actions.resetForm()

  } catch ({ errors }) {
    yield put(getProductsFail("Creation failed!"))
    if (errors) {
      let { user } = errors
      actions.setErrors({ ...errors, ...user })

    }
    toast.error("Something went wrong!");
  }
}

function* editProduct({ payload: { id, product, actions } }) {
  try {
    yield call(editProductAPI, id, product)
    toast.success("Product Updated!");
    yield put(getProducts(true));

  } catch ({ errors }) {
    yield put(getProductsFail("Creation failed!"))
    if (errors && actions) {
      let { user } = errors
      actions.setErrors({ ...errors, ...user })

    }
    toast.error("Something went wrong!");
  }
}

function* deleteProduct({ payload: { product } }) {
  try {
    yield call(deleteProductAPI, product.id);
    toast.warn("Product Deleted!");
    put(deleteProductModal())
    yield put(getProducts(true));
  } catch (error) {
    yield put(getProductsFail(""))
    toast.error("Something went wrong!");
  }
}

function* productSaga() {
  yield takeEvery(GET_PRODUCTS, fetchProducts);
  yield takeEvery(DELETE_PRODUCT, deleteProduct);
  yield takeEvery(ADD_PRODUCT, addProduct);
  yield takeEvery(EDIT_PRODUCT, editProduct);
}

export default productSaga;
