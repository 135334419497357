import {
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  DELETE_CATEGORY,
  ADD_CATEGORY,
  EDIT_CATEGORY
} from "./actionTypes"

export const getCategories = (loading = false, params = {}) => ({
  type: GET_CATEGORIES,
  payload: { loading, params }
})

export const getCategoriesSuccess = categories => ({
  type: GET_CATEGORIES_SUCCESS,
  payload: categories,
})

export const getCategoriesFail = error => ({
  type: GET_CATEGORIES_FAIL,
  payload: error,
})

export const deleteCategory = category => ({
  type: DELETE_CATEGORY,
  payload: { category }
})

export const addCategory = (category, actions) => {
  return {
    type: ADD_CATEGORY,
    payload: { category, actions },
  }
}

export const editCategory = (id, category, actions) => {
  return {
    type: EDIT_CATEGORY,
    payload: { id, category, actions },
  }
}

export const newCategoryModal = () => ({
  type: 'NEW_CATEGORY_MODAL'
})

export const deleteCategoryModal = () => ({
  type: 'DELETE_CATEGORY_MODAL'
})

export const editCategoryModal = () => ({
  type: 'EDIT_CATEGORY_MODAL'
})
