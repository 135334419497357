import React from "react";
import {
  Col,
  Row,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap";


function index({ validation, edit = false }) {
  let [avatar, setAvatar] = React.useState()

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <Row>
        <Col xs="12" className="mb-3">
          <Label className="form-label">Email</Label>
          <Input
            name="email"
            type="email"
            disabled={edit}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.email || ""}
            invalid={
              validation.touched.email && validation.errors.email ? true : false
            }
          />
          {validation.touched.email && validation.errors.email ? (
            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
          ) : null}
        </Col>

        <Col xs="12" className="mb-3">
          <Label className="form-label">First Name</Label>
          <Input
            name="first_name"
            type="text"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.first_name || ""}
            invalid={
              validation.touched.first_name && validation.errors.first_name ? true : false
            }
          />
          {validation.touched.first_name && validation.errors.first_name ? (
            <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
          ) : null}
        </Col>

        <Col xs="12" className="mb-3">
          <Label className="form-label">Last Name</Label>
          <Input
            name="last_name"
            type="text"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.last_name || ""}
            invalid={
              validation.touched.last_name && validation.errors.last_name ? true : false
            }
          />
          {validation.touched.last_name && validation.errors.last_name ? (
            <FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
          ) : null}
        </Col>

        <Col xs="12" className="mb-3">
          <Label className="form-label">Phone</Label>
          <Input
            name="phone"
            type="text"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.phone || ""}
            invalid={
              validation.touched.phone && validation.errors.phone ? true : false
            }
          />
          {validation.touched.phone && validation.errors.phone ? (
            <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
          ) : null}
        </Col>

        <Col xs="12">
          <div className="mb-3">
            <Label className="form-label">Avatar</Label>
            <Input
              name="avatar"
              type="file"
              onChange={event => {
                validation.setFieldValue('avatar', event.target.files[0])
                if (event.target.files.length > 0) {
                  var reader = new FileReader();
                  reader.readAsDataURL(event.target.files[0]);
                  reader.onloadend = function (e) {
                    setAvatar(reader.result)
                  }

                } else {
                  setAvatar(null)
                }
              }}
              accept="image/*"
              onBlur={validation.handleBlur}
              invalid={
                validation.touched.avatar && validation.errors.avatar ? true : false
              }
            />
            {validation.touched.avatar && validation.errors.avatar ? (
              <FormFeedback type="invalid">{validation.errors.avatar}</FormFeedback>
            ) : null}

            {
              (avatar || validation.values.avatar) &&
              <div className="table-avatar-lg mt-2">
                <img
                  src={avatar || validation.values.avatar}
                  alt={validation.values.first_name[0]}
                />
              </div>
            }
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="text-end">
            <button
              type="submit"
              className="btn btn-success save-customer"
            >
              Save
            </button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default index
