import { filterParams } from "../apiHelper";
import request from "../restClient";

export const getEstimates = async (params) => {
  return await request({
    url: "estimates/",
    method: "GET",
    params: filterParams(params),
    cancelPrevious: params.cancelPrevious || true,
  });
};

export const deleteEstimate = async (id) => {
  return await request({
    url: `estimates/${id}/`,
    method: "DELETE"
  });
};

export const generateEstimate = async (id) => {
  return await request({
    url: `estimates/${id}/generate_pdf/`,
    method: "PATCH"
  });
};

export const editEstimate = async (id, data) => {

  return await request({
    url: `estimates/${id}/`,
    method: "PATCH",
    data
  });
};

export const addEstimate = async (data) => {

  return await request({
    url: `estimates/`,
    method: "POST",
    data
  });
};
