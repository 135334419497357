import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'

import { editPurchaseModal, editPurchase } from 'store/purchases/actions'
import moment from "moment";

function index({ purchase: target }) {
  let dispatch = useDispatch()
  const { edit: modal } = useSelector((state) => state.purchases);
  let [purchase, setPurchase] = useState()

  useEffect(() => {
    if (modal) {
      setPurchase(target)
    }
  }, [modal])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      product: (purchase && purchase.product) || null,
      vendor: (purchase && purchase.vendor) || null,
      order_date: (purchase && moment(purchase.order_date, 'YYYY-MM-DDThh:mm:ssZ').format('YYYY-MM-DDThh:mm:ss')) || '',
      delivery_date: (purchase && purchase.delivery_date && moment(purchase.delivery_date, 'YYYY-MM-DDThh:mm:ssZ').format('YYYY-MM-DDThh:mm:ss')) || '',
      ordered_qty: (purchase && purchase.ordered_qty) || '',
      order_no: (purchase && purchase.order_no) || '',
      tracking_no: (purchase && purchase.tracking_no) || '',
      purchase_price: (purchase && purchase.purchase_price) || '',
      delivery_status: (purchase && purchase.delivery_status) || '',
      shipped_by: (purchase && purchase.shipped_by) || '',
      image: (purchase && purchase.image) || null
    },
    validationSchema: Yup.object({
      shipped_by: Yup.string().required("Please enter who will ship the order"),
      order_date: Yup.string().required("Please Select Order date"),
      ordered_qty: Yup.string().required("Please Enter Order quantity"),
      purchase_price: Yup.string().required("Please Enter Purchase Price"),
      order_no: Yup.string().required("Please Enter Order Number"),
      product: Yup.mixed().required("Please Select Product"),
      vendor: Yup.mixed().required("Please Select Vendor")
    }),
    onSubmit: (_values, actions) => {
      let { product,
        vendor, delivery_date, order_date, ...values } = _values

      dispatch(editPurchase(purchase.id, {
        order_date: moment(order_date, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DDTHH:mm:ssZ"),
        delivery_date: delivery_date ? moment(delivery_date, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DDTHH:mm:ssZ") : null,
        product: product.id,
        vendor: vendor.id,
        ...values
      }, actions))
    },
  });



  return (
    <React.Fragment>
      <Modal size="lg" isOpen={modal} toggle={() => dispatch(editPurchaseModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(editPurchaseModal())}>
          Edit Purchase
        </ModalHeader>
        <ModalBody>
          <Form
            edit
            validation={validation}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
