import {
  GET_PAYRATES_FAIL,
  GET_PAYRATES_SUCCESS,
  GET_PAYRATES,
  ADD_PAYRATE,
  EDIT_PAYRATE,
  DELETE_PAYRATE,
} from "./actionTypes";

const INIT_STATE = {
  payrateWithPagy: {
    total: 0,
    page: 1,
    page_size: 10,
    results: []
  },
  new: false,
  edit: false,
  delete: false,
  loading: false,
};

const payrates = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PAYRATES:
      return {
        ...state,
        new: false,
        edit: false,
        delete: false,
        loading: action.payload.loading
      };

    case GET_PAYRATES_SUCCESS:
      return {
        ...state,
        loading: false,
        payrateWithPagy: action.payload,
      };

    case ADD_PAYRATE:
      return {
        ...state,
        loading: true
      };

    case EDIT_PAYRATE:
      return {
        ...state,
        loading: true
      };

    case GET_PAYRATES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "NEW_PAYRATE_MODAL":
      return {
        ...state,
        new: !state.new
      };

    case "DELETE_PAYRATE_MODAL":
      return {
        ...state,
        delete: !state.delete
      };

    case "EDIT_PAYRATE_MODAL":
      return {
        ...state,
        edit: !state.edit
      };
    default:
      return state;
  }
};

export default payrates;
