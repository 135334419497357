import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'

import { editRoleModal, editRole } from 'store/roles/actions'

function index({ role: target }) {
  let dispatch = useDispatch()
  const { edit: modal } = useSelector((state) => state.roles);
  let [role, setRole] = useState()

  useEffect(() => {
    if (modal) {
      setRole(target)
    }
  }, [modal])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (role && role.title) || '',
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter role title"),
    }),
    onSubmit: (values, actions) => {
      dispatch(editRole(role.id, values, actions))
    },
  });

  return (
    <React.Fragment>
      <Modal isOpen={modal} toggle={() => dispatch(editRoleModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(editRoleModal())}>
          Edit Role
        </ModalHeader>
        <ModalBody>
          <Form
            validation={validation}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
