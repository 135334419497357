import {
  GET_EXPENSES,
  GET_EXPENSES_SUCCESS,
  GET_EXPENSES_FAIL,
  DELETE_EXPENSE,
  ADD_EXPENSE,
  EDIT_EXPENSE
} from "./actionTypes"

export const getExpenses = (projectId, loading = false, params = {}) => ({
  type: GET_EXPENSES,
  payload: { projectId, loading, params }
})

export const getExpensesSuccess = expenses => ({
  type: GET_EXPENSES_SUCCESS,
  payload: expenses,
})

export const getExpensesFail = error => ({
  type: GET_EXPENSES_FAIL,
  payload: error,
})

export const deleteExpense = (projectId, expense) => ({
  type: DELETE_EXPENSE,
  payload: { projectId, expense }
})

export const addExpense = (projectId, expense, actions) => {
  return {
    type: ADD_EXPENSE,
    payload: { projectId, expense, actions },
  }
}

export const editExpense = (projectId, id, expense, actions) => {
  return {
    type: EDIT_EXPENSE,
    payload: { projectId, id, expense, actions },
  }
}

export const newExpenseModal = () => ({
  type: 'NEW_EXPENSE_MODAL'
})

export const deleteExpenseModal = () => ({
  type: 'DELETE_EXPENSE_MODAL'
})

export const editExpenseModal = () => ({
  type: 'EDIT_EXPENSE_MODAL'
})
