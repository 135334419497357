import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { Card, CardBody } from 'reactstrap'
import { getInvoicesCount } from 'api/v1'
import Loader from 'common/Loader'
import { usFormat } from 'utils/common'
import { toast } from 'react-toastify'

function Quotes() {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    options: {
      chart: {
        stacked: !0,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: !0
        }
      },
      plotOptions: {
        bar: {
          horizontal: !1,
          columnWidth: "15%"
          // endingShape: "rounded"
        }
      },
      dataLabels: {
        enabled: !1
      },
      xaxis: {
        show: true,
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ],
        labels: {
          show: true
        }
      },
      colors: ["#f1b44c", "#2DB75B"],
      legend: {
        position: "bottom"
      },
      fill: {
        opacity: 1
      }
    },
    series: [{
      name: 'Estimates',
      data: []
    }, {
      name: 'Invoices',
      data: []
    }]
  })

  async function loadData(params = {}) {
    setLoading(true)
    try {
      let _data = await getInvoicesCount();
      if (_data.length > 0)
        setData(__data => ({
          series: [{
            name: 'Estimates',
            data: _data.map(_ => Object.values(_)[0].estimate_count)
          }, {
            name: 'Invoices',
            data: _data.map(_ => Object.values(_)[0].invoice_count)
          }],
          options: {
            ...__data.options,
            xaxis: { ...__data.options.xaxis, categories: _data.map(_ => Object.keys(_)[0]) }
          }
        }))


    } catch (error) {
      toast.error("Something went wrong loading data")
    }
    setLoading(false)
  }

  useEffect(() => {
    loadData()
  }, []);

  return (
    <Card>
      <CardBody>
        <h4 className="card-title mb-4">Quotes Overview</h4>

        <React.Fragment>
          <ReactApexChart
            options={data.options}
            series={data.series}
            type="bar"
            height="490"
            className="apex-charts"
          />
        </React.Fragment>
      </CardBody>
    </Card>
  )
}

export default Quotes
