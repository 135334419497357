import {
  GET_TIMEOFFS,
  GET_TIMEOFFS_SUCCESS,
  GET_TIMEOFFS_FAIL,
  DELETE_TIMEOFF,
  ADD_TIMEOFF,
  EDIT_TIMEOFF
} from "./actionTypes"

export const getTimeoffs = (loading = false, params = {}) => ({
  type: GET_TIMEOFFS,
  payload: { loading, params }
})

export const getTimeoffsSuccess = timeoffs => ({
  type: GET_TIMEOFFS_SUCCESS,
  payload: timeoffs,
})

export const getTimeoffsFail = error => ({
  type: GET_TIMEOFFS_FAIL,
  payload: error,
})

export const deleteTimeoff = timeoff => ({
  type: DELETE_TIMEOFF,
  payload: { timeoff }
})

export const addTimeoff = (timeoff, actions) => {
  return {
    type: ADD_TIMEOFF,
    payload: { timeoff, actions },
  }
}

export const editTimeoff = (id, timeoff, actions) => {
  return {
    type: EDIT_TIMEOFF,
    payload: { id, timeoff, actions },
  }
}

export const newTimeoffModal = () => ({
  type: 'NEW_TIMEOFF_MODAL'
})

export const deleteTimeoffModal = () => ({
  type: 'DELETE_TIMEOFF_MODAL'
})

export const editTimeoffModal = () => ({
  type: 'EDIT_TIMEOFF_MODAL'
})
