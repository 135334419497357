import React from 'react'
import { useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap'
import { getPrice, usFormat } from 'utils/common';
import { Link } from "react-router-dom";
import moment from 'moment';

function Subscription() {
  const { tenant } = useSelector(state => ({
    tenant: state.tenants.tenant,
    loading: state.tenants.loading
  }));

  if (!tenant)
    return null

  const type = (tenant.is_subscription_active || tenant.is_trial_active) ? "primary" : tenant.is_subscription_setup ? "danger" : "warning"

  return (
    <Card outline color={type} className="border">
      <CardHeader className="bg-transparent d-flex justify-content-between">
        <h5 className={`my-0 text-${type}`}><i className='bx bx-dollar-circle font-size-18 me-2' />{tenant.plan.name}</h5>
        <CardTitle>{tenant.is_subscription_active ? "Active" : tenant.is_trial_active ? "Trial" : tenant.is_subscription_setup ? "Disabled" : "Trial Expired"}</CardTitle>
      </CardHeader>

      <CardBody className='pt-0'>
        <Row className='my-1'>
          <Col xs="12" md="6">
            <Row className='mb-2'>
              {
                tenant.is_subscription_active &&
                <Col>
                  <CardTitle>Started on</CardTitle>

                  <p className='mb-0 text-muted font-size-14'>{moment(tenant.subscription_start_date, "YYYY-MM-DDThh:mm:ssZ").format("MMM DD, YYYY")}</p>
                </Col>
              }

              <Col>
                <CardTitle>Price</CardTitle>

                <p className='mb-0 text-muted font-size-16'>{usFormat(getPrice(tenant))}</p>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col xs="12" md="6">
            <CardTitle>Capacity</CardTitle>

            <Row>
              <Col>
                <p className='font-size-14'>Allowed: {tenant.seats_allowed}</p>
              </Col>

              <Col>
                <p className='font-size-14'>Availed: {tenant.seats_availed}</p>
              </Col>
            </Row>
          </Col>

          <Col xs="12" md="6" className='d-flex justify-content-end align-items-end'>
            <Link
              role='button'
              to="/settings/subscriptions"
              className={`btn btn-${type} px-4 float-end`}
            >
              {tenant.is_subscription_setup ? "Manage" : "Upgrade"}
            </Link>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default Subscription
