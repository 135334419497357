import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import * as moment from "moment";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Collapse,
  CardHeader,
  Input
} from "reactstrap";

import DeleteIcon from 'assets/images/Delete.svg'
import EditIcon from 'assets/images/Edit.svg'
import ListIcon from 'assets/images/List.svg'
import ShowIcon from 'assets/images/Show.svg'
import DollarIcon from 'assets/images/dollar.svg'

import { Menu, MenuItem } from '@szhsin/react-menu';
import { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../components/Common/DeleteModal";
import {
  getProjects as onGetProjects,
  deleteProject as onDeleteProject,
  deleteProjectModal,
  editProjectModal,
  newProjectModal,
  editProject
} from "store/projects/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setLoader } from "utils/common";
import Edit from './Edit'
import Create from './Create'
import BootstrapTable from "react-bootstrap-table-next";

let defaultHash = {
  'Processing': [],
  'To Do': [],
  'Working': [],
  'Complete': [],
  'Rejected': [],
}

const EcommerceProjects = props => {
  const dispatch = useDispatch();

  const { projectWithPagy, loading, deleteModal } = useSelector(state => ({
    projectWithPagy: state.projects.projectWithPagy,
    loading: state.projects.loading,
    deleteModal: state.projects.delete,
  }));
  let { results = [] } = projectWithPagy

  const [project, setProject] = useState(null);
  const [projects, setProjects] = useState([]);
  const [projectsHash, setProjectHash] = useState({ ...defaultHash })
  let [controls, changeControls] = useState({
    'Processing': { color: 'warning', isOpen: true },
    'To Do': { color: 'warning', isOpen: true },
    'Working': { color: 'info', isOpen: true },
    'Complete': { color: 'primary', isOpen: true },
    'Rejected': { color: 'danger', isOpen: true },
  })
  const [searchParams, setSearchParams] = useState({
    search: '',
  })

  const pagination = {
    sizePerPage: projectWithPagy.page_size,
    totalSize: projectWithPagy.total,
    page: projectWithPagy.page,
    is_next: projectWithPagy?.links?.next != null
  }

  /** set Date formate */
  const handleValidDate = date => {
    const date1 = date ? moment(new Date(date)).format("DD MMM Y") : "N/A";
    return date1;
  };

  useEffect(() => {
    let { page } = pagination
    let _defaultHash = {
      'Processing': [],
      'To Do': [],
      'Working': [],
      'Complete': [],
      'Rejected': [],
    }
    let _projects = []
    if (page == 1) {
      _projects = results
    }
    else if (results.length > 0) {
      _projects = [...projects, ...results]
    }

    _projects.forEach(_project => {
      _defaultHash[_project.status].push(_project)
    })
    setProjectHash(_defaultHash)
    setProjects(_projects)

  }, [results]);

  const COLS = [
    {
      text: "id",
      dataField: "id",
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      text: "No",
      dataField: "index",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row, rowIndex) => <>{rowIndex + 1}</>,
    },
    {
      dataField: "project_name",
      text: "Project Name",
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{`${row.project_name}`}</p>
        </>
      ),
    },
    {
      dataField: "project_start_date",
      text: "Start Date",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.project_start_date),
    },
    {
      dataField: "project_end",
      text: "End Date",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.project_end),
    },
    {
      dataField: "total_hours",
      text: "Working Time",
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{`${row.total_hours} Hours`}</p>
        </>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cellContent, row) => (
        <div style={{ width: '70%' }} className="mb-1">
          <Input
            name="status"
            type="select"
            className="form-select"
            onChange={event => {
              dispatch(editProject(row.id, { status: event.target.value }))
            }}
            value={row.status || ""}>
            <option value="Processing">Processing</option>
            <option value="To Do">To Do</option>
            <option value="Working">Working</option>
            <option value="Complete">Complete</option>
            <option value="Rejected">Rejected</option>
          </Input>
        </div>
      ),
    },
    {
      dataField: "menu",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, project) => (
        <Menu menuButton={<i className="mdi mdi-dots-horizontal font-size-18" />} transition>
          <MenuItem
            onClick={() => props.history.push(`projects/${project.id}`)}
          >

            <img
              src={ShowIcon}
              className="me-4"
              height="20"
              width="20"
            />

            Show
          </MenuItem>
          <MenuItem
            onClick={() => handleEditProject(project)}
          >
            <img
              src={EditIcon}
              className="me-4"
              height="20"
              width="20"
            />

            Edit
          </MenuItem>
          <MenuItem
            onClick={() => props.history.push(`projects/${project.id}/tasks`)}
          >
            <img
              src={ListIcon}
              className="me-4"
              height="20"
              width="20"
            />
            Tasks
          </MenuItem>
          <MenuItem
            onClick={() => props.history.push(`projects/${project.id}/expenses`)}
          >
            <img
              src={DollarIcon}
              className="me-4"
              height="20"
              width="20"
            />
            Expenses
          </MenuItem>
          <MenuItem
            onClick={() => onClickDelete(project)}
          >
            <img
              src={DeleteIcon}
              className="me-4"
              height="20"
              width="20"
            />

            Delete
          </MenuItem>
        </Menu>
      ),
    },
  ];

  //delete project

  const onClickDelete = (project) => {
    setProject(project);
    dispatch(deleteProjectModal())
  };

  const handleCreateProject = () => {
    dispatch(newProjectModal())
  };

  const handleDeleteProject = () => {
    dispatch(onDeleteProject(project))
  }

  const { SearchBar } = Search;

  function init() {
    dispatch(onGetProjects(true));
  }

  useEffect(() => {
    init()
  }, []);

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  // eslint-disable-next-line no-unused-vars
  const handlePageChange = (page) => {
    dispatch(onGetProjects(false, { page, ...searchParams }));
  };

  function loadMore() {
    handlePageChange(pagination.page + 1)
  }

  let { is_next } = pagination

  const handleEditProject = project => {
    setProject(project)
    dispatch(editProjectModal())
  };

  let handleSearch = (key, value) => {
    let _searchParams = { ...searchParams, [key]: value }
    setSearchParams(_searchParams)
    dispatch(onGetProjects(false, _searchParams));
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteProject}
        onCloseClick={() => dispatch(deleteProjectModal())}
      />

      <Edit project={project} />
      <Create />

      <div className="page-content">
        <MetaTags>
          <title>Projects</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Projects" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col sm="4">
                        <div className="search-box ms-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar
                              searchText={searchParams.search}
                              onSearch={text => handleSearch("search", text)} />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                      <Col sm="8">
                        <div className="text-sm-end">
                          <Button
                            type="button"
                            color="success"
                            className="btn-rounded  mb-2 me-2"
                            onClick={handleCreateProject}
                          >
                            <i className="mdi mdi-plus me-1" />
                            New Project
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    {
                      Object.entries(projectsHash).map((data, index) => {
                        return (
                          <Card key={`table-${index}`}>
                            <CardHeader>
                              <Row>
                                <Col>
                                  <div className="d-flex align-items-center">
                                    <h2 className={`text-${controls[data[0]].color}`}>{data[0]}</h2>
                                    <h5 style={{ marginLeft: '0.5rem' }}>({data[1].length} Task)</h5>
                                  </div>
                                </Col>
                                <Col>
                                  <div onClick={() => { changeControls({ ...controls, [data[0]]: { ...controls[data[0]], isOpen: !controls[data[0]].isOpen } }) }} style={{ float: 'right', cursor: 'pointer' }}>
                                    <p className="font-size-16">
                                      {`Show ${controls[data[0]].isOpen ? 'Less' : 'More'}`}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                            </CardHeader>
                            <CardBody>
                              <Collapse isOpen={controls[data[0]].isOpen}>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        columns={COLS}
                                        data={data[1]}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        noDataIndication={"No Data found"}
                                        defaultSorted={defaultSorted}
                                        classes={"table align-middle table-nowrap"}
                                        keyField="id"
                                      />
                                    </div>

                                  </Col>
                                </Row>
                              </Collapse>
                            </CardBody>
                          </Card>
                        )
                      })
                    }

                  </React.Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {
            is_next &&
            <Row>
              <Col xs="12">
                <div className="text-center mb-3">
                  <div style={{ cursor: "pointer" }} onClick={() => loadMore()} className="text-success">
                    <i className="bx bx-hourglass bx-spin me-2" />
                    Load more
                  </div>
                </div>
              </Col>
            </Row>
          }
        </Container>
      </div>
    </React.Fragment>
  );
};

EcommerceProjects.propTypes = {
  projects: PropTypes.array,
  onGetProjects: PropTypes.func,
  onAddNewProject: PropTypes.func,
  onDeleteProject: PropTypes.func,
  onUpdateProject: PropTypes.func,
};

export default EcommerceProjects;
