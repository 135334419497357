import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'

import { editPayrollReportModal, editPayrollReport } from 'store/payrollReports/actions'
import moment from "moment";

function index({ payrollReport: target }) {
  let dispatch = useDispatch()
  const { edit: modal } = useSelector((state) => state.payrollReports);
  let [payrollReport, setPayrollReport] = useState()

  useEffect(() => {
    if (modal) {
      setPayrollReport(target)
    }
  }, [modal])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      from_date: (payrollReport && payrollReport.from_date && moment(payrollReport.from_date, "YYYY-MM-DDThh:mm:ssZ").format("YYYY-MM-DD")) || '',
      to_date: (payrollReport && payrollReport.to_date && moment(payrollReport.to_date, "YYYY-MM-DDThh:mm:ssZ").format("YYYY-MM-DD")) || '',
      user: (payrollReport && payrollReport.user) || null,
      projects: (payrollReport && payrollReport.projects) || [],
    },
    validationSchema: Yup.object({
      user: Yup.mixed().required("Please Select User"),
      from_date: Yup.string().required("Enter Start Date"),
      to_date: Yup.string().required("Enter To Date"),
    }),
    onSubmit: (values, actions) => {
      dispatch(editPayrollReport(payrollReport.id, { ...values, user: values.user.id, projects: values.projects.map(_project => _project.id) }, actions))
    },
  });



  return (
    <React.Fragment>
      <Modal isOpen={modal} toggle={() => dispatch(editPayrollReportModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(editPayrollReportModal())}>
          Edit PayrollReport
        </ModalHeader>
        <ModalBody>
          <Form
            validation={validation}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
