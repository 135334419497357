import {
  GET_DISTRIBUTIONS_FAIL,
  GET_DISTRIBUTIONS_SUCCESS,
  GET_DISTRIBUTIONS,
  ADD_DISTRIBUTION,
  EDIT_DISTRIBUTION,
  DELETE_DISTRIBUTION,
} from "./actionTypes";

const INIT_STATE = {
  distributionWithPagy: {
    total: 0,
    page: 1,
    page_size: 10,
    results: []
  },
  new: false,
  edit: false,
  delete: false,
  loading: false,
};

const distributions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DISTRIBUTIONS:
      return {
        ...state,
        new: false,
        edit: false,
        delete: false,
        loading: action.payload.loading
      };

    case GET_DISTRIBUTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        distributionWithPagy: action.payload,
      };

    case ADD_DISTRIBUTION:
      return {
        ...state,
        loading: true
      };

    case EDIT_DISTRIBUTION:
      return {
        ...state,
        loading: true
      };

    case DELETE_DISTRIBUTION:
      return {
        ...state,
        loading: true
      };

    case GET_DISTRIBUTIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "NEW_DISTRIBUTION_MODAL":
      return {
        ...state,
        new: !state.new
      };

    case "DELETE_DISTRIBUTION_MODAL":
      return {
        ...state,
        delete: !state.delete
      };

    case "EDIT_DISTRIBUTION_MODAL":
      return {
        ...state,
        edit: !state.edit
      };
    default:
      return state;
  }
};

export default distributions;
