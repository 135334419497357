import { map } from 'lodash';
import moment from 'moment';
import React from 'react'
import { Col, Row } from 'reactstrap';
import DeleteIcon from 'assets/images/Delete.svg'
import EditIcon from 'assets/images/Edit.svg'
import { fromMinutes } from '../helper';

function capitalize(word) {
  const lower = word.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
}

function card({ data, handleEditTimesheet, onClickDelete }) {
  let dataSample = data[0].user
  let total_hours = data.reduce((total, _object) => (total + parseFloat(_object.total_hours)), 0)

  return (
    <div className="board-list">
      <div className="list-title">
        {`${capitalize(dataSample.type)}: ${dataSample.profile.first_name[0]}. ${dataSample.profile.last_name}`}
      </div>
      <div className="items-holder">
        {
          map(data, row => {
            return (
              <div key={row.id} className="card-new">
                <Row>
                  <Col xs="5">
                    <p>Date</p>
                    <p>Start Time</p>
                    <p>End Time</p>
                    <p>Time Out</p>
                  </Col>
                  <Col xs="7">
                    <p>{moment(row.date).format("DD MMM, YYYY")}</p>
                    <p>{moment(row.start_time, "hh:mm:ss").format("hh:mm A")}</p>
                    <p>{moment(row.end_time, "hh:mm:ss").format("hh:mm A")}</p>
                    <p>{fromMinutes(row.time_out)}</p>
                  </Col>
                </Row>

                <Row>
                  <Col xs="5">
                    <span
                      onClick={() => handleEditTimesheet(row)}
                      className="me-2">
                      <img
                        src={EditIcon}
                        height="20"
                        width="20"
                      />
                    </span>
                    <span
                      onClick={() => onClickDelete(row)}
                      className="me-2">
                      <img
                        src={DeleteIcon}
                        height="20"
                        width="20"
                      />
                    </span>
                  </Col>

                  <Col xs="7">
                    <div className="holder text-white bg-container" style={{ float: 'right' }}>
                      Total Hours {parseFloat(row.total_hours)}
                    </div>
                  </Col>
                </Row>
              </div>
            )
          })
        }
      </div>
      <div className="add-card">
        <Row>
          <Col xs="5">
            <h5>Total Hours</h5>
          </Col>

          <Col xs="7">
            <h5 className="text-primary">{parseFloat(total_hours)} Hours</h5>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default card
