import React, { useEffect } from "react";
import {
  Col,
  Row,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap";
import { FileUploader } from "react-drag-drop-files";
import { setTabBarLoader } from "utils/common";

import { deleteSubcontractorFile } from 'api/v1'
import { toast } from "react-toastify";

function index({ target = null, validation }) {
  let [avatar, setAvatar] = React.useState()
  const [loading, setLoading] = React.useState(false)

  useEffect(() => {
    setTabBarLoader(loading)
  }, [loading])

  async function deleteFile(_file, _index) {
    if (_file.id) {
      setLoading(true)

      try {
        await deleteSubcontractorFile(target.id, _file.id)
      } catch (error) {
        toast.error("Something went wrong deleting the file")
        setLoading(false)
        return
      }

      setLoading(false)
    }
    validation.setFieldValue('files', validation.values.files.filter((_, __index) => __index != _index))
  }

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <Row>
        <Col xs="12" md="6" className="mb-3">
          <Label className="form-label">First Name</Label>
          <Input
            name="first_name"
            type="text"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.first_name || ""}
            invalid={
              validation.touched.first_name && validation.errors.first_name ? true : false
            }
          />
          {validation.touched.first_name && validation.errors.first_name ? (
            <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
          ) : null}
        </Col>

        <Col xs="12" md="6" className="mb-3">
          <Label className="form-label">Last Name</Label>
          <Input
            name="last_name"
            type="text"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.last_name || ""}
            invalid={
              validation.touched.last_name && validation.errors.last_name ? true : false
            }
          />
          {validation.touched.last_name && validation.errors.last_name ? (
            <FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
          ) : null}
        </Col>

        <Col xs="12" className="mb-3">
          <Label className="form-label">Email</Label>
          <Input
            name="email"
            type="text"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.email || ""}
            invalid={
              validation.touched.email && validation.errors.email ? true : false
            }
          />
          {validation.touched.email && validation.errors.email ? (
            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
          ) : null}
        </Col>

        <Col xs="12" md="6" className="mb-3">
          <Label className="form-label">Business Phone</Label>
          <Input
            name="business_phone"
            type="text"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.business_phone || ""}
            invalid={
              validation.touched.business_phone && validation.errors.business_phone ? true : false
            }
          />
          {validation.touched.business_phone && validation.errors.business_phone ? (
            <FormFeedback type="invalid">{validation.errors.business_phone}</FormFeedback>
          ) : null}
        </Col>

        <Col xs="12" md="6" className="mb-3">
          <Label className="form-label">Company Name</Label>
          <Input
            name="company_name"
            type="text"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.company_name || ""}
            invalid={
              validation.touched.company_name && validation.errors.company_name ? true : false
            }
          />
          {validation.touched.company_name && validation.errors.company_name ? (
            <FormFeedback type="invalid">{validation.errors.company_name}</FormFeedback>
          ) : null}
        </Col>

        <div className="mb-3">
          <Label className="form-label">Address</Label>
          <Input
            name="address"
            type="textarea"
            rows="3"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.address || ""}
            invalid={
              validation.touched.address && validation.errors.address ? true : false
            }
          />
          {validation.touched.address && validation.errors.address ? (
            <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
          ) : null}
        </div>

        <Col xs="12" md="6" className="mb-3">
          <Label className="form-label">City</Label>
          <Input
            name="city"
            type="text"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.city || ""}
            invalid={
              validation.touched.city && validation.errors.city ? true : false
            }
          />
          {validation.touched.city && validation.errors.city ? (
            <FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
          ) : null}
        </Col>

        <Col xs="12" md="6" className="mb-3">
          <Label className="form-label">State</Label>
          <Input
            name="state"
            type="text"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.state || ""}
            invalid={
              validation.touched.state && validation.errors.state ? true : false
            }
          />
          {validation.touched.state && validation.errors.state ? (
            <FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
          ) : null}
        </Col>

        <Col xs="12" md="6" className="mb-3">
          <Label className="form-label">Country</Label>
          <Input
            name="country"
            type="text"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.country || ""}
            invalid={
              validation.touched.country && validation.errors.country ? true : false
            }
          />
          {validation.touched.country && validation.errors.country ? (
            <FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
          ) : null}
        </Col>

        <Col xs="12" md="6" className="mb-3">
          <Label className="form-label">Zip Code</Label>
          <Input
            name="zip_code"
            type="text"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.zip_code || ""}
            invalid={
              validation.touched.zip_code && validation.errors.zip_code ? true : false
            }
          />
          {validation.touched.zip_code && validation.errors.zip_code ? (
            <FormFeedback type="invalid">{validation.errors.zip_code}</FormFeedback>
          ) : null}
        </Col>

        <Col xs="12" md="6" className="mb-3">
          <Label className="form-label">Profession</Label>{' '}
          <Input
            name="profession"
            type="text"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.profession || ""}
            invalid={
              validation.touched.profession && validation.errors.profession ? true : false
            }
          />
          {validation.touched.profession && validation.errors.profession ? (
            <FormFeedback type="invalid">{validation.errors.profession}</FormFeedback>
          ) : null}
        </Col>

        <Col xs="12" md="6" className="mb-3">
          <Label className="form-label">Id No.</Label>{' '}
          <Input
            name="id_no"
            type="text"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.id_no || ""}
            invalid={
              validation.touched.id_no && validation.errors.id_no ? true : false
            }
          />
          {validation.touched.id_no && validation.errors.id_no ? (
            <FormFeedback type="invalid">{validation.errors.id_no}</FormFeedback>
          ) : null}
        </Col>

        <div className="mb-3">
          <Label className="form-label">Avatar</Label>
          <Input
            name="avatar"
            type="file"
            onChange={event => {
              validation.setFieldValue('avatar', event.target.files[0])
              if (event.target.files.length > 0) {
                var reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onloadend = function (e) {
                  setAvatar(reader.result)
                }

              } else {
                setAvatar(null)
              }
            }}
            accept="image/*"
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.avatar && validation.errors.avatar ? true : false
            }
          />
          {validation.touched.avatar && validation.errors.avatar ? (
            <FormFeedback type="invalid">{validation.errors.avatar}</FormFeedback>
          ) : null}

          {
            (avatar || validation.values.avatar) &&
            <div className="table-avatar-lg mt-2">
              <img
                src={avatar || validation.values.avatar}
                alt={validation.values.first_name[0]}
              />
            </div>
          }
        </div>

        <Col xs="12" className="mb-3">
          <Label className="form-label">Files</Label>

          <FileUploader
            multiple={true}
            handleChange={_files => validation.setFieldValue('files', [...validation.values.files, ..._files])}
            name="files"
            types={['PDF']}
          />

          <div className="mt-3">
            {
              validation.values.files.map((_file, _index) => <div key={`file-${_index + 1}`}
                className="mt-1 d-flex justify-content-between align-items-center">
                {
                  _file.id ?
                    <a target="_blank" rel="noreferrer" href={_file.file}>{_file.name || `Document ${_index + 1}`}</a>
                    : <span>{_file.name}</span>
                }
                <button
                  onClick={() => deleteFile(_file, _index)}
                  type="button"
                  disabled={loading}
                  className="btn btn-outline-danger rounded-circle"
                >
                  <i className="mdi mdi-trash-can-outline" />
                </button>
              </div>
              )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="text-end">
            <button
              type="submit"
              className="btn btn-success save-customer"
            >
              Save
            </button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default index
