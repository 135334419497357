import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'

import { editLocationModal, editLocation } from 'store/locations/actions'

function index({ location: target }) {
  let dispatch = useDispatch()
  const { edit: modal } = useSelector((state) => state.locations);
  let [location, setLocation] = useState()

  useEffect(() => {
    if (modal) {
      setLocation(target)
    }
  }, [modal])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      shelf: (location && location.shelf) || '',
      location: (location && location.location) || ''
    },
    validationSchema: Yup.object({
      shelf: Yup.string().required("Please Enter Shelf"),
      location: Yup.string().required("Please Enter Location")
    }),
    onSubmit: (values, actions) => {
      dispatch(editLocation(location.id, values, actions))
    },
  });



  return (
    <React.Fragment>
      <Modal isOpen={modal} toggle={() => dispatch(editLocationModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(editLocationModal())}>
          Edit Location
        </ModalHeader>
        <ModalBody>
          <Form
            validation={validation}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
