import {
  GET_CUSTOMERS,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_FAIL,
  DELETE_CUSTOMER,
  ADD_CUSTOMER,
  EDIT_CUSTOMER
} from "./actionTypes"

export const getCustomers = (loading = false, params = {}) => ({
  type: GET_CUSTOMERS,
  payload: { loading, params }
})

export const getCustomersSuccess = customers => ({
  type: GET_CUSTOMERS_SUCCESS,
  payload: customers,
})

export const getCustomersFail = error => ({
  type: GET_CUSTOMERS_FAIL,
  payload: error,
})

export const deleteCustomer = customer => ({
  type: DELETE_CUSTOMER,
  payload: { customer }
})

export const addCustomer = (customer, actions) => {
  return {
    type: ADD_CUSTOMER,
    payload: { customer, actions },
  }
}

export const editCustomer = (id, customer, actions) => {
  return {
    type: EDIT_CUSTOMER,
    payload: { id, customer, actions },
  }
}

export const newCustomerModal = () => ({
  type: 'NEW_CUSTOMER_MODAL'
})

export const deleteCustomerModal = () => ({
  type: 'DELETE_CUSTOMER_MODAL'
})

export const editCustomerModal = () => ({
  type: 'EDIT_CUSTOMER_MODAL'
})
