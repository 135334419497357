import {
  GET_TIMESHEET_REPORTS,
  GET_TIMESHEET_REPORTS_SUCCESS,
  GET_TIMESHEET_REPORTS_FAIL,
  DELETE_TIMESHEET_REPORT,
  ADD_TIMESHEET_REPORT,
  EDIT_TIMESHEET_REPORT
} from "./actionTypes"

export const getTimesheetReports = (loading = false, params = {}) => ({
  type: GET_TIMESHEET_REPORTS,
  payload: { loading, params }
})

export const getTimesheetReport = (id, history) => ({
  type: 'GET_TIMESHEET_REPORT',
  payload: { id, history }
})

export const getTimesheetReportsSuccess = timesheetReports => ({
  type: GET_TIMESHEET_REPORTS_SUCCESS,
  payload: timesheetReports,
})

export const getTimesheetReportsFail = error => ({
  type: GET_TIMESHEET_REPORTS_FAIL,
  payload: error,
})

export const getTimesheetReportSuccess = timesheetReport => ({
  type: 'GET_TIMESHEET_REPORT_SUCCESS',
  payload: timesheetReport,
})

export const getTimesheetReportFail = () => ({
  type: 'GET_TIMESHEET_REPORT_FAIL',
})

export const deleteTimesheetReport = timesheetReport => ({
  type: DELETE_TIMESHEET_REPORT,
  payload: { timesheetReport }
})

export const generateTimesheetReport = timesheetReport => ({
  type: 'GENERATE_TIMESHEET_REPORT',
  payload: { timesheetReport }
})

export const addTimesheetReport = (timesheetReport, actions) => {
  return {
    type: ADD_TIMESHEET_REPORT,
    payload: { timesheetReport, actions },
  }
}

export const editTimesheetReport = (id, timesheetReport, actions) => {
  return {
    type: EDIT_TIMESHEET_REPORT,
    payload: { id, timesheetReport, actions },
  }
}

export const setGeneratableTimesheetReport = (timesheetReport) => {
  return {
    type: 'SET_GENERATABLE_TIMESHEET_REPORT',
    payload: { timesheetReport },
  }
}

export const newTimesheetReportModal = () => ({
  type: 'NEW_TIMESHEET_REPORT_MODAL'
})

export const deleteTimesheetReportModal = () => ({
  type: 'DELETE_TIMESHEET_REPORT_MODAL'
})

export const generateTimesheetReportModal = () => ({
  type: 'GENERATE_TIMESHEET_REPORT_MODAL'
})

export const editTimesheetReportModal = () => ({
  type: 'EDIT_TIMESHEET_REPORT_MODAL'
})
