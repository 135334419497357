import {
  GET_TASKS,
  GET_TASKS_SUCCESS,
  GET_TASKS_FAIL,
  DELETE_TASK,
  ADD_TASK,
  EDIT_TASK
} from "./actionTypes"

export const getTasks = (projectId = null, loading = false, params = {}) => ({
  type: GET_TASKS,
  payload: { projectId, loading, params }
})

export const getTask = (projectId, loading = false, id, history = null) => ({
  type: 'GET_TASK',
  payload: { loading, projectId, id, history }
})

export const getTasksSuccess = tasks => ({
  type: GET_TASKS_SUCCESS,
  payload: tasks,
})

export const getTasksFail = error => ({
  type: GET_TASKS_FAIL,
  payload: error,
})

export const deleteTask = (projectId, task, params = {}) => ({
  type: DELETE_TASK,
  payload: { projectId, task, params }
})

export const addTask = (projectId, task, actions) => {
  return {
    type: ADD_TASK,
    payload: { projectId, task, actions },
  }
}

export const editTask = (projectId, id, task, actions = null, params = {}) => {
  return {
    type: EDIT_TASK,
    payload: { id, projectId, task, actions, params },
  }
}

export const deleteTaskImage = (projectId, taskId, id) => ({
  type: 'DELETE_TASK_IMAGE',
  payload: { projectId, taskId, id }
})

export const setTaskDetail = taskDetail => {
  return ({
    type: 'SET_TASK_DETAIL',
    payload: taskDetail,
  })
}

export const setTaskImages = images => ({
  type: 'SET_TASK_IMAGES',
  payload: images,
})

export const getTaskImages = (projectId, taskId) => {
  return {
    type: 'GET_TASK_IMAGES',
    payload: { projectId, taskId },
  }
}

export const newTaskModal = () => ({
  type: 'NEW_TASK_MODAL'
})

export const deleteTaskModal = () => ({
  type: 'DELETE_TASK_MODAL'
})

export const editTaskModal = () => ({
  type: 'EDIT_TASK_MODAL'
})
