import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';
import {
  GET_PAYROLL_REPORTS,
  DELETE_PAYROLL_REPORT,
  ADD_PAYROLL_REPORT,
  EDIT_PAYROLL_REPORT
} from "./actionTypes";

import {
  getPayrollReportsSuccess,
  getPayrollReports,
  getPayrollReportsFail,
  deletePayrollReportModal,
  generatePayrollReportModal,
  setGeneratablePayrollReport,
  getPayrollReportFail,
  getPayrollReportSuccess
} from "./actions";

import {
  getPayrollReports as getPayrollReportsAPI,
  getPayrollReport as getPayrollReportAPI,
  deletePayrollReport as deletePayrollReportAPI,
  generatePayrollReport as generatePayrollReportAPI,
  addPayrollReport as addPayrollReportAPI,
  editPayrollReport as editPayrollReportAPI,
} from "api/v1";


function* fetchPayrollReports({ payload: { params } }) {
  try {
    const response = yield call(getPayrollReportsAPI, params);
    yield put(getPayrollReportsSuccess(response));
  } catch (error) {
    yield put(getPayrollReportsFail("Something went wrong!"))
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* fetchPayrollReport({ payload: { id, history } }) {
  try {
    const response = yield call(getPayrollReportAPI, id);
    yield put(getPayrollReportSuccess(response));
  } catch (error) {
    yield put(getPayrollReportFail("Object not found"))
    if (history) history.goBack()
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* addPayrollReport({ payload: { payrollReport, actions } }) {
  try {
    let _payrollReport = yield call(addPayrollReportAPI, payrollReport)
    toast.success("PayrollReport Created!");
    yield put(getPayrollReports(true));
    actions.resetForm()
    // do here
    yield put(setGeneratablePayrollReport(_payrollReport))
    yield put(generatePayrollReportModal())

  } catch ({ errors }) {
    yield put(getPayrollReportsFail("Creation failed!"))
    if (errors) {
      actions.setErrors(errors)
    }
    if (errors?.non_field_errors)
      toast.error(errors.non_field_errors[0]);
    else
      toast.error("Something went wrong!");
  }
}

function* editPayrollReport({ payload: { id, payrollReport, actions } }) {
  try {
    let _payrollReport = yield call(editPayrollReportAPI, id, payrollReport)
    toast.success("PayrollReport Updated!");
    yield put(getPayrollReports(true));
    // do here
    yield put(setGeneratablePayrollReport(_payrollReport))
    yield put(generatePayrollReportModal())

  } catch ({ errors }) {
    yield put(getPayrollReportsFail("Creation failed!"))
    if (errors) {
      actions.setErrors(errors)
    }
    if (errors?.non_field_errors)
      toast.error(errors.non_field_errors[0]);
    else
      toast.error("Something went wrong!");
  }
}

function* deletePayrollReport({ payload: { payrollReport } }) {
  try {
    yield call(deletePayrollReportAPI, payrollReport.id);
    yield put(deletePayrollReportModal())
    yield put(getPayrollReports(true));
    toast.warn("PayrollReport Deleted!");
  } catch (error) {
    yield put(getPayrollReportsFail(""))
    toast.error("Something went wrong!");
  }
}

function* generatePayrollReport({ payload: { payrollReport } }) {
  try {
    yield call(generatePayrollReportAPI, payrollReport.id);
    yield put(generatePayrollReportModal())
    yield put(getPayrollReports(true));
    toast.success("PayrollReport generated!");
  } catch (error) {
    yield put(getPayrollReportsFail(""))
    toast.error("Something went wrong!");
  }
}

function* payrollReportSaga() {
  yield takeEvery('GET_PAYROLL_REPORT', fetchPayrollReport);
  yield takeEvery(GET_PAYROLL_REPORTS, fetchPayrollReports);
  yield takeEvery(DELETE_PAYROLL_REPORT, deletePayrollReport);
  yield takeEvery('GENERATE_PAYROLL_REPORT', generatePayrollReport);
  yield takeEvery(ADD_PAYROLL_REPORT, addPayrollReport);
  yield takeEvery(EDIT_PAYROLL_REPORT, editPayrollReport);
}

export default payrollReportSaga;
