import React from "react";

import { Close } from "../Svgs";
import { Link } from "react-router-dom";
import styles from "./css/LeftNavbar.module.css";

const navbarLinks = [
  {
    urlTxt: "why-allproplus",
    hrefTxt: "Why AllProPlus",
  },
  {
    hrefTxt: "Pricing",
    urlTxt: "pricing",
  },
  {
    hrefTxt: "Resources",
    urlTxt: "resources",
  },
];
const LeftNavbar = (props) => {
  return (
    <header className={styles.navbarCont}>
      <div className={styles.navbarLogoCont}>
        <Link to="/">
          <span className={styles.navbarLogoImage}>
            <img src="/assets/logo/allpromana.png" />
          </span>
          <span className={styles.navbarLogoText}>AllPromana</span>
        </Link>
        <span className={styles.closeIcon} onClick={props.setLeftNavbar}>
          <Close style={{ color: "rgba(255,255,255,1)" }}></Close>
        </span>
      </div>
      <div className={styles.navbarLinks}>
        <ul>
          {navbarLinks.map((navLink, index) => (
            <li key={index}>
              <a href={`${process.env.REACT_APP_WEB_HOST}/${navLink["urlTxt"]}`}>
                <span>{navLink["hrefTxt"]}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </header>
  );
};

export default LeftNavbar;
