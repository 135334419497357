import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import { setTabBarLoader } from "utils/common";
import { editTenant, getTenant } from "store/actions";


const Company = () => {
  let [avatar, setAvatar] = useState()
  const { tenant: company, loading } = useSelector(state => ({
    loading: state.tenants.loading,
    tenant: state.tenants.tenant
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTenant());
  }, [])

  useEffect(() => {
    setTabBarLoader(loading)
  }, [loading]) //loading

  const form = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (company && company.name) || '',
      email: (company && company.email) || '',
      city: (company && company.city) || '',
      state: (company && company.state) || '',
      phone: (company && company.phone) || '',
      zip_code: (company && company.zip_code) || '',
      street: (company && company.street) || '',
      avatar: (company && company.avatar) || null,
    },
    validationSchema: Yup.object({
      city: Yup.string().required("Please Enter Your City"),
      phone: Yup.string().required("Please Enter Your Phone number"),
      state: Yup.string().required("Please Enter Your State"),
      zip_code: Yup.string().required("Please Enter Your Zip code"),
      street: Yup.string().required("Please Enter Your Address")
    }),
    onSubmit: (values, actions) => {
      dispatch(editTenant(values, actions))
    }
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Company | AllPromana</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb back={true} title="Settings" breadcrumbItem="Company" />

          <Row>
            <Col lg="12">
              <Card>
                <h5 className="mt-3 px-4">Company Details</h5>
                <CardBody>
                  <Form onSubmit={e => {
                    e.preventDefault();
                    form.handleSubmit();
                    return false;
                  }}>
                    <Row form>
                      <Col className="col-12">
                        <div className="mb-3">
                          <Label className="form-label">Name</Label>
                          <Input
                            name="name"
                            disabled
                            type="text"
                            value={form.values.name || "No name added"}
                          />
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            disabled
                            type="text"
                            value={form.values.email || "No email added"}
                          />
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Phone</Label>
                          <Input
                            name="phone"
                            type="text"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={form.values.phone || ""}
                            invalid={
                              form.touched.phone && form.errors.phone ? true : false
                            }
                          />
                          {form.touched.phone && form.errors.phone ? (
                            <FormFeedback type="invalid">{form.errors.phone}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Address</Label>
                          <Input
                            name="street"
                            type="textarea"
                            rows="3"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={form.values.street || ""}
                            invalid={
                              form.touched.street && form.errors.street ? true : false
                            }
                          />
                          {form.touched.street && form.errors.street ? (
                            <FormFeedback type="invalid">{form.errors.street}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">City</Label>
                          <Input
                            name="city"
                            type="text"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={form.values.city || ""}
                            invalid={
                              form.touched.city && form.errors.city ? true : false
                            }
                          />
                          {form.touched.city && form.errors.city ? (
                            <FormFeedback type="invalid">{form.errors.city}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">State</Label>
                          <Input
                            name="state"
                            type="text"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={form.values.state || ""}
                            invalid={
                              form.touched.state && form.errors.state ? true : false
                            }
                          />
                          {form.touched.state && form.errors.state ? (
                            <FormFeedback type="invalid">{form.errors.state}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Zip Code</Label>
                          <Input
                            name="zip_code"
                            type="text"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={form.values.zip_code || ""}
                            invalid={
                              form.touched.zip_code && form.errors.zip_code ? true : false
                            }
                          />
                          {form.touched.zip_code && form.errors.zip_code ? (
                            <FormFeedback type="invalid">{form.errors.zip_code}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Avatar</Label>
                          <Input
                            name="avatar"
                            type="file"
                            onChange={event => {
                              form.setFieldValue('avatar', event.target.files[0])
                              if (event.target.files.length > 0) {
                                var reader = new FileReader();
                                reader.readAsDataURL(event.target.files[0]);
                                reader.onloadend = function (e) {
                                  setAvatar(reader.result)
                                }

                              } else {
                                setAvatar(null)
                              }
                            }}
                            accept="image/*"
                            onBlur={form.handleBlur}
                            invalid={
                              form.touched.avatar && form.errors.avatar ? true : false
                            }
                          />
                          {form.touched.avatar && form.errors.avatar ? (
                            <FormFeedback type="invalid">{form.errors.avatar}</FormFeedback>
                          ) : null}

                          {
                            (avatar || form.values.avatar) &&
                            <div className="table-avatar-lg mt-2">
                              <img
                                src={avatar || form.values.avatar}
                                alt={form.values.name[0]}
                              />
                            </div>
                          }
                        </div>

                        <div className="text-end mt-2">
                          <button
                            type="submit"
                            disabled={loading}
                            className="btn btn-success save-customer"
                          >
                            {loading ? "Saving" : "Save"} {" "}
                            {loading && <i className="bx bx-hourglass bx-spin font-size-16 align-middle"></i>}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Company);
