import {
  GET_VENDORS_FAIL,
  GET_VENDORS_SUCCESS,
  GET_VENDORS,
  ADD_VENDOR,
  EDIT_VENDOR,
  DELETE_VENDOR,
} from "./actionTypes";

const INIT_STATE = {
  vendorWithPagy: {
    total: 0,
    page: 1,
    page_size: 10,
    results: []
  },
  new: false,
  edit: false,
  delete: false,
  loading: false,
};

const vendors = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_VENDORS:
      return {
        ...state,
        new: false,
        edit: false,
        delete: false,
        loading: action.payload.loading
      };

    case GET_VENDORS_SUCCESS:
      return {
        ...state,
        loading: false,
        vendorWithPagy: action.payload,
      };

    case ADD_VENDOR:
      return {
        ...state,
        loading: true
      };

    case EDIT_VENDOR:
      return {
        ...state,
        loading: true
      };

    case DELETE_VENDOR:
      return {
        ...state,
        loading: true
      };

    case GET_VENDORS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "NEW_VENDOR_MODAL":
      return {
        ...state,
        new: !state.new
      };

    case "DELETE_VENDOR_MODAL":
      return {
        ...state,
        delete: !state.delete
      };

    case "EDIT_VENDOR_MODAL":
      return {
        ...state,
        edit: !state.edit
      };
    default:
      return state;
  }
};

export default vendors;
