import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';
import {
  GET_DISTRIBUTIONS,
  DELETE_DISTRIBUTION,
  ADD_DISTRIBUTION,
  EDIT_DISTRIBUTION
} from "./actionTypes";

import {
  getDistributionsSuccess,
  getDistributions,
  getDistributionsFail,
  deleteDistributionModal
} from "./actions";

import {
  getDistributions as getDistributionsAPI,
  deleteDistribution as deleteDistributionAPI,
  addDistribution as addDistributionAPI,
  editDistribution as editDistributionAPI,
} from "api/v1";


function* fetchDistributions({ payload: { params } }) {
  try {
    const response = yield call(getDistributionsAPI, params);
    yield put(getDistributionsSuccess(response));
  } catch (error) {
    yield put(getDistributionsFail("Something went wrong!"))
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* addDistribution({ payload: { distribution, actions } }) {
  try {
    yield call(addDistributionAPI, distribution)
    toast.success("Distribution Created!");
    yield put(getDistributions(true));
    actions.resetForm()

  } catch ({ errors }) {
    yield put(getDistributionsFail("Creation failed!"))
    if (errors) {
      let { user } = errors
      actions.setErrors({ ...errors, ...user })

    }
    toast.error("Something went wrong!");
  }
}

function* editDistribution({ payload: { id, distribution, actions } }) {
  try {
    yield call(editDistributionAPI, id, distribution)
    toast.success("Distribution Updated!");
    yield put(getDistributions(true));

  } catch ({ errors }) {
    yield put(getDistributionsFail("Creation failed!"))
    if (errors && actions) {
      let { user } = errors
      actions.setErrors({ ...errors, ...user })

    }
    toast.error("Something went wrong!");
  }
}

function* deleteDistribution({ payload: { distribution } }) {
  try {
    yield call(deleteDistributionAPI, distribution.id);
    toast.warn("Distribution Deleted!");
    put(deleteDistributionModal())
    yield put(getDistributions(true));
  } catch (error) {
    yield put(getDistributionsFail(""))
    toast.error("Something went wrong!");
  }
}

function* distributionSaga() {
  yield takeEvery(GET_DISTRIBUTIONS, fetchDistributions);
  yield takeEvery(DELETE_DISTRIBUTION, deleteDistribution);
  yield takeEvery(ADD_DISTRIBUTION, addDistribution);
  yield takeEvery(EDIT_DISTRIBUTION, editDistribution);
}

export default distributionSaga;
