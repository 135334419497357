import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'

import { newSubcontractorModal, addSubcontractor } from 'store/subcontractors/actions'

function index() {
  let dispatch = useDispatch()
  const { new: modal } = useSelector((state) => state.subcontractors);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id_no: '',
      first_name: '',
      last_name: '',
      company_name: '',
      business_phone: '',
      profession: '',
      city: '',
      state: '',
      zip_code: '',
      email: '',
      country: '',
      address: '',
      avatar: null,
      files: []
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please Enter Your First Name"),
      company_name: Yup.string().required("Please Enter Your Company Name"),
      business_phone: Yup.string().required("Please Enter Your Business Phone"),
      avatar: Yup.mixed().required("You need to provide a avatar"),
      profession: Yup.mixed().required("Tell us about your profession"),
      last_name: Yup.string().required("Please Enter Your Last Name"),
      email: Yup.string().required("Please Enter Your Email"),
      city: Yup.string().required("Please Enter Your City"),
      state: Yup.string().required("Please Enter Your State"),
      zip_code: Yup.string().required("Please Enter Your Zip code"),
      address: Yup.string().required("Please Enter Your Address"),
      country: Yup.string().required("Please Enter Your Country"),
    }),
    onSubmit: (values, actions) => {
      dispatch(addSubcontractor({ ...values, user: { email: values.email } }, actions))
    },
  });

  useEffect(() => {
    if (modal) {
      validation.resetForm()
    }
  }, [modal])

  return (
    <React.Fragment>
      <Modal size="lg" isOpen={modal} toggle={() => dispatch(newSubcontractorModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(newSubcontractorModal())}>
          Add Subcontractor
        </ModalHeader>
        <ModalBody>
          <Form
            validation={validation}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
