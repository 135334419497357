import { useState, useEffect, Fragment } from "react";
import { ElementsConsumer } from "@stripe/react-stripe-js";

import CardSection from "./CardSection";
import { toast } from "react-toastify";
import { redirectURI, usFormat } from "utils/common";
import { createSubscription } from "api/v1";

function CheckoutForm(props) {
  const [price, setPrice] = useState(null)
  const [loading, setLoading] = useState(false)

  function handleError(error) {
    setLoading(false);
    toast.error(error.message);
  }

  const handleSubmit = async event => {
    let subscription = null
    event.preventDefault();

    const { stripe, elements } = props;
    if (!stripe || !elements) {
      return;
    }

    setLoading(true)

    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }

    try {
      subscription = await createSubscription({ seats: price.seats })
    } catch (error) {
      toast.error("Something went wrong while creating subscription")
      setLoading(false)
      return
    }

    try {
      const result = await stripe.confirmPayment({
        elements,
        clientSecret: subscription.client_secret,
        confirmParams: {
          return_url: redirectURI('billing')
        },
        redirect: 'if_required'
      });

      if (result.error) {
        toast.error(result.error.message)
      } else {
        props.onComplete()
      }
    } catch (error) {
      toast.error("Something went wrong!")
    }

    setLoading(false)
  };

  useEffect(() => {
    if (props.price)
      setPrice(props.price)
  }, [props.price])

  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center">
        <h4>Basic Plan</h4>

        <h4>{usFormat(price?.unit_amount)}</h4>
      </div>

      <form onSubmit={handleSubmit}>
        <CardSection />
        <button disabled={!props.stripe || loading} className="btn btn-primary float-end mt-4">
          Subscribe
          {
            loading &&
            <i className="bx bx-hourglass bx-spin font-size-16 align-middle ms-2"></i>
          }
        </button>
      </form>
    </Fragment>
  );
}

export default function InjectedCheckoutForm(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} elements={elements} {...props} />
      )}
    </ElementsConsumer>
  );
}
