import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'

import { editPayrollModal, editPayroll } from 'store/payrolls/actions'
import moment from "moment";
let shortFormat = "YYYY-MM-DD"

function index({ payroll: target }) {
  let dispatch = useDispatch()
  const { edit: modal } = useSelector((state) => state.payrolls);
  let [payroll, setPayroll] = useState()

  useEffect(() => {
    if (modal) {
      setPayroll(target)
    }
  }, [modal])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      member: (payroll && payroll.payrate?.user) || null,
      new_payrate: false,
      projects: (payroll && payroll.projects) || [],
      payrate: (payroll && payroll.payrate) || null,
      payment_method: (payroll && payroll.payment_method) || '',
      payment_period_start: (payroll && moment(new Date(payroll.payment_period_start)).format(shortFormat)) || '',
      payment_period_end: (payroll && moment(new Date(payroll.payment_period_end)).format(shortFormat)) || '',
      hours_worked: (payroll && payroll.hours_worked) || '',
      cheque_no: (payroll && payroll.cheque_no) || '',
      overtime_hours: (payroll && payroll.overtime_hours) || '',
      timesheets: (payroll && payroll.timesheets) || [],
    },
    validationSchema: Yup.object({
      member: Yup.mixed().required("Please Select Member"),
      rate: Yup.string().when('payrate', {
        is: (payrate) => (payrate && payrate.rate == null),
        then: Yup.string().required("Please Enter Rate")
      }),
      payment_method: Yup.string().required("Please Select Payment Method"),
      payment_period_start: Yup.string().required("Please Enter Start Date"),
      payment_period_end: Yup.string().required("Please Enter End Date"),
      hours_worked: Yup.string().when('projects', {
        is: (projects) => (projects.length == 0),
        then: Yup.string().required("Please Enter Working Hours"),
        otherwise: Yup.string().nullable()
      }),
      timesheets: Yup.array().when('projects', {
        is: (projects) => (projects.length > 0),
        then: Yup.array().min(1, "at least 1 Timesheet is").required("required"),
      }),
    }),
    onSubmit: (values, actions) => {
      dispatch(editPayroll(payroll.id, values, actions))
    },
  });



  return (
    <React.Fragment>
      <Modal size="lg" isOpen={modal} toggle={() => dispatch(editPayrollModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(editPayrollModal())}>
          Edit Payroll
        </ModalHeader>
        <ModalBody>
          <Form
            validation={validation}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
