import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'
import { fromMinutes, toMinutes } from "./helper";

import { editTimesheetModal, editTimesheet } from 'store/timesheets/actions'
import moment from "moment";
import { getTimesheetImages, deleteTimesheetImage } from 'store/actions';

function index({ timesheet: target, projectId, taskId }) {
  let dispatch = useDispatch()
  const { edit: modal } = useSelector((state) => state.timesheets);
  let [timesheet, setTimesheet] = useState()

  useEffect(() => {
    if (modal) {
      setTimesheet(target)
      dispatch(getTimesheetImages(projectId, taskId, target.id))
    }
  }, [modal])

  function handleDeleteImage(id) {
    dispatch(deleteTimesheetImage(projectId, taskId, timesheet.id, id))
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      description: (timesheet && timesheet.description) || '',
      date: (timesheet && moment(timesheet.date).format("yyyy-MM-DD")) || '',
      start_time: (timesheet && moment(timesheet.start_time, "hh:mm:ss").format("hh:mm")) || '',
      end_time: (timesheet && moment(timesheet.end_time, "hh:mm:ss").format("hh:mm")) || '',
      time_out: (timesheet && fromMinutes(timesheet.time_out)) || '',
      images: [],
      user: (timesheet && timesheet.user) || null
    },
    validationSchema: Yup.object({
      date: Yup.string().required("Please Enter Date"),
      start_time: Yup.string().required("Please Enter Start Time"),
      end_time: Yup.string().required("Please Enter End Time"),
      user: Yup.mixed().required("Please Select User")
    }),
    onSubmit: (values, actions) => {
      dispatch(editTimesheet(projectId, taskId, timesheet.id, { ...values, time_out: toMinutes(values.time_out) }, actions))
    },
  });

  return (
    <React.Fragment>
      <Modal size="lg" isOpen={modal} toggle={() => dispatch(editTimesheetModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(editTimesheetModal())}>
          Edit Timesheet
        </ModalHeader>
        <ModalBody>
          <Form
            projectId={projectId}
            taskId={taskId}
            edit={true}
            handleDeleteImage={handleDeleteImage}
            validation={validation}
          />

        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
