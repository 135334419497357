import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';
import {
  GET_TASKS,
  DELETE_TASK,
  ADD_TASK,
  EDIT_TASK
} from "./actionTypes";

import {
  getTasksSuccess,
  getTasks,
  getTasksFail,
  deleteTaskModal,
  setTaskDetail,
  getTaskImages,
  setTaskImages
} from "./actions";

import {
  getTasks as getTasksAPI,
  deleteTask as deleteTaskAPI,
  addTask as addTaskAPI,
  editTask as editTaskAPI,
  getTask as getTaskAPI,
  deleteTaskImage as deleteTaskImageAPI,
  getTaskImages as getTaskImagesAPI
} from "api/v1";
import { getProject, getProjectHours } from "store/actions";
import moment from "moment";
let _format = "YYYY-MM-DDThh:mm:ssZ"

function* fetchTask({ payload: { projectId, id, history } }) {
  try {
    const response = yield call(getTaskAPI, projectId, id);
    yield put(setTaskDetail(response));
  } catch (error) {
    yield put(getTasksFail("Object not found"))
    if (history) history.goBack()
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* fetchTasks({ payload: { params, projectId } }) {
  try {
    const response = yield call(getTasksAPI, projectId, params);
    yield put(getTasksSuccess(response));
  } catch (error) {
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* addTask({ payload: { task, actions, projectId } }) {
  try {
    yield call(addTaskAPI, projectId, task)
    toast.success("Task Created!");
    yield put(getTasks(projectId, true));
    yield put(getProject(false, projectId));
    actions.resetForm()

  } catch ({ errors }) {
    yield put(getTasksFail("Creation failed!"))
    if (errors) {
      actions.setErrors(errors)

    }
    toast.error("Something went wrong!");
  }
}

function* editTask({ payload: { id, task, actions, projectId, params } }) {
  try {
    yield call(editTaskAPI, projectId, id, task)
    toast.success("Task Updated!");
    yield put(getTasks(projectId, true, params));
    yield put(getProject(true, projectId));
    yield put(getProjectHours(projectId, true, {type: 'week', start_date: moment().subtract(7, 'd').format(_format), end_date: moment().format(_format)}));
  } catch ({ errors }) {
    yield put(getTasksFail("Updation failed!"))
    if (errors && actions) {
      actions.setErrors(errors)

    }
    toast.error("Something went wrong!");
  }
}

function* deleteTask({ payload: { task, projectId, params } }) {
  try {
    yield call(deleteTaskAPI, projectId, task.id);
    put(deleteTaskModal())
    yield put(getTasks(projectId, true, params));
    toast.warn("Task Deleted!");
    yield put(getProject(true, projectId));
    yield put(getProjectHours(projectId, true, {type: 'week', start_date: moment().subtract(7, 'd').format(_format), end_date: moment().format(_format)}));
  } catch (error) {
    toast.error("Something went wrong!");
  }
}

function* deleteTaskImage({ payload: { projectId, taskId, id } }) {
  try {
    yield call(deleteTaskImageAPI, projectId, taskId, id);
    yield put(getTaskImages(projectId, taskId));
    toast.warn("Image Deleted!");
  } catch (error) {
    toast.error("Something went wrong!");
    yield put(getTaskImages(projectId, taskId));
  }
}

function* fetchTaskImages({ payload: { projectId, taskId } }) {
  try {
    const _images = yield call(getTaskImagesAPI, projectId, taskId);
    yield put(setTaskImages(_images));
  } catch (error) {
    yield put(setTaskImages([]))
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* taskSaga() {
  yield takeEvery('GET_TASK', fetchTask);
  yield takeEvery(GET_TASKS, fetchTasks);
  yield takeEvery(DELETE_TASK, deleteTask);
  yield takeEvery('DELETE_TASK_IMAGE', deleteTaskImage);
  yield takeEvery('GET_TASK_IMAGES', fetchTaskImages);
  yield takeEvery(ADD_TASK, addTask);
  yield takeEvery(EDIT_TASK, editTask);
}

export default taskSaga;
