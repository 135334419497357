import React, { useEffect, useState, useRef, Fragment } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { v4 as uuid } from 'uuid';
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

//redux
import { Link } from "react-router-dom";
import Loader from "common/Loader";
import { map } from "lodash";
import { toast } from "react-toastify";
import { getTasks } from "api/v1";

const WaitingTask = props => {
  const [loading, setLoading] = useState(false)
  const [taskWithPagy, setTaskWithPagy] = useState({})
  let { results: tasks = [] } = taskWithPagy

  //pagination customization
  const pageOptions = {
    sizePerPage: taskWithPagy.page_size,
    totalSize: taskWithPagy.total,
    page: taskWithPagy.page,
    custom: true,
  };

  const COLS = [
    {
      text: "id",
      dataField: "id",
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      dataField: "project_name",
      text: "Project Name",
      formatter: (cellContent, row) => (
        <>
          <p className="mb-0">{row.project.project_name}</p>
          <small className="mb-1 text-muted">Project Name</small>
        </>
      ),
    },
    {
      dataField: "task_name",
      text: "Task Name",
      formatter: (cellContent, row) => (
        <>
          <p className="mb-0">{row.task}</p>
          <small className="mb-1 text-muted">Task Name</small>
        </>
      ),
    },
    {
      dataField: 'custom',
      text: "Members",
      sort: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        return (
          <div className="avatar-group">
            {map(row.users, (_member, index) => {
              let { profile: member } = _member
              let _uuid = uuid()
              return (
                !member.avatar || member.avatar !== "Null" && (
                  <div
                    className="avatar-group-item"
                    key={_uuid}
                  >
                    <Link
                      to="#"
                      className="team-member d-inline-block"
                      id={`tooltip-${_uuid}`}
                    >
                      <img
                        src={member.avatar}
                        className="rounded-circle avatar-xs"
                        alt=""
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target={`tooltip-${_uuid}`}
                      >
                        {`${member.first_name} (${_member.type})`}
                      </UncontrolledTooltip>
                    </Link>
                  </div>
                )
              )
            })}
          </div>
        )
      },
    },
  ];


  var node = useRef();

  //delete task

  async function loadData(params = {}) {
    setLoading(true)
    try {
      let _pageSize = await getTasks(null, params, false);
      setTaskWithPagy(_pageSize)

    } catch (error) {
      toast.error("Something went wrong loading data")
    }
    setLoading(false)
  }

  useEffect(() => {
    loadData({ status: 'Waiting' })
  }, []);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page }) => {
    if (type == "pagination")
      loadData({ page, status: 'Waiting' })
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  return (
    <React.Fragment>
      <Row>
        <Col xs="12">
          <Card style={{ borderWidth: 1, borderColor: '#F1F1F1' }}>
            <CardHeader className="bg-white pt-3">
              <Row>
                <Col>
                  <CardTitle className="mb-2">Waiting Task Alert</CardTitle>
                </Col>
              </Row>
            </CardHeader>

            <CardBody>
              {
                loading ?
                  <Loader message={"Please wait"} />
                  :
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={COLS}
                    data={tasks}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <ToolkitProvider
                          keyField="id"
                          data={tasks.slice(0, 3) || []}
                          columns={COLS}
                          bootstrap4
                          search
                        >
                          {toolkitProps => {
                            return (
                              <React.Fragment>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        noDataIndication={"No Data found"}
                                        defaultSorted={defaultSorted}
                                        classes={"table align-middle table-nowrap"}
                                        keyField="id"
                                        remote={{ search: true, pagination: true }}
                                        {...toolkitProps.baseProps}
                                        onTableChange={handleTableChange}
                                        {...paginationTableProps}
                                        ref={node}
                                      />
                                    </div>

                                  </Col>
                                </Row>
                              </React.Fragment>
                            )
                          }}
                        </ToolkitProvider>
                      )
                    }}
                  </PaginationProvider>
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

WaitingTask.propTypes = {
  tasks: PropTypes.array,
  onGetTasks: PropTypes.func,
  onAddNewTask: PropTypes.func,
  onDeleteTask: PropTypes.func,
  onUpdateTask: PropTypes.func,
};

export default WaitingTask;
