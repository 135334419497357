import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'

import { editCategoryModal, editCategory } from 'store/categories/actions'

function index({ category: target }) {
  let dispatch = useDispatch()
  const { edit: modal } = useSelector((state) => state.categories);
  let [category, setCategory] = useState()

  useEffect(() => {
    if (modal) {
      setCategory(target)
    }
  }, [modal])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (category && category.name) || '',
      description: (category && category.description) || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Category Name"),
      description: Yup.string().required("Please Enter Your Description of Category"),
    }),
    onSubmit: (values, actions) => {
      dispatch(editCategory(category.id, values, actions))
    },
  });

  return (
    <React.Fragment>
      <Modal isOpen={modal} toggle={() => dispatch(editCategoryModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(editCategoryModal())}>
          Edit Category
        </ModalHeader>
        <ModalBody>
          <Form
            validation={validation}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
