import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";

//i18n
import { withTranslation } from "react-i18next";
import { getTimeoffs } from "api/v1";

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [notifications, setNotifications] = useState({ total: 0, results: [] })

  async function loadNotifications() {
    try {
      let { results = [], total } = await getTimeoffs({ status: 'pending' })
      setNotifications({
        results, total
      })
    } catch (error) { }
  }

  useEffect(() => {
    loadNotifications()
  }, [])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          {notifications.total > 0 && <span className="badge bg-danger rounded-pill">{notifications.total}</span>}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {
              notifications.results.map(_notification =>
                <Link key={`notification-${_notification.id}`}
                  to={'timeoffs'} className="text-reset notification-item">
                  <div className="d-flex">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title bg-primary rounded-circle font-size-16">
                        <i className="bx bx-bell-off" />
                      </span>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mt-0 mb-1">
                        Timeoff
                      </h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">
                          {_notification?.user?.profile?.first_name || ""} has requested timeoff
                        </p>
                        {/* <p className="mb-0">
                          <i className="mdi mdi-clock-outline" />{" "}
                          {props.t("3 min ago")}{" "}
                        </p> */}
                      </div>
                    </div>
                  </div>
                </Link>
              )}
          </SimpleBar>

          <div className="p-2 border-top d-grid">
            <Link className="btn btn-sm btn-link font-size-14 text-center" to="/timeoffs">
              <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">{props.t("View More...")}</span>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any
};
