import {
  GET_MATERIALS_FAIL,
  GET_MATERIALS_SUCCESS,
  GET_MATERIALS,
  ADD_MATERIAL,
  EDIT_MATERIAL,
  DELETE_MATERIAL,
} from "./actionTypes";

const INIT_STATE = {
  materialWithPagy: {
    total: 0,
    page: 1,
    page_size: 10,
    results: []
  },
  new: false,
  edit: false,
  delete: false,
  loading: false,
};

const materials = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MATERIALS:
      return {
        ...state,
        new: false,
        edit: false,
        delete: false,
        loading: action.payload.loading
      };

    case GET_MATERIALS_SUCCESS:
      return {
        ...state,
        loading: false,
        materialWithPagy: action.payload,
      };

    case ADD_MATERIAL:
      return {
        ...state,
        loading: true
      };

    case EDIT_MATERIAL:
      return {
        ...state,
        loading: true
      };

    case GET_MATERIALS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "NEW_MATERIAL_MODAL":
      return {
        ...state,
        new: !state.new
      };

    case "DELETE_MATERIAL_MODAL":
      return {
        ...state,
        delete: !state.delete
      };

    case "EDIT_MATERIAL_MODAL":
      return {
        ...state,
        edit: !state.edit
      };
    default:
      return state;
  }
};

export default materials;
