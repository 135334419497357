const INIT_STATE = {
  loading: false,
  tenant: null
};

const tenants = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'GET_TENANT':
      return {
        ...state,
        loading: true
      };

    case 'TENANT_COMPLETE':
      return {
        ...state,
        loading: false
      };

    case 'SET_TENANT':
      return {
        ...state,
        tenant: action.payload.tenant
      };

    case 'EDIT_TENANT':
      return {
        ...state,
        loading: true
      };

    default:
      return state;
  }
};

export default tenants;
