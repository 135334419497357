import React, { useEffect } from 'react'
import { useHistory } from "react-router-dom";

function Callback() {
  const history = useHistory()

  useEffect(() => {
    redirectTo()
  }, [])

  function redirectTo() {
    const queryParams = new URLSearchParams(location.search);
    let stateInfo = queryParams.get('state')?.split('_')

    if (stateInfo) {
      let subdomain = stateInfo.length > 1 ? stateInfo[stateInfo.length - 1] : null
      window.location.href = `${process.env.REACT_APP_PROTOCOL}://${subdomain || 'app'}.${process.env.REACT_APP_HOST}/settings/quickbooks/?${queryParams}`
    }
    else
      history.replace('/dashboard')

  }


  return (
    <h5 className='m-1'>redirecting...</h5>
  )
}

export default Callback
