import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import { setTabBarLoader } from "utils/common";
import { updatePassword } from "store/actions";


const Password = () => {
  const { loading } = useSelector(state => ({
    loading: state.profiles.loading,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    setTabBarLoader(loading)
  }, [loading]) //loading

  const form = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      old_password: '',
      new_password: '',
      new_password2: ''
    },
    validationSchema: Yup.object({
      old_password: Yup.string().required("Please Enter Old Password"),
      new_password: Yup.string().required("Please Enter New Password"),
      new_password2: Yup.string().required("Please Confirm Password"),
    }),
    onSubmit: (values, actions) => {
      dispatch(updatePassword(values, actions))
    }
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Password | AllPromana</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb back={true} title="Settings" breadcrumbItem="Password" />

          <Row>
            <Col lg="12">
              <Card>
                <h5 className="mt-3 px-4">Password Details</h5>
                <CardBody>
                  <Form onSubmit={e => {
                    e.preventDefault();
                    form.handleSubmit();
                    return false;
                  }}>
                    <Row form>
                      <Col className="col-12">
                        <div className="mb-3">
                          <Label className="form-label">Old Password</Label>
                          <Input
                            name="old_password"
                            type="password"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={form.values.old_password || ""}
                            invalid={
                              form.touched.old_password && form.errors.old_password ? true : false
                            }
                          />
                          {form.touched.old_password && form.errors.old_password ? (
                            <FormFeedback type="invalid">{form.errors.old_password}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">New Password</Label>
                          <Input
                            name="new_password"
                            type="password"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={form.values.new_password || ""}
                            invalid={
                              form.touched.new_password && form.errors.new_password ? true : false
                            }
                          />
                          {form.touched.new_password && form.errors.new_password ? (
                            <FormFeedback type="invalid">{form.errors.new_password}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Confirm Password</Label>
                          <Input
                            name="new_password2"
                            type="password"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={form.values.new_password2 || ""}
                            invalid={
                              form.touched.new_password2 && form.errors.new_password2 ? true : false
                            }
                          />
                          {form.touched.new_password2 && form.errors.new_password2 ? (
                            <FormFeedback type="invalid">{form.errors.new_password2}</FormFeedback>
                          ) : null}
                        </div>


                        <div className="text-end mt-2">
                          <button
                            type="submit"
                            disabled={loading}
                            className="btn btn-success save-customer"
                          >
                            {loading ? "Saving" : "Save"} {" "}
                            {loading && <i className="bx bx-hourglass bx-spin font-size-16 align-middle"></i>}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Password);
