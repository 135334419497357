import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'

import { editTimesheetReportModal, editTimesheetReport } from 'store/timesheetReports/actions'
import moment from "moment";

function index({ timesheetReport: target }) {
  let dispatch = useDispatch()
  const { edit: modal } = useSelector((state) => state.timesheetReports);
  let [timesheetReport, setTimesheetReport] = useState()

  useEffect(() => {
    if (modal) {
      setTimesheetReport(target)
    }
  }, [modal])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      from_date: (timesheetReport && timesheetReport.from_date && moment(timesheetReport.from_date, "YYYY-MM-DDThh:mm:ssZ").format("YYYY-MM-DD")) || '',
      to_date: (timesheetReport && timesheetReport.to_date && moment(timesheetReport.to_date, "YYYY-MM-DDThh:mm:ssZ").format("YYYY-MM-DD")) || '',
      users: (timesheetReport && timesheetReport.users) || [],
      projects: (timesheetReport && timesheetReport.projects) || [],
    },
    validationSchema: Yup.object({
      users: Yup.array().min(1, "Please Select at least one user").required("required"),
      from_date: Yup.string().required("Enter Start Date"),
      to_date: Yup.string().required("Enter To Date"),
    }),
    onSubmit: (values, actions) => {
      dispatch(editTimesheetReport(timesheetReport.id, { ...values, users: values.users.map(_user => _user.id), projects: values.projects.map(_project => _project.id) }, actions))
    },
  });



  return (
    <React.Fragment>
      <Modal isOpen={modal} toggle={() => dispatch(editTimesheetReportModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(editTimesheetReportModal())}>
          Edit Timesheet Report
        </ModalHeader>
        <ModalBody>
          <Form
            validation={validation}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
