import {
  GET_TIMEOFFS_FAIL,
  GET_TIMEOFFS_SUCCESS,
  GET_TIMEOFFS,
  ADD_TIMEOFF,
  EDIT_TIMEOFF,
  DELETE_TIMEOFF,
} from "./actionTypes";

const INIT_STATE = {
  timeoffWithPagy: {
    total: 0,
    page: 1,
    page_size: 10,
    results: []
  },
  new: false,
  edit: false,
  delete: false,
  loading: false,
};

const timeoffs = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TIMEOFFS:
      return {
        ...state,
        new: false,
        edit: false,
        delete: false,
        loading: action.payload.loading
      };

    case GET_TIMEOFFS_SUCCESS:
      return {
        ...state,
        loading: false,
        timeoffWithPagy: action.payload,
      };

    case ADD_TIMEOFF:
      return {
        ...state,
        loading: true
      };

    case EDIT_TIMEOFF:
      return {
        ...state,
        loading: true
      };

    case GET_TIMEOFFS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "NEW_TIMEOFF_MODAL":
      return {
        ...state,
        new: !state.new
      };

    case DELETE_TIMEOFF:
      return {
        ...state,
        loading: true
      };

    case "DELETE_TIMEOFF_MODAL":
      return {
        ...state,
        delete: !state.delete
      };

    case "EDIT_TIMEOFF_MODAL":
      return {
        ...state,
        edit: !state.edit
      };
    default:
      return state;
  }
};

export default timeoffs;
