import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';
import {
  GET_VENDORS,
  DELETE_VENDOR,
  ADD_VENDOR,
  EDIT_VENDOR
} from "./actionTypes";

import {
  getVendorsSuccess,
  getVendors,
  getVendorsFail,
  deleteVendorModal
} from "./actions";

import {
  getVendors as getVendorsAPI,
  deleteVendor as deleteVendorAPI,
  addVendor as addVendorAPI,
  editVendor as editVendorAPI,
} from "api/v1";


function* fetchVendors({ payload: { params } }) {
  try {
    const response = yield call(getVendorsAPI, params);
    yield put(getVendorsSuccess(response));
  } catch (error) {
    yield put(getVendorsFail("Something went wrong!"))
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* addVendor({ payload: { vendor, actions } }) {
  try {
    yield call(addVendorAPI, vendor)
    toast.success("Vendor Created!");
    yield put(getVendors(true));
    actions.resetForm()

  } catch ({ errors }) {
    yield put(getVendorsFail("Creation failed!"))
    if (errors) {
      let { user } = errors
      actions.setErrors({ ...errors, ...user })

    }
    toast.error("Something went wrong!");
  }
}

function* editVendor({ payload: { id, vendor, actions } }) {
  try {
    yield call(editVendorAPI, id, vendor)
    toast.success("Vendor Updated!");
    yield put(getVendors(true));

  } catch ({ errors }) {
    yield put(getVendorsFail("Creation failed!"))
    if (errors && actions) {
      let { user } = errors
      actions.setErrors({ ...errors, ...user })

    }
    toast.error("Something went wrong!");
  }
}

function* deleteVendor({ payload: { vendor } }) {
  try {
    yield call(deleteVendorAPI, vendor.id);
    toast.warn("Vendor Deleted!");
  } catch (error) {
    toast.error("Something went wrong!");
  }
  put(deleteVendorModal())
  yield put(getVendors(true));
}

function* vendorSaga() {
  yield takeEvery(GET_VENDORS, fetchVendors);
  yield takeEvery(DELETE_VENDOR, deleteVendor);
  yield takeEvery(ADD_VENDOR, addVendor);
  yield takeEvery(EDIT_VENDOR, editVendor);
}

export default vendorSaga;
