import PropTypes from "prop-types";
import React from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Row,
  Col,
  UncontrolledAlert as Alert
} from "reactstrap";
import { Link } from "react-router-dom";

// Pages Components
import WelcomeComp from "./WelcomeComp";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector } from "react-redux";
import Stats from "./Stats";
import Tasks from './Tasks'
import Timeoffs from "./Timeoffs";
import WaitingTask from "./WaitingTask";
import Projects from "./Projects";
import Quotes from "./Quotes";
import Payroll from "./Payroll";
import Subscription from "./Subscription";

const Dashboard = props => {

  const { profile } = useSelector(state => ({
    profile: state.profiles.profile
  }));

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Contractor</title>
        </MetaTags>
        <Container fluid>
          {
            profile && profile.profile.first_name == null
            && <Alert color="warning" role="alert">
              The profile is not setup yet, click{" "}
              <Link to="/profile" className="alert-link">
                here
              </Link>
              {" "} to setup your profile
            </Alert>
          }
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Subscription />

          <Row>
            <Col xl="4">
              <WelcomeComp />

              <Timeoffs />

              <WaitingTask />
            </Col>

            <Col xl="8">
              <Stats />

              <Quotes />

              <Row>
                <Col xl="6">
                  <Payroll />
                </Col>

                <Col xl="6">
                  <Projects />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              <Tasks />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
