import React from 'react'
import { Col, Row } from 'reactstrap'

function Timesheets({ next, submit }) {
  return (
    <div>
      <Row>
        <Col lg={12}>
          <div className="text-center">
            <i
              className="mdi mdi-alert-circle-outline"
              style={{ fontSize: "9em", color: "orange" }}
            />
            <h2>Are you sure?</h2>
            <h4>{"Do you want to send/attach timesheet PDF with customer!"}</h4>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="text-center mt-3">
            <button
              type="button"
              className="btn btn-success btn-lg ms-2"
              onClick={next}
            >
              Yes, send it!
            </button>
            <button
              type="button"
              className="btn btn-danger btn-lg ms-2"
              onClick={submit}
            >
              No
            </button>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Timesheets
