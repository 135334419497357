import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';
import {
  GET_TIMESHEETS,
  DELETE_TIMESHEET,
  ADD_TIMESHEET,
  EDIT_TIMESHEET
} from "./actionTypes";

import {
  getTimesheetsSuccess,
  getTimesheets,
  getTimesheetsFail,
  deleteTimesheetModal,
  setTimesheetDetail,
  getTimesheetImages,
  setTimesheetImages
} from "./actions";

import {
  getTimesheetImages as getTimesheetImagesAPI,
  deleteTimesheetImage as deleteTimesheetImageAPI,
  getTimesheets as getTimesheetsAPI,
  deleteTimesheet as deleteTimesheetAPI,
  addTimesheet as addTimesheetAPI,
  editTimesheet as editTimesheetAPI,
  getTimesheet as getTimesheetAPI,
} from "api/v1";
import { getTask } from "store/actions";


function* fetchTimesheet({ payload: { projectId, taskId, id, history } }) {
  try {
    const response = yield call(getTimesheetAPI, projectId, taskId, id);
    yield put(setTimesheetDetail(response));
  } catch (error) {
    yield put(getTimesheetsFail("Object not found"))
    if (history) history.goBack()
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* fetchTimesheets({ payload: { params, projectId, taskId } }) {
  try {
    const response = yield call(getTimesheetsAPI, projectId, taskId, params);
    yield put(getTimesheetsSuccess(response));
  } catch (error) {
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* fetchTimesheetImages({ payload: { projectId, taskId, timesheetId } }) {
  try {
    const _images = yield call(getTimesheetImagesAPI, projectId, taskId, timesheetId);
    yield put(setTimesheetImages(_images));
  } catch (error) {
    yield put(setTimesheetImages([]))
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* addTimesheet({ payload: { timesheet, actions, projectId, taskId } }) {
  try {
    yield call(addTimesheetAPI, projectId, taskId, timesheet)
    toast.success("Timesheet Created!");
    yield put(getTimesheets(projectId, taskId, true));
    actions.resetForm()
    // yield put(getTask(projectId, false, taskId)); // TODO will be added later

  } catch ({ errors }) {
    yield put(getTimesheetsFail("Creation failed!"))
    if (errors) {
      actions.setErrors(errors)
      if (errors.non_field_errors) {
        toast.error(errors.non_field_errors[0]);
      }
    }
    else
      toast.error("Something went wrong!");
  }
}

function* editTimesheet({ payload: { id, timesheet, actions, projectId, taskId } }) {
  try {
    yield call(editTimesheetAPI, projectId, taskId, id, timesheet)
    toast.success("Timesheet Updated!");
    yield put(getTimesheets(projectId, taskId, true));
    // yield put(getTask(projectId, false, taskId)); // TODO will be added later

  } catch ({ errors }) {
    yield put(getTimesheetsFail("Updation failed!"))
    if (errors) {
      actions.setErrors(errors)
      if (errors.non_field_errors) {
        toast.error(errors.non_field_errors[0]);
      }
    }
    else
      toast.error("Something went wrong!");
  }
}

function* deleteTimesheet({ payload: { timesheet, projectId, taskId } }) {
  try {
    yield call(deleteTimesheetAPI, projectId, taskId, timesheet.id);
    put(deleteTimesheetModal())
    yield put(getTimesheets(projectId, taskId, true));
    toast.warn("Timesheet Deleted!");
    yield put(getTask(projectId, false, taskId));
  } catch (error) {
    toast.error("Something went wrong!");
  }
}

function* deleteTimesheetImage({ payload: { projectId, taskId, timesheetId, id } }) {
  try {
    yield call(deleteTimesheetImageAPI, projectId, taskId, timesheetId, id);
    yield put(getTimesheetImages(projectId, taskId, timesheetId));
    toast.warn("Image Deleted!");
  } catch (error) {
    toast.error("Something went wrong!");
    yield put(getTimesheetImages(projectId, taskId, timesheetId));
  }
}

function* timesheetSaga() {
  yield takeEvery('GET_TIMESHEET', fetchTimesheet);
  yield takeEvery(GET_TIMESHEETS, fetchTimesheets);
  yield takeEvery(DELETE_TIMESHEET, deleteTimesheet);
  yield takeEvery('DELETE_TIMESHEET_IMAGE', deleteTimesheetImage);
  yield takeEvery(ADD_TIMESHEET, addTimesheet);
  yield takeEvery('GET_TIMESHEET_IMAGES', fetchTimesheetImages);
  yield takeEvery(EDIT_TIMESHEET, editTimesheet);
}

export default timesheetSaga;
