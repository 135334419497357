import {
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS,
  ADD_PRODUCT,
  EDIT_PRODUCT,
  DELETE_PRODUCT,
} from "./actionTypes";

const INIT_STATE = {
  productWithPagy: {
    total: 0,
    page: 1,
    page_size: 10,
    results: []
  },
  new: false,
  edit: false,
  delete: false,
  loading: false,
};

const products = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCTS:
      return {
        ...state,
        new: false,
        edit: false,
        delete: false,
        loading: action.payload.loading
      };

    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        productWithPagy: action.payload,
      };

    case ADD_PRODUCT:
      return {
        ...state,
        loading: true
      };

    case EDIT_PRODUCT:
      return {
        ...state,
        loading: true
      };

    case DELETE_PRODUCT:
      return {
        ...state,
        loading: true
      };

    case GET_PRODUCTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "NEW_PRODUCT_MODAL":
      return {
        ...state,
        new: !state.new
      };

    case "DELETE_PRODUCT_MODAL":
      return {
        ...state,
        delete: !state.delete
      };

    case "EDIT_PRODUCT_MODAL":
      return {
        ...state,
        edit: !state.edit
      };
    default:
      return state;
  }
};

export default products;
