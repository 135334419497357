import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Input,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import { v4 as uuid } from 'uuid';
import { Search } from "react-bootstrap-table2-toolkit";

import DeleteIcon from 'assets/images/Delete.svg'
import MemberIcon from 'assets/images/member.png'
import EditIcon from 'assets/images/Edit.svg'

import BootstrapTable from "react-bootstrap-table-next";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../components/Common/DeleteModal";
import {
  getTasks as onGetTasks,
  deleteTask as onDeleteTask,
  newTaskModal,
  deleteTaskModal,
  editTaskModal,
  editTask
} from "store/tasks/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setLoader } from "utils/common";
import Create from './Create'
import Edit from './Edit'
import { Link } from "react-router-dom";
import { map } from "lodash";
import moment from "moment";
import Member from "./Member";

let defaultHash = {
  'Processing': [],
  'Open': [],
  'Waiting': [],
  'Close': [],
}

const Tasks = props => {
  const dispatch = useDispatch();

  const {
    match: { params },
  } = props;


  const { taskWithPagy, loading, deleteModal } = useSelector(state => ({
    taskWithPagy: state.tasks.taskWithPagy,
    loading: state.tasks.loading,
    deleteModal: state.tasks.delete,
  }));
  let { results = [] } = taskWithPagy

  const [task, setTask] = useState(null);
  const [memberTask, setMemberTask] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [tasksHash, setTaskHash] = useState({ ...defaultHash })
  let [controls, changeControls] = useState({
    'Processing': { color: 'warning', isOpen: true },
    'Open': { color: 'warning', isOpen: true },
    'Waiting': { color: 'info', isOpen: true },
    'Close': { color: 'primary', isOpen: true },
  })
  const [searchParams, setSearchParams] = useState({
    search: '',
  })

  const pagination = {
    sizePerPage: taskWithPagy.page_size,
    totalSize: taskWithPagy.total,
    page: taskWithPagy.page,
    is_next: taskWithPagy?.links?.next != null
  }

  const COLS = [
    {
      text: "id",
      dataField: "id",
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      text: "No",
      dataField: "index",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row, rowIndex) => <>{rowIndex + 1}</>,
    },
    {
      dataField: "task",
      text: "Name",
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{`${row.task}`}</p>
        </>
      ),
    },
    {
      dataField: 'custom',
      text: "Members",
      sort: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        return (
          <div className="avatar-group">
            {map(row.users, (_member, index) => {
              let { profile: member } = _member
              let _uuid = uuid()
              return (
                !member.avatar || member.avatar !== "Null" && (
                  <div
                    className="avatar-group-item"
                    key={_uuid}
                  >
                    <Link
                      to="#"
                      className="team-member d-inline-block"
                      id={`tooltip-${_uuid}`}
                    >
                      <img
                        src={member.avatar}
                        className="rounded-circle avatar-xs"
                        alt=""
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target={`tooltip-${_uuid}`}
                      >
                        {`${member.first_name} (${_member.type})`}
                      </UncontrolledTooltip>
                    </Link>
                  </div>
                )
              )
            })}
          </div>
        )
      },
    },
    {
      dataField: "estimated_hours",
      text: "Estimated Time",
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{`${row.estimated_hours || 0} Hours`}</p>
        </>
      ),
    },
    {
      dataField: "total_hours",
      text: "Working Time",
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{`${row.total_hours} Hours`}</p>
        </>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cellContent, row) => (
        <div style={{ width: '60%' }} className="mb-1">
          <Input
            name="status"
            type="select"
            className="form-select"
            onChange={event => {
              dispatch(editTask(params.id, row.id, { status: event.target.value, users: row.users || [] }))
            }}
            value={row.status || ""}>
            <option value="Processing">Processing</option>
            <option value="Open">Open</option>
            <option value="Waiting">Waiting</option>
            <option value="Close">Close</option>
          </Input>
        </div>
      ),
    },
    {
      dataField: "menu",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, task) => (
        <div className="d-flex align-items-center">
          {
            task.users.length > 0 &&
            <div onClick={() => handleMember(task)} className="me-1 mb-1">
              <img
                src={MemberIcon}
                height="30"
                width="30"
              />
            </div>
          }

          <div onClick={() => handleEditTask(task)} className="me-2 mb-1">
            <img
              src={EditIcon}
              height="20"
              width="20"
            />
          </div>

          <div onClick={() => props.history.push(props.component ? `${params.id}/tasks/${task.id}/timesheets` : `tasks/${task.id}/timesheets`)} className="me-1">
            <i className="far fa-clock font-size-18 text-info" />
          </div>

          <div onClick={() => onClickDelete(task)} className="me-2 mb-1">
            <img
              src={DeleteIcon}
              height="20"
              width="20"
            />
          </div>
        </div>
      ),
    },
  ];


  var node = useRef();

  //delete task

  const onClickDelete = (task) => {
    setTask(task);
    dispatch(deleteTaskModal())
  };

  const handleDeleteTask = () => {
    dispatch(onDeleteTask(params.id, task))
  }

  const { SearchBar } = Search;

  function init() {
    dispatch(onGetTasks(params.id, true));
  }

  useEffect(() => {
    init()
  }, []);

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  useEffect(() => {
    let { page } = pagination
    let _defaultHash = {
      'Processing': [],
      'Open': [],
      'Waiting': [],
      'Close': [],
    }
    let _tasks = []
    if (page == 1) {
      _tasks = results
    }
    else if (results.length > 0) {
      _tasks = [...tasks, ...results]
    }

    _tasks.forEach(_task => {
      _defaultHash[_task.status].push(_task)
    })
    setTaskHash(_defaultHash)
    setTasks(_tasks)

  }, [results]);

  const handleCreateTask = (_task = null) => {
    setTask(_task)
    dispatch(newTaskModal())
  };

  const handleEditTask = task => {
    setTask(task)
    dispatch(editTaskModal())
  };

  const handleMember = task => {
    setMemberTask(task)
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];


  let handleSearch = (key, value) => {
    let _searchParams = { ...searchParams, [key]: value }
    setSearchParams(_searchParams)
    dispatch(onGetTasks(params.id, false, _searchParams));
  }

  // eslint-disable-next-line no-unused-vars
  const handlePageChange = (page) => {
    dispatch(onGetTasks(params.id, true, { page, ...searchParams }));
  };

  function loadMore() {
    handlePageChange(pagination.page + 1)
  }

  let { is_next } = pagination

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteTask}
        onCloseClick={() => dispatch(deleteTaskModal())}
      />

      <Create projectId={params.id} task={task} />
      <Edit projectId={params.id} task={task} />

      <Member
        hide={() => setMemberTask(null)}
        isVisible={!!memberTask}
        projectId={params.id}
        task={memberTask}
      />

      <div className={props.component ? "" : "page-content"}>
        <MetaTags>
          <title>Tasks</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs back={true} breadcrumbItem="Tasks" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col sm="4">
                        <div className="search-box ms-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar
                              searchText={searchParams.search}
                              onSearch={text => handleSearch("search", text)} />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                      <Col sm="8">
                        <div className="text-sm-end">
                          <Button
                            type="button"
                            color="success"
                            className="btn-rounded  mb-2 me-2"
                            onClick={() => handleCreateTask()}
                          >
                            <i className="mdi mdi-plus me-1" />
                            New Task
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    {
                      Object.entries(tasksHash).map((data, index) => {
                        return (
                          <Card key={`table-${index}`}>
                            <CardHeader>
                              <Row>
                                <Col>
                                  <div className="d-flex align-items-center">
                                    <h2 className={`text-${controls[data[0]].color}`}>{data[0]}</h2>
                                    <h5 style={{ marginLeft: '0.5rem' }}>({data[1].length} Task)</h5>
                                  </div>
                                </Col>
                                <Col>
                                  <div onClick={() => { changeControls({ ...controls, [data[0]]: { ...controls[data[0]], isOpen: !controls[data[0]].isOpen } }) }} style={{ float: 'right', cursor: 'pointer' }}>
                                    <p className="font-size-16">
                                      {`Show ${controls[data[0]].isOpen ? 'Less' : 'More'}`}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                            </CardHeader>
                            <CardBody>
                              <Collapse isOpen={controls[data[0]].isOpen}>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        columns={COLS}
                                        data={data[1]}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={"table align-middle table-nowrap"}
                                        keyField="id"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </Collapse>
                              <div style={{ cursor: 'pointer' }} onClick={() => { handleCreateTask({ status: data[0] }) }}>
                                <p className={`text-${controls[data[0]].color} font-size-14`}>
                                  {`Add Task`}
                                </p>
                              </div>
                            </CardBody>
                          </Card>
                        )
                      })
                    }

                  </React.Fragment>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {
            is_next &&
            <Row>
              <Col xs="12">
                <div className="text-center mb-3">
                  <div style={{ cursor: "pointer" }} onClick={() => loadMore()} className="text-success">
                    <i className="bx bx-hourglass bx-spin me-2" />
                    Load more
                  </div>
                </div>
              </Col>
            </Row>
          }
        </Container>
      </div>
    </React.Fragment>
  );
};

Tasks.propTypes = {
  tasks: PropTypes.array,
  onGetTasks: PropTypes.func,
  onAddNewTask: PropTypes.func,
  onDeleteTask: PropTypes.func,
  onUpdateTask: PropTypes.func,
};

export default Tasks;
