import { getProjectStats } from 'api/v1';
import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'

function Stats() {
  const [stats, setStats] = useState({})

  async function loadProjectStats() {
    try {
      let _stats = await getProjectStats()
      setStats(_stats)
    } catch (error) { }
  }

  useEffect(() => {
    loadProjectStats()
  }, [])

  return (
    <Row>
      {/* Reports Render */}
      <Col md="4">
        <Card className="mini-stats-wid">
          <CardBody>
            <div className="d-flex">
              <div className="flex-grow-1">
                <p className="text-muted fw-medium">
                  Working On Project
                </p>
                <h4 className="mb-0">{stats?.total_working_projects || 0}</h4>
              </div>
              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                <span className="avatar-title rounded-circle bg-primary">
                  <i
                    className={
                      "fas fa-paint-brush font-size-24"
                    }
                  ></i>
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>

      <Col md="4">
        <Card className="mini-stats-wid">
          <CardBody>
            <div className="d-flex">
              <div className="flex-grow-1">
                <p className="text-muted fw-medium">
                  To Do Project
                </p>
                <h4 className="mb-0">{stats?.total_todo_projects || 0}</h4>
              </div>
              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                <span className="avatar-title rounded-circle bg-primary">
                  <i
                    className={
                      "far fa-calendar-check font-size-24"
                    }
                  ></i>
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>

      <Col md="4">
        <Card className="mini-stats-wid">
          <CardBody>
            <div className="d-flex">
              <div className="flex-grow-1">
                <p className="text-muted fw-medium">
                  Complete Project
                </p>
                <h4 className="mb-0">{stats?.total_complete_projects || 0}</h4>
              </div>
              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                <span className="avatar-title rounded-circle bg-primary">
                  <i
                    className={
                      "fas fa-briefcase font-size-24"
                    }
                  ></i>
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default Stats
