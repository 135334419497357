import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import * as moment from "moment";
import { Link, withRouter } from "react-router-dom"
import { Button, Col, Container, Row } from "reactstrap"
import { map } from "lodash"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Card
import CardStaff from "./card-staff"

import { Search } from "react-bootstrap-table2-toolkit";
import DeleteModal from "../../components/Common/DeleteModal";
import {
  getStaffs as onGetStaffs,
  deleteStaff as onDeleteStaff,
  newStaffModal,
  deleteStaffModal,
  editStaffModal
} from "store/staffs/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { isSeatAllowed, setLoader } from "utils/common";
import Create from './Create'
import Edit from './Edit'
import { getTenant } from "store/actions";

const StaffsGrid = props => {
  const dispatch = useDispatch();

  const { staffWithPagy, loading, deleteModal, tenant } = useSelector(state => ({
    staffWithPagy: state.staffs.staffWithPagy,
    loading: state.staffs.loading,
    deleteModal: state.staffs.delete,
    tenant: state.tenants.tenant
  }));
  let { results = [] } = staffWithPagy

  const [staff, setStaff] = useState(null);
  const [staffs, setStaffs] = useState([]);
  const [searchParams, setSearchParams] = useState({
    search: '',
  })


  var node = useRef();

  //delete staff

  const onClickDelete = (staff) => {
    setStaff(staff);
    dispatch(deleteStaffModal())
  };

  const handleDeleteStaff = () => {
    dispatch(onDeleteStaff(staff))
  }

  const { SearchBar } = Search;

  function init() {
    dispatch(getTenant())
    dispatch(onGetStaffs(true));
  }

  useEffect(() => {
    init()
  }, []);

  let pagination = {
    sizePerPage: staffWithPagy.page_size,
    totalSize: staffWithPagy.total,
    page: staffWithPagy.page,
    is_next: staffWithPagy?.links?.next != null
  }

  useEffect(() => {
    let { page } = pagination
    if (page == 1)
      setStaffs(results)
    else if (results.length > 0)
      setStaffs([...staffs, ...results])
  }, [results]);

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page }) => {
    if (type == "pagination")
      dispatch(onGetStaffs(false, { page, ...searchParams }));
  };

  const handleCreateStaff = () => {
    dispatch(newStaffModal())
  };

  const handleEditStaff = staff => {
    setStaff(staff)
    dispatch(editStaffModal())
  };

  let handleSearch = (key, value) => {
    let _searchParams = { ...searchParams, [key]: value }
    setSearchParams(_searchParams)
    dispatch(onGetStaffs(false, _searchParams));
  }

  function loadMore() {
    handleTableChange("pagination", { page: pagination.page + 1 })
  }

  let { is_next } = pagination

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteStaff}
        onCloseClick={() => dispatch(deleteStaffModal())}
      />

      <Create />
      <Edit staff={staff} />

      <div className="page-content">
        <MetaTags>
          <title>Staff</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem="Staff Members" />

          <Row className="mb-2">
            <Col sm="4">
              <div className="search-box ms-2 mb-2 d-inline-block">
                <div className="position-relative">
                  <SearchBar
                    searchText={searchParams.search}
                    onSearch={text => handleSearch("search", text)} />
                  <i className="bx bx-search-alt search-icon" />
                </div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                <Button
                  disabled={!isSeatAllowed(tenant)}
                  type="button"
                  color="success"
                  className="btn-rounded  mb-2 me-2"
                  onClick={handleCreateStaff}
                >
                  <i className="mdi mdi-plus me-1" />
                  New Staff Member
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            {map(staffs, (user, key) => (
              <CardStaff
                onClickDelete={onClickDelete}
                handleEditStaff={handleEditStaff}
                user={user}
                key={"_user_" + key}
              />
            ))}
          </Row>

          {
            is_next &&
            <Row>
              <Col xs="12">
                <div className="text-center my-3">
                  <div style={{ cursor: "pointer" }} onClick={() => loadMore()} className="text-success">
                    <i className="bx bx-hourglass bx-spin me-2" />
                    Load more
                  </div>
                </div>
              </Col>
            </Row>
          }
        </Container>
      </div>
    </React.Fragment>
  )
}

StaffsGrid.propTypes = {
  staffs: PropTypes.array,
  onGetStaffs: PropTypes.func,
  onAddNewStaff: PropTypes.func,
  onDeleteStaff: PropTypes.func,
  onUpdateStaff: PropTypes.func,
};

export default withRouter(StaffsGrid)
