import * as React from 'react';
export const loadingRef = React.createRef();


const setLoader = (loading) => {
  if (loading) {
    document.getElementById("preloader") && (document.getElementById("preloader").style.display = "block");
    document.getElementById("status") && (document.getElementById("status").style.display = "block");
  } else {
    document.getElementById("preloader") && (document.getElementById("preloader").style.display = "none");
    document.getElementById("status") && (document.getElementById("status").style.display = "none");
  }
};

const setTabBarLoader = (loading) => {
  if (loading) {
    loadingRef.current.continuousStart()
  } else {
    loadingRef.current.complete()
  }
};

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
})

function usFormat(amount) {
  return formatter.format(amount || 0)
}

function redirectURI(url) {
  return window.location.origin + `/${url}`
}

function isSeatAllowed(tenant) {
  if (!tenant) return false
  return tenant.seats_availed < tenant.seats_allowed
}

function getPrice(tenant) {
  if (!tenant) return 0
  let plan = tenant.plan

  if (tenant.seats_allowed <= plan.free_allowed_seats)
    return plan.price.unit_amount
  else
    return plan.price.unit_amount + (tenant.seats_allowed - plan.free_allowed_seats) * plan.additional_seat_price.unit_amount
}

function isSettingPage() {
  return window.location.pathname.includes("settings")
}


export {
  setLoader,
  setTabBarLoader,
  usFormat,
  redirectURI,
  isSeatAllowed,
  getPrice,
  isSettingPage
};


