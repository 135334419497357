import {
  GET_CATEGORIES_FAIL,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES,
  ADD_CATEGORY,
  EDIT_CATEGORY,
  DELETE_CATEGORY,
} from "./actionTypes";

const INIT_STATE = {
  categoryWithPagy: {
    total: 0,
    page: 1,
    page_size: 10,
    results: []
  },
  new: false,
  edit: false,
  delete: false,
  loading: false,
};

const categories = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CATEGORIES:
      return {
        ...state,
        new: false,
        edit: false,
        delete: false,
        loading: action.payload.loading
      };

    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categoryWithPagy: action.payload,
      };

    case ADD_CATEGORY:
      return {
        ...state,
        loading: true
      };

    case EDIT_CATEGORY:
      return {
        ...state,
        loading: true
      };

    case GET_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "NEW_CATEGORY_MODAL":
      return {
        ...state,
        new: !state.new
      };

    case "DELETE_CATEGORY_MODAL":
      return {
        ...state,
        delete: !state.delete
      };

    case "EDIT_CATEGORY_MODAL":
      return {
        ...state,
        edit: !state.edit
      };
    default:
      return state;
  }
};

export default categories;
