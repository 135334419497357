import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import { v4 as uuid } from 'uuid';

import DeleteIcon from 'assets/images/Delete.svg'
import EditIcon from 'assets/images/Edit.svg'

import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../components/Common/DeleteModal";
import {
  getTimesheets as onGetTimesheets,
  deleteTimesheet as onDeleteTimesheet,
  newTimesheetModal,
  deleteTimesheetModal,
  editTimesheetModal
} from "store/timesheets/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setLoader } from "utils/common";
import Create from './Create'
import Edit from './Edit'
import moment from "moment";
import { Link } from "react-router-dom";
import { getTask } from "store/actions";

const Timesheets = props => {
  const dispatch = useDispatch();

  const {
    match: { params },
  } = props;


  const { timesheetWithPagy, loading, deleteModal, taskDetail } = useSelector(state => ({
    timesheetWithPagy: state.timesheets.timesheetWithPagy,
    loading: state.timesheets.loading,
    deleteModal: state.timesheets.delete,
    taskDetail: state.tasks.taskDetail,
  }));
  let { results: timesheets } = timesheetWithPagy

  const [timesheet, setTimesheet] = useState(null);
  const [searchParams, setSearchParams] = useState({
    search: '',
  })

  //pagination customization
  const pageOptions = {
    sizePerPage: timesheetWithPagy.page_size,
    totalSize: timesheetWithPagy.total,
    page: timesheetWithPagy.page,
    custom: true,
  };

  const COLS = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      text: "#",
      dataField: "index",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row, rowIndex) => <>{rowIndex + 1}</>,
    },
    {
      dataField: "start_time",
      text: "Start Time",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => moment(row.start_time).format("yyyy-MM-DD,  hh:mm A"),
    },
    {
      dataField: "end_time",
      text: "End Time",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => moment(row.end_time).format("yyyy-MM-DD,  hh:mm A"),
    },
    {
      dataField: "total_hours",
      text: "Total Hours",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => row.total_hours,
    },
    {
      dataField: "custom",
      text: "Member",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        let _uuid = uuid()
        return (
          <Link
            to="#"
            className="team-member d-inline-block"
            id={`tooltip-${_uuid}`}
          >
            <img
              src={row.content_object.avatar}
              className="rounded-circle avatar-xs"
              alt=""
            />
            <UncontrolledTooltip
              placement="top"
              target={`tooltip-${_uuid}`}
            >
              {`${row.content_object.first_name} (${row.content_type})`}
            </UncontrolledTooltip>
          </Link>
        )
      }
    },
    {
      dataField: "menu",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, timesheet) => (
        <div className="d-flex align-items-center">
          <div onClick={() => handleEditTimesheet(timesheet)} className="me-1">
            <img
              src={EditIcon}
              height="20"
              width="20"
            />
          </div>
          <div onClick={() => onClickDelete(timesheet)} className="me-1">
            <img
              src={DeleteIcon}
              height="20"
              width="20"
            />
          </div>
        </div>
      ),
    },
  ];


  var node = useRef();

  //delete timesheet

  const onClickDelete = (timesheet) => {
    setTimesheet(timesheet);
    dispatch(deleteTimesheetModal())
  };

  const handleDeleteTimesheet = () => {
    dispatch(onDeleteTimesheet(params.id, params.task_id, timesheet))
  }

  useEffect(() => {
    dispatch(getTask(params.id, true, params.task_id, props.history))
  }, [])


  function init() {
    dispatch(onGetTimesheets(params.id, params.task_id, true));
  }

  useEffect(() => {
    init()
  }, []);

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page }) => {
    if (type == "pagination")
      dispatch(onGetTimesheets(params.id, params.task_id, true, { page, ...searchParams }));
  };

  const handleCreateTimesheet = () => {
    dispatch(newTimesheetModal())
  };

  const handleEditTimesheet = timesheet => {
    setTimesheet(timesheet)
    dispatch(editTimesheetModal())
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];


  let handleSearch = (key, value) => {
    let _searchParams = { ...searchParams, [key]: value }
    setSearchParams(_searchParams)
    dispatch(onGetTimesheets(params.id, params.task_id, false, _searchParams));
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteTimesheet}
        onCloseClick={() => dispatch(deleteTimesheetModal())}
      />

      <Create projectId={params.id} taskId={params.task_id} />
      <Edit projectId={params.id} taskId={params.task_id} timesheet={timesheet} />

      <div className={props.component ? "" : "page-content"}>
        <MetaTags>
          <title>Timesheets</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Timesheets" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={COLS}
                    data={timesheets}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <ToolkitProvider
                          keyField="id"
                          data={timesheets || []}
                          columns={COLS}
                          bootstrap4
                          search
                        >
                          {toolkitProps => {
                            return (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col sm="4">
                                  </Col>
                                  <Col sm="8">
                                    {
                                      taskDetail?.status != 'Processing' &&
                                      <div className="text-sm-end">
                                        <Button
                                          type="button"
                                          color="success"
                                          className="btn-rounded  mb-2 me-2"
                                          onClick={handleCreateTimesheet}
                                        >
                                          <i className="mdi mdi-plus me-1" />
                                          New Timesheet
                                        </Button>
                                      </div>
                                    }
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        noDataIndication={"No Data found"}
                                        defaultSorted={defaultSorted}
                                        classes={"table align-middle table-nowrap"}
                                        keyField="id"
                                        remote={{ search: true, pagination: true }}
                                        {...toolkitProps.baseProps}
                                        onTableChange={handleTableChange}
                                        {...paginationTableProps}
                                        ref={node}
                                      />
                                    </div>

                                  </Col>
                                </Row>
                                <Row className="align-items-md-center mt-30">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )
                          }}
                        </ToolkitProvider>
                      )
                    }}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Timesheets.propTypes = {
  timesheets: PropTypes.array,
  onGetTimesheets: PropTypes.func,
  onAddNewTimesheet: PropTypes.func,
  onDeleteTimesheet: PropTypes.func,
  onUpdateTimesheet: PropTypes.func,
};

export default Timesheets;
