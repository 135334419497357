import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'

import { editProjectModal, editProject } from 'store/projects/actions'
import moment from "moment";

function index({ project: target }) {
  let dispatch = useDispatch()
  const { edit: modal } = useSelector((state) => state.projects);
  let [project, setProject] = useState()

  useEffect(() => {
    if (modal) {
      setProject(target)
    }
  }, [modal])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      project_name: (project && project.project_name) || '',
      project_description: (project && project.project_description) || '',
      project_address: (project && project.project_address) || '',
      status: (project && project.status) || '',
      project_start_date: (project && moment(project.project_start_date).format('YYYY-MM-DD')) || '',
      project_end: (project && moment(project.project_end).format('YYYY-MM-DD')) || '',
      customer: (project && project.customer) || null,
    },
    validationSchema: Yup.object({
      customer: Yup.mixed().required("Select Customer"),
      project_name: Yup.string().required("Please add Project Name"),
      project_description: Yup.string().required("Please add Project Description"),
      project_address: Yup.string().required("Please add Project Address"),
      status: Yup.string().required("Please add Project Status"),
      project_start_date: Yup.string().required("Please add Project Start Date"),
      project_end: Yup.string().required("Please add Project End Date"),
    }),
    onSubmit: (values, actions) => {
      dispatch(editProject(project.id, values, actions))
    },
  });

  return (
    <React.Fragment>
      <Modal size="lg" isOpen={modal} toggle={() => dispatch(editProjectModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(editProjectModal())}>
          Edit Project
        </ModalHeader>
        <ModalBody>
          <Form
            validation={validation}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
