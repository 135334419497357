import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'

import { editPayrateModal, editPayrate } from 'store/payrates/actions'

function index({ payrate: target }) {
  let dispatch = useDispatch()
  const { edit: modal } = useSelector((state) => state.payrates);
  let [payrate, setPayrate] = useState()

  useEffect(() => {
    if (modal) {
      setPayrate(target)
    }
  }, [modal])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      rate: (payrate && payrate.rate) || '',
      user: (payrate && payrate.user) || null
    },
    validationSchema: Yup.object({
      rate: Yup.string().required("Please Enter Rate"),
      user: Yup.mixed().required("Please Enter Member"),
    }),
    onSubmit: (values, actions) => {
      dispatch(editPayrate(payrate.id, values, actions))
    },
  });



  return (
    <React.Fragment>
      <Modal isOpen={modal} toggle={() => dispatch(editPayrateModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(editPayrateModal())}>
          Edit Payrate
        </ModalHeader>
        <ModalBody>
          <Form
            edit={true}
            validation={validation}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
