import {
  GET_TIMESHEETS_FAIL,
  GET_TIMESHEETS_SUCCESS,
  GET_TIMESHEETS,
  ADD_TIMESHEET,
  EDIT_TIMESHEET,
  DELETE_TIMESHEET,
} from "./actionTypes";

const INIT_STATE = {
  timesheetWithPagy: {
    total: 0,
    page: 1,
    page_size: 10,
    results: []
  },
  new: false,
  edit: false,
  delete: false,
  loading: false,
  loadingImages: false,
  images: [],
  timesheetDetail: null
};

const timesheets = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'GET_TIMESHEET':
      return {
        ...state,
        timesheetDetail: null,
        new: false,
        edit: false,
        delete: false,
        loading: action.payload.loading
      };

    case 'GET_TIMESHEET_IMAGES':
      return {
        ...state,
        images: [],
        loadingImages: true
      };

    case 'DELETE_TIMESHEET_IMAGE':
      return {
        ...state,
        loadingImages: true
      };

    case 'SET_TIMESHEET_IMAGES':
      return {
        ...state,
        images: action.payload,
        loadingImages: false
      };

    case GET_TIMESHEETS:
      return {
        ...state,
        new: false,
        edit: false,
        delete: false,
        loading: action.payload.loading
      };

    case 'SET_TIMESHEET_DETAIL':
      return {
        ...state,
        loading: false,
        timesheetDetail: action.payload
      };

    case GET_TIMESHEETS_SUCCESS:
      return {
        ...state,
        loading: false,
        timesheetWithPagy: action.payload,
      };

    case ADD_TIMESHEET:
      return {
        ...state,
        loading: true
      };

    case EDIT_TIMESHEET:
      return {
        ...state,
        loading: true
      };

    case GET_TIMESHEETS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "NEW_TIMESHEET_MODAL":
      return {
        ...state,
        new: !state.new
      };

    case "DELETE_TIMESHEET_MODAL":
      return {
        ...state,
        delete: !state.delete
      };

    case "EDIT_TIMESHEET_MODAL":
      return {
        ...state,
        edit: !state.edit
      };
    default:
      return state;
  }
};

export default timesheets;
