import { filterParams } from "../apiHelper";
import request from "../restClient";

export const getTimesheetReports = async (params) => {
  return await request({
    url: "project/timesheets/reports/",
    method: "GET",
    params: filterParams(params),
    cancelPrevious: params.cancelPrevious || true,
  });
};

export const getTimesheetReport = async (id) => {
  return await request({
    url: `project/timesheets/reports/${id}/`,
    method: "GET"
  });
};

export const deleteTimesheetReport = async (id) => {
  return await request({
    url: `project/timesheets/reports/${id}/`,
    method: "DELETE"
  });
};

export const generateTimesheetReport = async (id) => {
  return await request({
    url: `project/timesheets/reports/${id}/generate_pdf/`,
    method: "PATCH"
  });
};

export const editTimesheetReport = async (id, data) => {

  return await request({
    url: `project/timesheets/reports/${id}/`,
    method: "PATCH",
    data
  });
};

export const addTimesheetReport = async (data) => {

  return await request({
    url: `project/timesheets/reports/`,
    method: "POST",
    data
  });
};
