import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';
import {
  GET_MATERIALS,
  DELETE_MATERIAL,
  ADD_MATERIAL,
  EDIT_MATERIAL
} from "./actionTypes";

import {
  getMaterialsSuccess,
  getMaterials,
  getMaterialsFail,
  deleteMaterialModal
} from "./actions";

import {
  getMaterials as getMaterialsAPI,
  deleteMaterial as deleteMaterialAPI,
  addMaterial as addMaterialAPI,
  editMaterial as editMaterialAPI,
} from "api/v1";


function* fetchMaterials({ payload: { params } }) {
  try {
    const response = yield call(getMaterialsAPI, params);
    yield put(getMaterialsSuccess(response));
  } catch (error) {
    yield put(getMaterialsFail("Something went wrong!"))
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* addMaterial({ payload: { material, actions } }) {
  try {
    yield call(addMaterialAPI, material)
    toast.success("Material Created!");
    yield put(getMaterials(true));
    actions.resetForm()

  } catch ({ errors }) {
    yield put(getMaterialsFail("Creation failed!"))
    if (errors) {
      actions.setErrors(errors)

    }
    toast.error("Something went wrong!");
  }
}

function* editMaterial({ payload: { id, material, actions } }) {
  try {
    yield call(editMaterialAPI, id, material)
    toast.success("Material Updated!");
    yield put(getMaterials(true));

  } catch ({ errors }) {
    yield put(getMaterialsFail("Creation failed!"))
    if (errors) {
      actions.setErrors(errors)

    }
    toast.error("Something went wrong!");
  }
}

function* deleteMaterial({ payload: { material } }) {
  try {
    yield call(deleteMaterialAPI, material.id);
    put(deleteMaterialModal())
    yield put(getMaterials(true));
    toast.warn("Material Deleted!");
  } catch (error) {
    toast.error("Something went wrong!");
  }
}

function* materialSaga() {
  yield takeEvery(GET_MATERIALS, fetchMaterials);
  yield takeEvery(DELETE_MATERIAL, deleteMaterial);
  yield takeEvery(ADD_MATERIAL, addMaterial);
  yield takeEvery(EDIT_MATERIAL, editMaterial);
}

export default materialSaga;
