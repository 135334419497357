import React, { useEffect } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { isEmpty } from "lodash";
import { Col, Container, Row } from "reactstrap";
import {
  getTask
} from "store/tasks/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setLoader } from "utils/common";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import TaskDetail from "./taskDetail";
import TaskMembers from './TaskMembers'
import Timesheets from "pages/TimeSheet";
//redux

const TaskOverview = props => {

  const {
    match: { params },
  } = props;

  useEffect(() => {
    dispatch(getTask(params.id, true, params.task_id, props.history))
  }, [])

  const dispatch = useDispatch();

  const { taskDetail, loading } = useSelector(state => ({
    taskDetail: state.tasks.taskDetail,
    loading: state.tasks.loading,
  }));

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Task Overview | AllPromana
          </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs back={true} title="Tasks" breadcrumbItem="Task Overview" />

          {!isEmpty(taskDetail) && (
            <>
              <Row>
                <Col lg="8">
                  <TaskDetail task={taskDetail} />
                </Col>
                <Col lg="4">
                  <TaskMembers members={[...taskDetail.staff?.map(_item => ({ ..._item, type: 'Staff' })), ...taskDetail.subcontractor?.map(_item => ({ ..._item, type: 'Subcontractor' }))]} />
                </Col>
              </Row>

            </>
          )}
        </Container>

        <Timesheets
          component={true}
          {...props}
        />
      </div>
    </React.Fragment>
  );
};

TaskOverview.propTypes = {
  match: PropTypes.object,
};

export default withRouter(TaskOverview);
