import { billingPortal } from 'api/v1'
import React, { useState } from 'react'
import { Alert } from 'reactstrap'
import { redirectURI } from 'utils/common'

function Status({ settings }) {
  const [loading, setLoading] = useState(false)

  async function getBillingPortalUrl() {
    setLoading(true)
    try {
      const { billing_portal_url } = await billingPortal({ return_url: redirectURI('settings/subscriptions') })
      window.location.href = billing_portal_url
    } catch (error) { }
    setLoading(false)
  }

  if (!settings.is_subscription_setup)
    return null

  return (
    <div className="col-lg-5 col-xl-6 mb-3">
      <div className="item">
        <div className="heading">
          <h3>Subscription Status</h3>
        </div>

        <div className="features mt-4">
          <Alert color={settings.is_subscription_active ? "success" : "danger"}>
            {settings.is_subscription_active ? "Your account is in good standing status" : "Your credit card is declined, please check through billing portal"}
          </Alert>
        </div>

        <div className="pricing">
          <button
            onClick={getBillingPortalUrl}
            disabled={loading}
            className={`btn btn-block btn${loading ? '' : `-outline`}-primary w-100 mt-1`}>
            {
              loading ? <i className="bx bx-hourglass bx-spin font-size-16 align-middle"></i>
                : `Billing Portal`
            }
          </button>
        </div>
      </div>
    </div>
  )
}

export default Status
