import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, CardTitle, Col, Nav, NavItem, NavLink, Row } from 'reactstrap'
import classnames from "classnames";
import ReactApexChart from 'react-apexcharts';
import { getProjectHours } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import Loader from 'common/Loader';

let range = (type) => {
  let _format = "YYYY-MM-DDThh:mm:ssZ"
  let _startDate, _endDate

  if (type == "week") {
    _endDate = moment().format(_format)
    _startDate = moment().subtract(7, 'd').format(_format)
  } else if (type == "month") {
    _endDate = moment().format(_format)
    _startDate = moment().subtract(1, 'month').format(_format)
  }
  else {
    _endDate = moment().format(_format)
    _startDate = moment().subtract(1, 'year').format(_format)
  }

  return { start_date: _startDate, end_date: _endDate }
}

function HoursDetails({ projectId }) {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    series: [],
    categories: []
  })

  const { projectHours, loading } = useSelector(state => ({
    projectHours: state.projects.projectHours,
    loading: state.projects.hoursLoading,
  }));

  const [searchParams, setSearchParams] = useState({
    type: 'week',
    ...range("week")
  })

  useEffect(() => {
    dispatch(getProjectHours(projectId, true, searchParams));
  }, [])

  let handleFilterChange = (type) => {
    let _searchParams = { type, ...range(type) }
    setSearchParams(_searchParams)
    dispatch(getProjectHours(projectId, true, _searchParams));
  }

  useEffect(() => {
    if (projectHours.length > 0) {

      let _data = {
        series: [],
        categories: []
      }

      let _selector = Object.keys(projectHours[0])[0]
      _data.series = projectHours.map(_hour => _hour.hours_worked)
      _data.categories = projectHours.map(_hour => _hour[_selector])

      setData(_data)
    }


  }, [projectHours])


  const series = [
    {
      name: "Total Hours",
      data: data.series,
    }
  ];
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '20px',
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },

    colors: ["#2DB75B"],
    xaxis: {
      axisTicks: {
        show: false
      },
      categories: data.categories,
    },
    yaxis: {
      labels: {
        formatter: (value) => { return value + "H" },
      },
      axisBorder: {
        show: true
      }
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " hours";
        },
      },
    },
  };

  return (
    <Card style={{ borderWidth: 1, borderColor: '#F1F1F1' }}>
      <CardHeader style={{ paddingBottom: 0 }}>
        <Row>
          <Col>
            <CardTitle>Total Hours</CardTitle>
          </Col>

          <Col>
            <Nav tabs className="nav-tabs-custom nav-end" style={{ float: 'right' }}>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: searchParams.type == "week"
                  })}
                  onClick={() => {
                    handleFilterChange("week");
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="fas fa-home"></i>
                  </span>
                  <span className="d-none d-sm-block">Week</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: searchParams.type == "month"
                  })}
                  onClick={() => {
                    handleFilterChange("month");
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="far fa-user"></i>
                  </span>
                  <span className="d-none d-sm-block">Month</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: searchParams.type == "year"
                  })}
                  onClick={() => {
                    handleFilterChange("year");
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="far fa-envelope"></i>
                  </span>
                  <span className="d-none d-sm-block">Year</span>
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        {
          loading ?
            <Loader />
            : <ReactApexChart options={options} series={series} type="bar" height={350} />
        }
      </CardBody>
    </Card>
  )
}

export default HoursDetails
