import { filterParams } from "../apiHelper";
import request from "../restClient";

export const getExpenses = async (projectId, params) => {
  return await request({
    url: `project/${projectId}/expenses/`,
    method: "GET",
    params: filterParams(params),
    cancelPrevious: params.cancelPrevious || true,
  });
};

export const deleteExpense = async (projectId, id) => {
  return await request({
    url: `project/${projectId}/expenses/${id}/`,
    method: "DELETE"
  });
};

export const editExpense = async (projectId, id, data) => {
  const formD = new FormData()
  Object.entries(data).forEach((keyValue) => {
    if (keyValue[0] != "avatar" || typeof (keyValue[1]) != "string")
      formD.append(keyValue[0], keyValue[1])
  })

  return await request({
    url: `project/${projectId}/expenses/${id}/`,
    method: "PATCH",
    headers: {
      'Content-type': 'multipart/form-data',
    },
    data: formD
  });
};

export const addExpense = async (projectId, data) => {
  const formD = new FormData()
  Object.entries(data).forEach((keyValue) => {
    formD.append(keyValue[0], keyValue[1])
  })

  return await request({
    url: `project/${projectId}/expenses/`,
    method: "POST",
    headers: {
      'Content-type': 'multipart/form-data',
    },
    data: formD
  });
};
