import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import MemberTable from "./MemberTable";
import { toast } from "react-toastify";
import { getTaskMembers } from "api/v1";

function Member({ task: target, projectId, hide, isVisible }) {
  let [task, setTask] = useState()
  const [loading, setLoading] = useState(false)
  const [members, setMembers] = useState([])

  async function loadMembers() {
    setLoading(true)
    try {
      let _members = await getTaskMembers(projectId, task.id, { no_paginate: true })
      setMembers(_members)
    } catch (error) {
      toast.error("Something went wrong loading members")
    }
    setLoading(false)
  }

  useEffect(() => {
    if (isVisible) {
      setTask(target)
    }
  }, [isVisible])

  useEffect(() => {
    if (task && isVisible)
      loadMembers()
  }, [task, isVisible])


  return (
    <React.Fragment>
      <Modal size="lg" isOpen={isVisible} toggle={hide}>
        <ModalHeader tag="h4" toggle={hide}>
          Edit Members
        </ModalHeader>
        <ModalBody>
          <MemberTable task={task} members={members} loadingMembers={loading} />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default Member
