import React, { useEffect, useState, useRef, Fragment } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

import {
  getTimeoffs as onGetTimeoffs,
} from "store/timeoffs/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "common/Loader";
import moment from "moment";

const EcommerceTimeoffs = props => {
  const dispatch = useDispatch();

  const { timeoffWithPagy, loading } = useSelector(state => ({
    timeoffWithPagy: state.timeoffs.timeoffWithPagy,
    loading: state.timeoffs.loading,
  }));
  let { results: timeoffs } = timeoffWithPagy

  const [searchParams, setSearchParams] = useState({
    status: 'approved'
  })

  //pagination customization
  const pageOptions = {
    sizePerPage: timeoffWithPagy.page_size,
    totalSize: timeoffWithPagy.total,
    page: timeoffWithPagy.page,
    custom: true,
  };

  const COLS = [
    {
      text: "id",
      dataField: "id",
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      dataField: 'custom',
      text: "Members",
      sort: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        let member = row.user.profile
        return (
          <div className="d-flex flex-column">
            <div className="avatar-group">
              {!member.avatar || member.avatar !== "Null" && (
                <div
                  className="avatar-group-item"
                  key={row.id}
                >
                  <Link
                    to="#"
                    className="team-member d-inline-block"
                    id={`tooltip-${row.id}`}
                  >
                    <img
                      src={member.avatar}
                      className="rounded-circle avatar-xs"
                      alt=""
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target={`tooltip-${row.id}`}
                    >
                      {`${member.first_name} (${row.user.type})`}
                    </UncontrolledTooltip>
                  </Link>
                </div>
              )
              }
            </div>
            <small className="mt-1">{member.first_name} {member.last_name}</small>
          </div>
        )
      },
    },
    {
      dataField: "from_date",
      text: "Start Date",
      formatter: (cellContent, row) => (
        <>
          <p className="mb-0">{handleValidDate(row.from_date)}</p>
          <small className="mb-1 text-muted">From Date</small>
        </>
      ),
    },
    {
      dataField: "to_date",
      text: "End Date",
      formatter: (cellContent, row) => (
        <>
          <p className="mb-0">{handleValidDate(row.to_date)}</p>
          <small className="mb-1 text-muted">To Date</small>
        </>
      ),
    }
  ];


  var node = useRef();

  //delete timeoff

  function init() {
    dispatch(onGetTimeoffs(true, searchParams));
  }

  /** set Date formate */
  const handleValidDate = date => {
    const date1 = date ? moment(new Date(date)).format("DD MMM Y") : "N/A";
    return date1;
  };

  useEffect(() => {
    init()
  }, []);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page }) => {
    if (type == "pagination")
      dispatch(onGetTimeoffs(true, { page, ...searchParams }));
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  return (
    <React.Fragment>
      <Row>
        <Col xs="12">
          <Card style={{ borderWidth: 1, borderColor: '#F1F1F1' }}>
            <CardHeader className="bg-white pt-3">
              <Row>
                <Col>
                  <CardTitle className="mb-2">Schedule Day off</CardTitle>
                </Col>

                <Col className="text-end">
                  <Link to="/timeoffs" className="mb-2">View more</Link>
                </Col>
              </Row>
            </CardHeader>

            <CardBody>
              {
                loading ?
                  <Loader message={"Please wait"} />
                  :
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={COLS}
                    data={timeoffs}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <ToolkitProvider
                          keyField="id"
                          data={timeoffs.slice(0, 3) || []}
                          columns={COLS}
                          bootstrap4
                          search
                        >
                          {toolkitProps => {
                            return (
                              <React.Fragment>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        noDataIndication={"No Data found"}
                                        defaultSorted={defaultSorted}
                                        classes={"table align-middle table-nowrap"}
                                        keyField="id"
                                        remote={{ search: true, pagination: true }}
                                        {...toolkitProps.baseProps}
                                        onTableChange={handleTableChange}
                                        {...paginationTableProps}
                                        ref={node}
                                      />
                                    </div>

                                  </Col>
                                </Row>
                              </React.Fragment>
                            )
                          }}
                        </ToolkitProvider>
                      )
                    }}
                  </PaginationProvider>
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

EcommerceTimeoffs.propTypes = {
  timeoffs: PropTypes.array,
  onGetTimeoffs: PropTypes.func,
  onAddNewTimeoff: PropTypes.func,
  onDeleteTimeoff: PropTypes.func,
  onUpdateTimeoff: PropTypes.func,
};

export default EcommerceTimeoffs;
