import {
  GET_PURCHASES_FAIL,
  GET_PURCHASES_SUCCESS,
  GET_PURCHASES,
  ADD_PURCHASE,
  EDIT_PURCHASE,
  DELETE_PURCHASE,
} from "./actionTypes";

const INIT_STATE = {
  purchaseWithPagy: {
    total: 0,
    page: 1,
    page_size: 10,
    results: []
  },
  new: false,
  edit: false,
  delete: false,
  loading: false,
};

const purchases = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PURCHASES:
      return {
        ...state,
        new: false,
        edit: false,
        delete: false,
        loading: action.payload.loading
      };

    case GET_PURCHASES_SUCCESS:
      return {
        ...state,
        loading: false,
        purchaseWithPagy: action.payload,
      };

    case ADD_PURCHASE:
      return {
        ...state,
        loading: true
      };

    case EDIT_PURCHASE:
      return {
        ...state,
        loading: true
      };

    case DELETE_PURCHASE:
      return {
        ...state,
        loading: true
      };

    case GET_PURCHASES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "NEW_PURCHASE_MODAL":
      return {
        ...state,
        new: !state.new
      };

    case "DELETE_PURCHASE_MODAL":
      return {
        ...state,
        delete: !state.delete
      };

    case "EDIT_PURCHASE_MODAL":
      return {
        ...state,
        edit: !state.edit
      };
    default:
      return state;
  }
};

export default purchases;
