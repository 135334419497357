import Loader from 'common/Loader';
import React from 'react'

import { useSelector } from "react-redux";

function ImageContainer({ handleDeleteImage }) {

  const { images, loading } = useSelector(state => ({
    images: state.tasks.images,
    loading: state.tasks.loadingImages,
  }));

  return (
    <React.Fragment>
      {
        loading ? <Loader />
          :
          <React.Fragment>
            {
              images.length > 0 &&
              <div className="store-image-modal">
                <div className="image-section">
                  {
                    images.map((image) => (
                      <div
                        key={image.id}
                        className="image-wrapper"
                      >
                        <img
                          src={image.image}
                          alt=""
                        />
                        <div className="overlay">
                          <button
                            onClick={() => handleDeleteImage(image.id)}
                            type="button"
                            className="btn btn-outline-danger rounded-circle"
                          >
                            <i className="mdi mdi-trash-can-outline" />
                          </button>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            }
          </React.Fragment>
      }
    </React.Fragment>

  )
}

export default ImageContainer
