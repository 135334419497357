import React from "react";
import {
  Col,
  Row,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap";
import AsyncSelect from 'react-select/async';
import { getEmployees } from "api/v1";

function index({ validation, edit = false }) {

  let loadEmployees = (search) => {
    return getEmployees({ search, no_paginate: 1, no_payrate: 1 })
      .then((results = []) => {
        return results
      })
      .catch(({ message }) => {
        if (message !== 'request-cancel') {
          toast.error(message);
        }
      })
  }

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <Row form>
        <Col xs="12" className="mb-3">
          <Label className="form-label">Select Employee</Label>
          <AsyncSelect
            defaultOptions
            isDisabled={edit}
            placeholder="Select Member"
            required
            value={validation.values.user}
            getOptionLabel={({ profile: { first_name, last_name } }) => `${first_name} ${last_name}`}
            getOptionValue={({ id }) => id}
            loadOptions={loadEmployees}
            onChange={(_user) => {
              validation.setFieldValue('user', _user)
            }}

          />
          {validation.touched.user && validation.errors.user ? (
            <span className="text-danger font-size-11">{validation.errors.user}</span>
          ) : null}
        </Col>

        <Col className="col-12">
          <div className="mb-3">
            <Label className="form-label">Rate</Label>
            <Input
              name="rate"
              type="number"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.rate || ""}
              invalid={
                validation.touched.rate && validation.errors.rate ? true : false
              }
            />
            {validation.touched.rate && validation.errors.rate ? (
              <FormFeedback type="invalid">{validation.errors.rate}</FormFeedback>
            ) : null}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="text-end">
            <button
              type="submit"
              className="btn btn-success save-customer"
            >
              Save
            </button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default index
