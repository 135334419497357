import {
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  DELETE_USER,
  ADD_USER,
  EDIT_USER
} from "./actionTypes"

export const getUsers = (loading = false, params = {}) => ({
  type: GET_USERS,
  payload: { loading, params }
})

export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users,
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})

export const deleteUser = user => ({
  type: DELETE_USER,
  payload: { user }
})

export const addUser = (user, actions) => {
  return {
    type: ADD_USER,
    payload: { user, actions },
  }
}

export const editUser = (id, user, actions) => {
  return {
    type: EDIT_USER,
    payload: { id, user, actions },
  }
}

export const newUserModal = () => ({
  type: 'NEW_USER_MODAL'
})

export const deleteUserModal = () => ({
  type: 'DELETE_USER_MODAL'
})

export const editUserModal = () => ({
  type: 'EDIT_USER_MODAL'
})
