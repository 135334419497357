import {
  GET_TIMESHEET_REPORTS_FAIL,
  GET_TIMESHEET_REPORTS_SUCCESS,
  GET_TIMESHEET_REPORTS,
  ADD_TIMESHEET_REPORT,
  EDIT_TIMESHEET_REPORT,
  DELETE_TIMESHEET_REPORT,
} from "./actionTypes";

const INIT_STATE = {
  timesheetReportWithPagy: {
    total: 0,
    page: 1,
    page_size: 10,
    results: []
  },
  new: false,
  edit: false,
  delete: false,
  generatableTimesheetReport: null,
  generate: false,
  timesheetReport: null,
  loading: false,
};

const timesheetReports = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TIMESHEET_REPORTS:
      return {
        ...state,
        new: false,
        edit: false,
        delete: false,
        loading: action.payload.loading
      };
    case 'GET_TIMESHEET_REPORT':
      return {
        ...state,
        timesheetReport: null,
        loading: true
      };

    case 'SET_GENERATABLE_TIMESHEET_REPORT':
      return {
        ...state,
        generatableTimesheetReport: action.payload.timesheetReport
      };

    case GET_TIMESHEET_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        timesheetReportWithPagy: action.payload,
      };

    case 'GET_TIMESHEET_REPORT_SUCCESS':
      return {
        ...state,
        loading: false,
        timesheetReport: action.payload,
      };

    case 'GET_TIMESHEET_REPORT_FAILED':
      return {
        ...state,
        loading: false,
        timesheetReport: null
      };

    case ADD_TIMESHEET_REPORT:
      return {
        ...state,
        loading: true
      };

    case DELETE_TIMESHEET_REPORT:
      return {
        ...state,
        loading: true
      };

    case 'GENERATE_TIMESHEET_REPORT':
      return {
        ...state,
        loading: true
      };

    case EDIT_TIMESHEET_REPORT:
      return {
        ...state,
        loading: true
      };

    case GET_TIMESHEET_REPORTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "NEW_TIMESHEET_REPORT_MODAL":
      return {
        ...state,
        new: !state.new
      };

    case "DELETE_TIMESHEET_REPORT_MODAL":
      return {
        ...state,
        delete: !state.delete
      };

    case "GENERATE_TIMESHEET_REPORT_MODAL":
      return {
        ...state,
        generate: !state.generate
      };

    case "EDIT_TIMESHEET_REPORT_MODAL":
      return {
        ...state,
        edit: !state.edit
      };
    default:
      return state;
  }
};

export default timesheetReports;
