import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { Row, Col, Alert, Form, Input, FormFeedback, Label } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { setLoader } from "utils/common";
import { findUser } from "store/actions";

const FindUser = ({ subdomain, isSubdomainPresent, ...props }) => {
  const dispatch = useDispatch();

  const commonSchema = Yup.object({
    email: Yup.string().required("Please Enter Your Email"),
  })
  const commonValues = {
    email: '',
  }

  const form = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: isSubdomainPresent ? commonValues : { ...commonValues, subdomain: '' },
    validationSchema: isSubdomainPresent ? commonSchema : commonSchema.concat(Yup.object({ subdomain: Yup.string().required("Please Enter Your Subdomain") })),
    onSubmit: (values, actions) => {
      let { subdomain: _subdomain } = values

      dispatch(findUser({ ...values, subdomain: _subdomain || subdomain }, actions));
    }
  });

  const { error, loading, success } = useSelector((state) => state.Login);


  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  return (
    <Form
      className="form-horizontal"
      onSubmit={(e) => {
        e.preventDefault();
        form.handleSubmit();
        return false;
      }}
    >
      {error ? <Alert color="danger">{error}</Alert> : null}
      {success && success ? (
        <Alert color="success">
          {success}
        </Alert>
      ) : null}

      {!subdomain && <div className="mb-3">
        <Label className="form-label">Company name:</Label>
        <Input
          name="subdomain"
          type="text"
          placeholder="Enter your Company name"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.subdomain || ""}
          invalid={
            form.touched.subdomain && form.errors.subdomain ? true : false
          }
        />
        {form.touched.subdomain && form.errors.subdomain ? (
          <FormFeedback type="invalid">{form.errors.subdomain}</FormFeedback>
        ) : null}
      </div>}

      <div className="mb-3">
        <Label className="form-label">Email</Label>
        <Input
          id="email"
          name="email"
          className="form-control"
          placeholder="Enter email"
          type="email"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.email || ""}
          invalid={
            form.touched.email && form.errors.email ? true : false
          }
        />
        {form.touched.email && form.errors.email ? (
          <FormFeedback type="invalid">{form.errors.email}</FormFeedback>
        ) : null}
      </div>

      <Row className="py-2">
        <Col>
        </Col>

        <Col>
          <p className="mb-0">
            <Link to="/forgot-password" style={{ float: 'right' }} className="font-weight-medium text-primary">
              Forgot Password
            </Link>
          </p>
        </Col>
      </Row>

      <div className="mt-2">
        <button
          className="btn btn-primary d-block w-100"
          type="submit"
        >
          Find
        </button>
      </div>
    </Form>
  );
};

export default withRouter(FindUser);

FindUser.propTypes = {
  history: PropTypes.object,
};
