import request from "../restClient";

export const getPlans = async () => {
  return await request({
    url: "plans/",
    method: "GET"
  });
};

export const createSubscription = async (data) => {
  return await request({
    url: "subscriptions/",
    method: "POST",
    data
  });
};

export const updateSubscription = async (data) => {
  return await request({
    url: "subscriptions/update",
    method: "POST",
    data
  });
};

export const billingPortal = async (data) => {
  return await request({
    url: "billing_portal/",
    method: "POST",
    data
  });
};
