import React from 'react'
import { Button, Col, Input, Label, Row } from 'reactstrap'

function Filters({ params, handleSearch, handleSearchChange }) {

  return (
    <div className="mb-4">
      <Row>
        <Col sm="2">
          <Label className="form-label">From Date</Label>
          <Input
            name="from_date"
            type="date"
            onChange={handleSearchChange}
            value={params.from_date}
          />
        </Col>
        <Col sm="2">
          <Label className="form-label">To Date</Label>
          <Input
            name="to_date"
            type="date"
            onChange={handleSearchChange}
            value={params.to_date}
          />
        </Col>
        <Col sm="2">
          <Label className="form-label">Project Name</Label>
          <Input
            name="project_name"
            type="text"
            onChange={handleSearchChange}
            value={params.project_name}
          />
        </Col>
        <Col sm="2">
          <Label className="form-label">Member Name</Label>
          <Input
            name="name"
            type="text"
            onChange={handleSearchChange}
            value={params.name}
          />
        </Col>

        <Col sm="4">
          <div className="mt-4">
            <Button
              type="button"
              color="success"
              className="btn-rounded"
              onClick={handleSearch}
            >
              <i className="bx bx-search-alt me-1" />
              Search
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Filters
