import { filterParams } from "../apiHelper";
import request from "../restClient";

export const getPayrates = async (params) => {
  return await request({
    url: "payrates/",
    method: "GET",
    params: filterParams(params),
    cancelPrevious: params.cancelPrevious || true,
  });
};

export const deletePayrate = async (id) => {
  return await request({
    url: `payrates/${id}/`,
    method: "DELETE"
  });
};

export const editPayrate = async (id, data) => {
  const formD = new FormData()
  Object.entries(data).forEach((keyValue) => {
    if (keyValue[0] == "user")
      formD.append(keyValue[0], keyValue[1]?.id)
    else
      formD.append(keyValue[0], keyValue[1])
  })

  return await request({
    url: `payrates/${id}/`,
    method: "PATCH",
    headers: {
      'Content-type': 'multipart/form-data',
    },
    data: formD
  });
};

export const addPayrate = async (data) => {
  const formD = new FormData()
  Object.entries(data).forEach((keyValue) => {
    if (keyValue[0] == "user")
      formD.append(keyValue[0], keyValue[1]?.id)
    else
      formD.append(keyValue[0], keyValue[1])
  })

  return await request({
    url: `payrates/`,
    method: "POST",
    headers: {
      'Content-type': 'multipart/form-data',
    },
    data: formD
  });
};
