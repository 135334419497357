import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import * as moment from "moment";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row
} from "reactstrap";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { Menu, MenuItem } from '@szhsin/react-menu';

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../components/Common/DeleteModal";
import {
  getPayrolls as onGetPayrolls,
  deletePayroll as onDeletePayroll,
  newPayrollModal,
  deletePayrollModal,
  editPayrollModal
} from "store/payrolls/actions";

const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

//redux
import { useSelector, useDispatch } from "react-redux";
import { setLoader } from "utils/common";
import Create from './Create'
import Edit from './Edit'
import PayPayroll from "./PayPayroll";
import Filters from "./Filters";

const EcommercePayrolls = () => {
  const dispatch = useDispatch();

  const { payrollWithPagy, loading, deleteModal } = useSelector(state => ({
    payrollWithPagy: state.payrolls.payrollWithPagy,
    loading: state.payrolls.loading,
    deleteModal: state.payrolls.delete,
  }));
  let { results: payrolls } = payrollWithPagy

  const [payroll, setPayroll] = useState(null);
  const [payable, setPayable] = useState(null)
  const [searchParams, setSearchParams] = useState({
    name: '',
    project_name: '',
    from_date: '',
    to_date: ''
  })

  //pagination customization
  const pageOptions = {
    sizePerPage: payrollWithPagy.page_size,
    totalSize: payrollWithPagy.total,
    page: payrollWithPagy.page,
    custom: true,
  };

  const COLS = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      dataField: "projects",
      text: "Project",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{`${row.projects.length == 0 ? 'N/A' : `${row.projects.slice(0, 2).map(_project => _project.project_name).join(',')}${row.projects.length > 2 ? '...' : ""}`}`}</p>
        </>
      ),
    },
    {
      dataField: "payrate",
      text: "Member",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <i className="mb-1">{`${row.payrate.user?.profile?.first_name} ${row.payrate.user?.profile?.last_name} (${row.payrate.user?.type})`}</i>
        </>
      ),
    },
    {
      dataField: "company_name",
      text: "Company",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <i className="mb-1">{row.payrate.user?.profile?.company_name}</i>
        </>
      ),
    },
    {
      dataField: "cheque_no",
      text: "Cheque No",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => row.cheque_no,
    },
    {
      dataField: "start_date",
      text: "Start Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.payment_period_start),
    },
    {
      dataField: "end_date",
      text: "End Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.payment_period_end),
    },
    {
      text: "Total Hours",
      dataField: "hours_worked",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <i className="mb-1">{`${row.hours_worked} + ${row.overtime_hours || 0}`}</i>
        </>
      ),
    },
    {
      text: "Pay per hour",
      dataField: "pay_per_hour",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <i className="mb-1">{`${row.rate_per_hour}`}</i>
        </>
      ),
    },
    {
      text: "Total Payment",
      dataField: "total_payment",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <i className="mb-1">{`$${row.total_payment}`}</i>
        </>
      ),
    },
    {
      dataField: "menu",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, payroll) => (
        <Menu menuButton={<i className="mdi mdi-dots-horizontal font-size-18" />} transition>
          <MenuItem disabled={!!payroll.paid_at} onClick={() => setPayable(payroll)}>{!payroll.paid_at ? "Mark as Paid" : 'Paid'}</MenuItem>
          <MenuItem onClick={() => openInNewTab(payroll.pdf_file)}>Print</MenuItem>
          <MenuItem onClick={() => handleEditPayroll(payroll)}>Edit</MenuItem>
          <MenuItem onClick={() => onClickDelete(payroll)}>Delete</MenuItem>
        </Menu>
      ),
    },
  ];


  var node = useRef();

  //delete payroll

  const onClickDelete = (payroll) => {
    setPayroll(payroll);
    dispatch(deletePayrollModal())
  };

  const handleDeletePayroll = () => {
    dispatch(onDeletePayroll(payroll))
  }

  const { SearchBar } = Search;

  function init() {
    dispatch(onGetPayrolls(true));
  }

  useEffect(() => {
    init()
  }, []);

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page }) => {
    if (type == "pagination")
      dispatch(onGetPayrolls(true, { page, ...searchParams }));
  };

  const handleCreatePayroll = () => {
    dispatch(newPayrollModal())
  };

  const handleEditPayroll = payroll => {
    setPayroll(payroll)
    dispatch(editPayrollModal())
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  /** set Date formate */
  const handleValidDate = date => {
    const date1 = date ? moment(new Date(date)).format("DD MMM Y") : "N/A";
    return date1;
  };

  let handleSearch = () => {
    dispatch(onGetPayrolls(false, {
      ...searchParams,
      from_date: searchParams.from_date ? moment(searchParams.from_date).format('YYYY-MM-DDT00:00:00Z') : null,
      to_date: searchParams.to_date ? moment(searchParams.to_date).format('YYYY-MM-DDT00:00:00Z') : null
    }));
  }

  let handleSearchChange = (e) => {
    setSearchParams({ ...searchParams, [e.target.name]: e.target.value })
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeletePayroll}
        onCloseClick={() => dispatch(deletePayrollModal())}
      />

      <Create />
      <Edit payroll={payroll} />

      <PayPayroll
        payable={payable}
        onPaid={() => {
          setPayable(null)
          dispatch(onGetPayrolls(true));
        }}
        onCloseClick={() => {
          setPayable(null)
        }}
      />

      <div className="page-content">
        <MetaTags>
          <title>Payrolls</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Payrolls" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={COLS}
                    data={payrolls}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <ToolkitProvider
                          keyField="id"
                          data={payrolls || []}
                          columns={COLS}
                          bootstrap4
                          search
                        >
                          {toolkitProps => {
                            return (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col sm="4">
                                    <h4>Search</h4>
                                  </Col>
                                  <Col sm="8">
                                    <div className="text-sm-end">
                                      <Button
                                        type="button"
                                        color="success"
                                        className="btn-rounded  mb-2 me-2"
                                        onClick={handleCreatePayroll}
                                      >
                                        <i className="mdi mdi-plus me-1" />
                                        New payroll
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="mb-2">
                                  <Filters
                                    params={searchParams}
                                    handleSearch={handleSearch}
                                    handleSearchChange={handleSearchChange}
                                  />
                                </Row>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        noDataIndication={"No Data found"}
                                        defaultSorted={defaultSorted}
                                        classes={"table align-middle table-nowrap"}
                                        keyField="id"
                                        remote={{ search: true, pagination: true }}
                                        {...toolkitProps.baseProps}
                                        onTableChange={handleTableChange}
                                        {...paginationTableProps}
                                        ref={node}
                                        rowStyle={row => row.paid_at ? { backgroundColor: '#c8e6c9' } : {}}
                                      />
                                    </div>

                                  </Col>
                                </Row>
                                <Row className="align-items-md-center mt-30">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )
                          }}
                        </ToolkitProvider>
                      )
                    }}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

EcommercePayrolls.propTypes = {
  payrolls: PropTypes.array,
  onGetPayrolls: PropTypes.func,
  onAddNewPayroll: PropTypes.func,
  onDeletePayroll: PropTypes.func,
  onUpdatePayroll: PropTypes.func,
};

export default EcommercePayrolls;
