import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed } from "./actions"
import { signup } from 'api/v1'

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user, actions } }) {
  try {
    const response = yield call(signup, user)
    yield put(registerUserSuccessful(response))
    actions.resetForm()

  } catch ({ errors }) {
    yield put(registerUserFailed("Registeration failed!"))
    if (errors) {
      actions.setErrors(errors)

    }
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
