import React, { useCallback, useEffect, useState } from "react";
import {
  Col,
  Row,
  Input,
  FormFeedback,
  Label,
  Form,
  ButtonGroup,
  Button,
} from "reactstrap";
import AsyncSelect from 'react-select/async';
import { getEmployees, getProjects } from "api/v1";

function index({ validation }) {
  const [isActive, setIsActive] = useState(false)

  const loadMembers = useCallback((search) => {
    let params = { search, no_paginate: 1 }

    return getEmployees(isActive ? { ...params, is_active: true } : params)
      .then((results = []) => {
        return results
      })
      .catch(({ message }) => {
        if (message !== 'request-cancel') {
          toast.error(message);
        }
      })
  }, [isActive])

  let loadProjects = (search) => {
    return getProjects({
      search,
      no_paginate: 1,
      user_ids: validation.values.users
    })
      .then((results = []) => {
        return results
      })
      .catch(({ message }) => {
        if (message !== 'request-cancel') {
          toast.error(message);
        }
      })
  }

  useEffect(() => {
    validation.setValues(_values => ({ ..._values, users: [], projects: [] }))
  }, [isActive])

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <Row>
        <Col xs="12" className="mb-3">
          <ButtonGroup className="mb-2 d-block">
            <Button
              active={!isActive}
              type="button"
              color="success"
              outline
              onClick={() => setIsActive(false)}
            >
              All Users
            </Button>

            <Button
              active={isActive}
              type="button"
              color="success"
              outline
              onClick={() => setIsActive(true)}
            >
              Active Users
            </Button>
          </ButtonGroup>

          <Label className="form-label">User</Label>
          <AsyncSelect
            key={`user-${isActive.toString()}`}
            defaultOptions
            placeholder="Select User(s)"
            required
            isMulti
            value={validation.values.users}
            getOptionLabel={({ profile: { first_name, last_name }, type }) => `${first_name} ${last_name} (${type})`}
            getOptionValue={({ id }) => id}
            loadOptions={loadMembers}
            onChange={(_users) => {
              validation.setFieldValue('users', _users)
              validation.setFieldValue('projects', [])
            }}
          />
          {validation.touched.users && validation.errors.users ? (
            <span className="text-danger font-size-11">{validation.errors.users}</span>
          ) : null}
        </Col>

        <Col xs="12" className="mb-3">
          <Label className="form-label">Project</Label>
          <AsyncSelect
            key={`projectKey-${validation.values.users.length}`}
            defaultOptions
            isMulti
            placeholder="Select Project"
            required
            value={validation.values.projects}
            getOptionLabel={({ project_name }) => project_name}
            getOptionValue={({ id }) => id}
            loadOptions={loadProjects}
            onChange={(_projects) => {
              validation.setFieldValue('projects', _projects)
            }}
          />
          {validation.touched.projects && validation.errors.projects ? (
            <span className="text-danger font-size-11">{validation.errors.projects}</span>
          ) : null}
        </Col>

        <Col xs="12" className="mb-3">
          <Label className="form-label">From date</Label>
          <Input
            name="from_date"
            type="date"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.from_date || ""}
            invalid={
              validation.touched.from_date && validation.errors.from_date ? true : false
            }
          />
          {validation.touched.from_date && validation.errors.from_date ? (
            <FormFeedback type="invalid">{validation.errors.from_date}</FormFeedback>
          ) : null}
        </Col>

        <Col xs="12" className="mb-3">
          <Label className="form-label">To date</Label>
          <Input
            name="to_date"
            type="date"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.to_date || ""}
            invalid={
              validation.touched.to_date && validation.errors.to_date ? true : false
            }
          />
          {validation.touched.to_date && validation.errors.to_date ? (
            <FormFeedback type="invalid">{validation.errors.to_date}</FormFeedback>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="text-end">
            <button
              type="submit"
              className="btn btn-success save-customer"
            >
              Save
            </button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default index
