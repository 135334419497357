import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'

import { newTimesheetModal, addTimesheet } from 'store/timesheets/actions'
import { toMinutes } from "./helper";

function index({ projectId, taskId }) {
  let dispatch = useDispatch()
  const { new: modal } = useSelector((state) => state.timesheets);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      description: '',
      date: '',
      start_time: '',
      end_time: '',
      time_out: '',
      user: null,
      images: [],
    },
    validationSchema: Yup.object({
      date: Yup.string().required("Please Enter Date"),
      start_time: Yup.string().required("Please Enter Start Time"),
      end_time: Yup.string().required("Please Enter End Time"),
      user: Yup.mixed().required("Please Select User")
    }),
    onSubmit: (values, actions) => {
      dispatch(addTimesheet(projectId, taskId, { ...values, time_out: toMinutes(values.time_out) }, actions))
    },
  });

  useEffect(() => {
    if (modal) {
      validation.resetForm()
    }
  }, [modal])

  return (
    <React.Fragment>
      <Modal size="lg" isOpen={modal} toggle={() => dispatch(newTimesheetModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(newTimesheetModal())}>
          Add Timesheet
        </ModalHeader>
        <ModalBody>
          <Form
            projectId={projectId}
            taskId={taskId}
            validation={validation}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
