import { filterParams } from "../apiHelper";
import request from "../restClient";

export const getTimeoffs = async (params) => {
  return await request({
    url: "time_off/",
    method: "GET",
    params: filterParams(params),
    cancelPrevious: false,
  });
};

export const deleteTimeoff = async (id) => {
  return await request({
    url: `time_off/${id}/`,
    method: "DELETE"
  });
};

export const editTimeoff = async (id, data) => {

  return await request({
    url: `time_off/${id}/`,
    method: "PATCH",
    data
  });
};

export const addTimeoff = async (data) => {
  return await request({
    url: `time_off/`,
    method: "POST",
    data
  });
};
