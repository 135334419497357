import { filterParams } from "../apiHelper";
import request from "../restClient";

export const getCategories = async (params) => {
  return await request({
    url: "materials/categories/",
    method: "GET",
    params: filterParams(params),
    cancelPrevious: params.cancelPrevious || true,
  });
};

export const deleteCategory = async (id) => {
  return await request({
    url: `materials/categories/${id}/`,
    method: "DELETE"
  });
};

export const editCategory = async (id, data) => {
  const formD = new FormData()
  Object.entries(data).forEach((keyValue) => {
    if (keyValue[0] != "avatar" || typeof (keyValue[1]) != "string")
      formD.append(keyValue[0], keyValue[1])
  })

  return await request({
    url: `materials/categories/${id}/`,
    method: "PATCH",
    headers: {
      'Content-type': 'multipart/form-data',
    },
    data: formD
  });
};

export const addCategory = async (data) => {
  const formD = new FormData()
  Object.entries(data).forEach((keyValue) => {
    formD.append(keyValue[0], keyValue[1])
  })

  return await request({
    url: `materials/categories/`,
    method: "POST",
    headers: {
      'Content-type': 'multipart/form-data',
    },
    data: formD
  });
};
