import React, { useCallback, useState } from 'react'
import { getPrice, usFormat } from 'utils/common'
import Stripe from './Stripe'
import Seats from './Seats'

function Plan({ plan, tenant, isActive }) {
  const [price, setPrice] = useState(null)
  const [seats, setSeats] = useState(null)

  const handleSeatsUpdate = useCallback(_seats => {
    if (_seats)
      setPrice(_seats)

    setSeats(null)
  }, [])

  if (!tenant)
    return null

  return (
    <div className="col-lg-5 col-xl-3 mb-3">
      <div className="item">
        {isActive && <div className="ribbon">Subscribed</div>}
        <div className="heading">
          <h3>{plan.name}</h3>
        </div>
        <p>Allowed mobile app users for Staff and Subcontractors</p>
        <div className="features">
          <h4><span className="feature">Users</span> : <span className="value">{Math.max(tenant.seats_allowed, (plan.free_allowed_seats || 0))}</span></h4>
        </div>
        <div className="price">
          <h4>{usFormat(getPrice(tenant)) || '--'}</h4>
        </div>
        <div className="pricing">
          {
            isActive ?
              <button
                onClick={() => setSeats({ upgrade: true })}
                className={`btn btn-block btn-primary w-100 mt-1`}>
                Update
              </button>
              :
              <button
                onClick={() => setSeats({})}
                className={`btn btn-block btn-outline-primary w-100 mt-1`}>
                Subscribe
              </button>
          }
        </div>
      </div>
      <p className='font-size-14 mt-2'>An additional charge of <b>{usFormat(plan.additional_seat_price.unit_amount || 0)}</b> will apply for each extra mobile app user.</p>

      <Seats
        seats={seats}
        setSeats={handleSeatsUpdate}
      />

      <Stripe
        price={price}
        setPrice={setPrice}
      />
    </div>
  )
}

export default Plan
