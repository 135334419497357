import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';
import {
  GET_EXPENSES,
  DELETE_EXPENSE,
  ADD_EXPENSE,
  EDIT_EXPENSE
} from "./actionTypes";

import {
  getExpensesSuccess,
  getExpenses,
  getExpensesFail,
  deleteExpenseModal
} from "./actions";

import {
  getExpenses as getExpensesAPI,
  deleteExpense as deleteExpenseAPI,
  addExpense as addExpenseAPI,
  editExpense as editExpenseAPI,
} from "api/v1";


function* fetchExpenses({ payload: { projectId, params } }) {
  try {
    const response = yield call(getExpensesAPI, projectId, params);
    yield put(getExpensesSuccess(response));
  } catch (error) {
    yield put(getExpensesFail("Something went wrong!"))
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* addExpense({ payload: { projectId, expense, actions } }) {
  try {
    yield call(addExpenseAPI, projectId, expense)
    toast.success("Expense Created!");
    yield put(getExpenses(projectId, true));
    actions.resetForm()

  } catch ({ errors }) {
    yield put(getExpensesFail("Creation failed!"))
    if (errors) {
      actions.setErrors(errors)

    }
    if (errors?.non_field_errors)
      toast.error(errors.non_field_errors[0]);
    else
      toast.error("Something went wrong!");
  }
}

function* editExpense({ payload: { projectId, id, expense, actions } }) {
  try {
    yield call(editExpenseAPI, projectId, id, expense)
    toast.success("Expense Updated!");
    yield put(getExpenses(projectId, true));

  } catch ({ errors }) {
    yield put(getExpensesFail("Creation failed!"))
    if (errors) {
      actions.setErrors(errors)

    }
    if (errors?.non_field_errors)
      toast.error(errors.non_field_errors[0]);
    else
      toast.error("Something went wrong!");
  }
}

function* deleteExpense({ payload: { projectId, expense } }) {
  try {
    yield call(deleteExpenseAPI, projectId, expense.id);
    put(deleteExpenseModal())
    yield put(getExpenses(projectId, true));
    toast.warn("Expense Deleted!");
  } catch (error) {
    toast.error("Something went wrong!");
  }
}

function* expenseSaga() {
  yield takeEvery(GET_EXPENSES, fetchExpenses);
  yield takeEvery(DELETE_EXPENSE, deleteExpense);
  yield takeEvery(ADD_EXPENSE, addExpense);
  yield takeEvery(EDIT_EXPENSE, editExpense);
}

export default expenseSaga;
