import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'

import { newPayrateModal, addPayrate } from 'store/payrates/actions'

function index() {
  let dispatch = useDispatch()
  const { new: modal } = useSelector((state) => state.payrates);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      rate: '',
      user: null,
    },
    validationSchema: Yup.object({
      rate: Yup.string().required("Please Enter Rate"),
      user: Yup.mixed().required("Please Enter Member"),
    }),
    onSubmit: (values, actions) => {
      dispatch(addPayrate(values, actions))
    },
  });

  useEffect(() => {
    if (modal) {
      validation.resetForm()
    }
  }, [modal])

  return (
    <React.Fragment>
      <Modal isOpen={modal} toggle={() => dispatch(newPayrateModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(newPayrateModal())}>
          Add Payrate
        </ModalHeader>
        <ModalBody>
          <Form
            validation={validation}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
