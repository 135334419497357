import {
  GET_LOCATIONS_FAIL,
  GET_LOCATIONS_SUCCESS,
  GET_LOCATIONS,
  ADD_LOCATION,
  EDIT_LOCATION,
  DELETE_LOCATION,
} from "./actionTypes";

const INIT_STATE = {
  locationWithPagy: {
    total: 0,
    page: 1,
    page_size: 10,
    results: []
  },
  new: false,
  edit: false,
  delete: false,
  loading: false,
};

const locations = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LOCATIONS:
      return {
        ...state,
        new: false,
        edit: false,
        delete: false,
        loading: action.payload.loading
      };

    case GET_LOCATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        locationWithPagy: action.payload,
      };

    case ADD_LOCATION:
      return {
        ...state,
        loading: true
      };

    case EDIT_LOCATION:
      return {
        ...state,
        loading: true
      };

    case DELETE_LOCATION:
      return {
        ...state,
        loading: true
      };

    case GET_LOCATIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "NEW_LOCATION_MODAL":
      return {
        ...state,
        new: !state.new
      };

    case "DELETE_LOCATION_MODAL":
      return {
        ...state,
        delete: !state.delete
      };

    case "EDIT_LOCATION_MODAL":
      return {
        ...state,
        edit: !state.edit
      };
    default:
      return state;
  }
};

export default locations;
