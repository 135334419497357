import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';
import {
  GET_ROLES,
  DELETE_ROLE,
  ADD_ROLE,
  EDIT_ROLE
} from "./actionTypes";

import {
  getRolesSuccess,
  getRoles,
  getRolesFail,
  deleteRoleModal
} from "./actions";

import {
  getRoles as getRolesAPI,
  deleteRole as deleteRoleAPI,
  addRole as addRoleAPI,
  editRole as editRoleAPI,
} from "api/v1";


function* fetchRoles({ payload: { params } }) {
  try {
    const response = yield call(getRolesAPI, params);
    yield put(getRolesSuccess(response));
  } catch (error) {
    yield put(getRolesFail("Something went wrong!"))
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* addRole({ payload: { role, actions } }) {
  try {
    yield call(addRoleAPI, role)
    toast.success("Role Created!");
    yield put(getRoles(true));
    actions.resetForm()

  } catch ({ errors }) {
    yield put(getRolesFail("Creation failed!"))
    if (errors) {
      actions.setErrors(errors)

    }
    toast.error("Something went wrong!");
  }
}

function* editRole({ payload: { id, role, actions } }) {
  try {
    yield call(editRoleAPI, id, role)
    toast.success("Role Updated!");
    yield put(getRoles(true));

  } catch ({ errors }) {
    yield put(getRolesFail("Creation failed!"))
    if (errors) {
      actions.setErrors(errors)

    }
    toast.error("Something went wrong!");
  }
}

function* deleteRole({ payload: { role } }) {
  try {
    yield call(deleteRoleAPI, role.id);
    put(deleteRoleModal())
    yield put(getRoles(true));
    toast.warn("Role Deleted!");
  } catch (error) {
    toast.error("Something went wrong!");
  }
}

function* roleSaga() {
  yield takeEvery(GET_ROLES, fetchRoles);
  yield takeEvery(DELETE_ROLE, deleteRole);
  yield takeEvery(ADD_ROLE, addRole);
  yield takeEvery(EDIT_ROLE, editRole);
}

export default roleSaga;
