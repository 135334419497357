import { filterParams } from "../apiHelper";
import request from "../restClient";

export const getSheets = async (params) => {
  return await request({
    url: `project/timesheets/`,
    method: "GET",
    params: filterParams(params),
    cancelPrevious: params.cancelPrevious || true,
  });
};

export const getTimesheets = async (project_id, task_id, params) => {
  return await request({
    url: `project/${project_id}/tasks/${task_id}/timesheets/`,
    method: "GET",
    params: filterParams(params),
    cancelPrevious: params.cancelPrevious || true,
  });
};

export const getProjectTimesheets = async (params = {}) => {
  var queryParams = new URLSearchParams();
  Object.entries(params).forEach(keyValue => {
    if (keyValue[0] == "project_ids")
      keyValue[1].forEach(_item => {
        queryParams.append(keyValue[0], _item?.id);
      })
    else if (keyValue[1])
      queryParams.append(keyValue[0], keyValue[1]);
  })

  return await request({
    url: `project/timesheets/`,
    method: "GET",
    params: queryParams
  });
};

export const getTimesheet = async (project_id, task_id, id) => {
  return await request({
    url: `project/${project_id}/tasks/${task_id}/timesheets/${id}/`,
    method: "GET"
  });
};

export const getTimesheetImages = async (project_id, task_id, id) => {
  return await request({
    url: `project/${project_id}/tasks/${task_id}/timesheets/${id}/images/`,
    method: "GET"
  });
};

export const deleteTimesheet = async (project_id, task_id, id) => {
  return await request({
    url: `project/${project_id}/tasks/${task_id}/timesheets/${id}/`,
    method: "DELETE"
  });
};

export const deleteTimesheetImage = async (project_id, task_id, timesheetId, id) => {
  return await request({
    url: `project/${project_id}/tasks/${task_id}/timesheets/${timesheetId}/images/${id}/`,
    method: "DELETE"
  });
};

export const editTimesheet = async (project_id, task_id, id, data) => {
  const formD = new FormData()
  Object.entries(data).forEach((keyValue) => {
    if (keyValue[0] == "user")
      formD.append(keyValue[0], keyValue[1]?.id)
    else if (keyValue[0] == "images")
      keyValue[1].forEach(_image => formD.append("images", _image))
    else
      formD.append(keyValue[0], keyValue[1])
  })

  return await request({
    url: `project/${project_id}/tasks/${task_id}/timesheets/${id}/`,
    method: "PATCH",
    headers: {
      'Content-type': 'multipart/form-data',
    },
    data: formD
  });
};

export const addTimesheet = async (project_id, task_id, data) => {
  const formD = new FormData()
  Object.entries(data).forEach((keyValue) => {
    if (keyValue[0] == "user")
      formD.append(keyValue[0], keyValue[1]?.id)
    else if (keyValue[0] == "images")
      keyValue[1].forEach(_image => formD.append("images", _image))
    else
      formD.append(keyValue[0], keyValue[1])

  })

  return await request({
    url: `project/${project_id}/tasks/${task_id}/timesheets/`,
    method: "POST",
    headers: {
      'Content-type': 'multipart/form-data',
    },
    data: formD
  });
};
