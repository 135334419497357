import { filterParams } from "../apiHelper";
import request from "../restClient";

export const getPayrollReports = async (params) => {
  return await request({
    url: "payrolls/reports/",
    method: "GET",
    params: filterParams(params),
    cancelPrevious: params.cancelPrevious || true,
  });
};

export const getPayrollReport = async (id) => {
  return await request({
    url: `payrolls/reports/${id}/`,
    method: "GET"
  });
};

export const deletePayrollReport = async (id) => {
  return await request({
    url: `payrolls/reports/${id}/`,
    method: "DELETE"
  });
};

export const generatePayrollReport = async (id) => {
  return await request({
    url: `payrolls/reports/${id}/generate_pdf/`,
    method: "PATCH"
  });
};

export const editPayrollReport = async (id, data) => {

  return await request({
    url: `payrolls/reports/${id}/`,
    method: "PATCH",
    data
  });
};

export const addPayrollReport = async (data) => {

  return await request({
    url: `payrolls/reports/`,
    method: "POST",
    data
  });
};
