import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'

import { newTaskModal, addTask } from 'store/tasks/actions'

function index({ projectId, task: target }) {
  let dispatch = useDispatch()
  let [task, setTask] = useState()
  const { new: modal } = useSelector((state) => state.tasks);

  useEffect(() => {
    if (modal) {
      if (target)
        setTask(target)
      else
        setTask(null)

    }
  }, [modal])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      task: (task && task.task) || '',
      code: (task && task.code) || '',
      description: (task && task.description) || '',
      status: (task && task.status) || '',
      estimated_hours: (task && task.estimated_hours) || '',
      images: (task && task.images) || [],
      users: (task && task.users) || [],
    },
    validationSchema: Yup.object({
      task: Yup.string().required("Please Enter Task Name"),
      description: Yup.string().required("Please Enter Task Description"),
      status: Yup.string().required("Please Enter Task Status")
    }),
    onSubmit: (values, actions) => {
      dispatch(addTask(projectId, values, actions))
    },
  });

  return (
    <React.Fragment>
      <Modal isOpen={modal} toggle={() => dispatch(newTaskModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(newTaskModal())}>
          Add Task
        </ModalHeader>
        <ModalBody>
          <Form
            validation={validation}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
