import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';

import {
  setProfile,
  onProfileComplete
} from "./actions";

import {
  getProfile as getProfileAPI,
  editProfile as editProfileAPI,
  updatePassword as updatePasswordAPI,
} from "api/v1";


function* fetchProfile() {
  try {
    const response = yield call(getProfileAPI);
    yield put(setProfile(response));

  } catch (error) {
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
  yield put(onProfileComplete());
}

function* editProfile({ payload: { profile, actions } }) {
  try {
    const response = yield call(editProfileAPI, profile)
    yield put(setProfile(response));
    toast.success("Profile Updated!");

  } catch ({ errors }) {
    if (errors) {
      actions.setErrors(errors)

    }
    toast.error("Something went wrong!");
  }
  yield put(onProfileComplete());
}

function* updatePassword({ payload: { profile, actions } }) {
  try {
    yield call(updatePasswordAPI, profile)
    toast.success("Password Updated!");

    actions.resetForm();
  } catch ({ errors }) {
    if (errors) {
      actions.setErrors(errors)

    }
    toast.error("Something went wrong!");
  }
  yield put(onProfileComplete());
}

function* profileSaga() {
  yield takeEvery('GET_PROFILE', fetchProfile);
  yield takeEvery('EDIT_PROFILE', editProfile);
  yield takeEvery('UPDATE_PASSWORD', updatePassword);
}

export default profileSaga;
