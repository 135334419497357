import {
  GET_ROLES,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAIL,
  DELETE_ROLE,
  ADD_ROLE,
  EDIT_ROLE
} from "./actionTypes"

export const getRoles = (loading = false, params = {}) => ({
  type: GET_ROLES,
  payload: { loading, params }
})

export const getRolesSuccess = roles => ({
  type: GET_ROLES_SUCCESS,
  payload: roles,
})

export const getRolesFail = error => ({
  type: GET_ROLES_FAIL,
  payload: error,
})

export const deleteRole = role => ({
  type: DELETE_ROLE,
  payload: { role }
})

export const addRole = (role, actions) => {
  return {
    type: ADD_ROLE,
    payload: { role, actions },
  }
}

export const editRole = (id, role, actions) => {
  return {
    type: EDIT_ROLE,
    payload: { id, role, actions },
  }
}

export const newRoleModal = () => ({
  type: 'NEW_ROLE_MODAL'
})

export const deleteRoleModal = () => ({
  type: 'DELETE_ROLE_MODAL'
})

export const editRoleModal = () => ({
  type: 'EDIT_ROLE_MODAL'
})
