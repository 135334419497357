import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody } from "reactstrap";

let colors = {
  'Open': 'warning',
  'Waiting': 'info',
  'Close': 'primary',
}

const TaskDetail = ({ task }) => {

  return (
    <Card style={{ height: '260px', overflow: 'auto' }}>
      <CardBody>
        <div className="d-flex">
          <div className="flex-grow-1 overflow-hidden">
            <h5 className="text-truncate font-size-15">{`${task.task}`}</h5>
          </div>
          <div className="float-right">
            <h5 className="text-truncate font-size-24">{`${task.total_hours} Hours`}</h5>
          </div>
        </div>

        <div className="d-flex mt-3">
          <div className="flex-grow-1 overflow-hidden">
            <h5 className="font-size-15">Task Details :</h5>
            <p className="text-muted">
              {task.description}
            </p>
          </div>
          <div className="float-right">
          <h5 className={`text-truncate font-size-24 text-${colors[task.status]}`}>{`${task.status}`}</h5>

            <div className="table-avatar-lg mt-3">
              <img
                src={task.image}
                alt={task.task[0]}
              />
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

TaskDetail.propTypes = {
  project: PropTypes.object,
};

export default TaskDetail;
