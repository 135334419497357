import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'

import { newStaffModal, addStaff } from 'store/staffs/actions'

function index() {
  let dispatch = useDispatch()
  const { new: modal } = useSelector((state) => state.staffs);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      first_name: '',
      last_name: '',
      date_birth: '',
      role_start: '',
      job_related_skills: '',
      email: '',
      phone: '',
      city: '',
      state: '',
      zip_code: '',
      country: '',
      street: '',
      avatar: null,
      role: null,
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please Enter Your First Name"),
      role: Yup.mixed().required("Please Enter Role"),
      last_name: Yup.string().required("Please Enter Your Last Name"),
      city: Yup.string().required("Please Enter Your City"),
      email: Yup.string().required("Please Enter Your Email"),
      phone: Yup.string().required("Please Enter Your Phone Number"),
      state: Yup.string().required("Please Enter Your State"),
      zip_code: Yup.string().required("Please Enter Your Zip code"),
      job_related_skills: Yup.string().required("Please Enter jobs related skills"),
      street: Yup.string().required("Please Enter Your Street"),
      country: Yup.string().required("Please Enter Your Country"),
      date_birth: Yup.string().required("Please Enter Your Date of Birth"),
      role_start: Yup.string().required("Please Enter role start date")
    }),
    onSubmit: (values, actions) => {
      dispatch(addStaff({ ...values, user: { email: values.email, phone: values.phone } }, actions))
    },
  });

  useEffect(() => {
    if (modal) {
      validation.resetForm()
    }
  }, [modal])

  return (
    <React.Fragment>
      <Modal size="lg" isOpen={modal} toggle={() => dispatch(newStaffModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(newStaffModal())}>
          Add Staff
        </ModalHeader>
        <ModalBody>
          <Form
            validation={validation}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
