import React, { useState } from "react";
import styles from "./css/Navbar.module.css";
import { useMediaQuery } from "react-responsive";

import { Link } from "react-router-dom";
import LeftNavbar from "./LeftNavbar";

import { Menu } from "../Svgs/index";

const navbarLinks = [
  {
    hrefTxt: "Why AllProPlus",
    urlTxt: "why-allproplus",
  },
  {
    hrefTxt: "Pricing",
    urlTxt: "pricing",
  },
  {
    hrefTxt: "Resources",
    urlTxt: "resources",
  },
];

export const Navbar = () => {
  const [leftNavbar, setLeftNavbar] = useState(false);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 780 });

  return (
    <div id="headerContainer">
      <header className={styles.navbar}>
        <div className={styles.navbarInner}>
          {isTabletOrMobile ? (
            <div
              className={styles.navbarToggle}
              onClick={() => setLeftNavbar(!leftNavbar)}
            >
              <Menu></Menu>
            </div>
          ) : null}
          <div className={styles.logoArea}>
            <LogoCont></LogoCont>
          </div>

          {isTabletOrMobile ? null : (
            <div className={styles.navLinksArea}>
              <NavigationLinks></NavigationLinks>
            </div>
          )}
          <div className={styles.accountArea}>
            <AccountActions></AccountActions>
          </div>
        </div>

        {leftNavbar == true ? (
          <LeftNavbar
            setLeftNavbar={() => setLeftNavbar(!leftNavbar)}
          ></LeftNavbar>
        ) : null}
      </header>
    </div>
  );
};

const LogoCont = (props) => {
  return (
    <div className={styles.logoCont}>
      <div>
        <img src="/assets/logo/allpromana.png" />
      </div>
    </div>
  );
};

const NavigationLinks = (props) => {
  return (
    <div className={styles.accountActions}>
      <div className={styles.accountActionsInner}>
        <ul>
          {navbarLinks.map((navLink, index) => (
            <li key={index}>
              <a href={`${process.env.REACT_APP_WEB_HOST}/${navLink["urlTxt"]}`}> 
                <span>{navLink["hrefTxt"]}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export const AccountActions = (props) => {
  return (
    <div className={styles.accountActions}>
      <div className={styles.accountActionsInner}>
        <div className={styles.loginBtn}>
          <Link to={`/login`}>
            <span>Login</span>
          </Link>
        </div>
        <div className={styles.signupBtn}>
          <a href={`${process.env.REACT_APP_WEB_HOST}/request-demo`}>
            <span>Request Demo</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
