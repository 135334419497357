import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import { setTabBarLoader } from "utils/common";
import { editProfile, getProfile } from "store/actions";


const Profile = () => {
  let [avatar, setAvatar] = useState()
  const { profile, loading } = useSelector(state => ({
    loading: state.profiles.loading,
    profile: state.profiles.profile
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfile());
  }, [])

  useEffect(() => {
    setTabBarLoader(loading)
  }, [loading]) //loading

  let _profileDetail = profile?.profile


  const form = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: (profile && profile.email) || '',
      phone: (profile && profile.phone) || '',

      first_name: (_profileDetail && _profileDetail.first_name) || "",
      last_name: (_profileDetail && _profileDetail.last_name) || "",
      avatar: (_profileDetail && _profileDetail.avatar) || null,
    },
    validationSchema: Yup.object({
      phone: Yup.string().required("Please enter your phone number"),
      first_name: Yup.string().required("Please enter your first name"),
      last_name: Yup.string().required("Please enter your last name"),
      avatar: Yup.mixed().required("You need to provide a avatar")
    }),
    onSubmit: (values, actions) => {
      dispatch(editProfile(values, actions))
    }
  });

  return (
    <React.Fragment >
      <div className="page-content">
        <MetaTags>
          <title>Profile | AllPromana</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb back={true} title="Settings" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              <Card>
                <h5 className="mt-3 px-4">Profile Details</h5>
                <CardBody>
                  <Form onSubmit={e => {
                    e.preventDefault();
                    form.handleSubmit();
                    return false;
                  }}>
                    <Row>
                      <div className="mb-3 col-6">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          disabled
                          type="text"
                          value={form.values.email || "No email added"}
                        />
                      </div>

                      <div className="mb-3 col-6">
                        <Label className="form-label">Phone</Label>
                        <Input
                          name="phone"
                          type="text"
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                          value={form.values.phone || ""}
                          invalid={
                            form.touched.phone && form.errors.phone ? true : false
                          }
                        />
                        {form.touched.phone && form.errors.phone ? (
                          <FormFeedback type="invalid">{form.errors.phone}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3 col-6">
                        <Label className="form-label">First Name</Label>
                        <Input
                          name="first_name"
                          type="text"
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                          value={form.values.first_name || ""}
                          invalid={
                            form.touched.first_name && form.errors.first_name ? true : false
                          }
                        />
                        {form.touched.first_name && form.errors.first_name ? (
                          <FormFeedback type="invalid">{form.errors.first_name}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3 col-6">
                        <Label className="form-label">Last Name</Label>
                        <Input
                          name="last_name"
                          type="text"
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                          value={form.values.last_name || ""}
                          invalid={
                            form.touched.last_name && form.errors.last_name ? true : false
                          }
                        />
                        {form.touched.last_name && form.errors.last_name ? (
                          <FormFeedback type="invalid">{form.errors.last_name}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3 col-6">
                        <Label className="form-label">Avatar</Label>
                        <Input
                          name="avatar"
                          type="file"
                          onChange={event => {
                            form.setFieldValue('avatar', event.target.files[0])
                            if (event.target.files.length > 0) {
                              var reader = new FileReader();
                              reader.readAsDataURL(event.target.files[0]);
                              reader.onloadend = function (e) {
                                setAvatar(reader.result)
                              }

                            } else {
                              setAvatar(null)
                            }
                          }}
                          accept="image/*"
                          onBlur={form.handleBlur}
                          invalid={
                            form.touched.avatar && form.errors.avatar ? true : false
                          }
                        />
                        {form.touched.avatar && form.errors.avatar ? (
                          <FormFeedback type="invalid">{form.errors.avatar}</FormFeedback>
                        ) : null}

                        {
                          (avatar || form.values.avatar) &&
                          <div className="table-avatar-lg mt-2">
                            <img
                              src={avatar || form.values.avatar}
                              alt={form.values.email[0]}
                            />
                          </div>
                        }
                      </div>

                      <div className="text-end mt-2">
                        <button
                          type="submit"
                          disabled={loading}
                          className="btn btn-success save-customer"
                        >
                          {loading ? "Saving" : "Save"} {" "}
                          {loading && <i className="bx bx-hourglass bx-spin font-size-16 align-middle"></i>}
                        </button>
                      </div>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment >
  );
};

export default withRouter(Profile);
