import { updateTaskAssignee } from "api/v1";
import Loader from "common/Loader";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import { setTabBarLoader } from "utils/common";

const MemberTable = ({ members = [], task = null, loadingMembers = false }) => {

  function Input({ row: target }) {
    const [row, setRow] = useState(target)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      setTabBarLoader(loading)
    }, [loading])

    async function updateMember(assigneeId, data) {
      setLoading(true)
      try {
        await updateTaskAssignee(task.project.id, task.id, assigneeId, data)
        setRow({ ...row, ...data })
        toast.success("Member updated")
      } catch (error) {
        toast.error("Something went wrong updating assignee")
      }
      setLoading(false)
    }

    if (!row)
      return null

    return (
      <div className="form-check form-switch form-switch-md">
        <input
          type="checkbox"
          className="form-check-input"
          id="customSwitch2"
          disabled={loading}
          defaultChecked={row.done}
          onClick={e => {
            updateMember(row.id, { done: !row.done })
          }}
        />
      </div>
    )
  }

  const COLS = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      text: "#",
      dataField: "index",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row, index) => <>{index + 1}</>,
    },
    {
      dataField: "avatar",
      text: "Avatar",
      sort: false,
      formatter: (cellContent, row) => (
        <>
          <div className="table-avatar">
            {
              row.assignee.profile.avatar ? (
                <img
                  src={row.assignee.profile.avatar}
                  alt={row.assignee.profile.first_name[0]}
                />
              ) : (<span>{row.assignee.profile.first_name[0]}</span>)
            }

          </div>
        </>
      ),
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{`${row.assignee.profile.first_name} ${row.assignee.profile.last_name}`}</p>
        </>
      ),
    },
    {
      text: "Done",
      dataField: "done",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <Input
        key={`member-${row.id}-${row.done}`}
        row={row}
      />
    },
  ];


  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  return (
    <div>
      {loadingMembers ? <Loader /> : <BootstrapTable
        data={members}
        responsive
        columns={COLS}
        bordered={false}
        striped={false}
        noDataIndication={"No Data found"}
        defaultSorted={defaultSorted}
        classes={"table align-middle table-nowrap"}
        keyField="id"
      />
      }
    </div>
  );
};

export default MemberTable;
