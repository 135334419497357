import PropTypes from 'prop-types'
import React from "react"
import { useDispatch } from 'react-redux'
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"
import { editStaff } from 'store/actions'

const CardContact = props => {
  const { user, onClickDelete, handleEditStaff } = props
  const dispatch = useDispatch()

  return (
    <React.Fragment>
      <Col xl="3" sm="6">
        <Card className="text-center">
          <div className="form-check form-switch form-switch-sm d-flex justify-content-end">
            <input
              type="checkbox"
              className="form-check-input m-2"
              id="customSwitch2"
              defaultChecked={user.user.is_active}
              onClick={e => {
                dispatch(editStaff(user.id, { user: { is_active: !user.user.is_active } }), null)
              }}
            />
          </div>
          <CardBody>
            {!user.avatar ? (
              <div className="avatar-sm mx-auto mb-4">
                <span
                  className={
                    "avatar-title rounded-circle bg-soft bg-primary text-primary font-size-16"
                  }>
                  {user.first_name.charAt(0)}
                </span>
              </div>
            ) : (
              <div className="mb-4">
                <img
                  className="rounded-circle avatar-sm"
                  src={user.avatar}
                  alt=""
                />
              </div>
            )}

            <h5 className="font-size-15 mb-1">
              <span className="text-dark">
                {`${user.first_name} ${user.last_name}`}
              </span>
            </h5>
            <i className="mt-2"><strong>{user.user.email}</strong></i>
            <p className="text-muted my-2">{user.job_related_skills || 'No job related skill specified'}</p>

            <div>
              <span
                className="badge bg-primary font-size-11 m-1"
                key={"_skill_" + user.id} >
                {user.role?.title || 'No role specified'}
              </span>
            </div>
          </CardBody>
          <CardFooter className="bg-transparent border-top">
            <div className="contact-links d-flex font-size-20">
              <div className="flex-fill">
                <Link to="#" id={"message" + user.id}>
                  <i className="bx bx-message-square-dots" />
                  <UncontrolledTooltip
                    placement="top"
                    target={"message" + user.id}
                  >
                    Message
                  </UncontrolledTooltip>
                </Link>
              </div>
              <div className="flex-fill">
                <Link to="#" id={"project" + user.id}>
                  <i className="bx bx-pie-chart-alt" />
                  <UncontrolledTooltip
                    placement="top"
                    target={"project" + user.id}
                  >
                    Projects
                  </UncontrolledTooltip>
                </Link>
              </div>
              <div className="flex-fill">
                <Link to="#" id={"profile" + user.id}>
                  <i className="bx bx-user-circle" />
                  <UncontrolledTooltip
                    placement="top"
                    target={"profile" + user.id}
                  >
                    Profile
                  </UncontrolledTooltip>
                </Link>
              </div>
              <div className="flex-fill">
                <UncontrolledDropdown direction="down">
                  <DropdownToggle className="card-drop" tag="i">
                    <i className="mdi mdi-dots-horizontal font-size-18" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem
                      onClick={() => handleEditStaff(user)}
                    >
                      <i className="fas fa-pencil-alt text-success me-1" />
                      Edit
                    </DropdownItem>
                    {/* <DropdownItem
                      onClick={() => onClickDelete(user)}
                    >
                      <i className="fas fa-trash-alt text-danger me-1" />
                      Delete
                    </DropdownItem> */}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </CardFooter>
        </Card>
      </Col>
    </React.Fragment>
  )
}

CardContact.propTypes = {
  user: PropTypes.object
}

export default CardContact
