import {
  GET_PAYROLL_REPORTS_FAIL,
  GET_PAYROLL_REPORTS_SUCCESS,
  GET_PAYROLL_REPORTS,
  ADD_PAYROLL_REPORT,
  EDIT_PAYROLL_REPORT,
  DELETE_PAYROLL_REPORT,
} from "./actionTypes";

const INIT_STATE = {
  payrollReportWithPagy: {
    total: 0,
    page: 1,
    page_size: 10,
    results: []
  },
  new: false,
  edit: false,
  delete: false,
  generatablePayrollReport: null,
  generate: false,
  payrollReport: null,
  loading: false,
};

const payrollReports = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PAYROLL_REPORTS:
      return {
        ...state,
        new: false,
        edit: false,
        delete: false,
        loading: action.payload.loading
      };
    case 'GET_PAYROLL_REPORT':
      return {
        ...state,
        payrollReport: null,
        loading: true
      };

    case 'SET_GENERATABLE_PAYROLL_REPORT':
      return {
        ...state,
        generatablePayrollReport: action.payload.payrollReport
      };

    case GET_PAYROLL_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        payrollReportWithPagy: action.payload,
      };

    case 'GET_PAYROLL_REPORT_SUCCESS':
      return {
        ...state,
        loading: false,
        payrollReport: action.payload,
      };

    case 'GET_PAYROLL_REPORT_FAILED':
      return {
        ...state,
        loading: false,
        payrollReport: null
      };

    case ADD_PAYROLL_REPORT:
      return {
        ...state,
        loading: true
      };

    case DELETE_PAYROLL_REPORT:
      return {
        ...state,
        loading: true
      };

    case 'GENERATE_PAYROLL_REPORT':
      return {
        ...state,
        loading: true
      };

    case EDIT_PAYROLL_REPORT:
      return {
        ...state,
        loading: true
      };

    case GET_PAYROLL_REPORTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "NEW_PAYROLL_REPORT_MODAL":
      return {
        ...state,
        new: !state.new
      };

    case "DELETE_PAYROLL_REPORT_MODAL":
      return {
        ...state,
        delete: !state.delete
      };

    case "GENERATE_PAYROLL_REPORT_MODAL":
      return {
        ...state,
        generate: !state.generate
      };

    case "EDIT_PAYROLL_REPORT_MODAL":
      return {
        ...state,
        edit: !state.edit
      };
    default:
      return state;
  }
};

export default payrollReports;
