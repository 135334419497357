import {
  GET_EXPENSES_FAIL,
  GET_EXPENSES_SUCCESS,
  GET_EXPENSES,
  ADD_EXPENSE,
  EDIT_EXPENSE,
  DELETE_EXPENSE,
} from "./actionTypes";

const INIT_STATE = {
  expenseWithPagy: {
    total: 0,
    page: 1,
    page_size: 10,
    results: []
  },
  new: false,
  edit: false,
  delete: false,
  loading: false,
};

const expenses = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EXPENSES:
      return {
        ...state,
        new: false,
        edit: false,
        delete: false,
        loading: action.payload.loading
      };

    case GET_EXPENSES_SUCCESS:
      return {
        ...state,
        loading: false,
        expenseWithPagy: action.payload,
      };

    case ADD_EXPENSE:
      return {
        ...state,
        loading: true
      };

    case EDIT_EXPENSE:
      return {
        ...state,
        loading: true
      };

    case GET_EXPENSES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "NEW_EXPENSE_MODAL":
      return {
        ...state,
        new: !state.new
      };

    case "DELETE_EXPENSE_MODAL":
      return {
        ...state,
        delete: !state.delete
      };

    case "EDIT_EXPENSE_MODAL":
      return {
        ...state,
        edit: !state.edit
      };
    default:
      return state;
  }
};

export default expenses;
