import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import LayoutSaga from "./layout/saga"
import invoiceSaga from "./invoices/saga"
import projectsSaga from "./projects/saga"
import tasksSaga from "./tasks/saga"
import timesheetsSaga from "./timesheets/saga"
import customerSaga from './customers/saga'
import categorySaga from './categories/saga'
import profileSaga from './profiles/saga'
import estimateSaga from './estimates/saga'
import tenantSaga from './tenants/saga'
import materialSaga from './materials/saga'
import staffSaga from './staffs/saga'
import roleSaga from './roles/saga'
import subcontractorSaga from './subcontractors/saga'
import payrollSaga from './payrolls/saga'
import payrateSaga from './payrates/saga'
import timeoffSaga from './timeoffs/saga'
import expenseSaga from './expenses/saga'
import timesheetReportSaga from './timesheetReports/saga'
import payrollReportSaga from './payrollReports/saga'
import vendorSaga from './vendors/saga'
import productSaga from './products/saga'
import purchaseSaga from './purchases/saga'
import distributionsSaga from './distributions/saga'
import locationSaga from './locations/saga'
import usersSaga from "./users/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(profileSaga),
    fork(tenantSaga),
    fork(LayoutSaga),
    fork(estimateSaga),
    fork(invoiceSaga),
    fork(projectsSaga),
    fork(timeoffSaga),
    fork(tasksSaga),
    fork(customerSaga),
    fork(subcontractorSaga),
    fork(categorySaga),
    fork(roleSaga),
    fork(timesheetsSaga),
    fork(materialSaga),
    fork(usersSaga),
    fork(expenseSaga),
    fork(payrollSaga),
    fork(timesheetReportSaga),
    fork(payrollReportSaga),
    fork(staffSaga),
    fork(vendorSaga),
    fork(productSaga),
    fork(purchaseSaga),
    fork(distributionsSaga),
    fork(locationSaga),
    fork(payrateSaga)
  ])
}
