import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'

import { editStaffModal, editStaff } from 'store/staffs/actions'
import moment from "moment";

function index({ staff: target }) {
  let dispatch = useDispatch()
  const { edit: modal } = useSelector((state) => state.staffs);
  let [staff, setStaff] = useState()

  useEffect(() => {
    if (modal) {
      setStaff(target)
    }
  }, [modal])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      first_name: (staff && staff.first_name) || '',
      last_name: (staff && staff.last_name) || '',
      date_birth: (staff && staff.date_birth) || '',
      role_start: (staff && moment(staff.role_start).format("YYYY-MM-DD")) || '',
      job_related_skills: (staff && staff.job_related_skills) || '',
      city: (staff && staff.city) || '',
      email: (staff && staff.user?.email) || '',
      phone: (staff && staff.user?.phone) || '',
      state: (staff && staff.state) || '',
      zip_code: (staff && staff.zip_code) || '',
      country: (staff && staff.country) || '',
      street: (staff && staff.street) || '',
      avatar: (staff && staff.avatar) || null,
      role: (staff && staff.role) || null,
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please Enter Your First Name"),
      role: Yup.mixed().required("Please Enter Role"),
      email: Yup.string().required("Please Enter Your Email"),
      phone: Yup.string().required("Please Enter Your Phone Number"),
      last_name: Yup.string().required("Please Enter Your Last Name"),
      city: Yup.string().required("Please Enter Your City"),
      state: Yup.string().required("Please Enter Your State"),
      zip_code: Yup.string().required("Please Enter Your Zip code"),
      job_related_skills: Yup.string().required("Please Enter jobs related skills"),
      street: Yup.string().required("Please Enter Your Street"),
      country: Yup.string().required("Please Enter Your Country"),
      date_birth: Yup.string().required("Please Enter Your Date of Birth"),
      role_start: Yup.string().required("Please Enter role start date")
    }),
    onSubmit: (_values, actions) => {
      let { email, phone, ...values } = _values
      dispatch(editStaff(staff.id, { ...values, user: { email, phone } }, actions))
    },
  });



  return (
    <React.Fragment>
      <Modal size="lg" isOpen={modal} toggle={() => dispatch(editStaffModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(editStaffModal())}>
          Edit Staff
        </ModalHeader>
        <ModalBody>
          <Form
            validation={validation}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
