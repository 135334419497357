import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'

import { editExpenseModal, editExpense } from 'store/expenses/actions'

function index({ expense: target, projectId }) {
  let dispatch = useDispatch()
  const { edit: modal } = useSelector((state) => state.expenses);
  let [expense, setExpense] = useState()

  useEffect(() => {
    if (modal) {
      setExpense(target)
    }
  }, [modal])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      type: (expense && expense.product) ? 'stock' : 'non-stock',
      item: (expense && expense.item) || '',
      product: (expense && expense.product) || null,
      location: (expense && expense.location) || null,
      unit_cost: (expense && expense.unit_cost) || '',
      quantity: (expense && expense.quantity) || '',
    },
    validationSchema: Yup.object({
      type: Yup.string().required("Please Select Expense Type"),
      product: Yup.mixed().when('type', {
        is: type => type == 'stock',
        then: Yup.mixed().required("Please Select Product")
      }),
      location: Yup.mixed().when('type', {
        is: type => type == 'stock',
        then: Yup.mixed().required("Please Select Location")
      }),
      item: Yup.mixed().when('type', {
        is: type => type == 'non-stock',
        then: Yup.string().required("Please Enter Item Name")
      }),
      unit_cost: Yup.number().required("Please Enter Unit Cost"),
      quantity: Yup.number().required("Please Enter Quantity"),
    }),
    onSubmit: (_values, actions) => {
      let { item, product, location, type, rest = {}, ...values } = _values
      rest = type == 'non-stock' ? { item } : { product: product?.id, location: location?.id }
      dispatch(editExpense(projectId, expense.id, { ...rest, ...values }, actions))
    },
  });

  return (
    <React.Fragment>
      <Modal isOpen={modal} toggle={() => dispatch(editExpenseModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(editExpenseModal())}>
          Edit Expense
        </ModalHeader>
        <ModalBody>
          <Form
            edit
            validation={validation}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
