import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"

//invoices
import invoices from "./invoices/reducer"

//expenses
import expenses from "./expenses/reducer"

//timeoffs
import timeoffs from "./timeoffs/reducer"

//projects
import projects from "./projects/reducer"

//payrolls
import payrolls from "./payrolls/reducer"

//tasks
import tasks from "./tasks/reducer"

//timesheets
import timesheets from "./timesheets/reducer"

//customers
import customers from "./customers/reducer"

//users
import users from "./users/reducer"

//timesheetReports
import timesheetReports from "./timesheetReports/reducer"

//payrollReports
import payrollReports from "./payrollReports/reducer"

//profiles
import profiles from "./profiles/reducer"

//tenants
import tenants from "./tenants/reducer"

//subcontractors
import subcontractors from "./subcontractors/reducer"

//categories
import categories from "./categories/reducer"

//estimates
import estimates from "./estimates/reducer"

//materials
import materials from "./materials/reducer"

//payrates
import payrates from "./payrates/reducer"

//staffs
import staffs from "./staffs/reducer";

//roles
import roles from "./roles/reducer";

//vendors
import vendors from "./vendors/reducer";

//products
import products from "./products/reducer";

//purchases
import purchases from "./purchases/reducer";

//distributions
import distributions from "./distributions/reducer";

//locations
import locations from "./locations/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  invoices,
  profiles,
  tenants,
  staffs,
  projects,
  estimates,
  tasks,
  customers,
  roles,
  subcontractors,
  users,
  payrates,
  categories,
  materials,
  timesheets,
  timeoffs,
  timesheetReports,
  payrollReports,
  expenses,
  payrolls,
  vendors,
  purchases,
  distributions,
  locations,
  products
})

export default rootReducer
