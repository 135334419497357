import { PaymentElement } from "@stripe/react-stripe-js";


function CardSection() {
  return (
    <PaymentElement />
  );
}

export default CardSection;
