import request from "../restClient";

export const login = async (data) => {
  return await request({ url: "login/", method: "POST", disableSubdomain: true, data });
};

export const signup = async (data) => {
  return await request({ url: "signup/", method: "POST", disableSubdomain: true, data });
};

export const find = async (data) => {
  return await request({ url: "users/find/", method: "POST", disableSubdomain: true, data });
};

export const requestPasswordChange = async (data) => {
  return await request({ url: "users/password_change/request/", method: "POST", disableSubdomain: true, data });
};

export const verifyPasswordChange = async (data) => {
  let { uidb64, token, ...formdata } = data
  return await request({ url: `users/password_change/verify_token/${uidb64}/${token}`, method: "POST", disableSubdomain: true, data: formdata });
};

export const passwordChange = async (data) => {
  let { uidb64, token, ...formdata } = data
  return await request({ url: `users/password_change/${uidb64}/${token}`, method: "POST", disableSubdomain: true, data: formdata });
};
