import React, { useEffect } from "react";
import {
  Col,
  Row,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap";
import AsyncSelect from 'react-select/async';
import { getEmployees } from "api/v1";

function index({ validation, edit = false }) {

  let loadEmployees = (search) => {
    return getEmployees({ search, no_paginate: 1 })
      .then((results = []) => {
        return results
      })
      .catch(({ message }) => {
        if (message !== 'request-cancel') {
          toast.error(message);
        }
      })
  }

  useEffect(() => {
    if (validation.values.to_date)
      return

    validation.setFieldValue('to_date', validation.values.from_date)
  }, [validation.values.from_date])

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <Row form>
        <Col xs="12" className="mb-3">
          <Label className="form-label">Select Employee</Label>
          <AsyncSelect
            defaultOptions
            isDisabled={edit}
            placeholder="Select Member"
            required
            value={validation.values.user}
            getOptionLabel={({ profile: { first_name, last_name } }) => `${first_name} ${last_name}`}
            getOptionValue={({ id }) => id}
            loadOptions={loadEmployees}
            onChange={(_user) => {
              validation.setFieldValue('user', _user)
            }}

          />
          {validation.touched.user && validation.errors.user ? (
            <span className="text-danger font-size-11">{validation.errors.user}</span>
          ) : null}
        </Col>

        <Col className="col-12">
          <div className="mb-3">
            <Label className="form-label">From Date</Label>
            <Input
              name="from_date"
              type="date"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.from_date || ""}
              invalid={
                validation.touched.from_date && validation.errors.from_date ? true : false
              }
            />
            {validation.touched.from_date && validation.errors.from_date ? (
              <FormFeedback type="invalid">{validation.errors.from_date}</FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">To Date</Label>
            <Input
              name="to_date"
              type="date"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.to_date || ""}
              invalid={
                validation.touched.to_date && validation.errors.to_date ? true : false
              }
            />
            {validation.touched.to_date && validation.errors.to_date ? (
              <FormFeedback type="invalid">{validation.errors.to_date}</FormFeedback>
            ) : null}
          </div>

          {
            edit &&
            <div className="mb-3">
              <Label className="form-label">Status</Label>
              <Input
                name="status"
                type="select"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.status || ""}
                invalid={
                  validation.touched.status && validation.errors.status ? true : false
                }
              >
                <option value="">Select Status</option>
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
              </Input>
              {validation.touched.status && validation.errors.status ? (
                <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
              ) : null}
            </div>
          }
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="text-end">
            <button
              type="submit"
              className="btn btn-success save-customer"
            >
              Save
            </button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default index
