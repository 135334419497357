import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import Loader from "common/Loader";
import { usFormat } from "utils/common";


const ExpenseDetail = ({ expenses, loading }) => {
  function total() {
    let _total = 0

    expenses.forEach(expense => {
      _total += parseFloat(expense.total_cost)
    })
    return _total
  }


  return (
    <Card style={{ borderWidth: 1, borderColor: '#F1F1F1' }}>
      <CardHeader>
        <CardTitle className="mb-2">Total Expenses</CardTitle>
      </CardHeader>
      <CardBody>
        {
          loading ?
            <Loader />
            :
            <React.Fragment>
              {expenses && <React.Fragment>
                <Row>
                  <Col xs="4">
                    <h3>{usFormat(total() || 0)}</h3>
                  </Col>
                </Row>
              </React.Fragment>
              }
            </React.Fragment>
        }
      </CardBody>
    </Card>
  );
};

ExpenseDetail.propTypes = {
  project: PropTypes.object,
};

export default ExpenseDetail;
