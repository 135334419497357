import {
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS,
  ADD_CUSTOMER,
  EDIT_CUSTOMER,
  DELETE_CUSTOMER,
} from "./actionTypes";

const INIT_STATE = {
  customerWithPagy: {
    total: 0,
    page: 1,
    page_size: 10,
    results: []
  },
  new: false,
  edit: false,
  delete: false,
  loading: false,
};

const customers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CUSTOMERS:
      return {
        ...state,
        new: false,
        edit: false,
        delete: false,
        loading: action.payload.loading
      };

    case GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        loading: false,
        customerWithPagy: action.payload,
      };

    case ADD_CUSTOMER:
      return {
        ...state,
        loading: true
      };

    case EDIT_CUSTOMER:
      return {
        ...state,
        loading: true
      };

    case GET_CUSTOMERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "NEW_CUSTOMER_MODAL":
      return {
        ...state,
        new: !state.new
      };

    case "DELETE_CUSTOMER_MODAL":
      return {
        ...state,
        delete: !state.delete
      };

    case "EDIT_CUSTOMER_MODAL":
      return {
        ...state,
        edit: !state.edit
      };
    default:
      return state;
  }
};

export default customers;
