export const getTenant = () => ({
  type: 'GET_TENANT'
})

export const onTenantComplete = () => ({
  type: 'TENANT_COMPLETE'
})

export const editTenant = (tenant, actions) => {
  return {
    type: 'EDIT_TENANT',
    payload: { tenant, actions },
  }
}

export const setTenant = (tenant) => {
  return {
    type: 'SET_TENANT',
    payload: { tenant }
  }
}
