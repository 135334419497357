import {
  GET_ESTIMATES,
  GET_ESTIMATES_SUCCESS,
  GET_ESTIMATES_FAIL,
  DELETE_ESTIMATE,
  ADD_ESTIMATE,
  EDIT_ESTIMATE
} from "./actionTypes"

export const getEstimates = (loading = false, params = {}) => ({
  type: GET_ESTIMATES,
  payload: { loading, params }
})

export const getEstimatesSuccess = estimates => ({
  type: GET_ESTIMATES_SUCCESS,
  payload: estimates,
})

export const getEstimatesFail = error => ({
  type: GET_ESTIMATES_FAIL,
  payload: error,
})

export const deleteEstimate = estimate => ({
  type: DELETE_ESTIMATE,
  payload: { estimate }
})

export const generateEstimate = estimate => ({
  type: 'GENERATE_ESTIMATE',
  payload: { estimate }
})

export const addEstimate = (estimate, actions) => {
  return {
    type: ADD_ESTIMATE,
    payload: { estimate, actions },
  }
}

export const editEstimate = (id, estimate, actions) => {
  return {
    type: EDIT_ESTIMATE,
    payload: { id, estimate, actions },
  }
}

export const setGeneratableEstimate = (estimate) => {
  return {
    type: 'SET_GENERATABLE_ESTIMATE',
    payload: { estimate },
  }
}

export const newEstimateModal = () => ({
  type: 'NEW_ESTIMATE_MODAL'
})

export const deleteEstimateModal = () => ({
  type: 'DELETE_ESTIMATE_MODAL'
})

export const generateEstimateModal = () => ({
  type: 'GENERATE_ESTIMATE_MODAL'
})

export const editEstimateModal = () => ({
  type: 'EDIT_ESTIMATE_MODAL'
})
