import {
  GET_SUBCONTRACTORS,
  GET_SUBCONTRACTORS_SUCCESS,
  GET_SUBCONTRACTORS_FAIL,
  DELETE_SUBCONTRACTOR,
  ADD_SUBCONTRACTOR,
  EDIT_SUBCONTRACTOR
} from "./actionTypes"

export const getSubcontractors = (loading = false, params = {}) => ({
  type: GET_SUBCONTRACTORS,
  payload: { loading, params }
})

export const getSubcontractorsSuccess = subcontractors => ({
  type: GET_SUBCONTRACTORS_SUCCESS,
  payload: subcontractors,
})

export const getSubcontractorsFail = error => ({
  type: GET_SUBCONTRACTORS_FAIL,
  payload: error,
})

export const deleteSubcontractor = subcontractor => ({
  type: DELETE_SUBCONTRACTOR,
  payload: { subcontractor }
})

export const addSubcontractor = (subcontractor, actions) => {
  return {
    type: ADD_SUBCONTRACTOR,
    payload: { subcontractor, actions },
  }
}

export const editSubcontractor = (id, subcontractor, actions) => {
  return {
    type: EDIT_SUBCONTRACTOR,
    payload: { id, subcontractor, actions },
  }
}

export const newSubcontractorModal = () => ({
  type: 'NEW_SUBCONTRACTOR_MODAL'
})

export const deleteSubcontractorModal = () => ({
  type: 'DELETE_SUBCONTRACTOR_MODAL'
})

export const editSubcontractorModal = () => ({
  type: 'EDIT_SUBCONTRACTOR_MODAL'
})
