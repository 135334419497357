import React, { Fragment } from "react";
import {
  Col,
  Row,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap";
import NumberFormat from 'react-number-format';
import AsyncSelect from 'react-select/async';
import { getProduct, getProducts } from "api/v1";

function currencyFormatter(value) {
  if (!Number(value)) return "";

  const amount = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  }).format(value / 100);

  return `${amount}`;
}

let loadProducts = (search) => {
  return getProducts({ search, no_paginate: 1 })
    .then((results = []) => {
      return results
    })
    .catch(({ message }) => {
      if (message !== 'request-cancel') {
        toast.error(message);
      }
    })
}

let loadLocations = (productId) => {
  return getProduct(productId)
    .then(_product => {
      return _product.stock.map(_stock => ({ available_stock: _stock.available_stock, ..._stock.location })) || []
    })
    .catch(({ message }) => {
      if (message !== 'request-cancel') {
        toast.error(message);
      }
    })
}

function index({ validation, edit = false }) {
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <Row>
        <Col xs="12" className="mb-3">
          <Label className="form-label">Expense type</Label>
          <Input
            disabled={edit}
            name="type"
            type="select"
            className="form-select"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.type || ""}
            invalid={
              validation.touched.type && validation.errors.type ? true : false
            }
          >
            <option value=""></option>
            <option value="non-stock">Non Stock</option>
            <option value="stock">Stock</option>

          </Input>
          {validation.touched.type && validation.errors.type ? (
            <FormFeedback type="invalid">{validation.errors.type}</FormFeedback>
          ) : null}
        </Col>

        {
          validation.values.type == 'non-stock' ?
            <Col xs="12" className="mb-3">
              <Label className="form-label">Item name</Label>
              <Input
                name="item"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.item || ""}
                invalid={
                  validation.touched.item && validation.errors.item ? true : false
                }
              />
              {validation.touched.item && validation.errors.item ? (
                <FormFeedback type="invalid">{validation.errors.item}</FormFeedback>
              ) : null}
            </Col>
            : validation.values.type == 'stock' ?
              <Fragment>
                <Col xs="12" className="mb-3">
                  <Label className="form-label">Product</Label>
                  <AsyncSelect
                    defaultOptions
                    placeholder="Select Product"
                    required
                    value={validation.values.product}
                    getOptionLabel={({ name }) => name}
                    getOptionValue={({ id }) => id}
                    loadOptions={loadProducts}
                    onChange={(_product) => {
                      validation.setValues({
                        ...validation.values,
                        product: _product,
                        location: null,
                        quantity: 0
                      })
                    }}

                  />
                  {validation.touched.product && validation.errors.product ? (
                    <span className="text-danger font-size-11">{validation.errors.product}</span>
                  ) : null}
                </Col>

                {
                  validation.values.product &&
                  <Col xs="12" className="mb-3">
                    <Label className="form-label">Location</Label>
                    <AsyncSelect
                      key={`key-${validation.values.product.id}`}
                      isSearchable={false}
                      defaultOptions
                      placeholder="Select Location"
                      required
                      value={validation.values.location}
                      getOptionLabel={({ location }) => location}
                      getOptionValue={({ id }) => id}
                      loadOptions={_ => loadLocations(validation.values.product.id)}
                      onChange={(_location) => {
                        validation.setValues({
                          ...validation.values,
                          location: _location,
                          quantity: _location.available_stock || 0
                        })
                      }}

                    />
                    {validation.touched.location && validation.errors.location ? (
                      <span className="text-danger font-size-11">{validation.errors.location}</span>
                    ) : null}
                  </Col>
                }

              </Fragment> : null
        }

        <Col xs="12" className="mb-3">
          <Label className="form-label">Quantity</Label>
          <Input
            name="quantity"
            type="text"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.quantity || ""}
            invalid={
              validation.touched.quantity && validation.errors.quantity ? true : false
            }
          />
          {validation.touched.quantity && validation.errors.quantity ? (
            <FormFeedback type="invalid">{validation.errors.quantity}</FormFeedback>
          ) : null}
        </Col>

        <Col xs="12" className="mb-3">
          <Label className="form-label">Unit Cost</Label>
          <NumberFormat
            className={`form-control ${validation.touched.unit_cost && validation.errors.unit_cost && "border-danger"}`}
            format={currencyFormatter}
            name="unit_cost"
            onBlur={validation.handleBlur}
            value={validation.values.unit_cost || ""}
            onChange={e => {
              validation.setFieldValue('unit_cost', e.target.value.replace("$", "").replace(/,/g, ""))
            }}
          />
          {validation.touched.unit_cost && validation.errors.unit_cost ? (
            <span className="text-danger font-size-11">{validation.errors.unit_cost}</span>
          ) : null}
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="text-end">
            <button
              type="submit"
              className="btn btn-success save-customer"
            >
              Save
            </button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default index
