import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'
import moment from 'moment';

import { editTimeoffModal, editTimeoff } from 'store/timeoffs/actions'

function index({ timeoff: target }) {
  let dispatch = useDispatch()
  const { edit: modal } = useSelector((state) => state.timeoffs);
  let [timeoff, setTimeoff] = useState()

  useEffect(() => {
    if (modal) {
      setTimeoff(target)
    }
  }, [modal])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      user: (timeoff && timeoff.user) || null,
      status: (timeoff && timeoff.status) || "",
      from_date: (timeoff && moment(timeoff.from_date, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD')) || '',
      to_date: (timeoff && moment(timeoff.to_date, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY-MM-DD')) || ''
    },
    validationSchema: Yup.object({
      from_date: Yup.string().required("Please Enter Start Date"),
      status: Yup.string().required("Please Select Status"),
      user: Yup.mixed().required("Please Enter Member")
    }),
    onSubmit: (values, actions) => {
      dispatch(editTimeoff(timeoff.id, {
        ...values,
        user: timeoff.user.id,
        from_date: moment(values.from_date, "YYYY-MM-DD").startOf('day').format("YYYY-MM-DDTHH:mm:ss"),
        to_date: moment(values.to_date || values.from_date, "YYYY-MM-DD").endOf('day').format("YYYY-MM-DDTHH:mm:ss"),
      }, actions))
    },
  });



  return (
    <React.Fragment>
      <Modal isOpen={modal} toggle={() => dispatch(editTimeoffModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(editTimeoffModal())}>
          Edit Timeoff
        </ModalHeader>
        <ModalBody>
          <Form
            edit
            validation={validation}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
