import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';
import {
  GET_CUSTOMERS,
  DELETE_CUSTOMER,
  ADD_CUSTOMER,
  EDIT_CUSTOMER
} from "./actionTypes";

import {
  getCustomersSuccess,
  getCustomers,
  getCustomersFail,
  deleteCustomerModal
} from "./actions";

import {
  getCustomers as getCustomersAPI,
  deleteCustomer as deleteCustomerAPI,
  addCustomer as addCustomerAPI,
  editCustomer as editCustomerAPI,
} from "api/v1";


function* fetchCustomers({ payload: { params } }) {
  try {
    const response = yield call(getCustomersAPI, params);
    yield put(getCustomersSuccess(response));
  } catch (error) {
    yield put(getCustomersFail("Something went wrong!"))
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* addCustomer({ payload: { customer, actions } }) {
  try {
    yield call(addCustomerAPI, customer)
    toast.success("Customer Created!");
    yield put(getCustomers(true));
    actions.resetForm()

  } catch ({ errors }) {
    yield put(getCustomersFail("Creation failed!"))
    if (errors) {
      let { user } = errors
      actions.setErrors({ ...errors, ...user })

    }
    toast.error("Something went wrong!");
  }
}

function* editCustomer({ payload: { id, customer, actions } }) {
  try {
    yield call(editCustomerAPI, id, customer)
    toast.success("Customer Updated!");
    yield put(getCustomers(true));

  } catch ({ errors }) {
    yield put(getCustomersFail("Creation failed!"))
    if (errors && actions) {
      let { user } = errors
      actions.setErrors({ ...errors, ...user })

    }
    toast.error("Something went wrong!");
  }
}

function* deleteCustomer({ payload: { customer } }) {
  try {
    yield call(deleteCustomerAPI, customer.id);
    put(deleteCustomerModal())
    yield put(getCustomers(true));
    toast.warn("Customer Deleted!");
  } catch (error) {
    toast.error("Something went wrong!");
  }
}

function* customerSaga() {
  yield takeEvery(GET_CUSTOMERS, fetchCustomers);
  yield takeEvery(DELETE_CUSTOMER, deleteCustomer);
  yield takeEvery(ADD_CUSTOMER, addCustomer);
  yield takeEvery(EDIT_CUSTOMER, editCustomer);
}

export default customerSaga;
