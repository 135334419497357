import {
  GET_PAYROLLS,
  GET_PAYROLLS_SUCCESS,
  GET_PAYROLLS_FAIL,
  DELETE_PAYROLL,
  ADD_PAYROLL,
  EDIT_PAYROLL
} from "./actionTypes"

export const getPayrolls = (loading = false, params = {}) => ({
  type: GET_PAYROLLS,
  payload: { loading, params }
})

export const getPayrollsSuccess = payrolls => ({
  type: GET_PAYROLLS_SUCCESS,
  payload: payrolls,
})

export const getPayrollsFail = error => ({
  type: GET_PAYROLLS_FAIL,
  payload: error,
})

export const deletePayroll = payroll => ({
  type: DELETE_PAYROLL,
  payload: { payroll }
})

export const addPayroll = (payroll, actions) => {
  return {
    type: ADD_PAYROLL,
    payload: { payroll, actions },
  }
}

export const editPayroll = (id, payroll, actions) => {
  return {
    type: EDIT_PAYROLL,
    payload: { id, payroll, actions },
  }
}

export const newPayrollModal = () => ({
  type: 'NEW_PAYROLL_MODAL'
})

export const deletePayrollModal = () => ({
  type: 'DELETE_PAYROLL_MODAL'
})

export const editPayrollModal = () => ({
  type: 'EDIT_PAYROLL_MODAL'
})
