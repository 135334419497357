import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'

import { newEstimateModal, addEstimate } from 'store/estimates/actions'

function index() {
  let dispatch = useDispatch()
  const { new: modal } = useSelector((state) => state.estimates);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      customer: null,
      project: null,
      details: '',
      valid_until: '',
      discount_percent: 0,
      _deleted_estimated_costs: [], // not usefull here
      estimated_costs: [],
    },
    validationSchema: Yup.object().shape({
      customer: Yup.mixed().required("Please Select Customer"),
      project: Yup.mixed().required("Please Select Project"),
      details: Yup.string().required("Please Enter Description"),
      valid_until: Yup.string().required("Please Enter Due Date"),

      estimated_costs: Yup.array().of(
        Yup.object().shape({
          unit_cost: Yup.string().required("unit cost required"),
          quantity: Yup.string().required("quantity required"),
          unit: Yup.string().required("unit required"),
          item_name: Yup.string().required("item name required"),
        })
      ).min(1, "Please add at least one item").required("required")
    }),
    onSubmit: (values, actions) => {
      let {
        customer,
        project,
        estimated_costs,
        _deleted_estimated_costs,
        ...payload
      } = values

      dispatch(addEstimate({
        customer: customer.id,
        project: project.id,
        estimated_costs: estimated_costs.map(_item => {
          let { quantity, square_ft, ..._rest } = _item
          return ({ ..._rest, [_item.unit == "Quantity" ? "quantity" : "square_ft"]: quantity })
        }),
        ...payload
      }, actions))
    },
  });

  useEffect(() => {
    if (modal) {
      validation.resetForm()
    }
  }, [modal])

  return (
    <React.Fragment>
      <Modal size="xl" isOpen={modal} toggle={() => dispatch(newEstimateModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(newEstimateModal())}>
          Add Estimate
        </ModalHeader>
        <ModalBody>
          <Form
            validation={validation}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
