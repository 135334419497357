import React from "react"
import PropTypes from "prop-types"
import { Card, CardBody, CardTitle, Table } from "reactstrap"
import { Link } from "react-router-dom"
import { map } from "lodash"

const ProjectMembers = ({ members }) => {
  return (
    <Card style={{ height: '260px', overflow: 'auto' }}>
      <CardBody>
        <CardTitle className="mb-4">Team Members</CardTitle>

        <div className="table-responsive">
          <Table className="table align-middle table-nowrap">
            <tbody>
              {map(members, (member, k) => (
                <tr key={"_member_" + k}>
                  <td style={{ width: "50px" }}>
                    {member.avatar !== "Null" ? (
                      <img
                        src={member.avatar}
                        className="rounded-circle avatar-xs"
                        alt=""
                      />
                    ) : (
                      <div className="avatar-xs">
                        <span className="avatar-title rounded-circle bg-primary text-white font-size-16">
                          {member.first_name.charAt(0)}
                        </span>
                      </div>
                    )}
                  </td>
                  <td>
                    <h5 className="font-size-14 m-0">
                      <Link to="" className="text-dark">
                        {`${member.first_name} ${member.last_name}`}
                      </Link>
                    </h5>
                  </td>
                  <td>
                    <div className="badge bg-primary bg-soft text-primary font-size-11 me-1">
                      {member.type}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  )
}

ProjectMembers.propTypes = {
  team: PropTypes.array,
}

export default ProjectMembers
