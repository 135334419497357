import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';
import {
  GET_PAYRATES,
  DELETE_PAYRATE,
  ADD_PAYRATE,
  EDIT_PAYRATE
} from "./actionTypes";

import {
  getPayratesSuccess,
  getPayrates,
  getPayratesFail,
  deletePayrateModal
} from "./actions";

import {
  getPayrates as getPayratesAPI,
  deletePayrate as deletePayrateAPI,
  addPayrate as addPayrateAPI,
  editPayrate as editPayrateAPI,
} from "api/v1";


function* fetchPayrates({ payload: { params } }) {
  try {
    const response = yield call(getPayratesAPI, params);
    yield put(getPayratesSuccess(response));
  } catch (error) {
    yield put(getPayratesFail("Something went wrong!"))
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* addPayrate({ payload: { payrate, actions } }) {
  try {
    yield call(addPayrateAPI, payrate)
    toast.success("Payrate Created!");
    yield put(getPayrates(true));
    actions.resetForm()

  } catch ({ errors }) {
    yield put(getPayratesFail("Creation failed!"))
    if (errors) {
      actions.setErrors(errors)

    }
    toast.error("Something went wrong!");
  }
}

function* editPayrate({ payload: { id, payrate, actions } }) {
  try {
    yield call(editPayrateAPI, id, payrate)
    toast.success("Payrate Updated!");
    yield put(getPayrates(true));

  } catch ({ errors }) {
    yield put(getPayratesFail("Creation failed!"))
    if (errors) {
      actions.setErrors(errors)

    }
    toast.error("Something went wrong!");
  }
}

function* deletePayrate({ payload: { payrate } }) {
  try {
    yield call(deletePayrateAPI, payrate.id);
    put(deletePayrateModal())
    yield put(getPayrates(true));
    toast.warn("Payrate Deleted!");
  } catch (error) {
    toast.error("Something went wrong!");
  }
}

function* payrateSaga() {
  yield takeEvery(GET_PAYRATES, fetchPayrates);
  yield takeEvery(DELETE_PAYRATE, deletePayrate);
  yield takeEvery(ADD_PAYRATE, addPayrate);
  yield takeEvery(EDIT_PAYRATE, editPayrate);
}

export default payrateSaga;
