import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap'
import { getEmployees } from 'api/v1'
import * as Yup from "yup";
import { useFormik } from "formik";
import AsyncSelect from 'react-select/async';

function Select({ submit, close }) {

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      users: [],
      from_date: '',
      to_date: ''
    },
    validationSchema: Yup.object({
      users: Yup.array().min(1, "Please Select at least one user").required("required"),
      from_date: Yup.string().required("Enter Start Date"),
      to_date: Yup.string().required("Enter To Date"),
    }),
    onSubmit: (values) => {
      submit({ ...values, users: values.users.map(_user => _user.id), is_timesheet_report_shared_with_customer: true })
    },
  });


  let loadMembers = (search) => {
    return getEmployees({ search, no_paginate: 1 })
      .then((results = []) => {
        return results
      })
      .catch(({ message }) => {
        if (message !== 'request-cancel') {
          toast.error(message);
        }
      })
  }

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <h4>Select Timesheets </h4>
      <div className='container'>
        <Row>
          <Col xs="12" className="mb-3">
            <Label className="form-label">User</Label>
            <AsyncSelect
              defaultOptions
              placeholder="Select User"
              required
              isMulti
              value={validation.values.users}
              getOptionLabel={({ profile: { first_name, last_name }, type }) => `${first_name} ${last_name} (${type})`}
              getOptionValue={({ id }) => id}
              loadOptions={loadMembers}
              onChange={(_users) => {
                validation.setFieldValue('users', _users)
              }}
            />
            {validation.touched.users && validation.errors.users ? (
              <span className="text-danger font-size-11">{validation.errors.users}</span>
            ) : null}
          </Col>

          <Col xs="12" className="mb-3">
            <Label className="form-label">From date</Label>
            <Input
              name="from_date"
              type="date"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.from_date || ""}
              invalid={
                validation.touched.from_date && validation.errors.from_date ? true : false
              }
            />
            {validation.touched.from_date && validation.errors.from_date ? (
              <FormFeedback type="invalid">{validation.errors.from_date}</FormFeedback>
            ) : null}
          </Col>

          <Col xs="12" className="mb-3">
            <Label className="form-label">To date</Label>
            <Input
              name="to_date"
              type="date"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.to_date || ""}
              invalid={
                validation.touched.to_date && validation.errors.to_date ? true : false
              }
            />
            {validation.touched.to_date && validation.errors.to_date ? (
              <FormFeedback type="invalid">{validation.errors.to_date}</FormFeedback>
            ) : null}
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                onClick={close}
                className="btn btn-danger me-1"
              >
                Cancel
              </button>

              <button
                type='submit'
                className="btn btn-success"
              >
                Submit
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </Form>
  )
}

export default Select
