import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { Row, Col, Alert, Form, Input, FormFeedback, Label } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, useParams } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { setLoader } from "utils/common";
import { resetPassword } from "store/actions";

const ResetPassword = ({ subdomain, isSubdomainPresent, ...props }) => {
  const dispatch = useDispatch();

  const commonSchema = Yup.object({
    password: Yup.string().required("Please Enter Your Password"),
    confirm_password: Yup.string().required("Please Confirm Password"),
  })
  const commonValues = {
    password: '',
    confirm_password: ''
  }
  let { uidb64, token } = useParams();

  const form = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: isSubdomainPresent ? commonValues : { ...commonValues, subdomain: '' },
    validationSchema: isSubdomainPresent ? commonSchema : commonSchema.concat(Yup.object({ subdomain: Yup.string().required("Please Enter Your Subdomain") })),
    onSubmit: (values, actions) => {
      let { subdomain: _subdomain } = values

      dispatch(resetPassword({
        ...values,
        subdomain: _subdomain || subdomain,
        uidb64, token
      }, actions, props.history));
    }
  });

  const { error, loading, success } = useSelector((state) => state.Login);


  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  return (
    <Form
      className="form-horizontal"
      onSubmit={(e) => {
        e.preventDefault();
        form.handleSubmit();
        return false;
      }}
    >
      {error ? <Alert color="danger">{error}</Alert> : null}
      {success && success ? (
        <Alert color="success">
          {success}
        </Alert>
      ) : null}

      {!subdomain && <div className="mb-3">
        <Label className="form-label">Company name:</Label>
        <Input
          name="subdomain"
          type="text"
          placeholder="Enter your Company name"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.subdomain || ""}
          invalid={
            form.touched.subdomain && form.errors.subdomain ? true : false
          }
        />
        {form.touched.subdomain && form.errors.subdomain ? (
          <FormFeedback type="invalid">{form.errors.subdomain}</FormFeedback>
        ) : null}
      </div>}


      <div className="mb-3">
        <Label className="form-label">Password</Label>
        <Input
          name="password"
          type="password"
          placeholder="Enter Password"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.password || ""}
          invalid={
            form.touched.password && form.errors.password ? true : false
          }
        />
        {form.touched.password && form.errors.password ? (
          <FormFeedback type="invalid">{form.errors.password}</FormFeedback>
        ) : null}
      </div>


      <div className="mb-3">
        <Label className="form-label">Confirm Password</Label>
        <Input
          name="confirm_password"
          type="password"
          placeholder="Enter Password"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.confirm_password || ""}
          invalid={
            form.touched.confirm_password && form.errors.confirm_password ? true : false
          }
        />
        {form.touched.confirm_password && form.errors.confirm_password ? (
          <FormFeedback type="invalid">{form.errors.confirm_password}</FormFeedback>
        ) : null}
      </div>

      <div className="mt-4">
        <button
          className="btn btn-primary d-block w-100"
          type="submit"
        >
          Reset Password
        </button>
      </div>
    </Form>
  );
};

export default withRouter(ResetPassword);

ResetPassword.propTypes = {
  history: PropTypes.object,
};
