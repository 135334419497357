import {
  GET_MATERIALS,
  GET_MATERIALS_SUCCESS,
  GET_MATERIALS_FAIL,
  DELETE_MATERIAL,
  ADD_MATERIAL,
  EDIT_MATERIAL
} from "./actionTypes"

export const getMaterials = (loading = false, params = {}) => ({
  type: GET_MATERIALS,
  payload: { loading, params }
})

export const getMaterialsSuccess = materials => ({
  type: GET_MATERIALS_SUCCESS,
  payload: materials,
})

export const getMaterialsFail = error => ({
  type: GET_MATERIALS_FAIL,
  payload: error,
})

export const deleteMaterial = material => ({
  type: DELETE_MATERIAL,
  payload: { material }
})

export const addMaterial = (material, actions) => {
  return {
    type: ADD_MATERIAL,
    payload: { material, actions },
  }
}

export const editMaterial = (id, material, actions) => {
  return {
    type: EDIT_MATERIAL,
    payload: { id, material, actions },
  }
}

export const newMaterialModal = () => ({
  type: 'NEW_MATERIAL_MODAL'
})

export const deleteMaterialModal = () => ({
  type: 'DELETE_MATERIAL_MODAL'
})

export const editMaterialModal = () => ({
  type: 'EDIT_MATERIAL_MODAL'
})
