import {
  GET_SUBCONTRACTORS_FAIL,
  GET_SUBCONTRACTORS_SUCCESS,
  GET_SUBCONTRACTORS,
  ADD_SUBCONTRACTOR,
  EDIT_SUBCONTRACTOR,
  DELETE_SUBCONTRACTOR,
} from "./actionTypes";

const INIT_STATE = {
  subcontractorWithPagy: {
    total: 0,
    page: 1,
    page_size: 10,
    results: []
  },
  new: false,
  edit: false,
  delete: false,
  loading: false,
};

const subcontractors = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SUBCONTRACTORS:
      return {
        ...state,
        new: false,
        edit: false,
        delete: false,
        loading: action.payload.loading
      };

    case GET_SUBCONTRACTORS_SUCCESS:
      return {
        ...state,
        loading: false,
        subcontractorWithPagy: action.payload,
      };

    case ADD_SUBCONTRACTOR:
      return {
        ...state,
        loading: true
      };

    case EDIT_SUBCONTRACTOR:
      return {
        ...state,
        loading: true
      };

    case DELETE_SUBCONTRACTOR:
      return {
        ...state,
        loading: true
      };

    case GET_SUBCONTRACTORS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "NEW_SUBCONTRACTOR_MODAL":
      return {
        ...state,
        new: !state.new
      };

    case "DELETE_SUBCONTRACTOR_MODAL":
      return {
        ...state,
        delete: !state.delete
      };

    case "EDIT_SUBCONTRACTOR_MODAL":
      return {
        ...state,
        edit: !state.edit
      };
    default:
      return state;
  }
};

export default subcontractors;
