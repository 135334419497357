import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import MetaTags from "react-meta-tags";

import {
  addInvoice
} from 'store/invoices/actions'
import moment from "moment";
import { Card, CardBody, Col, Container, Form, Row } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import Common from './common'
import { getHeaderText } from "./helper";
import { setLoader } from "utils/common";
import { useHistory } from "react-router-dom";

function Create() {
  let dispatch = useDispatch()
  const { loading } = useSelector(state => ({
    loading: state.invoices.loading,
  }));
  const history = useHistory()

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      step: 1,
      customer: null,
      project: null,
      status: '',
      payment_method: '',
      due_date: '',
      date_sent: '',
      discount_percent: 0,
      notes: '',
      prev_invoices: [],
      remote_services: [],
      remote_expenses: [],
      _deleted_expenses: [], // not usefull here
      _deleted_services: [], // not usefull here
      services: [],
      expenses: []
    },
    validationSchema: Yup.object().shape({
      customer: Yup.mixed().when('step', {
        is: step => step == 1,
        then: Yup.mixed().required("Please Select Customer")
      }),
      project: Yup.mixed().when('step', {
        is: step => step == 2,
        then: Yup.mixed().required("Please Select Project")
      }),
      date_sent: Yup.string().when('step', {
        is: step => step == 3,
        then: Yup.string().required("Please Enter Date Sent")
      }),
      due_date: Yup.string().when('step', {
        is: step => step == 3,
        then: Yup.string().required("Please Enter Due Date")
      }),
      status: Yup.string().when('step', {
        is: step => step == 3,
        then: Yup.string().required("Please Enter Status")
      }),
      payment_method: Yup.string().when('step', {
        is: step => step == 3,
        then: Yup.string().required("Please Enter Payment Method")
      }),

      services: Yup.array().of(
        Yup.object().shape({
          task: Yup.string().test('task', 'name required', async function (value) {
            return this.from[1]?.value?.step != 4 || (typeof value != 'undefined' && value != "")
          }),
          // description: Yup.string().test('description', 'description required', async function (value) {

          //   return this.from[1]?.value?.step != 4 || (typeof value != 'undefined' && value != "")
          // }),
          total_hours: Yup.string().test('total_hours', 'total hours required', async function (value) {
            return this.from[1]?.value?.step != 4 || parseFloat(value) > 0
          }),
          rate_per_hour: Yup.string().test('rate_per_hour', 'rate per hour required', async function (value) {
            return this.from[1]?.value?.step != 4 || parseFloat(value) > 0
          }),
        })
      ).when(['step', 'expenses'], {
        is: (step, expenses) => (step == 4 && expenses && expenses.length == 0),
        then: Yup.array().min(1, "Please Select at least one Service").required("required"),
        otherwise: Yup.array()
      }),
      expenses: Yup.array().of(
        Yup.object().shape({
          type: Yup.string().test('type', 'type required', async function (value) {
            return this.from[1]?.value?.step != 5 || (typeof value != 'undefined' && value != "")
          }),
          item: Yup.mixed().when('type', {
            is: type => type == 'non-stock',
            then: Yup.string().test('item', 'item required', async function (value) {
              return this.from[1]?.value?.step != 5 || (typeof value != 'undefined' && value != "")
            })
          }),
          product: Yup.mixed().when('type', {
            is: type => type == 'stock',
            then: Yup.mixed().test('product', 'product required', async function (value) {
              return this.from[1]?.value?.step != 5 || (typeof value != 'undefined' && value != null)
            })
          }),
          location: Yup.mixed().when('type', {
            is: type => type == 'stock',
            then: Yup.mixed().test('location', 'location required', async function (value) {
              return this.from[1]?.value?.step != 5 || (typeof value != 'undefined' && value != null)
            })
          }),
          quantity: Yup.string().test('quantity', 'quantity required', async function (value) {
            return this.from[1]?.value?.step != 5 || parseFloat(value) > 0
          }),
          unit_cost: Yup.string().test('unit_cost', 'unit cost required', async function (value) {
            return this.from[1]?.value?.step != 5 || parseFloat(value) > 0
          })
        })
      ).when(['step', 'services'], {
        is: (step, services) => (step == 5 && services && services.length == 0),
        then: Yup.array().min(1, "Please Select at least one Expense").required("required"),
        otherwise: Yup.array()
      }),
    }, ['expenses', 'services']),
    onSubmit: (values, actions) => {
      let {
        date_sent,
        project,
        step,
        customer,
        expenses,
        services,
        remote_services,
        prev_invoices,
        remote_expenses,
        _deleted_expenses,
        _deleted_services,
        ...payload
      } = values

      if (step == 6)
        dispatch(addInvoice({
          date_sent: moment(date_sent, "YYYY-MM-DD").format("YYYY-MM-DDThh:mm:ssZ"),
          project: project.id,
          prev_invoices: prev_invoices.map(_prev_invoice => _prev_invoice.id),
          expenses: expenses.filter(_item => _item.hasOwnProperty("expense")).map(_expense => ({ ..._expense, product: _expense?.product?.id, location: _expense?.location?.id })),
          new_expenses: expenses.filter(_item => !_item.hasOwnProperty("expense")).map(_expense => ({ ..._expense, product: _expense?.product?.id, location: _expense?.location?.id })),
          services: services.filter(_item => _item.hasOwnProperty("service")),
          new_services: services.filter(_item => !_item.hasOwnProperty("service")),
          ...payload
        }, actions, history))
      else if (step < 6)
        actions.setFieldValue('step', step + 1)

      actions.setTouched({})
    },
  });

  useEffect(() => {
    validation.resetForm()
  }, [])

  let { step } = validation.values

  useEffect(() => {
    setLoader(step != 2 && loading)
  }, [step, loading]);

  return (
    <div className="page-content">
      <MetaTags>
        <title>New Invoice</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs back title="Invoices" breadcrumbItem="New Invoice" />
        <Row>
          <Col xs="12">
            <Card className="w-70 mx-auto">
              <CardBody>
                <h4 className="text-center mb-4">{getHeaderText(validation.values)}</h4>

                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  {step <= 6 && <Common validation={validation} />}
                  <hr className="mt-5" />
                  <div className="mt-3 text-center d-flex justify-content-center">
                    {
                      step > 1 && step <= 6 &&
                      <button
                        onClick={() => validation.setFieldValue('step', step - 1)}
                        className="btn btn-outline-primary btn-lg px-4 me-2"
                        type="button"
                      >
                        Previous
                      </button>
                    }

                    <button
                      className="btn btn-primary btn-lg px-4"
                      type="submit"
                    >
                      {step < 6 ? "Next" : step == 6 ? "Submit" : "Print"}
                    </button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Create
