import React, { Component } from "react";
import styles from "./css/Footer.module.css";
import { Link } from "react-router-dom";


export class Footer extends Component {
  render() {
    return (
      <div id="footerContainer">
        <footer className={styles.footerCont}>
          <div className={styles.footerContInner}>
            <div className={styles.infoBlock}>
              <div className={styles.block12}>
                <div className={styles.block1}>
                  <div className={styles.block1Inner}>
                    <div className={styles.logoCont}>
                      <img src={"../../assets/logo/allpromana.png"} />
                    </div>
                    <div className={styles.introStm}>
                      <span>
                        3DCape & AllPromana works with do grow network of
                        professional, giving them all the technical support that
                        they need to be successful.
                      </span>
                    </div>
                  </div>
                </div>
                <div id="enhanced" className={styles.block2}>
                  <div className={styles.block2Inner}>
                    <ul>
                      <li>
                        <a href={`${process.env.REACT_APP_WEB_HOST}/why-allproplus`}>
                          <span>Why AllProPlus</span>
                        </a>
                      </li>
                      <li>
                        <a href={`${process.env.REACT_APP_WEB_HOST}/pricing`}>
                          <span>Pricing</span>
                        </a>
                      </li>
                      <li>
                        <a href={`${process.env.REACT_APP_WEB_HOST}/resources`}>
                          <span>Resources</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className={styles.block34}>
                <div className={styles.block4}>
                  <div className={styles.block4Inner}>
                    <div className={styles.followUs}>
                      <p>Follow Us</p>
                    </div>
                    <div className={styles.socialIcons}>
                      <div className={styles.icon}>
                        <Link to={{ pathname: "https://www.facebook.com/allpromana" }} target="_blank" rel="noopener noreferrer" >
                          <img src="/assets/icons/facebook.svg" />
                        </Link>
                      </div>
                      <div className={styles.icon}>
                        <Link to={{ pathname: "https://www.instagram.com/allpromana/" }} target="_blank" rel="noopener noreferrer" >
                          <img src="/assets/icons/instagram.svg" />
                        </Link>
                      </div>
                      <div className={styles.icon}>
                        <Link to={{ pathname: "https://www.linkedin.com/company/allpromana/" }} target="_blank" rel="noopener noreferrer" >
                          <img src="/assets/icons/linkedin.svg" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div >
          <div className={styles.copyRightBlock}>
            <p>Copyright 2023 All Rights Reserved. 3DCape.</p>
          </div>
        </footer >
      </div >
    );
  }
}

export default Footer;
