import React from 'react'
import './style.css'
import { isEmpty } from "lodash";
import Card from './card'

function index({ cards, ...props }) {
  return (
    <React.Fragment>
      {isEmpty(cards) ? <h4>No Data Found</h4>
        : <div className="base mt-4">
          <div className="board">
            <div className="board-lists">
              {
                Object.entries(cards).map(keyValue => {
                  return (
                    <Card
                      {...props}
                      key={keyValue[0]}
                      data={keyValue[1]}
                    />
                  )
                })
              }
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  )
}

export default index
