import { shareInvoice as shareInvoiceApi } from 'api/v1'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { Modal, ModalBody } from "reactstrap"
import { getInvoices } from 'store/actions'
import { setLoader } from 'utils/common'
import Customer from './Share/Customer'
import Timesheets from './Share/Timesheets'
import Select from './Share/Select'

const Share = ({ invoice, onCloseClick }) => {
  let show = !!invoice
  const dispatch = useDispatch()
  const [step, setStep] = useState(1)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoader(loading)
  }, [loading])

  useEffect(() => {
    if (show)
      setStep(1)

  }, [show])

  async function shareInvoice(payload = {}) {
    setLoading(true)
    try {
      await shareInvoiceApi(invoice.id, payload)
      dispatch(getInvoices(true))
      onCloseClick()
      toast.success("Invoice is shared with customer")
    } catch (error) {
      toast.error("Something went wrong!")
    }
    setLoading(false)
  }

  return (
    <Modal isOpen={show} size='lg' toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-3">
        {
          step == 1 &&
          <Customer
            next={() => setStep(2)}
            close={onCloseClick}
          />
        }

        {
          step == 2 &&
          <Timesheets
            next={() => setStep(3)}
            submit={() => shareInvoice({ is_timesheet_report_shared_with_customer: false })}
          />
        }

        {
          step == 3 &&
          <Select
            close={onCloseClick}
            submit={shareInvoice}
          />
        }
      </ModalBody>
    </Modal>
  )
}

Share.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any
}

export default Share
