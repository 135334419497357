import React, { useState, useEffect } from 'react'
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState, RichUtils } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Col, FormFeedback } from 'reactstrap';

function PolicyEditor({ validation, target = null }) {
  const [editorState, setEditorState] = useState()

  useEffect(() => {
    if (target?.policy) {
      const contentBlock = htmlToDraft(target.policy);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const _editorState = EditorState.createWithContent(contentState);
        setEditorState(_editorState)
      }
    }
    else
      setEditorState(EditorState.createEmpty())

  }, [target?.policy])

  function onEditorStateChange(_editorState) {
    setEditorState(_editorState)
  }

  useEffect(() => {
    if (editorState)
      validation.setFieldValue('policy', draftToHtml(convertToRaw(editorState.getCurrentContent())))
  }, [editorState])

  function handleReturn(e) {
    if (e.shiftKey || e.key == "Enter") {
      setEditorState(RichUtils.insertSoftNewline(editorState));
      return 'handled';
    }
    return 'not-handled';
  }

  return (
    <Col xs="12" className="mb-3">
      <Editor
        handleReturn={handleReturn}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
      />

      {validation.touched.policy && validation.errors.policy ? (
        <FormFeedback type="invalid">{validation.errors.policy}</FormFeedback>
      ) : null}
    </Col>
  )
}

export default PolicyEditor
