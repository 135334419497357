import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'

import { newTimesheetReportModal, addTimesheetReport } from 'store/timesheetReports/actions'

function index() {
  let dispatch = useDispatch()
  const { new: modal } = useSelector((state) => state.timesheetReports);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      from_date: '',
      to_date: '',
      users: [],
      projects: []
    },
    validationSchema: Yup.object({
      users: Yup.array().min(1, "Please Select at least one user").required("required"),
      from_date: Yup.string().required("Enter Start Date"),
      to_date: Yup.string().required("Enter To Date"),
    }),
    onSubmit: (values, actions) => {
      dispatch(addTimesheetReport({ ...values, users: values.users.map(_user => _user.id), projects: values.projects.map(_project => _project.id) }, actions))
    },
  });

  useEffect(() => {
    if (modal) {
      validation.resetForm()
    }
  }, [modal])

  return (
    <React.Fragment>
      <Modal isOpen={modal} toggle={() => dispatch(newTimesheetReportModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(newTimesheetReportModal())}>
          Add Timesheet Report
        </ModalHeader>
        <ModalBody>
          <Form
            validation={validation}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
