import moment from "moment";
import { filterParams } from "../apiHelper";
import request from "../restClient";
let longFormat = "YYYY-MM-DDThh:mm:ssZ"
let shortFormat = "YYYY-MM-DD"

export const getPayrolls = async (params) => {
  return await request({
    url: "payrolls/",
    method: "GET",
    params: filterParams(params),
    cancelPrevious: params.cancelPrevious || true,
  });
};

export const deletePayroll = async (id) => {
  return await request({
    url: `payrolls/${id}/`,
    method: "DELETE"
  });
};

export const payPayroll = async (id) => {
  return await request({
    url: `payrolls/${id}/pay`,
    method: "POST"
  });
};

export const editPayroll = async (id, data) => {
  let permittedParams = [
    'projects',
    'cheque_no',
    'payrate',
    'payment_method',
    'payment_period_start',
    'payment_period_end',
    'hours_worked',
    'overtime_hours',
    'timesheets'
  ]

  let sentizedData = {}
  Object.entries(data).forEach(keyValue => {
    if (permittedParams.includes(keyValue[0]))
      sentizedData[keyValue[0]] = keyValue[1]
  })

  let {
    payment_period_start, payment_period_end, projects, payrate, timesheets
  } = sentizedData

  const formD = {
    ...sentizedData,
    projects: projects?.map(_item => _item.id),
    payrate: payrate?.id || payrate,
    payment_period_start: moment(payment_period_start, payment_period_start.length == shortFormat.length ? shortFormat : longFormat).format(longFormat),
    payment_period_end: moment(payment_period_end, payment_period_end.length == shortFormat.length ? shortFormat : longFormat).format(longFormat),
    timesheets: timesheets?.map(_item => _item.id)
  }

  return await request({
    url: `payrolls/${id}/`,
    method: "PATCH",
    data: formD
  });
};

export const addPayroll = async (data) => {
  let permittedParams = [
    'projects',
    'payrate',
    'cheque_no',
    'payment_method',
    'payment_period_start',
    'payment_period_end',
    'hours_worked',
    'overtime_hours',
    'timesheets'
  ]

  let sentizedData = {}
  Object.entries(data).forEach(keyValue => {
    if (permittedParams.includes(keyValue[0]))
      sentizedData[keyValue[0]] = keyValue[1]
  })

  let {
    payment_period_start, payment_period_end, projects, payrate, timesheets
  } = sentizedData

  const formD = {
    ...sentizedData,
    projects: projects?.map(_item => _item.id),
    payrate: payrate?.id || payrate,
    payment_period_start: moment(payment_period_start, payment_period_start.length == shortFormat.length ? shortFormat : longFormat).format(longFormat),
    payment_period_end: moment(payment_period_end, payment_period_end.length == shortFormat.length ? shortFormat : longFormat).format(longFormat),
    timesheets: timesheets?.map(_item => _item.id)
  }

  return await request({
    url: `payrolls/`,
    method: "POST",
    data: formD
  });
};
