import {
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  GET_USERS,
  ADD_USER,
  EDIT_USER,
  DELETE_USER,
} from "./actionTypes";

const INIT_STATE = {
  userWithPagy: {
    total: 0,
    page: 1,
    page_size: 10,
    results: []
  },
  new: false,
  edit: false,
  delete: false,
  loading: false,
};

const users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        new: false,
        edit: false,
        delete: false,
        loading: action.payload.loading
      };

    case GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        userWithPagy: action.payload,
      };

    case ADD_USER:
      return {
        ...state,
        loading: true
      };

    case EDIT_USER:
      return {
        ...state,
        loading: true
      };

    case GET_USERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "NEW_USER_MODAL":
      return {
        ...state,
        new: !state.new
      };

    case "DELETE_USER_MODAL":
      return {
        ...state,
        delete: !state.delete
      };

    case "EDIT_USER_MODAL":
      return {
        ...state,
        edit: !state.edit
      };
    default:
      return state;
  }
};

export default users;
