import React from "react";
import {
  Col,
  Row,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap";


function index({ validation }) {
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <Row>
        <Col xs="12" className="mb-3">
          <Label className="form-label">Title</Label>
          <Input
            name="title"
            type="text"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.title || ""}
            invalid={
              validation.touched.title && validation.errors.title ? true : false
            }
          />
          {validation.touched.title && validation.errors.title ? (
            <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="text-end">
            <button
              type="submit"
              className="btn btn-success save-customer"
            >
              Save
            </button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default index
