import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import * as moment from "moment";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row
} from "reactstrap";
import DeleteIcon from 'assets/images/Delete.svg'
import EditIcon from 'assets/images/Edit.svg'

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../components/Common/DeleteModal";
import {
  getEstimates as onGetEstimates,
  deleteEstimate as onDeleteEstimate,
  newEstimateModal,
  deleteEstimateModal,
  generateEstimate as onGenerateEstimate,
  editEstimateModal,
  generateEstimateModal
} from "store/estimates/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setLoader, usFormat } from "utils/common";
import Create from './Create'
import Edit from './Edit'
import GenerateEstimate from './GenerateEstimate'

const Estimates = props => {
  const dispatch = useDispatch();

  const { estimateWithPagy, loading, deleteModal, generateModal, generatableEstimate } = useSelector(state => ({
    estimateWithPagy: state.estimates.estimateWithPagy,
    loading: state.estimates.loading,
    deleteModal: state.estimates.delete,
    generateModal: state.estimates.generate,
    generatableEstimate: state.estimates.generatableEstimate,
  }));
  let { results: estimates } = estimateWithPagy

  const [estimate, setEstimate] = useState(null);
  const [searchParams, setSearchParams] = useState({
    search: '',
  })

  //pagination customization
  const pageOptions = {
    sizePerPage: estimateWithPagy.page_size,
    totalSize: estimateWithPagy.total,
    page: estimateWithPagy.page,
    custom: true,
  };

  const COLS = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      text: "#",
      dataField: "index",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row, rowIndex) => <>{rowIndex + 1}</>,
    },
    {
      text: "Customer",
      dataField: "customer",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <b><i>{`${row.customer.first_name} ${row.customer.last_name}`}</i></b>
    },
    {
      dataField: "valid_until",
      text: "Valid Until",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.valid_until),
    },
    {
      dataField: "subtotal_amount",
      text: "Subtotal",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => usFormat(row.subtotal_amount),
    },
    {
      dataField: "discount_percent",
      text: "Discount",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => `${row.discount_percent}%`,
    },
    {
      dataField: "total_amount",
      text: "Total",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => usFormat(row.total_amount),
    },
    {
      dataField: "menu",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, estimate) => (
        <div className="d-flex align-items-center">
          <div onClick={() => handleEditEstimate(estimate)} className="me-1">
            <img
              src={EditIcon}
              height="20"
              width="20"
            />
          </div>

          <div onClick={() => onClickDelete(estimate)} className="me-1">
            <img
              src={DeleteIcon}
              height="20"
              width="20"
            />
          </div>

          {
            estimate.pdf_file &&
            <a href={estimate.pdf_file} rel="noopener noreferrer" target={"_blank"} className="me-1">
              <i className="fas fa-file-invoice-dollar text-secondary font-size-18" />
            </a>
          }
        </div>
      ),
    },
  ];


  var node = useRef();

  //delete estimate

  const onClickDelete = (estimate) => {
    setEstimate(estimate);
    dispatch(deleteEstimateModal())
  };

  const handleDeleteEstimate = () => {
    dispatch(onDeleteEstimate(estimate))
  }

  const handleGenerateEstimate = () => {
    dispatch(onGenerateEstimate(generatableEstimate))
  }

  const { SearchBar } = Search;

  function init() {
    dispatch(onGetEstimates(true));
  }

  useEffect(() => {
    init()
  }, []);

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page }) => {
    if (type == "pagination")
      dispatch(onGetEstimates(true, { page, ...searchParams }));
  };

  const handleCreateEstimate = () => {
    dispatch(newEstimateModal())
  };

  const handleEditEstimate = estimate => {
    setEstimate(estimate)
    dispatch(editEstimateModal())
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  /** set Date formate */
  const handleValidDate = date => {
    const date1 = date ? moment(new Date(date)).format("DD MMM Y") : "N/A";
    return date1;
  };

  let handleSearch = (key, value) => {
    let _searchParams = { ...searchParams, [key]: value }
    setSearchParams(_searchParams)
    dispatch(onGetEstimates(false, _searchParams));
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEstimate}
        onCloseClick={() => dispatch(deleteEstimateModal())}
      />

      <GenerateEstimate
        show={generateModal}
        estimate={generatableEstimate}
        onGenerateClick={handleGenerateEstimate}
        onCloseClick={() => dispatch(generateEstimateModal())}
      />

      <Create />
      <Edit estimate={estimate} />

      <div className="page-content">
        <MetaTags>
          <title>Estimates</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Estimates" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={COLS}
                    data={estimates}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <ToolkitProvider
                          keyField="id"
                          data={estimates || []}
                          columns={COLS}
                          bootstrap4
                          search
                        >
                          {toolkitProps => {
                            return (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col sm="4">
                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          searchText={searchParams.search}
                                          onSearch={text => handleSearch("search", text)} />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm="8">
                                    <div className="text-sm-end">
                                      <Button
                                        type="button"
                                        color="success"
                                        className="btn-rounded  mb-2 me-2"
                                        onClick={handleCreateEstimate}
                                      >
                                        <i className="mdi mdi-plus me-1" />
                                        New estimate
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        noDataIndication={"No Data found"}
                                        defaultSorted={defaultSorted}
                                        classes={"table align-middle table-nowrap"}
                                        keyField="id"
                                        remote={{ search: true, pagination: true }}
                                        {...toolkitProps.baseProps}
                                        onTableChange={handleTableChange}
                                        {...paginationTableProps}
                                        ref={node}
                                      />
                                    </div>

                                  </Col>
                                </Row>
                                <Row className="align-items-md-center mt-30">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )
                          }}
                        </ToolkitProvider>
                      )
                    }}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Estimates.propTypes = {
  estimates: PropTypes.array,
  onGetEstimates: PropTypes.func,
  onAddNewEstimate: PropTypes.func,
  onDeleteEstimate: PropTypes.func,
  onUpdateEstimate: PropTypes.func,
};

export default Estimates;
