import { filterParams } from "../apiHelper";
import request from "../restClient";

export const getUsers = async (params) => {
  return await request({
    url: "users/",
    method: "GET",
    params: filterParams(params),
    cancelPrevious: params.cancelPrevious || true,
  });
};

export const getEmployees = async (params) => {
  return await request({
    url: "tenant/employees/",
    method: "GET",
    params: filterParams(params),
    cancelPrevious: params.cancelPrevious || true,
  });
};

export const deleteUser = async (id) => {
  return await request({
    url: `users/${id}/`,
    method: "DELETE"
  });
};

export const addUser = async (_data) => {
  const form = new FormData()

  let { phone, email, type, ...data } = _data

  let { avatar, ..._formData } = data

  if (typeof (avatar) != "string" && avatar)
    form.append('avatar', avatar)

  form.append('email', email)
  form.append('phone', phone)
  form.append("profile", JSON.stringify(_formData))

  return await request({
    url: `users/`,
    method: "POST",
    headers: {
      'Content-type': 'multipart/form-data',
    },
    data: form
  });
};


export const editUser = async (id, _data) => {
  const form = new FormData()

  let { phone, email, type, is_active, ...data } = _data

  let { avatar, ..._formData } = data

  if (typeof (avatar) != "string" && avatar)
    form.append('avatar', avatar)

  if (phone)
    form.append('phone', phone)

  if (Object.keys(_formData).length > 0)
    form.append("profile", JSON.stringify(_formData))
  else
    form.append('is_active', is_active)

  return await request({
    url: `users/${id}/`,
    method: "PATCH",
    headers: {
      'Content-type': 'multipart/form-data',
    },
    data: form
  });
};
