import { updateSubscription } from 'api/v1';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getTenant } from 'store/actions';

function Seats({ seats, setSeats }) {
  let show = !!seats
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const [totalSeats, setTotalSeats] = useState(0)
  const { tenant } = useSelector(state => ({
    tenant: state.tenants.tenant
  }));

  useEffect(() => {
    if (show)
      setTotalSeats(Math.max(tenant.seats_availed, tenant.plan.free_allowed_seats))
  }, [tenant, show])

  let price = useMemo(() => {
    if (!tenant) return 0
    let plan = tenant.plan

    if (totalSeats <= plan.free_allowed_seats)
      return plan.price.unit_amount
    else
      return plan.price.unit_amount + (totalSeats - plan.free_allowed_seats) * plan.additional_seat_price.unit_amount

  }, [tenant, totalSeats])

  async function onSubmit() {
    if (!seats.upgrade) {
      setSeats({
        unit_amount: price,
        seats: totalSeats,
        currency: 'usd'
      })

      return
    }

    setLoading(true)
    try {
      await updateSubscription({ seats: totalSeats })
      toast.success("Your subscription has been updated")
      dispatch(getTenant())
      setSeats(null)
    } catch (error) {
      toast.error("Something went wrong updating your subscription")
    }
    setLoading(false)
  }

  return (
    <Modal isOpen={show} toggle={() => setSeats(null)} centered={true}>
      <ModalHeader tag="h4" toggle={() => setSeats(null)}>
        Confirm Mobile Users
      </ModalHeader>

      <ModalBody className="py-3">
        <div className='text-center mt-2 mb-2'>
          <div className='mb-4 mx-auto text-light bg-primary rounded-circle d-flex justify-content-center align-items-center' style={{ width: 130, height: 130 }}>
            <h1 className='text-light'>${price}</h1>
          </div>

          <div className="btn-group" role="group">
            <button onClick={() => {
              setTotalSeats(_totalSeats => _totalSeats - 1)
            }} type="button" disabled={totalSeats <= Math.max(tenant.seats_availed, tenant.plan.free_allowed_seats)} className="font-size-18 btn btn-primary">-</button>
            <button disabled type="button" className="font-size-18 btn btn-light px-4">{totalSeats}</button>
            <button onClick={() => {
              setTotalSeats(_totalSeats => _totalSeats + 1)
            }} type="button" className="font-size-18 btn btn-primary">+</button>
          </div>
        </div>
      </ModalBody>

      <ModalFooter>
        <button
          onClick={onSubmit}
          disabled={loading}
          className="btn btn-primary d-block"
          type="button"
        >
          Submit {loading && <i className="bx bx-hourglass bx-spin font-size-16 align-middle ms-2"></i>}
        </button>
      </ModalFooter>
    </Modal>
  )
}

export default Seats
