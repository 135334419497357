import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'

import { newUserModal, addUser } from 'store/users/actions'

function index() {
  let dispatch = useDispatch()
  const { new: modal } = useSelector((state) => state.users);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      first_name: '',
      last_name: '',
      phone: '',
      avatar: null
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required("Please enter email"),
      first_name: Yup.string().required("Please enter your first name"),
      last_name: Yup.string().required("Please enter your last name"),
      avatar: Yup.mixed().required("You need to provide a avatar")
    }),
    onSubmit: (values, actions) => {
      dispatch(addUser(values, actions))
    },
  });

  useEffect(() => {
    if (modal) {
      validation.resetForm()
    }
  }, [modal])

  return (
    <React.Fragment>
      <Modal isOpen={modal} toggle={() => dispatch(newUserModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(newUserModal())}>
          Add User
        </ModalHeader>
        <ModalBody>
          <Form
            validation={validation}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
