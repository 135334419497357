import React, { useEffect, useState, useRef, Fragment } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import * as moment from "moment";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap";
import { Menu, MenuItem } from '@szhsin/react-menu';

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../../components/Common/DeleteModal";
import {
  getPayrollReports as onGetPayrollReports,
  deletePayrollReport as onDeletePayrollReport,
  deletePayrollReportModal,
  generatePayrollReport as onGeneratePayrollReport,
  generatePayrollReportModal,
  setGeneratablePayrollReport,
  editPayrollReportModal,
  newPayrollReportModal
} from "store/payrollReports/actions";

import Create from './Create'
import Edit from './Edit'

//redux
import { useSelector, useDispatch } from "react-redux";
import { setLoader, usFormat } from "utils/common";
import GeneratePayrollReport from './GeneratePayrollReport'
import { useHistory } from "react-router-dom";

const PayrollReports = () => {
  const dispatch = useDispatch();

  const { payrollReportWithPagy, loading, deleteModal, generateModal, generatablePayrollReport } = useSelector(state => ({
    payrollReportWithPagy: state.payrollReports.payrollReportWithPagy,
    loading: state.payrollReports.loading,
    deleteModal: state.payrollReports.delete,
    generateModal: state.payrollReports.generate,
    generatablePayrollReport: state.payrollReports.generatablePayrollReport,
  }));
  let { results: payrollReports } = payrollReportWithPagy

  const [payrollReport, setPayrollReport] = useState(null);
  const [searchParams, setSearchParams] = useState({
    search: '',
    status: ''
  })

  //pagination customization
  const pageOptions = {
    sizePerPage: payrollReportWithPagy.page_size,
    totalSize: payrollReportWithPagy.total,
    page: payrollReportWithPagy.page,
    custom: true,
  };

  const COLS = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      text: "#",
      dataField: "index",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row, rowIndex) => <>{rowIndex + 1}</>,
    },
    {
      text: "Projects",
      dataField: "projects",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <b><i><p className="mb-1">{`${row.projects.length == 0 ? 'N/A' : `${row.projects.slice(0, 2).map(_project => _project.project_name).join(',')}${row.projects.length > 2 ? '...' : ""}`}`}</p></i></b>
    },
    {
      text: "Member",
      dataField: "user",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <b><i>{`${row.user.profile.first_name} ${row.user.profile.last_name}` || 'N/A'}</i></b>
    },
    {
      dataField: "from_date",
      text: "From Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.from_date) || 'N/A',
    },
    {
      dataField: "to_date",
      text: "To Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.to_date) || 'N/A',
    },
    {
      text: "Total Payment",
      dataField: "total_payment",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <i className="mb-1">{`${usFormat(row.total_payment)}`}</i>
        </>
      ),
    },
    {
      dataField: "print",
      text: "Print",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, payrollReport) => <Fragment>
        {
          payrollReport.pdf_file ?
            <a href={payrollReport.pdf_file} rel="noopener noreferrer" target={"_blank"} className="me-1">
              <i className="fas fa-print text-secondary font-size-18" />
            </a>
            :
            <i onClick={() => {
              dispatch(setGeneratablePayrollReport(payrollReport))
              dispatch(generatePayrollReportModal())
            }} className="fas fa-print text-secondary font-size-18 me-1" />
        }
      </Fragment>
    },
    {
      dataField: "menu",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, payrollReport) => (
        <Menu menuButton={<i className="mdi mdi-dots-horizontal font-size-18" />} transition>
          <MenuItem
            onClick={() => handleEditPayrollReport(payrollReport)}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => onClickDelete(payrollReport)}
          >
            Delete
          </MenuItem>
        </Menu>
      ),
    },
  ];


  var node = useRef();

  //delete payrollReport

  const onClickDelete = (payrollReport) => {
    setPayrollReport(payrollReport);
    dispatch(deletePayrollReportModal())
  };

  const handleDeletePayrollReport = () => {
    dispatch(onDeletePayrollReport(payrollReport))
  }

  const handleGeneratePayrollReport = () => {
    dispatch(onGeneratePayrollReport(generatablePayrollReport))
  }

  const { SearchBar } = Search;

  function init() {
    dispatch(onGetPayrollReports(true));
  }

  useEffect(() => {
    init()
  }, []);

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  const history = useHistory()

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page }) => {
    if (type == "pagination")
      dispatch(onGetPayrollReports(true, { page, ...searchParams }));
  };

  const handleCreatePayrollReport = () => {
    dispatch(newPayrollReportModal())
  };

  const handleEditPayrollReport = payrollReport => {
    setPayrollReport(payrollReport)
    dispatch(editPayrollReportModal())
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  /** set Date formate */
  const handleValidDate = date => {
    const date1 = date ? moment(new Date(date)).format("DD MMM Y") : "N/A";
    return date1;
  };

  let handleSearch = () => {
    dispatch(onGetPayrollReports(false, searchParams));
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeletePayrollReport}
        onCloseClick={() => dispatch(deletePayrollReportModal())}
      />

      <Create />
      <Edit payrollReport={payrollReport} />

      <GeneratePayrollReport
        show={generateModal}
        payrollReport={generatablePayrollReport}
        onGenerateClick={handleGeneratePayrollReport}
        onCloseClick={() => {
          dispatch(setGeneratablePayrollReport(null))
          dispatch(generatePayrollReportModal())
        }}
      />

      <div className={"page-content"}>
        <MetaTags>
          <title>Payroll Reports</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Payroll Reports" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  {
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={COLS}
                      data={payrollReports}
                    >
                      {({ paginationProps, paginationTableProps }) => {
                        return (
                          <ToolkitProvider
                            keyField="id"
                            data={payrollReports || []}
                            columns={COLS}
                            bootstrap4
                            search
                          >
                            {toolkitProps => {
                              return (
                                <React.Fragment>
                                  <Row className="mb-2">
                                    <Col className="d-flex" sm="6">
                                      <div className="search-box ms-2 mb-2 d-inline-block">
                                        <Label className="form-label">Search</Label>
                                        <div className="position-relative d-flex flex-row">
                                          <SearchBar
                                            searchText={searchParams.search}
                                            onSearch={text => setSearchParams({ ...searchParams, search: text })} />
                                          <i className="bx bx-search-alt search-icon" />

                                          <Button
                                            type="button"
                                            color="success"
                                            className="btn-rounded mx-2 ms-2 mb-2"
                                            onClick={handleSearch}
                                          >
                                            <i className="bx bx-search-alt me-1" />
                                            Search
                                          </Button>
                                        </div>
                                      </div>
                                    </Col>

                                    <Col sm="6">
                                      <div className="text-sm-end">
                                        <Button
                                          type="button"
                                          color="success"
                                          className="btn-rounded  mb-2 me-2"
                                          onClick={handleCreatePayrollReport}
                                        >
                                          <i className="mdi mdi-plus me-1" />
                                          New payroll report
                                        </Button>
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          noDataIndication={"No Data found"}
                                          defaultSorted={defaultSorted}
                                          classes={"table align-middle table-nowrap"}
                                          keyField="id"
                                          remote={{ search: true, pagination: true }}
                                          {...toolkitProps.baseProps}
                                          onTableChange={handleTableChange}
                                          {...paginationTableProps}
                                          ref={node}
                                        />
                                      </div>

                                    </Col>
                                  </Row>
                                  <Row className="align-items-md-center mt-30">
                                    <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )
                            }}
                          </ToolkitProvider>
                        )
                      }}
                    </PaginationProvider>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

PayrollReports.propTypes = {
  payrollReports: PropTypes.array,
  onGetPayrollReports: PropTypes.func,
  onAddNewPayrollReport: PropTypes.func,
  onDeletePayrollReport: PropTypes.func,
  onUpdatePayrollReport: PropTypes.func,
};

export default PayrollReports;
