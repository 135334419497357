import MetaTags from "react-meta-tags";
import React, { useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import { setTabBarLoader } from "utils/common";
import { editTenant, getTenant } from "store/actions";
import PolicyEditor from "components/Common/PolicyEditor";


const Company = () => {
  const { tenant: company, loading } = useSelector(state => ({
    loading: state.tenants.loading,
    tenant: state.tenants.tenant
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTenant());
  }, [])

  useEffect(() => {
    setTabBarLoader(loading)
  }, [loading]) //loading

  const form = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      policy: (company && company.policy) || '',
    },
    validationSchema: Yup.object({
      policy: Yup.string().required("Please Enter Policy Details"),
    }),
    onSubmit: (values, actions) => {
      dispatch(editTenant(values, actions))
    }
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Policy | AllPromana</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb back={true} title="Settings" breadcrumbItem="Policy" />

          <Row>
            <Col lg="12">
              <Card>
                <h5 className="mt-3 px-4">Policy Details</h5>
                <CardBody>
                  <Form onSubmit={e => {
                    e.preventDefault();
                    form.handleSubmit();
                    return false;
                  }}>
                    <Row form>
                      <PolicyEditor
                        validation={form}
                        target={company}
                      />

                      <div className="text-end mt-2">
                        <button
                          type="submit"
                          disabled={loading}
                          className="btn btn-success save-customer"
                        >
                          {loading ? "Saving" : "Save"} {" "}
                          {loading && <i className="bx bx-hourglass bx-spin font-size-16 align-middle"></i>}
                        </button>
                      </div>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Company);
