import { getProjects, getProjectTimesheets, getEmployees } from "api/v1";
import React from "react";
import {
  Col,
  Row,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap";
import AsyncSelect from 'react-select/async';
import { v4 as uuid } from 'uuid';

function index({ validation, createable = false }) {
  let [projectKey, setProjectKey] = React.useState(uuid())
  let [sheetsKey, setSheetKey] = React.useState(uuid())

  let loadProjects = (search) => {
    return getProjects({
      search,
      no_paginate: 1,
      user_id: validation.values.member?.id
    })
      .then((results = []) => {
        return results
      })
      .catch(({ message }) => {
        if (message !== 'request-cancel') {
          toast.error(message);
        }
      })
  }

  let loadMembers = (search) => {
    return getEmployees({ search, is_active: true, no_paginate: 1 })
      .then((results = []) => {
        return results
      })
      .catch(({ message }) => {
        if (message !== 'request-cancel') {
          toast.error(message);
        }
      })
  }

  React.useEffect(() => {
    if (validation.values.projects.length > 0) {
      let total_hours = 0
      validation.values.timesheets.forEach(sheet => total_hours += parseFloat(sheet.total_hours))
      validation.setFieldValue('hours_worked', total_hours)
    }
  }, [validation.values.timesheets])

  let loadSheets = () => {
    let params = {
      no_paginate: 1,
      not_paid: createable ? true : null,
      project_ids: validation.values.projects,
      from_date: validation.values.payment_period_start,
      to_date: validation.values.payment_period_end,
      user_id: validation.values.member?.id
    }

    return getProjectTimesheets(params)
      .then((results = []) => {
        return results
      })
      .catch(({ message }) => {
        if (message !== 'request-cancel') {
          toast.error(message);
        }
      })
  }

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <Row>
        <Col xs="12" md={validation.values.new_payrate ? '6' : '12'} className="mb-3">
          <Label className="form-label">Member</Label>

          <AsyncSelect
            defaultOptions
            isClearable
            placeholder="Select Member"
            required
            isDisabled={!createable}
            loadOptions={loadMembers}
            onChange={(_member) => {
              setProjectKey(uuid())
              validation.setFieldValue('member', _member)

              validation.setFieldValue('projects', [])
              validation.setFieldValue('hours_worked', '')
              validation.setFieldValue('timesheets', [])

              if (_member)
                if (_member.payrate) {
                  validation.setFieldValue('payrate', _member.payrate)
                  validation.setFieldValue('new_payrate', false)
                }
                else {
                  validation.setFieldValue('payrate', {
                    user: _member.id
                  })
                  validation.setFieldValue('new_payrate', true)
                }
              else
                validation.setFieldValue('new_payrate', false)

            }}
            value={validation.values.member}
            getOptionValue={({ id }) => id}
            getOptionLabel={({ profile: { first_name, last_name }, type }) => `${first_name} ${last_name} (${type})`}
          />
          {validation.values.projects.length > 0 && !validation.values.member ? (
            <span className="text-danger font-size-11">{validation.errors.member}</span>
          ) : null}
        </Col>

        {
          validation.values.new_payrate &&
          <Col xs="12" md="6" className="mb-3">
            <Label className="form-label">Pay per hour</Label>
            <Input
              name="rate"
              type="number"
              onChange={e => {
                validation.setFieldValue('payrate', { ...validation.values.payrate, [e.target.name]: e.target.value })
              }}
              onBlur={validation.handleBlur}
              value={validation.values.payrate?.rate || ""}
              invalid={
                validation.touched.payrate && validation.errors.rate ? true : false
              }
            />
            {validation.touched.payrate && validation.errors.rate ? (
              <FormFeedback type="invalid">{validation.errors.rate}</FormFeedback>
            ) : null}
          </Col>
        }

        {
          validation.values.member && (validation.values.projects.length > 0 || createable) &&
          <Col xs="12" className="mb-3">
            <Label className="form-label">Project</Label>
            <AsyncSelect
              key={projectKey}
              defaultOptions
              isClearable
              placeholder="Select Project"
              required
              isMulti={true}
              isDisabled={!createable}
              loadOptions={loadProjects}
              value={validation.values.projects}
              getOptionLabel={({ project_name }) => project_name}
              getOptionValue={({ id }) => id}
              onChange={(_project) => {
                setSheetKey(uuid())
                validation.setFieldValue('projects', _project)

                validation.setFieldValue('hours_worked', '')
                validation.setFieldValue('timesheets', [])
              }}

            />
            {validation.touched.projects && validation.errors.projects ? (
              <span className="text-danger font-size-11">{validation.errors.projects}</span>
            ) : null}
          </Col>
        }

        <Col xs="12" md="6" className="mb-3">
          <Label className="form-label">Payment Method</Label>
          <Input
            name="payment_method"
            type="select"
            className="form-select"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.payment_method || ""}
            invalid={
              validation.touched.payment_method && validation.errors.payment_method ? true : false
            }
          >
            <option value=""></option>
            <option value="Cash">Cash</option>
            <option value="Credit card">Credit Card</option>
            <option value="Debit card">Debit Card</option>
            <option value="Cheque">Cheque</option>
            <option value="Bank Transfer">Bank Transfer</option>
          </Input>
          {validation.touched.payment_method && validation.errors.payment_method ? (
            <FormFeedback type="invalid">{validation.errors.payment_method}</FormFeedback>
          ) : null}
        </Col>

        <Col xs="12" md="6" className="mb-3">
          <Label className="form-label">Overtime Hours</Label>
          <Input
            name="overtime_hours"
            type="number"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.overtime_hours || ""}
            invalid={
              validation.touched.overtime_hours && validation.errors.overtime_hours ? true : false
            }
          />
          {validation.touched.overtime_hours && validation.errors.overtime_hours ? (
            <FormFeedback type="invalid">{validation.errors.overtime_hours}</FormFeedback>
          ) : null}
        </Col>

        <Col xs="12" md="6" className="mb-3">
          <Label className="form-label">Cheque No. (Optional)</Label>
          <Input
            name="cheque_no"
            type="text"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.cheque_no || ""}
            invalid={
              validation.touched.cheque_no && validation.errors.cheque_no ? true : false
            }
          />
          {validation.touched.cheque_no && validation.errors.cheque_no ? (
            <FormFeedback type="invalid">{validation.errors.cheque_no}</FormFeedback>
          ) : null}
        </Col>


        <Col xs="12" md="6" className="mb-3">
          <Label className="form-label">Start Period</Label>
          <Input
            name="payment_period_start"
            type="date"
            onChange={e => {
              validation.setFieldValue("payment_period_start", e.target.value)
              setSheetKey(uuid())
            }}
            onBlur={validation.handleBlur}
            value={validation.values.payment_period_start || ""}
            invalid={
              validation.touched.payment_period_start && validation.errors.payment_period_start ? true : false
            }
          />
          {validation.touched.payment_period_start && validation.errors.payment_period_start ? (
            <FormFeedback type="invalid">{validation.errors.payment_period_start}</FormFeedback>
          ) : null}
        </Col>

        <Col xs="12" md="6" className="mb-3">
          <Label className="form-label">End Period</Label>
          <Input
            name="payment_period_end"
            type="date"
            onChange={e => {
              validation.setFieldValue("payment_period_end", e.target.value)
              setSheetKey(uuid())
            }}
            onBlur={validation.handleBlur}
            value={validation.values.payment_period_end || ""}
            invalid={
              validation.touched.payment_period_end && validation.errors.payment_period_end ? true : false
            }
          />
          {validation.touched.payment_period_end && validation.errors.payment_period_end ? (
            <FormFeedback type="invalid">{validation.errors.payment_period_end}</FormFeedback>
          ) : null}
        </Col>

        <Col xs="12" className="mb-3">
          <Label className="form-label">Working Hours</Label>
          <Input
            name="hours_worked"
            type="number"
            disabled={validation.values.projects.length > 0}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.hours_worked || ""}
            invalid={
              validation.touched.hours_worked && validation.errors.hours_worked ? true : false
            }
          />
          {validation.touched.hours_worked && validation.errors.hours_worked ? (
            <FormFeedback type="invalid">{validation.errors.hours_worked}</FormFeedback>
          ) : null}
        </Col>

        {
          validation.values.projects.length > 0 && validation.values.member &&
          <Col xs="12" className="mb-3">
            <Label className="form-label">Timesheets</Label>
            <AsyncSelect
              key={sheetsKey}
              defaultOptions
              placeholder="Select Timesheets"
              required
              isDisabled={!createable}
              isMulti={true}
              isSearchable={false}
              value={validation.values.timesheets}
              getOptionLabel={({ total_hours, project: { project_name } }) => `${total_hours} hr - ${project_name || 'n/a'}`}
              getOptionValue={({ id }) => id}
              loadOptions={loadSheets}
              onChange={(_timesheets) => {
                validation.setFieldValue('timesheets', _timesheets)
              }}

            />
            {validation.touched.timesheets && validation.errors.timesheets ? (
              <span className="text-danger font-size-11">{validation.errors.timesheets}</span>
            ) : null}
          </Col>
        }
      </Row>
      <Row>
        <Col>
          {validation.values.payrate?.rate && <i className="text-muted">{validation.values.member?.first_name} {validation.values.member?.last_name} will be paid @{validation.values.payrate?.rate}/hr</i>}
          <div className="text-end">
            <button
              type="submit"
              className="btn btn-success save-customer"
            >
              Save
            </button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default index
