import request from "../restClient";

export const getProfile = async () => {
  return await request({
    url: "users/profile/",
    method: "GET"
  });
};

export const editProfile = async (_data) => {
  const form = new FormData()

  let { phone, type, ...data } = _data

  let { avatar, ..._formData } = data

  if (typeof (avatar) != "string" && avatar)
    form.append('avatar', avatar)

  form.append('phone', phone)
  form.append("profile", JSON.stringify(_formData))

  return await request({
    url: `users/profile/`,
    method: "PATCH",
    headers: {
      'Content-type': 'multipart/form-data',
    },
    data: form
  });
};

export const updatePassword = async (data) => {
  const formD = new FormData()
  Object.entries(data).forEach((keyValue) => {
    formD.append(keyValue[0], keyValue[1])
  })

  return await request({
    url: `users/password_update/`,
    method: "POST",
    headers: {
      'Content-type': 'multipart/form-data',
    },
    data: formD
  });
};
