import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'

import { editEstimateModal, editEstimate } from 'store/estimates/actions'
import moment from "moment";

function index({ estimate: target }) {
  let dispatch = useDispatch()
  const { edit: modal } = useSelector((state) => state.estimates);
  let [estimate, setEstimate] = useState()

  useEffect(() => {
    if (modal)
      setEstimate(target)
    else
      setTimeout(() => setEstimate(null), 100)

  }, [modal])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      customer: (estimate && estimate.customer) || null,
      project: (estimate && estimate.project) || null,
      valid_until: (estimate && estimate.valid_until) || '',
      discount_percent: (estimate && estimate.discount_percent) || 0,
      details: (estimate && estimate.details) || '',
      _deleted_estimated_costs: [],
      estimated_costs: (estimate && estimate.estimated_costs.map(_item => ({
        ..._item,
        quantity: _item.quantity || _item.square_ft
      }))) || []
    },
    validationSchema: Yup.object().shape({
      customer: Yup.mixed().required("Please Select Customer"),
      project: Yup.mixed().required("Please Select Project"),
      details: Yup.string().required("Please Enter Description"),
      valid_until: Yup.string().required("Please Enter Due Date"),

      estimated_costs: Yup.array().of(
        Yup.object().shape({
          unit_cost: Yup.string().required("unit cost required"),
          quantity: Yup.string().required("quantity required"),
          unit: Yup.string().required("unit required"),
          item_name: Yup.string().required("item name required"),
        })
      ).min(1, "Please add at least one item").required("required")
    }),
    onSubmit: (values, actions) => {
      let {
        customer,
        project,
        estimated_costs,
        _deleted_estimated_costs,
        ...payload
      } = values

      dispatch(editEstimate(estimate.id, {
        customer: customer.id,
        project: project.id,
        estimated_costs: [...estimated_costs, ..._deleted_estimated_costs].map(_item => {
          let { quantity, square_ft, estimate, ..._rest } = _item
          return ({ ..._rest, [_item.unit == "Quantity" ? "quantity" : "square_ft"]: quantity })
        }),
        ...payload
      }, actions))
    },
  });

  return (
    <React.Fragment>
      <Modal size="xl" isOpen={modal} toggle={() => dispatch(editEstimateModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(editEstimateModal())}>
          Edit Estimate
        </ModalHeader>
        <ModalBody>
          <Form
            target={target}
            validation={validation}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
