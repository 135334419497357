import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';
import {
  GET_CATEGORIES,
  DELETE_CATEGORY,
  ADD_CATEGORY,
  EDIT_CATEGORY
} from "./actionTypes";

import {
  getCategoriesSuccess,
  getCategories,
  getCategoriesFail,
  deleteCategoryModal
} from "./actions";

import {
  getCategories as getCategoriesAPI,
  deleteCategory as deleteCategoryAPI,
  addCategory as addCategoryAPI,
  editCategory as editCategoryAPI,
} from "api/v1";


function* fetchCategories({ payload: { params } }) {
  try {
    const response = yield call(getCategoriesAPI, params);
    yield put(getCategoriesSuccess(response));
  } catch (error) {
    yield put(getCategoriesFail("Something went wrong!"))
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* addCategory({ payload: { category, actions } }) {
  try {
    yield call(addCategoryAPI, category)
    toast.success("Category Created!");
    yield put(getCategories(true));
    actions.resetForm()

  } catch ({ errors }) {
    yield put(getCategoriesFail("Creation failed!"))
    if (errors) {
      actions.setErrors(errors)

    }
    toast.error("Something went wrong!");
  }
}

function* editCategory({ payload: { id, category, actions } }) {
  try {
    yield call(editCategoryAPI, id, category)
    toast.success("Category Updated!");
    yield put(getCategories(true));

  } catch ({ errors }) {
    yield put(getCategoriesFail("Updation failed!"))
    if (errors) {
      actions.setErrors(errors)

    }
    toast.error("Something went wrong!");
  }
}

function* deleteCategory({ payload: { category } }) {
  try {
    yield call(deleteCategoryAPI, category.id);
    put(deleteCategoryModal())
    yield put(getCategories(true));
    toast.warn("Category Deleted!");
  } catch (error) {
    toast.error("Something went wrong!");
  }
}

function* categorySaga() {
  yield takeEvery(GET_CATEGORIES, fetchCategories);
  yield takeEvery(DELETE_CATEGORY, deleteCategory);
  yield takeEvery(ADD_CATEGORY, addCategory);
  yield takeEvery(EDIT_CATEGORY, editCategory);
}

export default categorySaga;
