import MetaTags from "react-meta-tags";
import React, { useEffect, Fragment, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import { setTabBarLoader } from "utils/common";
import { getTenant } from "store/actions";
import { toast } from "react-toastify";
import { generateQucikbookAuth, quickbookCallback } from "api/v1";

const fullUrl = window.location.hostname.split('.')
const isSubdomainPresent = fullUrl.length > 1 && fullUrl[0] != 'app'

const Company = () => {
  let redirect_uri = `${process.env.REACT_APP_PROTOCOL}://app.${process.env.REACT_APP_HOST}/quickbooks/callback`
  const queryParams = new URLSearchParams(location.search);

  const [loading, setLoading] = useState(false)
  const { tenant: company, loading: companyLoading } = useSelector(state => ({
    loading: state.tenants.loading,
    tenant: state.tenants.tenant
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    if (queryParams.size == 3)
      _callback()
    else
      dispatch(getTenant());
  }, [])

  useEffect(() => {
    setTabBarLoader(loading || companyLoading)
  }, [loading || companyLoading]) //loading

  async function _generateQucikbookAuth() {
    setLoading(true)
    try {
      let { authorization_url } = await generateQucikbookAuth({ redirect_uri, is_subdomain: isSubdomainPresent })
      if (authorization_url)
        window.location.href = authorization_url
      else {
        setLoading(false)
        toast.error("Something went wrong redirecting to quickbooks auth")
      }

    } catch (error) {
      toast.error("Something went wrong!")
      setLoading(false)
    }
  }

  async function _callback() {
    setLoading(true)
    try {
      await quickbookCallback({
        redirect_uri,
        code: queryParams.get("code"),
        realm_id: queryParams.get("realmId"),
        state: queryParams.get("state")
      })

      toast.success("Account is successfully linked")
    } catch (error) {
      toast.error("Something went wrong connecting quickbook account!")
    }
    setLoading(false)
    dispatch(getTenant());
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Quickbooks | AllPromana</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Settings" breadcrumbItem="Quickbooks" />

          <Row>
            <Col lg="12">
              <Card>
                <h5 className="mt-3 px-4">Connected Account</h5>
                <CardBody>
                  <div className="d-flex align-items-center mt-1 justify-content-between">
                    <div className="text-muted">
                      <img src="/assets/icons/qb.png" height="24" width="24" />
                      <span className="mx-2">{company?.linked_quickbooks_email || 'Quickbooks'}</span>
                    </div>

                    <Fragment>
                      {
                        !company?.linked_quickbooks_email &&
                        <button
                          type="button"
                          onClick={_generateQucikbookAuth}
                          disabled={companyLoading || loading}
                          className="btn btn-primary text-white"
                        >
                          {loading ? "Linking..." : "Link"} {" "}
                          {loading && <i className="bx bx-hourglass bx-spin font-size-16 align-middle"></i>}
                        </button>
                      }
                    </Fragment>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Company);
