import request from "../restClient";

export const getTenant = async () => {
  return await request({
    url: "tenant/",
    method: "GET"
  });
};

export const editTenant = async (data) => {
  const formD = new FormData()
  Object.entries(data).forEach((keyValue) => {
    if (keyValue[0] == "avatar") {
      if (typeof (keyValue[1]) != "string" && keyValue[1])
        formD.append(keyValue[0], keyValue[1])
    }
    else
      formD.append(keyValue[0], keyValue[1])
  })

  return await request({
    url: `tenant/`,
    method: "PATCH",
    headers: {
      'Content-type': 'multipart/form-data',
    },
    data: formD
  });
};

export const generateQucikbookAuth = async (data) => {
  return await request({
    url: "tenant/quickbooks/generate_authorization_link/",
    method: "POST",
    data
  });
};

export const quickbookCallback = async (data) => {
  return await request({
    url: "tenant/quickbooks/callback/",
    method: "POST",
    data
  });
};
