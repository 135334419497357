import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect } from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";
import { pick } from 'lodash';
//redux
import { useSelector, useDispatch } from "react-redux";
import { setSubdomain } from "store/actions";
import { setLoader } from "utils/common";

import { withRouter, Link } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import profile from "../../assets/images/profile-img.png";
import Preloader from "components/Common/Preloader";
import logo from "../../assets/images/logo.svg";

import { requestPasswordChange } from "store/actions";
const fullUrl = window.location.hostname.split('.')
const [_subdomain, _undefined] = window.location.hostname.split('.')
const isSubdomainPresent = fullUrl.length > 1 && _subdomain != 'app'

const ForgetPasswordPage = props => {
  const { subdomain } = useSelector((state) => state.Login);
  const { passError, loading, email } = useSelector((state) => state.Login);

  const dispatch = useDispatch();

  const _setSubdomain = (_subdomain) => {
    dispatch(setSubdomain(_subdomain))
  }

  useEffect(() => {
    if (isSubdomainPresent)
      _setSubdomain(fullUrl[0])
  }, [])

  const commonSchema = Yup.object({
    email: Yup.string().required("Please Enter Your Email"),
  })
  const commonValues = {
    email: email || ''
  }

  const form = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: isSubdomainPresent ? commonValues : { ...commonValues, subdomain: subdomain || '' },
    validationSchema: isSubdomainPresent ? commonSchema : commonSchema.concat(Yup.object({ subdomain: Yup.string().required("Please Enter Your Subdomain") })),
    onSubmit: (values, actions) => {
      let { subdomain: _subdomain } = values

      dispatch(requestPasswordChange(pick(
        { ...values, subdomain: _subdomain || subdomain },
        ['email', 'subdomain']
      ), actions, props.history));
    }
  });

  useEffect(() => {
    setLoader(loading);
  }, [loading]);


  return (
    <React.Fragment>
      <Preloader />

      <MetaTags>
        <title>
          Forget Password | Contractor
        </title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-softbg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Skote.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {passError ? <Alert color="danger">{passError}</Alert> : null}

                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        form.handleSubmit();
                        return false;
                      }}
                    >
                      {!subdomain && <div className="mb-3">
                        <Label className="form-label">Company name:</Label>
                        <Input
                          name="subdomain"
                          type="text"
                          placeholder="Enter your Company name"
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                          value={form.values.subdomain || ""}
                          invalid={
                            form.touched.subdomain && form.errors.subdomain ? true : false
                          }
                        />
                        {form.touched.subdomain && form.errors.subdomain ? (
                          <FormFeedback type="invalid">{form.errors.subdomain}</FormFeedback>
                        ) : null}
                      </div>}

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                          value={form.values.email || ""}
                          invalid={
                            form.touched.email && form.errors.email ? true : false
                          }
                        />
                        {form.touched.email && form.errors.email ? (
                          <FormFeedback type="invalid">{form.errors.email}</FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className="btn btn-primary w-md "
                            type="submit"
                          >
                            Reset
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} by allpromana
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
