import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Input,
  Row,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";

import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { v4 as uuid } from 'uuid';

import DeleteModal from "../../components/Common/DeleteModal";
import DeleteIcon from 'assets/images/Delete.svg'
import EditIcon from 'assets/images/Edit.svg'

import {
  getTasks as onGetTasks,
  deleteTask as onDeleteTask,
  deleteTaskModal,
  editTaskModal,
  editTask
} from "store/tasks/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import Edit from './Edit'
import { Link } from "react-router-dom";
import { map } from "lodash";
import Loader from "common/Loader";
import moment from "moment";

let range = (type) => {
  let _format = "YYYY-MM-DDThh:mm"
  let _startDate, _endDate

  if (type == "Week") {
    _endDate = moment().format(_format)
    _startDate = moment().subtract(7, 'd').format(_format)
  } else if (type == "Month") {
    _endDate = moment().format(_format)
    _startDate = moment().subtract(1, 'month').format(_format)
  }
  else {
    _endDate = moment().format(_format)
    _startDate = moment().subtract(1, 'year').format(_format)
  }

  return { start_date: _startDate, end_date: _endDate }
}

const EcommerceTasks = props => {
  const dispatch = useDispatch();

  const {
    match: { params },
  } = props;

  const { taskWithPagy, loading, deleteModal } = useSelector(state => ({
    taskWithPagy: state.tasks.taskWithPagy,
    loading: state.tasks.loading,
    deleteModal: state.tasks.delete,
  }));
  let { results: tasks } = taskWithPagy

  const [task, setTask] = useState(null);
  const [searchParams, setSearchParams] = useState({
    type: 'Week',
    ...range("Week")
  })

  //pagination customization
  const pageOptions = {
    sizePerPage: taskWithPagy.page_size,
    totalSize: taskWithPagy.total,
    page: taskWithPagy.page,
    custom: true,
  };

  const COLS = [
    {
      text: "id",
      dataField: "id",
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      text: "No",
      dataField: "index",
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row, rowIndex) => <>{rowIndex + 1}</>,
    },
    {
      dataField: "task",
      text: "Name",
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{`${row.task}`}</p>
        </>
      ),
    },
    {
      dataField: 'custom',
      text: "Members",
      sort: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        return (
          <div className="avatar-group">
            {map(row.users, (_member, index) => {
              let { profile: member } = _member
              let _uuid = uuid()
              return (
                !member.avatar || member.avatar !== "Null" && (
                  <div
                    className="avatar-group-item"
                    key={_uuid}
                  >
                    <Link
                      to="#"
                      className="team-member d-inline-block"
                      id={`tooltip-${_uuid}`}
                    >
                      <img
                        src={member.avatar}
                        className="rounded-circle avatar-xs"
                        alt=""
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target={`tooltip-${_uuid}`}
                      >
                        {`${member.first_name} (${_member.type})`}
                      </UncontrolledTooltip>
                    </Link>
                  </div>
                )
              )
            })}
          </div>
        )
      },
    },
    {
      dataField: "estimated_hours",
      text: "Estimated Time",
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{`${row.estimated_hours || 0} Hours`}</p>
        </>
      ),
    },
    {
      dataField: "total_hours",
      text: "Working Time",
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{`${row.total_hours} Hours`}</p>
        </>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cellContent, row) => (
        <div style={{ width: '80%' }} className="mb-1">
          <Input
            name="status"
            type="select"
            className="form-select"
            onChange={event => {
              dispatch(editTask(params.id, row.id, { status: event.target.value, users: row.users || [] }, null, searchParams))
            }}
            value={row.status || ""}>
            <option value="Open">Processing</option>
            <option value="Open">Open</option>
            <option value="Waiting">Waiting</option>
            <option value="Close">Close</option>
          </Input>
        </div>
      ),
    },
    {
      dataField: "menu",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, task) => (
        <div className="d-flex align-items-center">
          <div onClick={() => handleEditTask(task)} className="me-2 mb-1">
            <img
              src={EditIcon}
              height="20"
              width="20"
            />
          </div>

          <div onClick={() => props.history.push(`${params.id}/tasks/${task.id}/timesheets`)} className="me-1">
            <i className="far fa-clock font-size-18 text-info" />
          </div>

          <div onClick={() => onClickDelete(task)} className="me-2 mb-1">
            <img
              src={DeleteIcon}
              height="20"
              width="20"
            />
          </div>
        </div>
      ),
    },
  ];


  var node = useRef();

  //delete task

  const onClickDelete = (task) => {
    setTask(task);
    dispatch(deleteTaskModal())
  };

  const handleDeleteTask = () => {
    dispatch(onDeleteTask(params.id, task, searchParams))
  }

  function init() {
    dispatch(onGetTasks(params.id, true, {
      start_date: searchParams.start_date,
      end_date: searchParams.end_date
    }));
  }

  useEffect(() => {
    init()
  }, []);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page }) => {
    if (type == "pagination")
      dispatch(onGetTasks(params.id, true, { page, ...searchParams }));
  };

  const handleEditTask = task => {
    setTask(task)
    dispatch(editTaskModal())
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  let handleFilterChange = (type) => {
    let _searchParams = range(type)
    setSearchParams({ type, ..._searchParams })
    dispatch(onGetTasks(params.id, true, _searchParams));
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteTask}
        onCloseClick={() => dispatch(deleteTaskModal())}
      />

      <Edit projectId={params.id} task={task} searchParams={searchParams} />

      <Row>
        <Col xs="12">
          <Card style={{ borderWidth: 1, borderColor: '#F1F1F1' }}>
            <CardHeader>
              <Row>
                <Col>
                  <CardTitle className="mb-2">Latest Task List</CardTitle>
                </Col>

                <Col>
                  <div style={{ cursor: 'pointer' }} className="text-sm-end">
                    <UncontrolledDropdown direction="down">
                      <DropdownToggle className="card-drop" tag="p">
                        Last {searchParams.type} <i className="mdi mdi-chevron-down font-size-18" />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        {['Week', 'Month', 'Year'].map(_item => (
                          <DropdownItem
                            key={_item}
                            onClick={() => handleFilterChange(_item)}
                          >
                            {_item}
                          </DropdownItem>

                        ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </Col>
              </Row>
            </CardHeader>

            <CardBody style={{ padding: 0 }}>
              {
                loading ?
                  <Loader message={"Please wait"} />
                  :
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={COLS}
                    data={tasks}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <ToolkitProvider
                          keyField="id"
                          data={tasks || []}
                          columns={COLS}
                          bootstrap4
                          search
                        >
                          {toolkitProps => {
                            return (
                              <React.Fragment>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        noDataIndication={"No Data found"}
                                        defaultSorted={defaultSorted}
                                        classes={"table align-middle table-nowrap"}
                                        keyField="id"
                                        remote={{ search: true, pagination: true }}
                                        {...toolkitProps.baseProps}
                                        onTableChange={handleTableChange}
                                        {...paginationTableProps}
                                        ref={node}
                                      />
                                    </div>

                                  </Col>
                                </Row>
                                <Row className="align-items-md-center mt-30 px-2">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )
                          }}
                        </ToolkitProvider>
                      )
                    }}
                  </PaginationProvider>
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

EcommerceTasks.propTypes = {
  tasks: PropTypes.array,
  onGetTasks: PropTypes.func,
  onAddNewTask: PropTypes.func,
  onDeleteTask: PropTypes.func,
  onUpdateTask: PropTypes.func,
};

export default EcommerceTasks;
