import {
  GET_PURCHASES,
  GET_PURCHASES_SUCCESS,
  GET_PURCHASES_FAIL,
  DELETE_PURCHASE,
  ADD_PURCHASE,
  EDIT_PURCHASE
} from "./actionTypes"

export const getPurchases = (loading = false, params = {}) => ({
  type: GET_PURCHASES,
  payload: { loading, params }
})

export const getPurchasesSuccess = purchases => ({
  type: GET_PURCHASES_SUCCESS,
  payload: purchases,
})

export const getPurchasesFail = error => ({
  type: GET_PURCHASES_FAIL,
  payload: error,
})

export const deletePurchase = purchase => ({
  type: DELETE_PURCHASE,
  payload: { purchase }
})

export const addPurchase = (purchase, actions) => {
  return {
    type: ADD_PURCHASE,
    payload: { purchase, actions },
  }
}

export const editPurchase = (id, purchase, actions) => {
  return {
    type: EDIT_PURCHASE,
    payload: { id, purchase, actions },
  }
}

export const newPurchaseModal = () => ({
  type: 'NEW_PURCHASE_MODAL'
})

export const deletePurchaseModal = () => ({
  type: 'DELETE_PURCHASE_MODAL'
})

export const editPurchaseModal = () => ({
  type: 'EDIT_PURCHASE_MODAL'
})
