import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"
import 'react-toastify/dist/ReactToastify.css';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { ToastContainer } from 'react-toastify';

import store from "./store"

const app = (
  <Provider store={store}>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      style={{
        zIndex: 1999,
      }}
    />

    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
