import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import * as moment from "moment";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Input,
} from "reactstrap";
import { Menu, MenuItem } from '@szhsin/react-menu';

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../components/Common/DeleteModal";
import {
  getTimeoffs as onGetTimeoffs,
  deleteTimeoff as onDeleteTimeoff,
  newTimeoffModal,
  deleteTimeoffModal,
  editTimeoffModal,
  editTimeoff
} from "store/timeoffs/actions";

/** set Date formate */
const handleValidDate = date => {
  const date1 = date ? moment(new Date(date)).format("DD MMM Y") : "N/A";
  return date1;
};

//redux
import { useSelector, useDispatch } from "react-redux";
import { setLoader } from "utils/common";
import Create from './Create'
import Edit from './Edit'

const Timeoffs = props => {
  const dispatch = useDispatch();

  const { timeoffWithPagy, loading, deleteModal } = useSelector(state => ({
    timeoffWithPagy: state.timeoffs.timeoffWithPagy,
    loading: state.timeoffs.loading,
    deleteModal: state.timeoffs.delete,
  }));
  let { results: timeoffs } = timeoffWithPagy

  const [timeoff, setTimeoff] = useState(null);
  const [searchParams, setSearchParams] = useState({
    search: '',
  })

  //pagination customization
  const pageOptions = {
    sizePerPage: timeoffWithPagy.page_size,
    totalSize: timeoffWithPagy.total,
    page: timeoffWithPagy.page,
    custom: true,
  };

  const COLS = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      text: "#",
      dataField: "index",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row, rowIndex) => <>{rowIndex + 1}</>,
    },
    {
      text: "Member",
      dataField: "user",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{row.user.profile.first_name} {row.user.profile.last_name} ({row.user.type})</p>
        </>
      ),
    },
    {
      dataField: "from_date",
      text: "From Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.from_date),
    },
    {
      dataField: "to_date",
      text: "To Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.to_date),
    },
    {
      dataField: "days_off",
      text: "Days Off",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => `${moment(new Date(row.to_date)).diff(moment(new Date(row.from_date)), 'days') + 1} day(s)`,
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cellContent, row) => (
        <div style={{ width: '60%' }} className="mb-1">
          <Input
            name="status"
            type="select"
            className="form-select"
            onChange={event => {
              dispatch(editTimeoff(row.id, { status: event.target.value }, null))
            }}
            value={row.status || ""}>
            <option value="pending">Pending</option>
            <option value="approved">Approved</option>
          </Input>
        </div>
      ),
    },
    {
      dataField: "menu",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, timeoff) => (
        <Menu menuButton={<i className="mdi mdi-dots-horizontal font-size-18" />} transition>
          <MenuItem
            onClick={() => handleEditTimeoff(timeoff)}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => onClickDelete(timeoff)}
          >
            Delete
          </MenuItem>
        </Menu>
      ),
    },
  ];


  var node = useRef();

  //delete timeoff

  const onClickDelete = (timeoff) => {
    setTimeoff(timeoff);
    dispatch(deleteTimeoffModal())
  };

  const handleDeleteTimeoff = () => {
    dispatch(onDeleteTimeoff(timeoff))
  }

  const { SearchBar } = Search;

  function init() {
    dispatch(onGetTimeoffs(true));
  }

  useEffect(() => {
    init()
  }, []);

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page }) => {
    if (type == "pagination")
      dispatch(onGetTimeoffs(true, { page, ...searchParams }));
  };

  const handleCreateTimeoff = () => {
    dispatch(newTimeoffModal())
  };

  const handleEditTimeoff = timeoff => {
    setTimeoff(timeoff)
    dispatch(editTimeoffModal())
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  let handleSearch = (key, value) => {
    let _searchParams = { ...searchParams, [key]: value }
    setSearchParams(_searchParams)
    dispatch(onGetTimeoffs(false, _searchParams));
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteTimeoff}
        onCloseClick={() => dispatch(deleteTimeoffModal())}
      />

      <Create />
      <Edit timeoff={timeoff} />

      <div className="page-content">
        <MetaTags>
          <title>Timeoffs</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Timeoffs" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={COLS}
                    data={timeoffs}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <ToolkitProvider
                          keyField="id"
                          data={timeoffs || []}
                          columns={COLS}
                          bootstrap4
                          search
                        >
                          {toolkitProps => {
                            return (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col sm="4">
                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          searchText={searchParams.search}
                                          onSearch={text => handleSearch("search", text)} />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm="8">
                                    <div className="text-sm-end">
                                      <Button
                                        type="button"
                                        color="success"
                                        className="btn-rounded  mb-2 me-2"
                                        onClick={handleCreateTimeoff}
                                      >
                                        <i className="mdi mdi-plus me-1" />
                                        New timeoff
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        noDataIndication={"No Data found"}
                                        defaultSorted={defaultSorted}
                                        classes={"table align-middle table-nowrap"}
                                        keyField="id"
                                        remote={{ search: true, pagination: true }}
                                        {...toolkitProps.baseProps}
                                        onTableChange={handleTableChange}
                                        {...paginationTableProps}
                                        ref={node}
                                      />
                                    </div>

                                  </Col>
                                </Row>
                                <Row className="align-items-md-center mt-30">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )
                          }}
                        </ToolkitProvider>
                      )
                    }}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Timeoffs.propTypes = {
  timeoffs: PropTypes.array,
  onGetTimeoffs: PropTypes.func,
  onAddNewTimeoff: PropTypes.func,
  onDeleteTimeoff: PropTypes.func,
  onUpdateTimeoff: PropTypes.func,
};

export default Timeoffs;
