import {
  GET_PAYRATES,
  GET_PAYRATES_SUCCESS,
  GET_PAYRATES_FAIL,
  DELETE_PAYRATE,
  ADD_PAYRATE,
  EDIT_PAYRATE
} from "./actionTypes"

export const getPayrates = (loading = false, params = {}) => ({
  type: GET_PAYRATES,
  payload: { loading, params }
})

export const getPayratesSuccess = payrates => ({
  type: GET_PAYRATES_SUCCESS,
  payload: payrates,
})

export const getPayratesFail = error => ({
  type: GET_PAYRATES_FAIL,
  payload: error,
})

export const deletePayrate = payrate => ({
  type: DELETE_PAYRATE,
  payload: { payrate }
})

export const addPayrate = (payrate, actions) => {
  return {
    type: ADD_PAYRATE,
    payload: { payrate, actions },
  }
}

export const editPayrate = (id, payrate, actions) => {
  return {
    type: EDIT_PAYRATE,
    payload: { id, payrate, actions },
  }
}

export const newPayrateModal = () => ({
  type: 'NEW_PAYRATE_MODAL'
})

export const deletePayrateModal = () => ({
  type: 'DELETE_PAYRATE_MODAL'
})

export const editPayrateModal = () => ({
  type: 'EDIT_PAYRATE_MODAL'
})
