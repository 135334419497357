import React from "react"
import { Redirect } from "react-router-dom"

// AllProPlus menu
import EcommerceCustomers from "../pages/Customers/index"
import Payrolls from "../pages/Payrolls/index"
import Payrates from "../pages/Payrates/index"
import Timeoffs from "../pages/Timeoffs/index"
import Subcontractors from "../pages/Subcontractors/index"
import Categories from "../pages/Categories/index"
import Expenses from "../pages/Expenses/index"
import Materials from "../pages/Materials/index"

// Profile
import UserProfile from "../pages/Settings/Profile"
import Policy from "../pages/Settings/Policy"
import Settings from "../pages/Settings/index"

//  // Quotes
import Invoices from "../pages/Invoices"
import NewInvoice from '../pages/Invoices/Create'
import EditInvoice from '../pages/Invoices/Edit'
import Estimates from "../pages/Estimates"

//  // Stocks
import Vendors from "../pages/Vendors"
import Products from "../pages/Products"
import Purchases from "../pages/Purchases"
import Distributions from "../pages/Distributions"
import Locations from "../pages/Locations"


// //Projects
import ProjectsList from "../pages/Projects/index"
import TaskList from "../pages/Tasks/index"
import TimeSheet from "../pages/TimeSheet/kanban"
import TimesheetReport from "../pages/Reports/TimesheetReport"
import PayrollReport from "../pages/Reports/PayrollReport"
import ProjectsOverview from "../pages/Projects/ProjectOverview/projects-overview"

// Authentication related pages
import Login from "../pages/Authentication/LoginControl"
import ResetPassword from "../pages/Authentication/ResetPasswordControl"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

//Staff
import StaffGrid from "../pages/Staff"
import Staff from "pages/Staff"
import Roles from "pages/Roles"
import Users from "pages/Users"
import taskOverview from "pages/Tasks/TaskOverview/task-overview"
import Password from "pages/Settings/Password"
import Company from "pages/Settings/Company"
import QuickBooks from "pages/Settings/QuickBooks"
import Callback from "pages/Settings/Callback"
import Subscription from "pages/Settings/Subscription"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  // //profile
  { path: "/profile", component: UserProfile },
  { path: "/settings/profile", component: UserProfile },
  { path: "/settings/company", component: Company },
  { path: "/settings/policy", component: Policy },
  { path: "/settings/quickbooks", component: QuickBooks },
  { path: "/settings/password", component: Password },
  // //profile
  { path: "/settings", component: Settings },
  { path: "/settings/subscriptions", component: Subscription, exact: true },

  { path: "/customers", component: EcommerceCustomers },
  { path: "/payrolls", component: Payrolls },
  { path: "/payrates", component: Payrates },
  { path: "/timeoffs", component: Timeoffs },
  { path: "/subcontractors", component: Subcontractors },
  // { path: "/categories", component: Categories },
  // { path: "/materials", component: Materials },

  // // Stocks start
  // // Vendors
  // { path: "/vendors", component: Vendors },

  // // Products
  // { path: "/products", component: Products },

  // // Purchases
  // { path: "/purchases", component: Purchases },

  // // distributions
  // { path: "/distributions", component: Distributions },

  // // Locations
  // { path: "/locations", component: Locations },
  // // Stocks end

  // Quotes
  { path: "/invoice", component: Invoices },
  { path: "/invoices/new", component: NewInvoice },
  { path: "/invoices/:id/edit", component: EditInvoice },
  { path: "/estimate", component: Estimates },

  //Projects
  { path: "/projects", component: ProjectsList },
  { path: "/timesheets/report", component: TimesheetReport, exact: true },
  { path: "/payrolls/report", component: PayrollReport, exact: true },
  { path: "/projects/:id/tasks/:task_id/timesheets", exact: true, component: TimeSheet },
  { path: "/projects/:id/tasks/:task_id", exact: true, component: taskOverview },
  { path: "/projects/:id/tasks", exact: true, component: TaskList },
  { path: "/projects/:id/expenses", exact: true, component: Expenses },
  { path: "/projects/:id", exact: true, component: ProjectsOverview },

  // Contacts
  { path: "/staff", component: Staff },

  // Staff
  { path: "/users", component: Users },
  { path: "/staff-grid", component: StaffGrid },
  { path: "/roles", component: Roles },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/quickbooks/callback", component: Callback },
  { path: "/login", component: Login },
  { path: "/reset-password/:uidb64/:token", component: ResetPassword },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register }
]

export { authProtectedRoutes, publicRoutes }
