import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';
import {
  GET_PURCHASES,
  DELETE_PURCHASE,
  ADD_PURCHASE,
  EDIT_PURCHASE
} from "./actionTypes";

import {
  getPurchasesSuccess,
  getPurchases,
  getPurchasesFail,
  deletePurchaseModal
} from "./actions";

import {
  getPurchases as getPurchasesAPI,
  deletePurchase as deletePurchaseAPI,
  addPurchase as addPurchaseAPI,
  editPurchase as editPurchaseAPI,
} from "api/v1";


function* fetchPurchases({ payload: { params } }) {
  try {
    const response = yield call(getPurchasesAPI, params);
    yield put(getPurchasesSuccess(response));
  } catch (error) {
    yield put(getPurchasesFail("Something went wrong!"))
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* addPurchase({ payload: { purchase, actions } }) {
  try {
    yield call(addPurchaseAPI, purchase)
    toast.success("Purchase Created!");
    yield put(getPurchases(true));
    actions.resetForm()

  } catch ({ errors }) {
    yield put(getPurchasesFail("Creation failed!"))
    if (errors) {
      let { user } = errors
      actions.setErrors({ ...errors, ...user })

    }
    toast.error("Something went wrong!");
  }
}

function* editPurchase({ payload: { id, purchase, actions } }) {
  try {
    yield call(editPurchaseAPI, id, purchase)
    toast.success("Purchase Updated!");
    yield put(getPurchases(true));

  } catch ({ errors }) {
    yield put(getPurchasesFail("Creation failed!"))
    if (errors && actions) {
      let { user } = errors
      actions.setErrors({ ...errors, ...user })

    }
    toast.error("Something went wrong!");
  }
}

function* deletePurchase({ payload: { purchase } }) {
  try {
    yield call(deletePurchaseAPI, purchase.id);
    toast.warn("Purchase Deleted!");
    put(deletePurchaseModal())
    yield put(getPurchases(true));
  } catch (error) {
    yield put(getPurchasesFail(""))
    toast.error("Something went wrong!");
  }
}

function* purchaseSaga() {
  yield takeEvery(GET_PURCHASES, fetchPurchases);
  yield takeEvery(DELETE_PURCHASE, deletePurchase);
  yield takeEvery(ADD_PURCHASE, addPurchase);
  yield takeEvery(EDIT_PURCHASE, editPurchase);
}

export default purchaseSaga;
