import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row
} from "reactstrap";
import { Menu, MenuItem } from '@szhsin/react-menu';

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../components/Common/DeleteModal";
import {
  getExpenses as onGetExpenses,
  deleteExpense as onDeleteExpense,
  newExpenseModal,
  deleteExpenseModal,
  editExpenseModal
} from "store/expenses/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setLoader, usFormat } from "utils/common";
import Create from './Create'
import Edit from './Edit'

const Expenses = props => {
  const dispatch = useDispatch();

  const {
    match: { params },
  } = props;

  const { expenseWithPagy, loading, deleteModal } = useSelector(state => ({
    expenseWithPagy: state.expenses.expenseWithPagy,
    loading: state.expenses.loading,
    deleteModal: state.expenses.delete,
  }));
  let { results: expenses, total_cost } = expenseWithPagy

  const [expense, setExpense] = useState(null);
  const [searchParams, setSearchParams] = useState({
    search: '',
  })

  //pagination customization
  const pageOptions = {
    sizePerPage: expenseWithPagy.page_size,
    totalSize: expenseWithPagy.total,
    page: expenseWithPagy.page,
    custom: true,
  };

  const COLS = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      text: "#",
      dataField: "index",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row, rowIndex) => <>{rowIndex + 1}</>,
    },
    {
      text: "Type",
      dataField: "type",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <b><i>{row.product ? 'Stock' : 'Non-Stock'}</i></b>
    },
    {
      text: "Name",
      dataField: "item",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <b><i>{row.product ? `${row.product.name} (${row.location?.location || "N/A"})` : row.item}</i></b>
    },
    {
      dataField: "unit_cost",
      text: "Unit Cost",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <p className="mb-1">{`${usFormat(row.unit_cost)}`}</p>,
    },
    {
      dataField: "quantity",
      text: "Quantity",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <p className="mb-1">{`${row.quantity}`}</p>,
    },
    {
      dataField: "total_cost",
      text: "Total Cost",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <p className="mb-1">{`${usFormat(row.total_cost)}`}</p>,
    },
    {
      dataField: "menu",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, expense) => (
        <Menu menuButton={<i className="mdi mdi-dots-horizontal font-size-18" />} transition>
          <MenuItem
            onClick={() => handleEditExpense(expense)}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => onClickDelete(expense)}
          >
            Delete
          </MenuItem>
        </Menu>
      ),
    },
  ];


  var node = useRef();

  //delete expense

  const onClickDelete = (expense) => {
    setExpense(expense);
    dispatch(deleteExpenseModal())
  };

  const handleDeleteExpense = () => {
    dispatch(onDeleteExpense(params.id, expense))
  }

  const { SearchBar } = Search;

  function init() {
    dispatch(onGetExpenses(params.id, true));
  }

  useEffect(() => {
    init()
  }, []);

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page }) => {
    if (type == "pagination")
      dispatch(onGetExpenses(params.id, true, { page, ...searchParams }));
  };

  const handleCreateExpense = () => {
    dispatch(newExpenseModal())
  };

  const handleEditExpense = _expense => {
    setExpense(_expense)
    dispatch(editExpenseModal())
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];


  let handleSearch = (key, value) => {
    let _searchParams = { ...searchParams, [key]: value }
    setSearchParams(_searchParams)
    dispatch(onGetExpenses(params.id, false, _searchParams));
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteExpense}
        onCloseClick={() => dispatch(deleteExpenseModal())}
      />

      <Create projectId={params.id} />
      <Edit projectId={params.id} expense={expense} />

      <div className="page-content">
        <MetaTags>
          <title>Expenses</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs back={true} breadcrumbItem="Expenses" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={COLS}
                    data={expenses}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <ToolkitProvider
                          keyField="id"
                          data={expenses || []}
                          columns={COLS}
                          bootstrap4
                          search
                        >
                          {toolkitProps => {
                            return (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col sm="4">
                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          searchText={searchParams.search}
                                          onSearch={text => handleSearch("search", text)} />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm="8">
                                    <div className="text-sm-end">
                                      <Button
                                        type="button"
                                        color="success"
                                        className="btn-rounded  mb-2 me-2"
                                        onClick={handleCreateExpense}
                                      >
                                        <i className="mdi mdi-plus me-1" />
                                        New Expense
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        noDataIndication={"No Data found"}
                                        defaultSorted={defaultSorted}
                                        classes={"table align-middle table-nowrap"}
                                        keyField="id"
                                        remote={{ search: true, pagination: true }}
                                        {...toolkitProps.baseProps}
                                        onTableChange={handleTableChange}
                                        {...paginationTableProps}
                                        ref={node}
                                      />
                                    </div>

                                  </Col>
                                </Row>
                                <Row className="align-items-md-center mt-30">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Row>
                                <Row className="align-items-md-center mt-30">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                    <h4>Total: {usFormat(total_cost || 0)}</h4>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )
                          }}
                        </ToolkitProvider>
                      )
                    }}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Expenses.propTypes = {
  expenses: PropTypes.array,
  onGetExpenses: PropTypes.func,
  onAddNewExpense: PropTypes.func,
  onDeleteExpense: PropTypes.func,
  onUpdateExpense: PropTypes.func,
};

export default Expenses;
