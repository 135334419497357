import { getEmployees } from "api/v1";
import React from "react";
import {
  Col,
  Row,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap";
import AsyncSelect from 'react-select/async';
import Dropzone from 'react-dropzone-uploader';
import './style.scss'
import ImageContainer from "./ImageContainer";

function index({ validation, handleDeleteImage, edit = false }) {
  let [images, setImages] = React.useState([])

  React.useEffect(() => {
    validation.setFieldValue("images", images.map(_img => _img.file))
  }, [images])

  const handleChangeStatus = ({ meta, file, xhr, ...rest }, status) => {

    const fileRemove = rest.remove;
    if (status === "removed") {
      setImages(fids => fids.filter(item => item.name !== file.name));
    }
    if (status === "done") {
      setImages(fids => [...fids, {
        file,
        name: file.name,
        remove: fileRemove
      }]);


    }
  }

  let loadEmployees = (search) => {
    return getEmployees({
      search,
      no_paginate: 1,
      is_active: true
    })
      .then((results = []) => {
        return results
      })
      .catch(({ message }) => {
        if (message !== 'request-cancel') {
          toast.error(message);
        }
      })
  }

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <Row form>
        <Col className="col-12">
          <div className="mb-3">
            <Label className="form-label">Task Name</Label>
            <Input
              name="task"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.task || ""}
              invalid={
                validation.touched.task && validation.errors.task ? true : false
              }
            />
            {validation.touched.task && validation.errors.task ? (
              <FormFeedback type="invalid">{validation.errors.task}</FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">Code</Label>
            <Input
              name="code"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.code || ""}
              invalid={
                validation.touched.code && validation.errors.code ? true : false
              }
            />
            {validation.touched.code && validation.errors.code ? (
              <FormFeedback type="invalid">{validation.errors.code}</FormFeedback>
            ) : null}
          </div>


          <div className="mb-3">
            <Label className="form-label">Description</Label>
            <Input
              name="description"
              type="textarea"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.description || ""}
              invalid={
                validation.touched.description && validation.errors.description ? true : false
              }
            />
            {validation.touched.description && validation.errors.description ? (
              <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">Estimated Hours</Label>
            <Input
              name="estimated_hours"
              type="number"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.estimated_hours || ""}
              invalid={
                validation.touched.estimated_hours && validation.errors.estimated_hours ? true : false
              }
            />
            {validation.touched.estimated_hours && validation.errors.estimated_hours ? (
              <FormFeedback type="invalid">{validation.errors.estimated_hours}</FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">Status</Label>
            <Input
              name="status"
              type="select"
              className="form-select"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.status || ""}
              invalid={
                validation.touched.status && validation.errors.status ? true : false
              }
            >
              <option value=""></option>
              <option value="Processing">Processing</option>
              <option value="Open">Open</option>
              <option value="Close">Close</option>
              <option value="Waiting">Waiting</option>

            </Input>
            {validation.touched.status && validation.errors.status ? (
              <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">Employees</Label>
            <AsyncSelect
              defaultOptions
              placeholder="Select Employees"
              required
              isMulti
              value={validation.values.users}
              getOptionLabel={({ profile: { first_name, last_name } }) => `${first_name} ${last_name}`}
              getOptionValue={({ id }) => id}
              loadOptions={loadEmployees}
              onChange={(_users) => {
                validation.setFieldValue('users', _users)
              }}

            />
            {validation.touched.users && validation.errors.users ? (
              <span className="text-danger font-size-11">{validation.errors.users}</span>
            ) : null}
          </div>
        </Col>

        <div className="mb-3">
          <Label className="form-label">Image</Label>
          <Dropzone
            inputContent={<span key="some_key" className="h5 mb-0">Click here or drag images</span>}
            onChangeStatus={handleChangeStatus}
            accept="image/*"
          />
        </div>

        {
          edit &&
          <div className="mb-3">
            <ImageContainer handleDeleteImage={handleDeleteImage} />
          </div>
        }
      </Row>
      <Row>
        <Col>
          <div className="text-end">
            <button
              type="submit"
              className="btn btn-success save-customer"
            >
              Save
            </button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default index
