import {
  GET_ROLES_FAIL,
  GET_ROLES_SUCCESS,
  GET_ROLES,
  ADD_ROLE,
  EDIT_ROLE,
  DELETE_ROLE,
} from "./actionTypes";

const INIT_STATE = {
  roleWithPagy: {
    total: 0,
    page: 1,
    page_size: 10,
    results: []
  },
  new: false,
  edit: false,
  delete: false,
  loading: false,
};

const roles = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ROLES:
      return {
        ...state,
        new: false,
        edit: false,
        delete: false,
        loading: action.payload.loading
      };

    case GET_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        roleWithPagy: action.payload,
      };

    case ADD_ROLE:
      return {
        ...state,
        loading: true
      };

    case EDIT_ROLE:
      return {
        ...state,
        loading: true
      };

    case GET_ROLES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "NEW_ROLE_MODAL":
      return {
        ...state,
        new: !state.new
      };

    case "DELETE_ROLE_MODAL":
      return {
        ...state,
        delete: !state.delete
      };

    case "EDIT_ROLE_MODAL":
      return {
        ...state,
        edit: !state.edit
      };
    default:
      return state;
  }
};

export default roles;
