import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';
import {
  GET_PAYROLLS,
  DELETE_PAYROLL,
  ADD_PAYROLL,
  EDIT_PAYROLL
} from "./actionTypes";

import {
  getPayrollsSuccess,
  getPayrolls,
  getPayrollsFail,
  deletePayrollModal
} from "./actions";

import {
  getPayrolls as getPayrollsAPI,
  deletePayroll as deletePayrollAPI,
  addPayroll as addPayrollAPI,
  editPayroll as editPayrollAPI,
} from "api/v1";


function* fetchPayrolls({ payload: { params } }) {
  try {
    const response = yield call(getPayrollsAPI, params);
    yield put(getPayrollsSuccess(response));
  } catch (error) {
    yield put(getPayrollsFail("Something went wrong!"))
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* addPayroll({ payload: { payroll, actions } }) {
  try {
    yield call(addPayrollAPI, payroll)
    toast.success("Payroll Created!");
    yield put(getPayrolls(true));
    actions.resetForm()

  } catch ({ errors }) {
    yield put(getPayrollsFail("Creation failed!"))
    if (errors) {
      actions.setErrors(errors)

    }
    toast.error("Something went wrong!");
  }
}

function* editPayroll({ payload: { id, payroll, actions } }) {
  try {
    yield call(editPayrollAPI, id, payroll)
    toast.success("Payroll Updated!");
    yield put(getPayrolls(true));

  } catch ({ errors }) {
    yield put(getPayrollsFail("Creation failed!"))
    if (errors) {
      actions.setErrors(errors)

    }
    toast.error("Something went wrong!");
  }
}

function* deletePayroll({ payload: { payroll } }) {
  try {
    yield call(deletePayrollAPI, payroll.id);
    put(deletePayrollModal())
    yield put(getPayrolls(true));
    toast.warn("Payroll Deleted!");
  } catch (error) {
    toast.error("Something went wrong!");
  }
}

function* payrollSaga() {
  yield takeEvery(GET_PAYROLLS, fetchPayrolls);
  yield takeEvery(DELETE_PAYROLL, deletePayroll);
  yield takeEvery(ADD_PAYROLL, addPayroll);
  yield takeEvery(EDIT_PAYROLL, editPayroll);
}

export default payrollSaga;
