import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'

import { editMaterialModal, editMaterial } from 'store/materials/actions'

function index({ material: target }) {
  let dispatch = useDispatch()
  const { edit: modal } = useSelector((state) => state.materials);
  let [material, setMaterial] = useState()

  useEffect(() => {
    if (modal) {
      setMaterial(target)
    }
  }, [modal])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (material && material.name) || '',
      price: (material && material.price) || '',
      description: (material && material.description) || '',
      size: (material && material.size) || '',
      image: (material && material.image) || null,
      category: (material && material.category) || null
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Material Name"),
      image: Yup.mixed().required("You need to provide a Image"),
      description: Yup.string().required("Please Enter Material Descripton"),
      size: Yup.string().required("Please Enter Size"),
      price: Yup.string().required("Please Enter Price"),
      category: Yup.mixed().required("Please Enter Category"),
    }),
    onSubmit: (values, actions) => {
      dispatch(editMaterial(material.id, values, actions))
    },
  });



  return (
    <React.Fragment>
      <Modal isOpen={modal} toggle={() => dispatch(editMaterialModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(editMaterialModal())}>
          Edit Material
        </ModalHeader>
        <ModalBody>
          <Form
            validation={validation}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
