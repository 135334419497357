import {
  GET_STAFFS_FAIL,
  GET_STAFFS_SUCCESS,
  GET_STAFFS,
  ADD_STAFF,
  EDIT_STAFF,
  DELETE_STAFF,
} from "./actionTypes";

const INIT_STATE = {
  staffWithPagy: {
    total: 0,
    page: 1,
    page_size: 10,
    results: []
  },
  new: false,
  edit: false,
  delete: false,
  loading: false,
};

const staffs = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STAFFS:
      return {
        ...state,
        new: false,
        edit: false,
        delete: false,
        loading: action.payload.loading
      };

    case GET_STAFFS_SUCCESS:
      return {
        ...state,
        loading: false,
        staffWithPagy: action.payload,
      };

    case ADD_STAFF:
      return {
        ...state,
        loading: true
      };

    case EDIT_STAFF:
      return {
        ...state,
        loading: true
      };

    case DELETE_STAFF:
      return {
        ...state,
        loading: true
      };

    case GET_STAFFS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "NEW_STAFF_MODAL":
      return {
        ...state,
        new: !state.new
      };

    case "DELETE_STAFF_MODAL":
      return {
        ...state,
        delete: !state.delete
      };

    case "EDIT_STAFF_MODAL":
      return {
        ...state,
        edit: !state.edit
      };
    default:
      return state;
  }
};

export default staffs;
