import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';
import {
  GET_LOCATIONS,
  DELETE_LOCATION,
  ADD_LOCATION,
  EDIT_LOCATION
} from "./actionTypes";

import {
  getLocationsSuccess,
  getLocations,
  getLocationsFail,
  deleteLocationModal
} from "./actions";

import {
  getLocations as getLocationsAPI,
  deleteLocation as deleteLocationAPI,
  addLocation as addLocationAPI,
  editLocation as editLocationAPI,
} from "api/v1";


function* fetchLocations({ payload: { params } }) {
  try {
    const response = yield call(getLocationsAPI, params);
    yield put(getLocationsSuccess(response));
  } catch (error) {
    yield put(getLocationsFail("Something went wrong!"))
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* addLocation({ payload: { location, actions } }) {
  try {
    yield call(addLocationAPI, location)
    toast.success("Location Created!");
    yield put(getLocations(true));
    actions.resetForm()

  } catch ({ errors }) {
    yield put(getLocationsFail("Creation failed!"))
    if (errors) {
      let { user } = errors
      actions.setErrors({ ...errors, ...user })

    }
    toast.error("Something went wrong!");
  }
}

function* editLocation({ payload: { id, location, actions } }) {
  try {
    yield call(editLocationAPI, id, location)
    toast.success("Location Updated!");
    yield put(getLocations(true));

  } catch ({ errors }) {
    yield put(getLocationsFail("Creation failed!"))
    if (errors && actions) {
      let { user } = errors
      actions.setErrors({ ...errors, ...user })

    }
    toast.error("Something went wrong!");
  }
}

function* deleteLocation({ payload: { location } }) {
  try {
    yield call(deleteLocationAPI, location.id);
    toast.warn("Location Deleted!");
    put(deleteLocationModal())
    yield put(getLocations(true));
  } catch (error) {
    yield put(getLocationsFail(""))
    toast.error("Something went wrong!");
  }
}

function* locationSaga() {
  yield takeEvery(GET_LOCATIONS, fetchLocations);
  yield takeEvery(DELETE_LOCATION, deleteLocation);
  yield takeEvery(ADD_LOCATION, addLocation);
  yield takeEvery(EDIT_LOCATION, editLocation);
}

export default locationSaga;
