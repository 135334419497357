import React, { useEffect } from 'react'
import { CardBody } from 'reactstrap'
import ReactApexChart from "react-apexcharts"

function TaskCountDetails({ tasks }) {
  const [series, setSeries] = React.useState([0, 0, 0])

  const options = {
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: "22px",
          },
          value: {
            fontSize: "20px",
            fontWeight: 'bolder',
            formatter: function (val) {
              return val == 0 ? 0 : (val / 100) * tasks.length
            }
          },
          total: {
            show: true,
            label: "Total Task",
            color: '#2DB75B',
            formatter: function (w) {
              return tasks.length
            },
          },
        },
      },
    },
    legend: {
      show: true,
      // floating: true,
      position: 'bottom',
      formatter: function (seriesName, opts) {
        return seriesName + "  " + parseInt((opts.w.globals.series[opts.seriesIndex] * tasks.length) / 100)
      },
    },
    stroke: {
      lineCap: 'round'
    },
    labels: ["Processing", "Open", "Waiting", "Close"],
    colors: ["#f1b44c", "#f1b44c", "#50a5f1", "#2DB75B"],
  }


  useEffect(() => {
    let _taskStats = { 'Processing': 0, 'Open': 0, 'Waiting': 0, 'Close': 0 }

    tasks.forEach((_task) => {
      _taskStats[_task.status]++
    })
    let _mutiplier = 100 / (tasks.length || 1)
    setSeries([_taskStats['Processing'] * _mutiplier, _taskStats['Open'] * _mutiplier, _taskStats['Waiting'] * _mutiplier, _taskStats['Close'] * _mutiplier])

  }, [tasks])

  return (

    <CardBody>
      <ReactApexChart
        options={options}
        series={series}
        type="radialBar"
        height="400"
      />
    </CardBody>
  )
}

export default TaskCountDetails
