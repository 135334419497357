export function fromMinutes(_integer) {
  function n(n) {
    return n > 9 ? "" + n : "0" + n;
  }

  return _integer ? `${n(parseInt(_integer / 60))}:${n(_integer % 60)}` : ""
}

export function toMinutes(_string) {
  let [hours, minutes] = _string.replaceAll("_", '').split(":")

  if (hours == "" && minutes == "")
    return null

  hours = parseInt(hours || 0)
  minutes = parseInt(minutes || 0)

  return minutes + (hours * 60)
}
