import { getProjectPayroll } from 'api/v1'
import Loader from 'common/Loader'
import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { Card, CardBody } from 'reactstrap'
import { usFormat } from 'utils/common'
import { toast } from 'react-toastify'

function Payroll() {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    series: [],
    options: {
      labels: [],
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
        verticalAlign: "middle",
        floating: false,
        fontSize: "14px",
        offsetX: 0,
        offsetY: -10,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return usFormat(val);
          },
        },
      },
      responsive: [
        {
          breakpoint: 600,
          options: {
            chart: {
              height: 240,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
    }
  })

  async function loadData(params = {}) {
    setLoading(true)
    try {
      let { results: _data = [] } = await getProjectPayroll({ paid_amount: true });
      setData(__data => ({
        series: _data.map(_ => _.paid_amount),
        options: {
          ...__data.options,
          labels: _data.map(_ => _.project_name)
        }
      }))

    } catch (error) {
      toast.error("Something went wrong loading data")
    }
    setLoading(false)
  }

  useEffect(() => {
    loadData()
  }, []);

  return (
    <Card>
      <CardBody>
        <h4 className="card-title mb-4">Payroll by project</h4>

        {
          loading ?
            <Loader />
            :
            data.series.length > 0 ?
              <ReactApexChart
                options={data.options}
                series={data.series}
                type="donut"
                height="310"
              />
              : <p className="mb-4">No Data Found</p>

        }
      </CardBody>
    </Card>
  )
}

export default Payroll
