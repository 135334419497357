import React, { useEffect, useState, useRef, Fragment } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row
} from "reactstrap";

import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

import {
  getProjects as onGetProjects,
} from "store/projects/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "common/Loader";
import { usFormat } from "utils/common";

const Projects = props => {
  const dispatch = useDispatch();

  const { projectWithPagy, loading } = useSelector(state => ({
    projectWithPagy: state.projects.projectWithPagy,
    loading: state.projects.loading,
  }));
  let { results: projects } = projectWithPagy

  const [searchParams, setSearchParams] = useState({
    status: 'Working'
  })

  //pagination customization
  const pageOptions = {
    sizePerPage: projectWithPagy.page_size,
    totalSize: projectWithPagy.total,
    page: projectWithPagy.page,
    custom: true,
  };

  const COLS = [
    {
      text: "id",
      dataField: "id",
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      dataField: "project_name",
      text: "Project Name",
      formatter: (cellContent, row) => (
        <>
          <p className="mb-0">{row.project_name}</p>
          <small className="mb-1 text-muted">Project Name</small>
        </>
      ),
    },
    {
      dataField: "total_hours",
      text: "Total Hours",
      formatter: (cellContent, row) => (
        <>
          <p className="mb-0">{row.total_hours}h</p>
          <small className="mb-1 text-muted">Total Hours</small>
        </>
      ),
    },
    {
      dataField: "estimates",
      text: "Estimates",
      formatter: (cellContent, row) => (
        <>
          <p className="mb-0">{usFormat(row.estimates || 0)}</p>
          <small className="mb-1 text-muted">Estimates</small>
        </>
      ),
    }
  ];


  var node = useRef();

  //delete project

  function init() {
    dispatch(onGetProjects(true, searchParams));
  }

  useEffect(() => {
    init()
  }, []);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page }) => {
    if (type == "pagination")
      dispatch(onGetProjects(true, { page, ...searchParams }));
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  return (
    <React.Fragment>
      <Row>
        <Col xs="12">
          <Card style={{ borderWidth: 1, borderColor: '#F1F1F1' }}>
            <CardHeader className="bg-white pt-3">
              <Row>
                <Col xs="8">
                  <CardTitle className="mb-2">Working on Project</CardTitle>
                </Col>

                <Col className="text-end">
                  <Link to="/projects" className="mb-2">View more</Link>
                </Col>
              </Row>
            </CardHeader>

            <CardBody>
              {
                loading ?
                  <Loader message={"Please wait"} />
                  :
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={COLS}
                    data={projects}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <ToolkitProvider
                          keyField="id"
                          data={projects.slice(0, 3) || []}
                          columns={COLS}
                          bootstrap4
                          search
                        >
                          {toolkitProps => {
                            return (
                              <React.Fragment>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        noDataIndication={"No Data found"}
                                        defaultSorted={defaultSorted}
                                        classes={"table align-middle table-nowrap"}
                                        keyField="id"
                                        remote={{ search: true, pagination: true }}
                                        {...toolkitProps.baseProps}
                                        onTableChange={handleTableChange}
                                        {...paginationTableProps}
                                        ref={node}
                                      />
                                    </div>

                                  </Col>
                                </Row>
                              </React.Fragment>
                            )
                          }}
                        </ToolkitProvider>
                      )
                    }}
                  </PaginationProvider>
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

Projects.propTypes = {
  projects: PropTypes.array,
  onGetProjects: PropTypes.func,
  onAddNewProject: PropTypes.func,
  onDeleteProject: PropTypes.func,
  onUpdateProject: PropTypes.func,
};

export default Projects;
