import { filterParams } from "../apiHelper";
import request from "../restClient";

export const getStaffs = async (params) => {
  return await request({
    url: "staffs/",
    method: "GET",
    params: filterParams(params),
    cancelPrevious: params.cancelPrevious || true,
  });
};

export const deleteStaff = async (id) => {
  return await request({
    url: `staffs/${id}/`,
    method: "DELETE"
  });
};

export const editStaff = async (id, data) => {
  const formD = new FormData()
  Object.entries(data).forEach((keyValue) => {
    if (keyValue[0] == "role")
      formD.append(keyValue[0], keyValue[1]?.id)
    else if (keyValue[0] == 'user')
      formD.append(keyValue[0], JSON.stringify(keyValue[1]))
    else if (keyValue[1] && (keyValue[0] != "avatar" || typeof (keyValue[1]) != "string"))
      formD.append(keyValue[0], keyValue[1])
  })

  return await request({
    url: `staffs/${id}/`,
    method: "PATCH",
    headers: {
      'Content-type': 'multipart/form-data'
    },
    data: formD
  });
};

export const addStaff = async (data) => {
  const formD = new FormData()
  Object.entries(data).forEach((keyValue) => {
    if (keyValue[0] == "role")
      formD.append(keyValue[0], keyValue[1]?.id)
    else if (keyValue[0] == 'user')
      formD.append(keyValue[0], JSON.stringify(keyValue[1]))
    else if (keyValue[1] && (keyValue[0] != "avatar" || typeof (keyValue[1]) != "string"))
      formD.append(keyValue[0], keyValue[1])
  })

  return await request({
    url: `staffs/`,
    method: "POST",
    headers: {
      'Content-type': 'multipart/form-data',
    },
    data: formD
  });
};
