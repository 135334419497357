import React from "react";
import {
  Col,
  Row,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap";


function index({ validation }) {
  let [avatar, setAvatar] = React.useState()

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <Row>
        <Col className="col-6">
          <div className="mb-3">
            <Label className="form-label">First Name</Label>
            <Input
              name="first_name"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.first_name || ""}
              invalid={
                validation.touched.first_name && validation.errors.first_name ? true : false
              }
            />
            {validation.touched.first_name && validation.errors.first_name ? (
              <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
            ) : null}
          </div>
        </Col>

        <Col className="col-6">
          <div className="mb-3">
            <Label className="form-label">Last Name</Label>
            <Input
              name="last_name"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.last_name || ""}
              invalid={
                validation.touched.last_name && validation.errors.last_name ? true : false
              }
            />
            {validation.touched.last_name && validation.errors.last_name ? (
              <FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
            ) : null}
          </div>
        </Col>

        <Col className="col-6">
          <div className="mb-3">
            <Label className="form-label">Company Name</Label>
            <Input
              name="company_name"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.company_name || ""}
              invalid={
                validation.touched.company_name && validation.errors.company_name ? true : false
              }
            />
            {validation.touched.company_name && validation.errors.company_name ? (
              <FormFeedback type="invalid">{validation.errors.company_name}</FormFeedback>
            ) : null}
          </div>
        </Col>

        <Col className="col-6">
          <div className="mb-3">
            <Label className="form-label">Email</Label>
            <Input
              name="email"
              type="email"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.email || ""}
              invalid={
                validation.touched.email && validation.errors.email ? true : false
              }
            />
            {validation.touched.email && validation.errors.email ? (
              <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
            ) : null}
          </div>
        </Col>

        <Col className="col-6">
          <div className="mb-3">
            <Label className="form-label">Phone</Label>
            <Input
              name="phone"
              type="tel"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.phone || ""}
              invalid={
                validation.touched.phone && validation.errors.phone ? true : false
              }
            />
            {validation.touched.phone && validation.errors.phone ? (
              <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
            ) : null}
          </div>
        </Col>

        <Col className="col-6">
          <div className="mb-3">
            <Label className="form-label">Date of Birth</Label>
            <Input
              name="date_birth"
              type="date"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.date_birth || ""}
              invalid={
                validation.touched.date_birth && validation.errors.date_birth ? true : false
              }
            />
            {validation.touched.date_birth && validation.errors.date_birth ? (
              <FormFeedback type="invalid">{validation.errors.date_birth}</FormFeedback>
            ) : null}
          </div>
        </Col>

        <Col className="col-12">
          <div className="mb-3">
            <Label className="form-label">Street</Label>
            <Input
              name="street"
              type="textarea"
              rows="3"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.street || ""}
              invalid={
                validation.touched.street && validation.errors.street ? true : false
              }
            />
            {validation.touched.street && validation.errors.street ? (
              <FormFeedback type="invalid">{validation.errors.street}</FormFeedback>
            ) : null}
          </div>
        </Col>

        <Col className="col-6">
          <div className="mb-3">
            <Label className="form-label">City</Label>
            <Input
              name="city"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.city || ""}
              invalid={
                validation.touched.city && validation.errors.city ? true : false
              }
            />
            {validation.touched.city && validation.errors.city ? (
              <FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
            ) : null}
          </div>
        </Col>

        <Col className="col-6">
          <div className="mb-3">
            <Label className="form-label">State</Label>
            <Input
              name="state"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.state || ""}
              invalid={
                validation.touched.state && validation.errors.state ? true : false
              }
            />
            {validation.touched.state && validation.errors.state ? (
              <FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
            ) : null}
          </div>
        </Col>

        <Col className="col-6">
          <div className="mb-3">
            <Label className="form-label">Country</Label>
            <Input
              name="country"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.country || ""}
              invalid={
                validation.touched.country && validation.errors.country ? true : false
              }
            />
            {validation.touched.country && validation.errors.country ? (
              <FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
            ) : null}
          </div>
        </Col>

        <Col className="col-6">
          <div className="mb-3">
            <Label className="form-label">Zip Code</Label>
            <Input
              name="zip_code"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.zip_code || ""}
              invalid={
                validation.touched.zip_code && validation.errors.zip_code ? true : false
              }
            />
            {validation.touched.zip_code && validation.errors.zip_code ? (
              <FormFeedback type="invalid">{validation.errors.zip_code}</FormFeedback>
            ) : null}
          </div>
        </Col>

        <Col className="col-12">
          <div className="mb-3">
            <Label className="form-label">Avatar</Label>
            <Input
              name="avatar"
              type="file"
              onChange={event => {
                validation.setFieldValue('avatar', event.target.files[0])
                if (event.target.files.length > 0) {
                  var reader = new FileReader();
                  reader.readAsDataURL(event.target.files[0]);
                  reader.onloadend = function (e) {
                    setAvatar(reader.result)
                  }

                } else {
                  setAvatar(null)
                }
              }}
              accept="image/*"
              onBlur={validation.handleBlur}
              invalid={
                validation.touched.avatar && validation.errors.avatar ? true : false
              }
            />
            {validation.touched.avatar && validation.errors.avatar ? (
              <FormFeedback type="invalid">{validation.errors.avatar}</FormFeedback>
            ) : null}

            {
              (avatar || validation.values.avatar) &&
              <div className="table-avatar-lg mt-2">
                <img
                  src={avatar || validation.values.avatar}
                  alt={validation.values.first_name[0]}
                />
              </div>
            }
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="text-end">
            <button
              type="submit"
              className="btn btn-success save-customer"
            >
              Save
            </button>
          </div>
        </Col>
      </Row>
    </Form >
  )
}

export default index
