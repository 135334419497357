import {
  GET_PAYROLL_REPORTS,
  GET_PAYROLL_REPORTS_SUCCESS,
  GET_PAYROLL_REPORTS_FAIL,
  DELETE_PAYROLL_REPORT,
  ADD_PAYROLL_REPORT,
  EDIT_PAYROLL_REPORT
} from "./actionTypes"

export const getPayrollReports = (loading = false, params = {}) => ({
  type: GET_PAYROLL_REPORTS,
  payload: { loading, params }
})

export const getPayrollReport = (id, history) => ({
  type: 'GET_PAYROLL_REPORT',
  payload: { id, history }
})

export const getPayrollReportsSuccess = payrollReports => ({
  type: GET_PAYROLL_REPORTS_SUCCESS,
  payload: payrollReports,
})

export const getPayrollReportsFail = error => ({
  type: GET_PAYROLL_REPORTS_FAIL,
  payload: error,
})

export const getPayrollReportSuccess = payrollReport => ({
  type: 'GET_PAYROLL_REPORT_SUCCESS',
  payload: payrollReport,
})

export const getPayrollReportFail = () => ({
  type: 'GET_PAYROLL_REPORT_FAIL',
})

export const deletePayrollReport = payrollReport => ({
  type: DELETE_PAYROLL_REPORT,
  payload: { payrollReport }
})

export const generatePayrollReport = payrollReport => ({
  type: 'GENERATE_PAYROLL_REPORT',
  payload: { payrollReport }
})

export const addPayrollReport = (payrollReport, actions) => {
  return {
    type: ADD_PAYROLL_REPORT,
    payload: { payrollReport, actions },
  }
}

export const editPayrollReport = (id, payrollReport, actions) => {
  return {
    type: EDIT_PAYROLL_REPORT,
    payload: { id, payrollReport, actions },
  }
}

export const setGeneratablePayrollReport = (payrollReport) => {
  return {
    type: 'SET_GENERATABLE_PAYROLL_REPORT',
    payload: { payrollReport },
  }
}

export const newPayrollReportModal = () => ({
  type: 'NEW_PAYROLL_REPORT_MODAL'
})

export const deletePayrollReportModal = () => ({
  type: 'DELETE_PAYROLL_REPORT_MODAL'
})

export const generatePayrollReportModal = () => ({
  type: 'GENERATE_PAYROLL_REPORT_MODAL'
})

export const editPayrollReportModal = () => ({
  type: 'EDIT_PAYROLL_REPORT_MODAL'
})
