import PropTypes from 'prop-types'
import React, { useState } from "react"
import { Col, Modal, ModalBody, Row } from "reactstrap"
import { toast } from 'react-toastify'
import { payPayroll } from 'api/v1'

const PayPayroll = ({ payable, onPaid, onCloseClick }) => {
  let show = !!payable
  const [loading, setLoading] = useState(false)

  async function _payPayroll() {
    setLoading(true)
    try {
      await payPayroll(payable.id)
      onPaid()
    } catch (error) {
      toast.error("Something went wrong while completing the payroll")
    }
    setLoading(false)
  }

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <i
                className="mdi mdi-alert-circle-outline"
                style={{ fontSize: "9em", color: "orange" }}
              />
              <h2>{`Do you want to mark this payroll as paid?`}</h2>
              <h4>{"You won't be able to revert this!"}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <button
                disabled={loading}
                type="button"
                className="btn btn-success btn-lg ms-2"
                onClick={_payPayroll}
              >
                {loading ? 'Updating...' : 'Yes'}
              </button>
              <button
                disabled={loading}
                type="button"
                className="btn btn-danger btn-lg ms-2"
                onClick={onCloseClick}
              >
                Cancel
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

PayPayroll.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any
}

export default PayPayroll
