import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';
import {
  GET_TIMEOFFS,
  DELETE_TIMEOFF,
  ADD_TIMEOFF,
  EDIT_TIMEOFF
} from "./actionTypes";

import {
  getTimeoffsSuccess,
  getTimeoffs,
  getTimeoffsFail,
  deleteTimeoffModal
} from "./actions";

import {
  getTimeoffs as getTimeoffsAPI,
  deleteTimeoff as deleteTimeoffAPI,
  addTimeoff as addTimeoffAPI,
  editTimeoff as editTimeoffAPI,
} from "api/v1";


function* fetchTimeoffs({ payload: { params } }) {
  try {
    const response = yield call(getTimeoffsAPI, params);
    yield put(getTimeoffsSuccess(response));
  } catch (error) {
    yield put(getTimeoffsFail("Something went wrong!"))
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* addTimeoff({ payload: { timeoff, actions } }) {
  try {
    yield call(addTimeoffAPI, timeoff)
    toast.success("Timeoff Created!");
    yield put(getTimeoffs(true));
    actions.resetForm()

  } catch ({ errors }) {
    yield put(getTimeoffsFail("Creation failed!"))
    if (errors) {
      actions.setErrors(errors)

    }
    if (errors?.non_field_errors)
      toast.error(errors.non_field_errors[0]);
    else
      toast.error("Something went wrong!");
  }
}

function* editTimeoff({ payload: { id, timeoff, actions } }) {
  try {
    yield call(editTimeoffAPI, id, timeoff)
    toast.success("Timeoff Updated!");
    yield put(getTimeoffs(true));

  } catch ({ errors }) {
    yield put(getTimeoffsFail("Creation failed!"))
    if (errors && actions) {
      actions.setErrors(errors)

    }
    if (errors?.non_field_errors)
      toast.error(errors.non_field_errors[0]);
    else
      toast.error("Something went wrong!");
  }
}

function* deleteTimeoff({ payload: { timeoff } }) {
  try {
    yield call(deleteTimeoffAPI, timeoff.id);
    put(deleteTimeoffModal())
    yield put(getTimeoffs(true));
    toast.warn("Timeoff Deleted!");
  } catch (error) {
    yield put(getTimeoffsFail(""))
    toast.error("Something went wrong!");
  }
}

function* timeoffSaga() {
  yield takeEvery(GET_TIMEOFFS, fetchTimeoffs);
  yield takeEvery(DELETE_TIMEOFF, deleteTimeoff);
  yield takeEvery(ADD_TIMEOFF, addTimeoff);
  yield takeEvery(EDIT_TIMEOFF, editTimeoff);
}

export default timeoffSaga;
