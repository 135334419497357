import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';
import {
  GET_STAFFS,
  DELETE_STAFF,
  ADD_STAFF,
  EDIT_STAFF
} from "./actionTypes";

import {
  getStaffsSuccess,
  getStaffs,
  getStaffsFail,
  deleteStaffModal
} from "./actions";

import {
  getStaffs as getStaffsAPI,
  deleteStaff as deleteStaffAPI,
  addStaff as addStaffAPI,
  editStaff as editStaffAPI,
} from "api/v1";
import { getTenant } from "store/actions";


function* fetchStaffs({ payload: { params } }) {
  try {
    const response = yield call(getStaffsAPI, params);
    yield put(getStaffsSuccess(response));
  } catch (error) {
    yield put(getStaffsFail("Something went wrong!"))
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* addStaff({ payload: { staff, actions } }) {
  try {
    yield call(addStaffAPI, staff)
    toast.success("Staff Created!");
    yield put(getStaffs(true));
    yield put(getTenant())
    actions.resetForm()

  } catch (error) {
    yield put(getStaffsFail("Creation failed!"))
    let { errors } = error

    if (errors) {
      let { user } = errors
      actions.setErrors({ ...errors, ...user })

    }
    if (error == 'Forbidden')
      yield put(getTenant())
    else
      toast.error("Something went wrong")
  }
}

function* editStaff({ payload: { id, staff, actions } }) {
  try {
    yield call(editStaffAPI, id, staff)
    toast.success("Staff Updated!");
    yield put(getStaffs(true));

  } catch ({ errors }) {
    yield put(getStaffsFail("Creation failed!"))
    if (errors && actions) {
      let { user } = errors
      actions.setErrors({ ...errors, ...user })

    }
    toast.error("Something went wrong!");
  }
}

function* deleteStaff({ payload: { staff } }) {
  try {
    yield call(deleteStaffAPI, staff.id);
    put(deleteStaffModal())
    yield put(getStaffs(true));
    yield put(getTenant())
    toast.warn("Staff Deleted!");
  } catch (error) {
    yield put(getStaffsFail(""))
    toast.error("Something went wrong!");
  }
}

function* staffSaga() {
  yield takeEvery(GET_STAFFS, fetchStaffs);
  yield takeEvery(DELETE_STAFF, deleteStaff);
  yield takeEvery(ADD_STAFF, addStaff);
  yield takeEvery(EDIT_STAFF, editStaff);
}

export default staffSaga;
