import {
  GET_PRODUCTS,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAIL,
  DELETE_PRODUCT,
  ADD_PRODUCT,
  EDIT_PRODUCT
} from "./actionTypes"

export const getProducts = (loading = false, params = {}) => ({
  type: GET_PRODUCTS,
  payload: { loading, params }
})

export const getProductsSuccess = products => ({
  type: GET_PRODUCTS_SUCCESS,
  payload: products,
})

export const getProductsFail = error => ({
  type: GET_PRODUCTS_FAIL,
  payload: error,
})

export const deleteProduct = product => ({
  type: DELETE_PRODUCT,
  payload: { product }
})

export const addProduct = (product, actions) => {
  return {
    type: ADD_PRODUCT,
    payload: { product, actions },
  }
}

export const editProduct = (id, product, actions) => {
  return {
    type: EDIT_PRODUCT,
    payload: { id, product, actions },
  }
}

export const newProductModal = () => ({
  type: 'NEW_PRODUCT_MODAL'
})

export const deleteProductModal = () => ({
  type: 'DELETE_PRODUCT_MODAL'
})

export const editProductModal = () => ({
  type: 'EDIT_PRODUCT_MODAL'
})
