import {
  GET_TASKS_FAIL,
  GET_TASKS_SUCCESS,
  GET_TASKS,
  ADD_TASK,
  EDIT_TASK,
  DELETE_TASK,
} from "./actionTypes";

const INIT_STATE = {
  taskWithPagy: {
    total: 0,
    page: 1,
    page_size: 10,
    results: []
  },
  new: false,
  edit: false,
  delete: false,
  loadingImages: false,
  loading: false,
  images: [],
  taskDetail: null
};

const tasks = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'GET_TASK':
      return {
        ...state,
        taskDetail: null,
        new: false,
        edit: false,
        delete: false,
        loading: action.payload.loading
      };

    case GET_TASKS:
      return {
        ...state,
        new: false,
        edit: false,
        delete: false,
        loading: action.payload.loading
      };

    case 'SET_TASK_DETAIL':
      return {
        ...state,
        loading: false,
        taskDetail: action.payload
      };

    case GET_TASKS_SUCCESS:
      return {
        ...state,
        loading: false,
        taskWithPagy: action.payload,
      };

    case ADD_TASK:
      return {
        ...state,
        loading: true
      };

    case EDIT_TASK:
      return {
        ...state,
        loading: true
      };

    case 'DELETE_TASK_IMAGE':
      return {
        ...state,
        loadingImages: true
      };

    case GET_TASKS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case 'GET_TASK_IMAGES':
      return {
        ...state,
        images: [],
        loadingImages: true
      };

    case 'SET_TASK_IMAGES':
      return {
        ...state,
        images: action.payload,
        loadingImages: false
      };

    case "NEW_TASK_MODAL":
      return {
        ...state,
        new: !state.new
      };

    case "DELETE_TASK_MODAL":
      return {
        ...state,
        delete: !state.delete
      };

    case "EDIT_TASK_MODAL":
      return {
        ...state,
        edit: !state.edit
      };
    default:
      return state;
  }
};

export default tasks;
