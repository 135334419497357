import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import * as moment from "moment";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row
} from "reactstrap";
import { Menu, MenuItem } from '@szhsin/react-menu';

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../components/Common/DeleteModal";
import {
  getPayrates as onGetPayrates,
  deletePayrate as onDeletePayrate,
  newPayrateModal,
  deletePayrateModal,
  editPayrateModal
} from "store/payrates/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setLoader, usFormat } from "utils/common";
import Create from './Create'
import Edit from './Edit'

const Payrates = props => {
  const dispatch = useDispatch();

  const { payrateWithPagy, loading, deleteModal } = useSelector(state => ({
    payrateWithPagy: state.payrates.payrateWithPagy,
    loading: state.payrates.loading,
    deleteModal: state.payrates.delete,
  }));
  let { results: payrates } = payrateWithPagy

  const [payrate, setPayrate] = useState(null);
  const [searchParams, setSearchParams] = useState({
    search: '',
  })

  //pagination customization
  const pageOptions = {
    sizePerPage: payrateWithPagy.page_size,
    totalSize: payrateWithPagy.total,
    page: payrateWithPagy.page,
    custom: true,
  };

  const COLS = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      text: "#",
      dataField: "index",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row, rowIndex) => <>{rowIndex + 1}</>,
    },
    {
      text: "Member Name",
      dataField: "user",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{row.user.profile.first_name} {row.user.profile.last_name}</p>
        </>
      ),
    },
    {
      dataField: "user",
      text: "Member Type",
      sort: true,
      formatter: (cellContent, row) => <b><i>{`${row.user.type}`}</i></b>
    },
    {
      dataField: "rate",
      text: "Rate",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => row.rate,
    },
    {
      dataField: "menu",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, payrate) => (
        <Menu menuButton={<i className="mdi mdi-dots-horizontal font-size-18" />} transition>
          <MenuItem
            onClick={() => handleEditPayrate(payrate)}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => onClickDelete(payrate)}
          >
            Delete
          </MenuItem>
        </Menu>
      ),
    },
  ];


  var node = useRef();

  //delete payrate

  const onClickDelete = (payrate) => {
    setPayrate(payrate);
    dispatch(deletePayrateModal())
  };

  const handleDeletePayrate = () => {
    dispatch(onDeletePayrate(payrate))
  }

  const { SearchBar } = Search;

  function init() {
    dispatch(onGetPayrates(true));
  }

  useEffect(() => {
    init()
  }, []);

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page }) => {
    if (type == "pagination")
      dispatch(onGetPayrates(true, { page, ...searchParams }));
  };

  const handleCreatePayrate = () => {
    dispatch(newPayrateModal())
  };

  const handleEditPayrate = payrate => {
    setPayrate(payrate)
    dispatch(editPayrateModal())
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  let handleSearch = (key, value) => {
    let _searchParams = { ...searchParams, [key]: value }
    setSearchParams(_searchParams)
    dispatch(onGetPayrates(false, _searchParams));
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeletePayrate}
        onCloseClick={() => dispatch(deletePayrateModal())}
      />

      <Create />
      <Edit payrate={payrate} />

      <div className="page-content">
        <MetaTags>
          <title>Payrates</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Payrates" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={COLS}
                    data={payrates}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <ToolkitProvider
                          keyField="id"
                          data={payrates || []}
                          columns={COLS}
                          bootstrap4
                          search
                        >
                          {toolkitProps => {
                            return (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col sm="4">
                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          searchText={searchParams.search}
                                          onSearch={text => handleSearch("search", text)} />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm="8">
                                    <div className="text-sm-end">
                                      <Button
                                        type="button"
                                        color="success"
                                        className="btn-rounded  mb-2 me-2"
                                        onClick={handleCreatePayrate}
                                      >
                                        <i className="mdi mdi-plus me-1" />
                                        New payrate
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        noDataIndication={"No Data found"}
                                        defaultSorted={defaultSorted}
                                        classes={"table align-middle table-nowrap"}
                                        keyField="id"
                                        remote={{ search: true, pagination: true }}
                                        {...toolkitProps.baseProps}
                                        onTableChange={handleTableChange}
                                        {...paginationTableProps}
                                        ref={node}
                                      />
                                    </div>

                                  </Col>
                                </Row>
                                <Row className="align-items-md-center mt-30">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )
                          }}
                        </ToolkitProvider>
                      )
                    }}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Payrates.propTypes = {
  payrates: PropTypes.array,
  onGetPayrates: PropTypes.func,
  onAddNewPayrate: PropTypes.func,
  onDeletePayrate: PropTypes.func,
  onUpdatePayrate: PropTypes.func,
};

export default Payrates;
