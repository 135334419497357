import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import {
  Container
} from "reactstrap";

//redux
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb";
import { toast } from "react-toastify";
import './subscription.scss'
import { setTabBarLoader } from "utils/common";
import Plan from "./Plan";
import Loader from "common/Loader";
import { getPlans } from "api/v1";
import Status from "./Status";
import { useDispatch, useSelector } from "react-redux";
import { getTenant } from "store/actions";

const Subscription = () => {
  const { tenant: company, loading: companyLoading } = useSelector(state => ({
    loading: state.tenants.loading,
    tenant: state.tenants.tenant
  }));

  const [loading, setLoading] = useState(true)
  const [plans, setPlans] = useState([])
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTenant());
  }, [])

  useEffect(() => {
    setTabBarLoader(loading || companyLoading)
  }, [loading, companyLoading]) //loading

  async function loadPlans() {
    setLoading(true)
    try {
      let _plans = await getPlans()
      setPlans([_plans])
    } catch (error) {
      toast.error("Something went wrong loading plans")
    }
    setLoading(false)
  }

  useEffect(() => {
    loadPlans()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Subscription | AllPromana</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Settings" breadcrumbItem="Subscription" />

          {
            (loading || !company) ? <Loader message="Loading Plans" />
              : <section className="pricing-table">
                <div className="row justify-content-md-center">
                  {
                    plans.map((_plan, _index) => <Plan tenant={company} isActive={company.is_subscription_setup} plan={_plan} key={`plan-${_index.toString()}`} />)

                  }

                  <Status settings={company} />
                </div>
              </section>
          }
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Subscription);
