import {
  GET_STAFFS,
  GET_STAFFS_SUCCESS,
  GET_STAFFS_FAIL,
  DELETE_STAFF,
  ADD_STAFF,
  EDIT_STAFF
} from "./actionTypes"

export const getStaffs = (loading = false, params = {}) => ({
  type: GET_STAFFS,
  payload: { loading, params }
})

export const getStaffsSuccess = staffs => ({
  type: GET_STAFFS_SUCCESS,
  payload: staffs,
})

export const getStaffsFail = error => ({
  type: GET_STAFFS_FAIL,
  payload: error,
})

export const deleteStaff = staff => ({
  type: DELETE_STAFF,
  payload: { staff }
})

export const addStaff = (staff, actions) => {
  return {
    type: ADD_STAFF,
    payload: { staff, actions },
  }
}

export const editStaff = (id, staff, actions) => {
  return {
    type: EDIT_STAFF,
    payload: { id, staff, actions },
  }
}

export const newStaffModal = () => ({
  type: 'NEW_STAFF_MODAL'
})

export const deleteStaffModal = () => ({
  type: 'DELETE_STAFF_MODAL'
})

export const editStaffModal = () => ({
  type: 'EDIT_STAFF_MODAL'
})
