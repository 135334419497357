import { useDispatch } from 'react-redux';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import Swal from 'sweetalert2'
import CheckoutForm from './components/CheckoutForm';
import { toast } from 'react-toastify';
import { getTenant } from "store/actions";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Loader from 'common/Loader';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function Stripe({ price, setPrice }) {
  const dispatch = useDispatch();
  let show = !!price

  const onComplete = () => {
    Swal.fire({
      title: 'Processing...',
      html: 'Please wait we are enabling subscription.',
      timer: 5000,
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      },
    }).finally(() => {
      setPrice(null)
      dispatch(getTenant())
      toast.success('Your subscription is active now')
    })
  }

  return (
    <Modal isOpen={show} toggle={() => setPrice(null)} centered={true}>
      <ModalHeader tag="h4" toggle={() => setPrice(null)}>
        Payment Form
      </ModalHeader>

      <ModalBody className="py-3">
        {
          price ?
          <Elements stripe={stripePromise} options={{
            amount: price.unit_amount * 100,
            currency: price.currency,
            setup_future_usage: 'off_session',
            mode: 'subscription',
            payment_method_types: ['card']
          }}>
            <CheckoutForm
              price={price}
              onComplete={onComplete}
            />
          </Elements>
          : <Loader message='Closing Subscription' />
        }
      </ModalBody>
    </Modal>
  )
}

export default Stripe
