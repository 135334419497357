import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";

import Board from './Board'

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import {
  getTask
} from "store/actions";

import DeleteModal from "../../components/Common/DeleteModal";
import {
  getTimesheets as onGetTimesheets,
  deleteTimesheet as onDeleteTimesheet,
  newTimesheetModal,
  deleteTimesheetModal,
  editTimesheetModal
} from "store/timesheets/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setLoader } from "utils/common";
import Create from './Create'
import Edit from './Edit'
import Filters from "./Filters";

const Timesheets = props => {
  const dispatch = useDispatch();

  const {
    match: { params },
  } = props;


  const { timesheetWithPagy, loading, deleteModal, taskDetail } = useSelector(state => ({
    timesheetWithPagy: state.timesheets.timesheetWithPagy,
    loading: state.timesheets.loading,
    deleteModal: state.timesheets.delete,
    taskDetail: state.tasks.taskDetail,
  }));
  let { results = [] } = timesheetWithPagy

  const [timesheet, setTimesheet] = useState(null);
  const [timesheets, setTimesheets] = useState([]);
  const [timesheetsHash, setTimesheetHash] = useState({})
  const [searchParams, setSearchParams] = useState({
    from_date: '',
    to_date: '',
    full_name: ''
  })

  let pagination = {
    sizePerPage: timesheetWithPagy.page_size,
    totalSize: timesheetWithPagy.total,
    page: timesheetWithPagy.page,
    is_next: timesheetWithPagy?.links?.next != null
  }

  useEffect(() => {
    let { page } = pagination
    if (page == 1)
      setTimesheets(results)
    else if (results.length > 0)
      setTimesheets([...timesheets, ...results])
  }, [results]);
  //delete timesheet

  useEffect(() => {
    let { page } = pagination
    let _defaultHash = {}
    let _timesheets = []
    if (page == 1) {
      _timesheets = results
    }
    else if (results.length > 0) {
      _timesheets = [...timesheets, ...results]
    }

    _timesheets.forEach(_timesheet => {
      let _key = `${_timesheet.user.type}-${_timesheet.user.id}`
      if (_defaultHash[_key])
        _defaultHash[_key].push(_timesheet)
      else
        _defaultHash[_key] = [_timesheet]
    })
    setTimesheetHash(_defaultHash)
    setTimesheets(_timesheets)

  }, [results]);

  const onClickDelete = (timesheet) => {
    setTimesheet(timesheet);
    dispatch(deleteTimesheetModal())
  };

  const handleDeleteTimesheet = () => {
    dispatch(onDeleteTimesheet(params.id, params.task_id, timesheet))
  }

  useEffect(() => {
    dispatch(getTask(params.id, true, params.task_id, props.history))
  }, [])

  function init() {
    dispatch(onGetTimesheets(params.id, params.task_id, true));
  }

  useEffect(() => {
    init()
  }, []);

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page }) => {
    if (type == "pagination")
      dispatch(onGetTimesheets(params.id, params.task_id, true, { page, ...searchParams }));
  };

  const handleCreateTimesheet = () => {
    dispatch(newTimesheetModal())
  };

  const handleEditTimesheet = timesheet => {
    setTimesheet(timesheet)
    dispatch(editTimesheetModal())
  };

  function loadMore() {
    handleTableChange("pagination", { page: pagination.page + 1 })
  }

  let { is_next } = pagination

  let handleSearchChange = (e) => {
    setSearchParams({ ...searchParams, [e.target.name]: e.target.value })
  }

  let handleSearch = () => {
    dispatch(onGetTimesheets(params.id, params.task_id, false, searchParams));
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteTimesheet}
        onCloseClick={() => dispatch(deleteTimesheetModal())}
      />

      <Create projectId={params.id} taskId={params.task_id} />
      <Edit projectId={params.id} taskId={params.task_id} timesheet={timesheet} />

      <div className={props.component ? "" : "page-content"}>
        <MetaTags>
          <title>Timesheets</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs back={true} breadcrumbItem="Timesheets" />

          <Card>
            <CardBody>
              <Row>
                <Col xs="12">
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col sm="4">
                      </Col>
                      <Col sm="8">
                        {
                          taskDetail?.status != 'Processing' &&
                          <div className="text-sm-end">
                            <Button
                              type="button"
                              color="success"
                              className="btn-rounded  mb-2 me-2"
                              onClick={handleCreateTimesheet}
                            >
                              <i className="mdi mdi-plus me-1" />
                              New Timesheet
                            </Button>
                          </div>
                        }
                      </Col>
                    </Row>

                  </React.Fragment>
                </Col>
              </Row>


              <Filters
                params={searchParams}
                handleSearch={handleSearch}
                handleSearchChange={handleSearchChange}
              />

              <Board
                handleEditTimesheet={handleEditTimesheet}
                onClickDelete={onClickDelete}
                cards={timesheetsHash}
              />
            </CardBody>
          </Card>
          {
            is_next &&
            <Row>
              <Col xs="12">
                <div className="text-center my-3">
                  <div style={{ cursor: "pointer" }} onClick={() => loadMore()} className="text-success">
                    <i className="bx bx-hourglass bx-spin me-2" />
                    Load more
                  </div>
                </div>
              </Col>
            </Row>
          }
        </Container>
      </div>
    </React.Fragment>
  );
};

Timesheets.propTypes = {
  timesheets: PropTypes.array,
  onGetTimesheets: PropTypes.func,
  onAddNewTimesheet: PropTypes.func,
  onDeleteTimesheet: PropTypes.func,
  onUpdateTimesheet: PropTypes.func,
};

export default Timesheets;
