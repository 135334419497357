import MetaTags from "react-meta-tags";
import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import { Link, withRouter } from "react-router-dom"
import Breadcrumb from "../../components/Common/Breadcrumb";
import './index.scss'

const Settings = props => {

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Settings | Contractor</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb breadcrumbItem="Settings" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xs="12" sm="6" md="4" lg="3" className="mb-4">
                      <Link to="/settings/company/" style={{ textDecoration: 'none', color: 'black' }}>
                        <div className="cursor-pointer setting-tab border rounded">
                          <i className="far fa-building card-icon mx-4"></i>

                          <div className="mt-4 px-3">
                            <p>Company Settings</p>
                            <p className="mt-2">Update your company information like phone, name and address</p>
                          </div>
                        </div>
                      </Link>
                    </Col>

                    <Col xs="12" sm="6" md="4" lg="3" className="mb-4">
                      <Link to="/settings/password/" style={{ textDecoration: 'none', color: 'black' }}>
                        <div className="cursor-pointer setting-tab border rounded">
                          <i className="fas fa-cog card-icon mx-4"></i>

                          <div className="mt-4 px-3">
                            <p>Security</p>
                            <p className="mt-2">Change password to make your connection secure</p>
                          </div>
                        </div>
                      </Link>
                    </Col>
                    <Col xs="12" sm="6" md="4" lg="3" className="mb-4">
                      <Link to="/settings/profile/" style={{ textDecoration: 'none', color: 'black' }}>
                        <div className="cursor-pointer setting-tab border rounded">
                          <i className="far fa-user card-icon mx-4"></i>

                          <div className="mt-4 px-3">
                            <p>Profile</p>
                            <p className="mt-2">Manage your profile details, name, phone and avatar</p>
                          </div>
                        </div>
                      </Link>
                    </Col>

                    <Col xs="12" sm="6" md="4" lg="3" className="mb-4">
                      <Link to="/settings/policy/" style={{ textDecoration: 'none', color: 'black' }}>
                        <div className="cursor-pointer setting-tab border rounded">
                          <i className="far fa-file card-icon mx-4"></i>

                          <div className="mt-4 px-3">
                            <p>Policy</p>
                            <p className="mt-2">Manage your company's policy details</p>
                          </div>
                        </div>
                      </Link>
                    </Col>

                    <Col xs="12" sm="6" md="4" lg="3" className="mb-4">
                      <Link to="/settings/quickbooks/" style={{ textDecoration: 'none', color: 'black' }}>
                        <div className="cursor-pointer setting-tab border rounded">
                          <img src="/assets/icons/qb.png" className="mx-4" />

                          <div className="mt-4 px-3">
                            <p>Quickbooks</p>
                            <p className="mt-2">Manage your Quickbook's details here</p>
                          </div>
                        </div>
                      </Link>
                    </Col>

                    <Col xs="12" sm="6" md="4" lg="3" className="mb-4">
                      <Link to="/settings/subscriptions/" style={{ textDecoration: 'none', color: 'black' }}>
                        <div className="cursor-pointer setting-tab border rounded">
                          <img src="/assets/icons/stripe.webp" className="mx-4" />

                          <div className="mt-4 px-3">
                            <p>Stripe</p>
                            <p className="mt-2">Manage your Company's subscription here</p>
                          </div>
                        </div>
                      </Link>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Settings);
