import React, { useState, Fragment, useEffect } from "react";
import {
  Col,
  Row,
  Input,
  FormFeedback,
  Label,
} from "reactstrap";
import AsyncSelect from 'react-select/async';
import { getCustomers, getProjects, getExpenses, getTasks, getProduct, getProducts } from "api/v1";
import { v4 as uuid } from 'uuid';
import { usFormat } from "utils/common";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState, RichUtils } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Invoices from 'pages/Invoices/index'

const defaultIds = {
  projectId: uuid(),
  expenseId: uuid(),
  serviceId: uuid(),
}

const customStyles = { multiValueRemove: (base) => ({ ...base, display: 'none' }) }

function getDifference(array1, array2) {
  return array1.filter(object1 => {
    return !array2.some(object2 => {
      return object1.id === object2.id;
    });
  });
}

function index({ validation, target }) {
  let [uuids, setUuids] = useState(defaultIds)
  const [editorState, setEditorState] = useState()
  const prevInvoicesTotal = validation.values.prev_invoices.reduce((a, b) => a + parseFloat(b.total_amount), 0)

  useEffect(() => {
    if (target?.notes) {
      const contentBlock = htmlToDraft(target.notes);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const _editorState = EditorState.createWithContent(contentState);
        setEditorState(_editorState)
      }
    }
    else
      setEditorState(EditorState.createEmpty())

  }, [])

  function onEditorStateChange(_editorState) {
    setEditorState(_editorState)
  }

  useEffect(() => {
    if (editorState)
      validation.setFieldValue('notes', draftToHtml(convertToRaw(editorState.getCurrentContent())))
  }, [editorState])

  function handleReturn(e) {
    if (e.shiftKey || e.key == "Enter") {
      setEditorState(RichUtils.insertSoftNewline(editorState));
      return 'handled';
    }
    return 'not-handled';
  }

  let loadCustomers = (search) => {
    return getCustomers({ search, no_paginate: 1 })
      .then((results = []) => {
        return results
      })
      .catch(({ message }) => {
        if (message !== 'request-cancel') {
          toast.error(message);
        }
      })
  }

  let loadProjects = (search) => {
    if (!validation.values.customer)
      return

    return getProjects({ search, no_paginate: 1, customer_id: validation.values.customer.id })
      .then((results = []) => {
        return results
      })
      .catch(({ message }) => {
        if (message !== 'request-cancel') {
          toast.error(message);
        }
      })
  }

  let loadExpenses = (search) => {
    if (!validation.values.project)
      return

    return getExpenses(validation.values.project.id, { search, no_paginate: 1 })
      .then((results = []) => {
        return results
      })
      .catch(({ message }) => {
        if (message !== 'request-cancel') {
          toast.error(message);
        }
      })
  }

  let loadServices = (search) => {
    if (!validation.values.project)
      return

    return getTasks(validation.values.project.id, { search, no_paginate: 1, unpaid_hours: true })
      .then((results = []) => {
        return results
      })
      .catch(({ message }) => {
        if (message !== 'request-cancel') {
          toast.error(message);
        }
      })
  }


  function _expensesTotal() {
    let __expensesTotal = 0

    validation.values.expenses.forEach(_expense => {
      if (_expense.quantity && _expense.unit_cost)
        __expensesTotal += (_expense.quantity * _expense.unit_cost)
    })

    return __expensesTotal
  }

  function _servicesTotal() {
    let __servicesTotal = 0

    validation.values.services.forEach(_service => {
      if (_service.total_hours && _service.rate_per_hour)
        __servicesTotal += (_service.total_hours * _service.rate_per_hour)
    })

    return __servicesTotal
  }

  function removeExpense(_expense, index) {
    // populate deleted here for only those who has id
    if (_expense.hasOwnProperty("id")) {
      let _deletedExpenses = [...validation.values._deleted_expenses]
      _deletedExpenses.push({ ..._expense, delete: true })

      validation.setFieldValue("_deleted_expenses", _deletedExpenses)
    }

    if (_expense.hasOwnProperty("expense"))
      validation.setFieldValue("remote_expenses", validation.values.remote_expenses.filter(_item => _item.id != _expense.expense))

    validation.setFieldValue("expenses", validation.values.expenses.filter((_, _index) => _index != index))
  }

  function removeService(_service, index) {
    // populate deleted here for only those who has id
    if (_service.hasOwnProperty("id")) {
      let _deletedServices = [...validation.values._deleted_services]
      _deletedServices.push({ ..._service, delete: true })

      validation.setFieldValue("_deleted_services", _deletedServices)
    }

    if (_service.hasOwnProperty("service"))
      validation.setFieldValue("remote_services", validation.values.remote_services.filter(_item => _item.id != _service.service))

    validation.setFieldValue("services", validation.values.services.filter((_, _index) => _index != index))
  }

  function handleExpenseChange(event, index) {
    validation.setFieldValue('expenses', validation.values.expenses.map((_item, _index) => ({
      ..._item, [event.target.name]: index == _index ? (['unit_cost', 'quantity'].includes(event.target.name) ? parseFloat(event.target.value) : event.target.value || '') : _item[event.target.name]
    })))
  }

  function handleServiceChange(event, index) {
    validation.setFieldValue('services', validation.values.services.map((_item, _index) => ({
      ..._item, [event.target.name]: index == _index ? (["description", "task"].includes(event.target.name) ? event.target.value : parseFloat(event.target.value) || '') : _item[event.target.name]
    })))
  }

  function addExpense() {
    let _items = [...validation.values.expenses]
    _items.push({ item: '', quantity: '', unit_cost: '', product: null, location: null, type: '' })

    validation.setFieldValue('expenses', _items)
  }

  let loadProducts = (search) => {
    return getProducts({ search, no_paginate: 1 })
      .then((results = []) => {
        return results
      })
      .catch(({ message }) => {
        if (message !== 'request-cancel') {
          toast.error(message);
        }
      })
  }

  let loadLocations = (productId) => {
    return getProduct(productId)
      .then(_product => {
        return _product.stock.map(_stock => ({ available_stock: _stock.available_stock, ..._stock.location })) || []
      })
      .catch(({ message }) => {
        if (message !== 'request-cancel') {
          toast.error(message);
        }
      })
  }

  function addService() {
    let _items = [...validation.values.services]
    _items.push({
      task: '',
      // description: '',
      total_hours: '',
      rate_per_hour: ''
    })

    validation.setFieldValue('services', _items)
  }

  function selectInvoice(_invoice, isSelect) {
    if (isSelect)
      validation.setFieldValue('prev_invoices', [_invoice, ...validation.values.prev_invoices])
    else
      validation.setFieldValue('prev_invoices', validation.values.prev_invoices.filter(__invoice => __invoice.id != _invoice.id))

  }

  let { step } = validation.values

  return (
    <Row>
      {
        step == 1 &&
        <Col xs="12" className="mb-3">
          <Label className="form-label">Customer</Label>
          <AsyncSelect
            defaultOptions
            placeholder="Select Customer"
            required
            value={validation.values.customer}
            getOptionLabel={({ first_name, last_name }) => `${first_name} ${last_name}`}
            getOptionValue={({ id }) => id}
            loadOptions={loadCustomers}
            onChange={(_customer) => {
              validation.setFieldValue('customer', _customer)
              validation.setFieldValue('project', null)
              setUuids({ ...uuids, projectId: uuid() })
            }}

          />
          {validation.touched.customer && validation.errors.customer ? (
            <span className="text-danger font-size-11">{validation.errors.customer}</span>
          ) : null}
        </Col>
      }

      {
        step == 2 &&
        <Fragment>
          <Col xs="12" className="mb-3">
            <Label className="form-label">Project</Label>
            <AsyncSelect
              key={uuids.projectId}
              defaultOptions
              placeholder="Select Project"
              required
              value={validation.values.project}
              getOptionLabel={({ project_name }) => `${project_name}`}
              getOptionValue={({ id }) => id}
              loadOptions={loadProjects}
              onChange={(_project) => {
                validation.setFieldValue('project', _project)
                validation.setFieldValue('prev_invoices', [])
                validation.setFieldValue('remote_expenses', [])
                validation.setFieldValue('remote_services', [])
                setUuids({ ...uuids, expenseId: uuid(), serviceId: uuid() })
              }}

            />
            {validation.touched.project && validation.errors.project ? (
              <span className="text-danger font-size-11">{validation.errors.project}</span>
            ) : null}
          </Col>

          {
            validation.values.project &&
            <Fragment>
              <Invoices
                invoiceId={target?.id}
                prevInvoices={validation.values.prev_invoices?.map(_prev_invoice => _prev_invoice.id)}
                onInvoiceSelect={selectInvoice}
                projectId={validation.values.project.id}
              />

              <h1 className="text-primary text-center mt-2">{usFormat(prevInvoicesTotal)}</h1>
            </Fragment>
          }
        </Fragment>
      }

      {
        step == 3 &&
        <React.Fragment>
          <Col xs="6" className="mb-3">
            <Label className="form-label">Payment Method</Label>
            <Input
              name="payment_method"
              type="select"
              className="form-select"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.payment_method || ""}
              invalid={
                validation.touched.payment_method && validation.errors.payment_method ? true : false
              }
            >
              <option value=""></option>
              <option value="Bank Transfer">Bank Transfer</option>
              <option value="Cash">Cash</option>
              <option value="Card">Card</option>
              <option value="Cheque">Cheque</option>

            </Input>
            {validation.touched.payment_method && validation.errors.payment_method ? (
              <FormFeedback type="invalid">{validation.errors.payment_method}</FormFeedback>
            ) : null}
          </Col>

          <Col xs="6" className="mb-3">
            <Label className="form-label">Status</Label>
            <Input
              name="status"
              type="select"
              className="form-select"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.status || ""}
              invalid={
                validation.touched.status && validation.errors.status ? true : false
              }
            >
              <option value=""></option>
              <option value="New">New</option>
              <option value="Sent">Sent</option>
              <option value="Returned">Returned</option>
              <option value="Canceled">Canceled</option>
              <option value="Paid">Paid</option>
              <option value="Credited">Credited</option>
              <option value="Uncollectable">Uncollectable</option>

            </Input>
            {validation.touched.status && validation.errors.status ? (
              <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
            ) : null}
          </Col>

          <Col xs="6" className="mb-3">
            <Label className="form-label">Due Date</Label>
            <Input
              name="due_date"
              type="date"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.due_date || ""}
              invalid={
                validation.touched.due_date && validation.errors.due_date ? true : false
              }
            />
            {validation.touched.due_date && validation.errors.due_date ? (
              <FormFeedback type="invalid">{validation.errors.due_date}</FormFeedback>
            ) : null}
          </Col>

          <Col xs="6" className="mb-3">
            <Label className="form-label">Date Sent</Label>
            <Input
              name="date_sent"
              type="date"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.date_sent || ""}
              invalid={
                validation.touched.date_sent && validation.errors.date_sent ? true : false
              }
            />
            {validation.touched.date_sent && validation.errors.date_sent ? (
              <FormFeedback type="invalid">{validation.errors.date_sent}</FormFeedback>
            ) : null}
          </Col>

          <Col xs="12" className="mb-3">
            <Label className="form-label">Notes</Label>
            <Editor
              handleReturn={handleReturn}
              editorState={editorState}
              onEditorStateChange={onEditorStateChange}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
            />
            {validation.touched.notes && validation.errors.notes ? (
              <FormFeedback type="invalid">{validation.errors.notes}</FormFeedback>
            ) : null}
          </Col>
        </React.Fragment>
      }

      {
        step == 4 &&
        <div className="w-80">
          <Col xs="12" className="mb-3">
            <AsyncSelect
              key={uuids.serviceId}
              defaultOptions
              placeholder="Select Existing Service"
              required
              isMulti={true}
              styles={customStyles}
              isClearable={false}
              value={validation.values.remote_services}
              getOptionLabel={({ task }) => `${task}`}
              getOptionValue={({ id }) => id}
              loadOptions={loadServices}
              onChange={(_remote_services) => {
                let _oldServices = [...validation.values.remote_services]

                const difference = [
                  ...getDifference(_oldServices, _remote_services),
                  ...getDifference(_remote_services, _oldServices)
                ];

                if (_oldServices.length > _remote_services.length)
                  validation.setFieldValue("services", validation.values.services.filter(_item => _item.service != difference[0].id))
                else {
                  validation.setFieldValue("services", [...validation.values.services, ...difference.map(_item => ({
                    service: _item.id,
                    task: _item.task,
                    // description: _item.description,
                    total_hours: validation.values._deleted_services.find(_service => _service.service == _item.id)?.total_hours || _item.unpaid_hours || 0,
                    rate_per_hour: validation.values._deleted_services.find(_service => _service.service == _item.id)?.rate_per_hour || '',
                  }))])
                }

                validation.setFieldValue('remote_services', _remote_services)
              }}

            />
          </Col>

          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Total Hours</th>
                <th scope="col">Rate per hour</th>
                <th scope="col">Total</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {
                validation.values.services.map((_service, index) => {

                  let errors = validation.errors.hasOwnProperty('services') ? validation.errors.services[index] : {}
                  let touched = validation.touched.hasOwnProperty('services') ? validation.touched.services[index] : {}
                  let values = _service

                  return (
                    <tr key={`services-${index}`}>
                      <th scope="row">{index + 1}</th>
                      <td>
                        <Input
                          name="task"
                          disabled={_service.service != null}
                          type="text"
                          onChange={e => handleServiceChange(e, index)}
                          onBlur={validation.handleBlur}
                          value={values?.task || ""}
                          invalid={
                            touched?.task && errors?.task ? true : false
                          }
                        />
                        {touched?.task && errors?.task ? (
                          <FormFeedback type="invalid">{errors?.task}</FormFeedback>
                        ) : null}
                      </td>

                      {/* <td>
                        <Input
                          name="description"
                          type="text"
                          onChange={e => handleServiceChange(e, index)}
                          onBlur={validation.handleBlur}
                          value={values?.description || ""}
                          invalid={
                            touched?.description && errors?.description ? true : false
                          }
                        />
                        {touched?.description && errors?.description ? (
                          <FormFeedback type="invalid">{errors?.description}</FormFeedback>
                        ) : null}
                      </td> */}

                      <td>
                        <Input
                          name="total_hours"
                          type="number"
                          onChange={e => handleServiceChange(e, index)}
                          onBlur={validation.handleBlur}
                          value={values?.total_hours || ""}
                          invalid={
                            touched?.total_hours && errors?.total_hours ? true : false
                          }
                        />
                        {touched?.total_hours && errors?.total_hours ? (
                          <FormFeedback type="invalid">{errors?.total_hours}</FormFeedback>
                        ) : null}
                      </td>

                      <td>
                        <Input
                          name="rate_per_hour"
                          type="number"
                          onChange={e => handleServiceChange(e, index)}
                          onBlur={validation.handleBlur}
                          value={values?.rate_per_hour || ""}
                          invalid={
                            touched?.rate_per_hour && errors?.rate_per_hour ? true : false
                          }
                        />
                        {touched?.rate_per_hour && errors?.rate_per_hour ? (
                          <FormFeedback type="invalid">{errors?.rate_per_hour}</FormFeedback>
                        ) : null}
                      </td>

                      <td>
                        {
                          values && values.rate_per_hour && values.total_hours &&
                          <b className="list-inline-item font-size-18">
                            {usFormat(values.rate_per_hour * values.total_hours)}
                          </b>
                        }
                      </td>

                      <td>
                        <li onClick={() => removeService(_service, index)} className="list-inline-item font-size-18">
                          <i className="bx bxs-trash text-danger p-1" />
                        </li>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>

          <div className="text-end">
            <button
              onClick={addService}
              type="button"
              className="btn btn-secondary">
              New
            </button>
          </div>

          {typeof (validation.errors.services) == 'string' && validation.touched.remote_services && validation.errors.services ? (
            <span className="text-danger font-size-11">{validation.errors.services}</span>
          ) : null}

          {validation.errors.services && typeof (validation.errors.services) != 'string' && typeof (validation.errors.services[0]) == 'string' ? (
            <span className="text-danger font-size-11">{validation.errors.services[0]}</span>
          ) : null}

          <div className="text-end my-1 mt-3">
            <h5>Sub Total: {usFormat(_servicesTotal()) || '-'}</h5>
          </div>
        </div>
      }

      {
        step == 5 &&
        <div className="w-80">
          <Col xs="12" className="mb-3">
            <AsyncSelect
              key={uuids.expenseId}
              defaultOptions
              placeholder="Select Existing Expense"
              required
              isMulti={true}
              styles={customStyles}
              isClearable={false}
              value={validation.values.remote_expenses}
              getOptionLabel={({ item, product }) => product ? product.name : `${item || "N/A"}`}
              getOptionValue={({ id }) => id}
              loadOptions={loadExpenses}
              onChange={(_remote_expenses) => {
                let _oldExpenses = [...validation.values.remote_expenses]

                const difference = [
                  ...getDifference(_oldExpenses, _remote_expenses),
                  ...getDifference(_remote_expenses, _oldExpenses)
                ];

                if (_oldExpenses.length > _remote_expenses.length)
                  validation.setFieldValue("expenses", validation.values.expenses.filter(_item => _item.expense != difference[0].id))
                else
                  validation.setFieldValue("expenses", [...validation.values.expenses, ...difference.map(_item => ({
                    expense: _item.id,
                    item: _item.item,
                    type: _item.product ? 'stock' : 'non-stock',
                    product: _item.product || null,
                    location: _item.location || null,
                    unit_cost: parseFloat(_item.unit_cost),
                    quantity: parseFloat(_item.quantity)
                  }))])

                validation.setFieldValue('remote_expenses', _remote_expenses)
              }}
            />
          </Col>

          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Expense</th>
                <th scope="col">Total</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {
                validation.values.expenses.map((_expense, index) => {

                  let errors = validation.errors.hasOwnProperty('expenses') ? validation.errors.expenses[index] : {}
                  let touched = validation.touched.hasOwnProperty('expenses') ? validation.touched.expenses[index] : {}
                  let values = _expense

                  return (
                    <tr key={`expenses-${index}`}>
                      <th scope="row">{index + 1}</th>
                      <td className="row">
                        <div className="col-4 mb-1">
                          <Input
                            name="type"
                            disabled={_expense.expense != null}
                            type="select"
                            className="form-select"
                            onChange={e => handleExpenseChange(e, index)}
                            onBlur={validation.handleBlur}
                            value={values?.type || ""}
                            invalid={
                              touched?.type && errors?.type ? true : false
                            }
                          >
                            <option value=""></option>
                            <option value="non-stock">Non Stock</option>
                            <option value="stock">Stock</option>
                          </Input>
                          {touched?.type && errors?.type ? (
                            <FormFeedback type="invalid">{errors?.type}</FormFeedback>
                          ) : null}
                        </div>

                        {
                          values?.type == 'non-stock' &&
                          <div className="col-4 mb-1">
                            <Input
                              name="item"
                              disabled={_expense.expense != null}
                              type="text"
                              placeholder="item"
                              onChange={e => handleExpenseChange(e, index)}
                              onBlur={validation.handleBlur}
                              value={values?.item || ""}
                              invalid={
                                touched?.item && errors?.item ? true : false
                              }
                            />
                            {touched?.item && errors?.item ? (
                              <FormFeedback type="invalid">{errors?.item}</FormFeedback>
                            ) : null}
                          </div>
                        }

                        {
                          values?.type == 'stock' &&
                          <Fragment>
                            <Col xs="4">
                              <AsyncSelect
                                defaultOptions
                                placeholder="Select Product"
                                required
                                className="mb-1"
                                value={values?.product || null}
                                getOptionLabel={({ name }) => name}
                                getOptionValue={({ id }) => id}
                                loadOptions={loadProducts}
                                onChange={(_product) => {
                                  let _expenses = [...validation.values.expenses]
                                  _expenses[index] = {
                                    ..._expenses[index],
                                    product: _product,
                                    location: null,
                                    quantity: 0
                                  }
                                  validation.setFieldValue('expenses', _expenses)
                                }}

                              />
                              {touched?.product && errors?.product ? (
                                <span className="text-danger font-size-11">{errors?.product}</span>
                              ) : null}
                            </Col>

                            {
                              values?.product &&
                              <Col xs="4">
                                <AsyncSelect
                                  key={`key-${values?.product?.id}-${index.toString()}`}
                                  isSearchable={false}
                                  defaultOptions
                                  className="mb-1"
                                  placeholder="Select Location"
                                  required
                                  value={values?.location}
                                  getOptionLabel={({ location }) => location}
                                  getOptionValue={({ id }) => id}
                                  loadOptions={_ => loadLocations(values?.product.id)}
                                  onChange={(_location) => {
                                    let _expenses = [...validation.values.expenses]
                                    _expenses[index] = {
                                      ..._expenses[index],
                                      location: _location,
                                      quantity: _location.available_stock || 0
                                    }
                                    validation.setFieldValue('expenses', _expenses)
                                  }}

                                />
                                {touched?.location && errors?.location ? (
                                  <span className="text-danger font-size-11">{errors?.location}</span>
                                ) : null}
                              </Col>
                            }

                          </Fragment>
                        }

                        <div className="col-4 mb-1">
                          <Input
                            name="quantity"
                            type="number"
                            placeholder="quantity"
                            onChange={e => handleExpenseChange(e, index)}
                            onBlur={validation.handleBlur}
                            value={values?.quantity}
                            invalid={
                              touched?.quantity && errors?.quantity ? true : false
                            }
                          />
                          {touched?.quantity && errors?.quantity ? (
                            <FormFeedback type="invalid">{errors?.quantity}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="col-4 mb-1">
                          <Input
                            name="unit_cost"
                            type="number"
                            placeholder="unit cost"
                            onChange={e => handleExpenseChange(e, index)}
                            onBlur={validation.handleBlur}
                            value={values?.unit_cost}
                            invalid={
                              touched?.unit_cost && errors?.unit_cost ? true : false
                            }
                          />
                          {touched?.unit_cost && errors?.unit_cost ? (
                            <FormFeedback type="invalid">{errors?.unit_cost}</FormFeedback>
                          ) : null}
                        </div>
                      </td>

                      <td>
                        {
                          <b className="list-inline-item font-size-18">
                            {usFormat(((values.unit_cost || 0) * (values.quantity || 0)))}
                          </b>
                        }
                      </td>

                      <td>
                        <li onClick={() => removeExpense(_expense, index)} className="list-inline-item font-size-18">
                          <i className="bx bxs-trash text-danger p-1" />
                        </li>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>

          <div className="text-end">
            <button
              onClick={addExpense}
              type="button"
              className="btn btn-secondary">
              New
            </button>
          </div>

          {typeof (validation.errors.expenses) == 'string' && validation.touched.remote_expenses && validation.errors.expenses ? (
            <span className="text-danger font-size-11">{validation.errors.expenses}</span>
          ) : null}

          {validation.errors.expenses && typeof (validation.errors.expenses) != 'string' && typeof (validation.errors.expenses[0]) == 'string' ? (
            <span className="text-danger font-size-11">{validation.errors.expenses[0]}</span>
          ) : null}

          <div className="text-end my-1 mt-3">
            <h5>Sub Total: {usFormat(_expensesTotal()) || '-'}</h5>
          </div>
        </div>
      }

      {
        step == 6 &&
        <Fragment>
          <Col xs="12" className="mb-3">
            <Label className="form-label">Discount Percentage</Label>
            <Input
              name="discount_percent"
              type="number"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.discount_percent || ""}
              invalid={
                validation.touched.discount_percent && validation.errors.discount_percent ? true : false
              }
            />
            {validation.touched.discount_percent && validation.errors.discount_percent ? (
              <FormFeedback type="invalid">{validation.errors.discount_percent}</FormFeedback>
            ) : null}
          </Col>

          <div className="text-end my-2">
            {
              validation.values.prev_invoices.length > 0 &&
              <Row>
                <Col xs="6" />
                <Col xs="4">
                  <h5>Payment Received:</h5>
                </Col>
                <Col xs="2">
                  <h5>{usFormat(-1 * prevInvoicesTotal)}</h5>
                </Col>
              </Row>
            }


            <Row>
              <Col xs="6" />
              <Col xs="4">
                <h5>Sub Total:</h5>
              </Col>
              <Col xs="2">
                <h5>{usFormat(_expensesTotal() + _servicesTotal() - prevInvoicesTotal) || '-'}</h5>
              </Col>
            </Row>

            <Row>
              <Col xs="6" />
              <Col xs="4">
                <h5>Discount:</h5>
              </Col>
              <Col xs="2">
                <h5>{validation.values.discount_percent}%</h5>
              </Col>
            </Row>
          </div>
          <h1 className="text-primary text-center">{usFormat(_expensesTotal() - prevInvoicesTotal + _servicesTotal() - (_expensesTotal() - prevInvoicesTotal + _servicesTotal()) * (validation.values.discount_percent / 100)) || '-'}</h1>
        </Fragment>
      }
    </Row>
  )
}

export default index
