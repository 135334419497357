import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'
import moment from 'moment'

import { newTimeoffModal, addTimeoff } from 'store/timeoffs/actions'

function index() {
  let dispatch = useDispatch()
  const { new: modal } = useSelector((state) => state.timeoffs);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      user: null,
      from_date: '',
      to_date: '',
    },
    validationSchema: Yup.object({
      from_date: Yup.string().required("Please Enter Start Date"),
      user: Yup.mixed().required("Please Enter Member"),
    }),
    onSubmit: (values, actions) => {
      dispatch(addTimeoff({
        user: values.user.id,
        from_date: moment(values.from_date, "YYYY-MM-DD").startOf('day').format("YYYY-MM-DDTHH:mm:ss"),
        to_date: moment(values.to_date || values.from_date, "YYYY-MM-DD").endOf('day').format("YYYY-MM-DDTHH:mm:ss"),
      }, actions))
    },
  });

  useEffect(() => {
    if (modal) {
      validation.resetForm()
    }
  }, [modal])

  return (
    <React.Fragment>
      <Modal isOpen={modal} toggle={() => dispatch(newTimeoffModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(newTimeoffModal())}>
          Add Timeoff
        </ModalHeader>
        <ModalBody>
          <Form
            validation={validation}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
