import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';
import {
  GET_TIMESHEET_REPORTS,
  DELETE_TIMESHEET_REPORT,
  ADD_TIMESHEET_REPORT,
  EDIT_TIMESHEET_REPORT
} from "./actionTypes";

import {
  getTimesheetReportsSuccess,
  getTimesheetReports,
  getTimesheetReportsFail,
  deleteTimesheetReportModal,
  generateTimesheetReportModal,
  setGeneratableTimesheetReport,
  getTimesheetReportFail,
  getTimesheetReportSuccess
} from "./actions";

import {
  getTimesheetReports as getTimesheetReportsAPI,
  getTimesheetReport as getTimesheetReportAPI,
  deleteTimesheetReport as deleteTimesheetReportAPI,
  generateTimesheetReport as generateTimesheetReportAPI,
  addTimesheetReport as addTimesheetReportAPI,
  editTimesheetReport as editTimesheetReportAPI,
} from "api/v1";


function* fetchTimesheetReports({ payload: { params } }) {
  try {
    const response = yield call(getTimesheetReportsAPI, params);
    yield put(getTimesheetReportsSuccess(response));
  } catch (error) {
    yield put(getTimesheetReportsFail("Something went wrong!"))
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* fetchTimesheetReport({ payload: { id, history } }) {
  try {
    const response = yield call(getTimesheetReportAPI, id);
    yield put(getTimesheetReportSuccess(response));
  } catch (error) {
    yield put(getTimesheetReportFail("Object not found"))
    if (history) history.goBack()
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* addTimesheetReport({ payload: { timesheetReport, actions } }) {
  try {
    let _timesheetReport = yield call(addTimesheetReportAPI, timesheetReport)
    toast.success("TimesheetReport Created!");
    yield put(getTimesheetReports(true));
    actions.resetForm()
    // do here
    yield put(setGeneratableTimesheetReport(_timesheetReport))
    yield put(generateTimesheetReportModal())

  } catch ({ errors }) {
    yield put(getTimesheetReportsFail("Creation failed!"))
    if (errors) {
      actions.setErrors(errors)
    }
    if (errors?.non_field_errors)
      toast.error(errors.non_field_errors[0]);
    else
      toast.error("Something went wrong!");
  }
}

function* editTimesheetReport({ payload: { id, timesheetReport, actions } }) {
  try {
    let _timesheetReport = yield call(editTimesheetReportAPI, id, timesheetReport)
    toast.success("TimesheetReport Updated!");
    yield put(getTimesheetReports(true));
    // do here
    yield put(setGeneratableTimesheetReport(_timesheetReport))
    yield put(generateTimesheetReportModal())

  } catch ({ errors }) {
    yield put(getTimesheetReportsFail("Creation failed!"))
    if (errors) {
      actions.setErrors(errors)
    }
    if (errors?.non_field_errors)
      toast.error(errors.non_field_errors[0]);
    else
      toast.error("Something went wrong!");
  }
}

function* deleteTimesheetReport({ payload: { timesheetReport } }) {
  try {
    yield call(deleteTimesheetReportAPI, timesheetReport.id);
    yield put(deleteTimesheetReportModal())
    yield put(getTimesheetReports(true));
    toast.warn("TimesheetReport Deleted!");
  } catch (error) {
    yield put(getTimesheetReportsFail(""))
    toast.error("Something went wrong!");
  }
}

function* generateTimesheetReport({ payload: { timesheetReport } }) {
  try {
    yield call(generateTimesheetReportAPI, timesheetReport.id);
    yield put(generateTimesheetReportModal())
    yield put(getTimesheetReports(true));
    toast.success("TimesheetReport generated!");
  } catch (error) {
    yield put(getTimesheetReportsFail(""))
    toast.error("Something went wrong!");
  }
}

function* timesheetReportSaga() {
  yield takeEvery('GET_TIMESHEET_REPORT', fetchTimesheetReport);
  yield takeEvery(GET_TIMESHEET_REPORTS, fetchTimesheetReports);
  yield takeEvery(DELETE_TIMESHEET_REPORT, deleteTimesheetReport);
  yield takeEvery('GENERATE_TIMESHEET_REPORT', generateTimesheetReport);
  yield takeEvery(ADD_TIMESHEET_REPORT, addTimesheetReport);
  yield takeEvery(EDIT_TIMESHEET_REPORT, editTimesheetReport);
}

export default timesheetReportSaga;
