import {
  GET_TIMESHEETS,
  GET_TIMESHEETS_SUCCESS,
  GET_TIMESHEETS_FAIL,
  DELETE_TIMESHEET,
  ADD_TIMESHEET,
  EDIT_TIMESHEET
} from "./actionTypes"

export const getTimesheets = (projectId, taskId, loading = false, params = {}) => ({
  type: GET_TIMESHEETS,
  payload: { projectId, taskId, loading, params }
})

export const getTimesheet = (projectId, taskId, loading = false, id, history = null) => ({
  type: 'GET_TIMESHEET',
  payload: { loading, taskId, projectId, id, history }
})

export const getTimesheetsSuccess = timesheets => ({
  type: GET_TIMESHEETS_SUCCESS,
  payload: timesheets,
})

export const setTimesheetImages = images => ({
  type: 'SET_TIMESHEET_IMAGES',
  payload: images,
})

export const getTimesheetsFail = error => ({
  type: GET_TIMESHEETS_FAIL,
  payload: error,
})

export const deleteTimesheet = (projectId, taskId, timesheet) => ({
  type: DELETE_TIMESHEET,
  payload: { projectId, taskId, timesheet }
})

export const deleteTimesheetImage = (projectId, taskId, timesheetId, id) => ({
  type: 'DELETE_TIMESHEET_IMAGE',
  payload: { projectId, taskId, timesheetId, id }
})

export const addTimesheet = (projectId, taskId, timesheet, actions) => {
  return {
    type: ADD_TIMESHEET,
    payload: { projectId, taskId, timesheet, actions },
  }
}

export const editTimesheet = (projectId, taskId, id, timesheet, actions) => {
  return {
    type: EDIT_TIMESHEET,
    payload: { id, projectId, taskId, timesheet, actions },
  }
}

export const getTimesheetImages = (projectId, taskId, timesheetId) => {
  return {
    type: 'GET_TIMESHEET_IMAGES',
    payload: { projectId, taskId, timesheetId },
  }
}

export const setTimesheetDetail = timesheetDetail => {
  return ({
    type: 'SET_TIMESHEET_DETAIL',
    payload: timesheetDetail,
  })
}

export const newTimesheetModal = () => ({
  type: 'NEW_TIMESHEET_MODAL'
})

export const deleteTimesheetModal = () => ({
  type: 'DELETE_TIMESHEET_MODAL'
})

export const editTimesheetModal = () => ({
  type: 'EDIT_TIMESHEET_MODAL'
})
