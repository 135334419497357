import {
  GET_ESTIMATES_FAIL,
  GET_ESTIMATES_SUCCESS,
  GET_ESTIMATES,
  ADD_ESTIMATE,
  EDIT_ESTIMATE,
  DELETE_ESTIMATE,
} from "./actionTypes";

const INIT_STATE = {
  estimateWithPagy: {
    total: 0,
    page: 1,
    page_size: 10,
    results: []
  },
  new: false,
  edit: false,
  delete: false,
  generatableEstimate: null,
  generate: false,
  loading: false,
};

const estimates = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ESTIMATES:
      return {
        ...state,
        new: false,
        edit: false,
        delete: false,
        loading: action.payload.loading
      };

    case 'SET_GENERATABLE_ESTIMATE':
      return {
        ...state,
        generatableEstimate: action.payload.estimate
      };

    case GET_ESTIMATES_SUCCESS:
      return {
        ...state,
        loading: false,
        estimateWithPagy: action.payload,
      };

    case ADD_ESTIMATE:
      return {
        ...state,
        loading: true
      };

    // case DELETE_ESTIMATE:
    //   return {
    //     ...state,
    //     loading: true
    //   };

    // case 'GENERATE_ESTIMATE':
    //   return {
    //     ...state,
    //     loading: true
    //   };

    case EDIT_ESTIMATE:
      return {
        ...state,
        loading: true
      };

    case GET_ESTIMATES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "NEW_ESTIMATE_MODAL":
      return {
        ...state,
        new: !state.new
      };

    case "DELETE_ESTIMATE_MODAL":
      return {
        ...state,
        delete: !state.delete
      };

    case "GENERATE_ESTIMATE_MODAL":
      return {
        ...state,
        generate: !state.generate
      };

    case "EDIT_ESTIMATE_MODAL":
      return {
        ...state,
        edit: !state.edit
      };
    default:
      return state;
  }
};

export default estimates;
