import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';
import {
  GET_ESTIMATES,
  DELETE_ESTIMATE,
  ADD_ESTIMATE,
  EDIT_ESTIMATE
} from "./actionTypes";

import {
  getEstimatesSuccess,
  getEstimates,
  getEstimatesFail,
  deleteEstimateModal,
  generateEstimateModal,
  setGeneratableEstimate
} from "./actions";

import {
  getEstimates as getEstimatesAPI,
  deleteEstimate as deleteEstimateAPI,
  generateEstimate as generateEstimateAPI,
  addEstimate as addEstimateAPI,
  editEstimate as editEstimateAPI,
} from "api/v1";


function* fetchEstimates({ payload: { params } }) {
  try {
    const response = yield call(getEstimatesAPI, params);
    yield put(getEstimatesSuccess(response));
  } catch (error) {
    yield put(getEstimatesFail("Something went wrong!"))
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* addEstimate({ payload: { estimate, actions } }) {
  try {
    let _estimate = yield call(addEstimateAPI, estimate)
    toast.success("Estimate Created!");
    yield put(getEstimates(true));
    actions.resetForm()
    // do here
    yield put(setGeneratableEstimate(_estimate))
    yield put(generateEstimateModal())

  } catch ({ errors }) {
    yield put(getEstimatesFail("Creation failed!"))
    if (errors) {
      actions.setErrors(errors)

    }
    toast.error("Something went wrong!");
  }
}

function* editEstimate({ payload: { id, estimate, actions } }) {
  try {
    let _estimate = yield call(editEstimateAPI, id, estimate)
    toast.success("Estimate Updated!");
    yield put(getEstimates(true));
    // do here
    yield put(setGeneratableEstimate(_estimate))
    yield put(generateEstimateModal())

  } catch ({ errors }) {
    yield put(getEstimatesFail("Creation failed!"))
    if (errors) {
      actions.setErrors(errors)

    }
    toast.error("Something went wrong!");
  }
}

function* deleteEstimate({ payload: { estimate } }) {
  try {
    yield call(deleteEstimateAPI, estimate.id);
    yield put(deleteEstimateModal())
    yield put(getEstimates(true));
    toast.warn("Estimate Deleted!");
  } catch (error) {
    toast.error("Something went wrong!");
  }
}

function* generateEstimate({ payload: { estimate } }) {
  try {
    yield call(generateEstimateAPI, estimate.id);
    yield put(generateEstimateModal())
    yield put(getEstimates(true));
    toast.success("Estimate generated!");
  } catch (error) {
    toast.error("Something went wrong!");
  }
}

function* estimateSaga() {
  yield takeEvery(GET_ESTIMATES, fetchEstimates);
  yield takeEvery(DELETE_ESTIMATE, deleteEstimate);
  yield takeEvery('GENERATE_ESTIMATE', generateEstimate);
  yield takeEvery(ADD_ESTIMATE, addEstimate);
  yield takeEvery(EDIT_ESTIMATE, editEstimate);
}

export default estimateSaga;
