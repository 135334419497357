import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'

import { editUserModal, editUser } from 'store/users/actions'

function index({ user: target }) {
  let dispatch = useDispatch()
  const { edit: modal } = useSelector((state) => state.users);
  let [user, setUser] = useState()

  useEffect(() => {
    if (modal) {
      setUser(target)
    }
  }, [modal])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      first_name: (user && user.profile.first_name) || '',
      last_name: (user && user.profile.last_name) || '',
      email: (user && user.email) || '',
      phone: (user && user.phone) || '',
      avatar: (user && user.profile.avatar) || null,
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please Enter Your First Name"),
      last_name: Yup.string().required("Please Enter Your Last Name"),
      email: Yup.string().required("Please Enter Your Email"),
      avatar: Yup.mixed().required("You need to provide a avatar"),

    }),
    onSubmit: (_values, actions) => {
      dispatch(editUser(user.id, _values, actions))
    },
  });



  return (
    <React.Fragment>
      <Modal isOpen={modal} toggle={() => dispatch(editUserModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(editUserModal())}>
          Edit User
        </ModalHeader>
        <ModalBody>
          <Form
            edit={true}
            validation={validation}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
