import React from "react";
import {
  Col,
  Row,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap";

import AsyncSelect from 'react-select/async';
import { getTaskMembers } from "api/v1";
import InputMask from "react-input-mask";
import Dropzone from 'react-dropzone-uploader';
import './style.scss'
import ImageContainer from "./ImageContainer";

function index({ validation, edit = false, handleDeleteImage, projectId,
  taskId
}) {
  let [images, setImages] = React.useState([])

  React.useEffect(() => {
    validation.setFieldValue("images", images.map(_img => _img.file))
  }, [images])

  const handleChangeStatus = ({ meta, file, xhr, ...rest }, status) => {

    const fileRemove = rest.remove;
    if (status === "removed") {
      setImages(fids => fids.filter(item => item.name !== file.name));
    }
    if (status === "done") {
      setImages(fids => [...fids, {
        file,
        name: file.name,
        remove: fileRemove
      }]);


    }
  }

  let loadMembers = (search) => {
    return getTaskMembers(projectId, taskId, { search, no_paginate: 1 })
      .then((results = []) => {
        return results.map(({ assignee }) => assignee)
      })
      .catch(({ message }) => {
        if (message !== 'request-cancel') {
          toast.error(message);
        }
      })
  }

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <Row>
        <Col xs="12" md="6" className="mb-3">
          <Label className="form-label">Date</Label>
          <Input
            name="date"
            type="date"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.date || ""}
            invalid={
              validation.touched.date && validation.errors.date ? true : false
            }
          />
          {validation.touched.date && validation.errors.date ? (
            <FormFeedback type="invalid">{validation.errors.date}</FormFeedback>
          ) : null}
        </Col>

        <Col xs="12" md="6" className="mb-3">
          <Label className="form-label">Time Out</Label>

          <InputMask
            mask="99:99"
            className="form-control input-color"
            name="time_out"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.time_out || ""}
          />

          {validation.touched.time_out && validation.errors.time_out ? (
            <span className="text-danger font-size-11">{validation.errors.time_out}</span>
          ) : null}
        </Col>

        <Col xs="12" md="6" className="mb-3">
          <Label className="form-label">Start Time</Label>
          <Input
            name="start_time"
            type="time"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.start_time}
            invalid={
              validation.touched.start_time && validation.errors.start_time ? true : false
            }
          />
          {validation.touched.start_time && validation.errors.start_time ? (
            <FormFeedback type="invalid">{validation.errors.start_time}</FormFeedback>
          ) : null}
        </Col>

        <Col xs="12" md="6" className="mb-3">
          <Label className="form-label">End Time</Label>
          <Input
            name="end_time"
            type="time"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.end_time}
            invalid={
              validation.touched.end_time && validation.errors.end_time ? true : false
            }
          />
          {validation.touched.end_time && validation.errors.end_time ? (
            <FormFeedback type="invalid">{validation.errors.end_time}</FormFeedback>
          ) : null}
        </Col>

        <div className="mb-3">
          <Label className="form-label">Description</Label>
          <Input
            name="description"
            type="textarea"
            rows="2"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.description || ""}
            invalid={
              validation.touched.description && validation.errors.description ? true : false
            }
          />
          {validation.touched.description && validation.errors.description ? (
            <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
          ) : null}
        </div>

        <Col xs="12" className="mb-3">
          <Label className="form-label">Select Member</Label>
          <AsyncSelect
            defaultOptions
            placeholder="Select Member"
            required
            value={validation.values.user}
            getOptionLabel={({ profile: { first_name, last_name } }) => `${first_name} ${last_name}`}
            getOptionValue={({ id }) => id}
            loadOptions={loadMembers}
            onChange={(user) => {
              validation.setFieldValue('user', user)
            }}

          />
          {validation.touched.user && validation.errors.user ? (
            <span className="text-danger font-size-11">{validation.errors.user}</span>
          ) : null}
        </Col>

        <div className="mb-3">
          <Label className="form-label">Image</Label>
          <Dropzone
            inputContent={<span key="some_key" className="h5 mb-0">Click here or drag images</span>}
            onChangeStatus={handleChangeStatus}
            accept="image/*"
          />
        </div>

        {
          edit &&
          <div className="mb-3">
            <ImageContainer handleDeleteImage={handleDeleteImage} />
          </div>
        }
      </Row>
      <Row>
        <Col>
          <div className="text-end">
            <button
              type="submit"
              className="btn btn-success save-customer"
            >
              Save
            </button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default index
