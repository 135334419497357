import {
  GET_DISTRIBUTIONS,
  GET_DISTRIBUTIONS_SUCCESS,
  GET_DISTRIBUTIONS_FAIL,
  DELETE_DISTRIBUTION,
  ADD_DISTRIBUTION,
  EDIT_DISTRIBUTION
} from "./actionTypes"

export const getDistributions = (loading = false, params = {}) => ({
  type: GET_DISTRIBUTIONS,
  payload: { loading, params }
})

export const getDistributionsSuccess = distributions => ({
  type: GET_DISTRIBUTIONS_SUCCESS,
  payload: distributions,
})

export const getDistributionsFail = error => ({
  type: GET_DISTRIBUTIONS_FAIL,
  payload: error,
})

export const deleteDistribution = distribution => ({
  type: DELETE_DISTRIBUTION,
  payload: { distribution }
})

export const addDistribution = (distribution, actions) => {
  return {
    type: ADD_DISTRIBUTION,
    payload: { distribution, actions },
  }
}

export const editDistribution = (id, distribution, actions) => {
  return {
    type: EDIT_DISTRIBUTION,
    payload: { id, distribution, actions },
  }
}

export const newDistributionModal = () => ({
  type: 'NEW_DISTRIBUTION_MODAL'
})

export const deleteDistributionModal = () => ({
  type: 'DELETE_DISTRIBUTION_MODAL'
})

export const editDistributionModal = () => ({
  type: 'EDIT_DISTRIBUTION_MODAL'
})
