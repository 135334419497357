import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Input,
  FormFeedback,
  Card,
  Label,
  Form,
} from "reactstrap";
import AsyncSelect from 'react-select/async';
import { getCustomers, getProjects } from "api/v1";
import { usFormat } from "utils/common";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState, RichUtils } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

function index({ validation, target = null }) {
  const [editorState, setEditorState] = useState()

  useEffect(() => {
    if (target?.details) {
      const contentBlock = htmlToDraft(target.details);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const _editorState = EditorState.createWithContent(contentState);
        setEditorState(_editorState)
      }
    }
    else
      setEditorState(EditorState.createEmpty())

  }, [])

  function onEditorStateChange(_editorState) {
    setEditorState(_editorState)
  }

  useEffect(() => {
    if (editorState)
      validation.setFieldValue('details', draftToHtml(convertToRaw(editorState.getCurrentContent())))
  }, [editorState])

  function handleReturn(e) {
    if (e.shiftKey || e.key == "Enter") {
      setEditorState(RichUtils.insertSoftNewline(editorState));
      return 'handled';
    }
    return 'not-handled';
  }

  let loadCustomers = (search) => {
    return getCustomers({ search, no_paginate: 1 })
      .then((results = []) => {
        return results
      })
      .catch(({ message }) => {
        if (message !== 'request-cancel') {
          toast.error(message);
        }
      })
  }

  let loadProjects = (search) => {
    if (!validation.values.customer)
      return

    return getProjects({ search, no_paginate: 1, customer_id: validation.values.customer.id })
      .then((results = []) => {
        return results
      })
      .catch(({ message }) => {
        if (message !== 'request-cancel') {
          toast.error(message);
        }
      })
  }

  function _estimatedCostTotal() {
    let __estimatedCostTotal = 0

    validation.values.estimated_costs.forEach(_estimated_cost => {
      if (_estimated_cost.quantity && _estimated_cost.unit_cost)
        __estimatedCostTotal += (_estimated_cost.quantity * _estimated_cost.unit_cost)
    })

    return __estimatedCostTotal
  }

  function removeEstimatedCost(_estimated_cost, index) {
    // populate deleted here for only those who has id
    if (_estimated_cost.hasOwnProperty("id")) {
      let _deletedEstimatedCosts = [...validation.values._deleted_estimated_costs]
      _deletedEstimatedCosts.push({ ..._estimated_cost, delete: true })

      validation.setFieldValue("_deleted_estimated_costs", _deletedEstimatedCosts)
    }

    validation.setFieldValue("estimated_costs", validation.values.estimated_costs.filter((_, _index) => _index != index))
  }

  function handleEstimatedCostChange(event, index) {
    validation.setFieldValue('estimated_costs', validation.values.estimated_costs.map((_item, _index) => ({
      ..._item, [event.target.name]: index == _index ? (["quantity", "unit_cost"].includes(event.target.name) ? parseFloat(event.target.value) : event.target.value || '') : _item[event.target.name]
    })))
  }

  function addEstimatedCost() {
    let _items = [...validation.values.estimated_costs]
    _items.push({ item_name: '', quantity: '', unit_cost: '', unit: "", description: "" })

    validation.setFieldValue('estimated_costs', _items)
  }

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >

      <Row>
        <Col xs="12" className="mb-3">
          <Label className="form-label">Customer</Label>
          <AsyncSelect
            defaultOptions
            placeholder="Select Customer"
            required
            value={validation.values.customer}
            getOptionLabel={({ first_name, last_name }) => `${first_name} ${last_name}`}
            getOptionValue={({ id }) => id}
            loadOptions={loadCustomers}
            onChange={(_customer) => {
              validation.setFieldValue('customer', _customer)
              validation.setFieldValue('project', null)
            }}

          />
          {validation.touched.customer && validation.errors.customer ? (
            <span className="text-danger font-size-11">{validation.errors.customer}</span>
          ) : null}
        </Col>

        {
          validation.values.customer &&
          <Col xs="12" className="mb-3">
            <Label className="form-label">Project</Label>
            <AsyncSelect
              key={`custId-${validation.values.customer?.id || 'no-customer'}`}
              defaultOptions
              placeholder="Select Project"
              required
              value={validation.values.project}
              getOptionLabel={({ project_name }) => `${project_name}`}
              getOptionValue={({ id }) => id}
              loadOptions={loadProjects}
              onChange={(_project) => {
                validation.setFieldValue('project', _project)
              }}

            />
            {validation.touched.project && validation.errors.project ? (
              <span className="text-danger font-size-11">{validation.errors.project}</span>
            ) : null}
          </Col>
        }

        {
          validation.values.project &&
          <React.Fragment>
            <Col xs="12" className="mb-3">
              <Label className="form-label">Valid Until</Label>
              <Input
                name="valid_until"
                type="date"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.valid_until || ""}
                invalid={
                  validation.touched.valid_until && validation.errors.valid_until ? true : false
                }
              />
              {validation.touched.valid_until && validation.errors.valid_until ? (
                <FormFeedback type="invalid">{validation.errors.valid_until}</FormFeedback>
              ) : null}
            </Col>

            <Col xs="12" className="mb-3">
              <Label className="form-label">Details</Label>
              <Editor
                handleReturn={handleReturn}
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
              />

              {validation.touched.details && validation.errors.details ? (
                <FormFeedback type="invalid">{validation.errors.details}</FormFeedback>
              ) : null}
            </Col>

            <div className="w-80">
              <Card className="border p-3 py-2 rounded">
                <h5>Estimated Costs</h5>

                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Item Name</th>
                      <th scope="col">Unit</th>
                      <th scope="col">Unit Cost</th>
                      <th scope="col">Quantity/Square Ft.</th>
                      <th scope="col">Total</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      validation.values.estimated_costs.map((_estimated_cost, index) => {

                        let errors = validation.errors.hasOwnProperty('estimated_costs') ? validation.errors.estimated_costs[index] : {}
                        let touched = validation.touched.hasOwnProperty('estimated_costs') ? validation.touched.estimated_costs[index] : {}
                        let values = _estimated_cost

                        return (
                          <tr key={`estimated_costs-${index}`}>
                            <th scope="row">{index + 1}</th>
                            <td>
                              <Input
                                name="item_name"
                                type="text"
                                onChange={e => handleEstimatedCostChange(e, index)}
                                onBlur={validation.handleBlur}
                                value={values?.item_name || ""}
                                invalid={
                                  touched?.item_name && errors?.item_name ? true : false
                                }
                              />
                              {touched?.item_name && errors?.item_name ? (
                                <FormFeedback type="invalid">{errors?.item_name}</FormFeedback>
                              ) : null}
                            </td>

                            <td width="20%">
                              <Input
                                name="unit"
                                type="select"
                                className="form-select"
                                onChange={e => handleEstimatedCostChange(e, index)}
                                onBlur={validation.handleBlur}
                                value={values?.unit || ""}
                                invalid={
                                  touched?.unit && errors?.unit ? true : false
                                }
                              >
                                <option value=""></option>
                                <option value="Quantity">Quantity</option>
                                <option value="Square ft">Square ft</option>
                              </Input>

                              {touched?.unit && errors?.unit ? (
                                <FormFeedback type="invalid">{errors?.unit}</FormFeedback>
                              ) : null}
                            </td>

                            <td>
                              <Input
                                name="unit_cost"
                                type="number"
                                onChange={e => handleEstimatedCostChange(e, index)}
                                onBlur={validation.handleBlur}
                                value={values?.unit_cost || ""}
                                invalid={
                                  touched?.unit_cost && errors?.unit_cost ? true : false
                                }
                              />
                              {touched?.unit_cost && errors?.unit_cost ? (
                                <FormFeedback type="invalid">{errors?.unit_cost}</FormFeedback>
                              ) : null}
                            </td>

                            <td>
                              <Input
                                name="quantity"
                                type="number"
                                onChange={e => handleEstimatedCostChange(e, index)}
                                onBlur={validation.handleBlur}
                                value={values?.quantity || ""}
                                invalid={
                                  touched?.quantity && errors?.quantity ? true : false
                                }
                              />
                              {touched?.quantity && errors?.quantity ? (
                                <FormFeedback type="invalid">{errors?.quantity}</FormFeedback>
                              ) : null}
                            </td>

                            <td>
                              {
                                values && values.unit_cost && values.quantity &&
                                <b className="list-inline-item font-size-18">
                                  {usFormat(values.unit_cost * values.quantity)}
                                </b>
                              }
                            </td>

                            <td>
                              <li onClick={() => removeEstimatedCost(_estimated_cost, index)} className="list-inline-item font-size-18">
                                <i className="bx bxs-trash text-danger p-1" />
                              </li>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>

                <div className="text-end">
                  <button
                    onClick={addEstimatedCost}
                    type="button"
                    className="btn btn-secondary">
                    New
                  </button>
                </div>

                {typeof (validation.errors.estimated_costs) == 'string' && validation.touched.estimated_costs && validation.errors.estimated_costs ? (
                  <span className="text-danger font-size-11">{validation.errors.estimated_costs}</span>
                ) : null}

                {validation.errors.estimated_costs && typeof (validation.errors.estimated_costs) != 'string' && typeof (validation.errors.estimated_costs[0]) == 'string' ? (
                  <span className="text-danger font-size-11">{validation.errors.estimated_costs[0]}</span>
                ) : null}

                <div className="text-end my-1 mt-3">
                  <h5>Sub Total: {usFormat(_estimatedCostTotal()) || '-'}</h5>
                </div>
              </Card>
            </div>

            <Col xs="8" className="mb-3" />
            <Col xs="4" className="mb-3">
              <Label className="form-label">Discount Percentage</Label>
              <Input
                name="discount_percent"
                type="number"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.discount_percent || ""}
                invalid={
                  validation.touched.discount_percent && validation.errors.discount_percent ? true : false
                }
              />
              {validation.touched.discount_percent && validation.errors.discount_percent ? (
                <FormFeedback type="invalid">{validation.errors.discount_percent}</FormFeedback>
              ) : null}
            </Col>

            <div className="text-end my-2">
              <Row>
                <Col xs="7" />
                <Col xs="2">
                  <h5>Sub Total:</h5>
                </Col>
                <Col xs="3">
                  <h5>{usFormat(_estimatedCostTotal()) || '-'}</h5>
                </Col>
              </Row>

              <Row>
                <Col xs="7" />
                <Col xs="2">
                  <h5>Discount:</h5>
                </Col>
                <Col xs="3">
                  <h5>{validation.values.discount_percent}%</h5>
                </Col>
              </Row>

              <Row>
                <Col xs="7" />
                <Col xs="2">
                  <h5>Total:</h5>
                </Col>
                <Col xs="3">
                  <h5>{usFormat(_estimatedCostTotal() - (_estimatedCostTotal() * (validation.values.discount_percent / 100))) || '-'}</h5>
                </Col>
              </Row>
            </div>
          </React.Fragment>
        }
      </Row>


      <Row>
        <Col>
          <div className="text-end">
            <button
              disabled={validation.values.customer == null}
              type="submit"
              className="btn btn-success save-customer">
              Save
            </button>
          </div>
        </Col>
      </Row>
    </Form >
  )
}

export default index
