import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'

import { editCustomerModal, editCustomer } from 'store/customers/actions'

function index({ customer: target }) {
  let dispatch = useDispatch()
  const { edit: modal } = useSelector((state) => state.customers);
  let [customer, setCustomer] = useState()

  useEffect(() => {
    if (modal) {
      setCustomer(target)
    }
  }, [modal])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      company_name: (customer && customer.company_name) || '',
      email: (customer && customer.user?.email) || '',
      phone: (customer && customer.user?.phone) || '',
      first_name: (customer && customer.first_name) || '',
      last_name: (customer && customer.last_name) || '',
      date_birth: (customer && customer.date_birth) || '',
      city: (customer && customer.city) || '',
      state: (customer && customer.state) || '',
      zip_code: (customer && customer.zip_code) || '',
      country: (customer && customer.country) || '',
      street: (customer && customer.street) || '',
      avatar: (customer && customer.avatar) || null,
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please Enter Your First Name"),
      last_name: Yup.string().required("Please Enter Your Last Name"),
      email: Yup.string().email().required("Please Your Email")
    }),
    onSubmit: (values, actions) => {
      dispatch(editCustomer(customer.id, { ...values, user: { email: values.email, phone: values.phone } }, actions))
    },
  });



  return (
    <React.Fragment>
      <Modal size="lg" isOpen={modal} toggle={() => dispatch(editCustomerModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(editCustomerModal())}>
          Edit Customer
        </ModalHeader>
        <ModalBody>
          <Form
            validation={validation}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
