import {
  GET_VENDORS,
  GET_VENDORS_SUCCESS,
  GET_VENDORS_FAIL,
  DELETE_VENDOR,
  ADD_VENDOR,
  EDIT_VENDOR
} from "./actionTypes"

export const getVendors = (loading = false, params = {}) => ({
  type: GET_VENDORS,
  payload: { loading, params }
})

export const getVendorsSuccess = vendors => ({
  type: GET_VENDORS_SUCCESS,
  payload: vendors,
})

export const getVendorsFail = error => ({
  type: GET_VENDORS_FAIL,
  payload: error,
})

export const deleteVendor = vendor => ({
  type: DELETE_VENDOR,
  payload: { vendor }
})

export const addVendor = (vendor, actions) => {
  return {
    type: ADD_VENDOR,
    payload: { vendor, actions },
  }
}

export const editVendor = (id, vendor, actions) => {
  return {
    type: EDIT_VENDOR,
    payload: { id, vendor, actions },
  }
}

export const newVendorModal = () => ({
  type: 'NEW_VENDOR_MODAL'
})

export const deleteVendorModal = () => ({
  type: 'DELETE_VENDOR_MODAL'
})

export const editVendorModal = () => ({
  type: 'EDIT_VENDOR_MODAL'
})
