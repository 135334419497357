import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'

import { newCustomerModal, addCustomer } from 'store/customers/actions'

function index() {
  let dispatch = useDispatch()
  const { new: modal } = useSelector((state) => state.customers);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      company_name: "",
      email: "",
      phone: "",
      first_name: '',
      last_name: '',
      date_birth: '',
      city: '',
      state: '',
      zip_code: '',
      country: '',
      street: '',
      avatar: null,
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please Enter Your First Name"),
      last_name: Yup.string().required("Please Enter Your Last Name"),
      email: Yup.string().email().required("Please Your Email")
    }),
    onSubmit: (values, actions) => {
      dispatch(addCustomer({ ...values, user: { email: values.email, phone: values.phone } }, actions))
    },
  });

  useEffect(() => {
    if (modal) {
      validation.resetForm()
    }
  }, [modal])

  return (
    <React.Fragment>
      <Modal size="lg" isOpen={modal} toggle={() => dispatch(newCustomerModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(newCustomerModal())}>
          Add Customer
        </ModalHeader>
        <ModalBody>
          <Form
            validation={validation}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
