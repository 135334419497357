import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { v4 as uuid } from 'uuid';

import {
  getTasks as onGetTasks,
} from "store/tasks/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { map } from "lodash";
import Loader from "common/Loader";
import moment from "moment";

const EcommerceTasks = props => {
  const dispatch = useDispatch();

  const { taskWithPagy, loading } = useSelector(state => ({
    taskWithPagy: state.tasks.taskWithPagy,
    loading: state.tasks.loading,
  }));
  let { results: tasks } = taskWithPagy

  const [searchParams, setSearchParams] = useState({
    status: 'Close'
  })

  //pagination customization
  const pageOptions = {
    sizePerPage: taskWithPagy.page_size,
    totalSize: taskWithPagy.total,
    page: taskWithPagy.page,
    custom: true,
  };

  const COLS = [
    {
      text: "id",
      dataField: "id",
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      text: "#",
      dataField: "index",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row, rowIndex) => <>{rowIndex + 1}</>,
    },
    {
      dataField: "task",
      text: "Project / Task name",
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{`${row.project.project_name} / ${row.task}`}</p>
        </>
      ),
    },
    {
      dataField: "start_date",
      text: "Start Date",
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{handleValidDate(row.project.project_start_date)}</p>
        </>
      ),
    },
    {
      dataField: "end_date",
      text: "End Date",
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{handleValidDate(row.project.project_end)}</p>
        </>
      ),
    },
    {
      dataField: "estimated_hours",
      text: "Estimated Time",
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{`${row.estimated_hours || 0} Hours`}</p>
        </>
      ),
    },
    {
      dataField: "total_hours",
      text: "Working Time",
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{`${row.total_hours} Hours`}</p>
        </>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cellContent, row) => (
        <p className="mb-1">{`${row.status}`}</p>
      ),
    },
    {
      dataField: 'custom',
      text: "Members",
      sort: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        return (
          <div className="avatar-group">
            {map(row.users, (_member, index) => {
              let { profile: member } = _member
              let _uuid = uuid()
              return (
                !member.avatar || member.avatar !== "Null" && (
                  <div
                    className="avatar-group-item"
                    key={_uuid}
                  >
                    <Link
                      to="#"
                      className="team-member d-inline-block"
                      id={`tooltip-${_uuid}`}
                    >
                      <img
                        src={member.avatar}
                        className="rounded-circle avatar-xs"
                        alt=""
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target={`tooltip-${_uuid}`}
                      >
                        {`${member.first_name} (${_member.type})`}
                      </UncontrolledTooltip>
                    </Link>
                  </div>
                )
              )
            })}
          </div>
        )
      },
    }
  ];


  var node = useRef();

  //delete task

  function init() {
    dispatch(onGetTasks(null, true, searchParams));
  }

  /** set Date formate */
  const handleValidDate = date => {
    const date1 = date ? moment(new Date(date)).format("DD MMM Y") : "N/A";
    return date1;
  };

  useEffect(() => {
    init()
  }, []);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page }) => {
    if (type == "pagination")
      dispatch(onGetTasks(null, true, { page, ...searchParams }));
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  return (
    <React.Fragment>
      <Row>
        <Col xs="12">
          <Card style={{ borderWidth: 1, borderColor: '#F1F1F1' }}>
            <CardHeader className="bg-white pt-3">
              <Row>
                <Col>
                  <CardTitle className="mb-2">Task Complete List</CardTitle>
                </Col>
              </Row>
            </CardHeader>

            <CardBody>
              {
                loading &&
                <Loader message={"Please wait"} />
              }

              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="id"
                columns={COLS}
                data={tasks}
              >
                {({ paginationProps, paginationTableProps }) => {
                  return (
                    <ToolkitProvider
                      keyField="id"
                      data={tasks || []}
                      columns={COLS}
                      bootstrap4
                      search
                    >
                      {toolkitProps => {
                        return (
                          <div className={loading ? "d-none" : 'd-block'}>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    noDataIndication={"No Data found"}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    keyField="id"
                                    remote={{ search: true, pagination: true }}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    ref={node}
                                  />
                                </div>

                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30 px-2">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </div>
                        )
                      }}
                    </ToolkitProvider>
                  )
                }}
              </PaginationProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

EcommerceTasks.propTypes = {
  tasks: PropTypes.array,
  onGetTasks: PropTypes.func,
  onAddNewTask: PropTypes.func,
  onDeleteTask: PropTypes.func,
  onUpdateTask: PropTypes.func,
};

export default EcommerceTasks;
