import {
  GET_INVOICES,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_FAIL,
  DELETE_INVOICE,
  ADD_INVOICE,
  EDIT_INVOICE
} from "./actionTypes"

export const getInvoices = (loading = false, params = {}) => ({
  type: GET_INVOICES,
  payload: { loading, params }
})

export const getInvoice = (id, history) => ({
  type: 'GET_INVOICE',
  payload: { id, history }
})

export const getInvoicesSuccess = invoices => ({
  type: GET_INVOICES_SUCCESS,
  payload: invoices,
})

export const getInvoicesFail = error => ({
  type: GET_INVOICES_FAIL,
  payload: error,
})

export const getInvoiceSuccess = invoice => ({
  type: 'GET_INVOICE_SUCCESS',
  payload: invoice,
})

export const getInvoiceFail = () => ({
  type: 'GET_INVOICE_FAIL',
})

export const deleteInvoice = invoice => ({
  type: DELETE_INVOICE,
  payload: { invoice }
})

export const unshareInvoice = (invoice, actions) => ({
  type: 'UNSHARE_INVOICE',
  payload: { invoice, actions }
})

export const generateInvoice = invoice => ({
  type: 'GENERATE_INVOICE',
  payload: { invoice }
})

export const addInvoice = (invoice, actions, history) => {
  return {
    type: ADD_INVOICE,
    payload: { invoice, actions, history },
  }
}

export const editInvoice = (id, invoice, actions, history) => {
  return {
    type: EDIT_INVOICE,
    payload: { id, invoice, actions, history },
  }
}

export const setGeneratableInvoice = (invoice) => {
  return {
    type: 'SET_GENERATABLE_INVOICE',
    payload: { invoice },
  }
}

export const newInvoiceModal = () => ({
  type: 'NEW_INVOICE_MODAL'
})

export const deleteInvoiceModal = () => ({
  type: 'DELETE_INVOICE_MODAL'
})

export const generateInvoiceModal = () => ({
  type: 'GENERATE_INVOICE_MODAL'
})

export const editInvoiceModal = () => ({
  type: 'EDIT_INVOICE_MODAL'
})
