import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'

import { newPayrollModal, addPayroll } from 'store/payrolls/actions'

function index() {
  let dispatch = useDispatch()
  const { new: modal } = useSelector((state) => state.payrolls);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      member: null,
      new_payrate: false,
      projects: [],
      payrate: null,
      cheque_no: '',
      payment_method: '',
      payment_period_start: '',
      payment_period_end: '',
      hours_worked: '',
      overtime_hours: '',
      timesheets: [],
    },
    validationSchema: Yup.object({
      member: Yup.mixed().required("Please Select Member"),
      rate: Yup.string().when('payrate', {
        is: (payrate) => (payrate && payrate.rate == null),
        then: Yup.string().required("Please Enter Rate")
      }),
      payment_method: Yup.string().required("Please Select Payment Method"),
      payment_period_start: Yup.string().required("Please Enter Start Date"),
      payment_period_end: Yup.string().required("Please Enter End Date"),
      hours_worked: Yup.string().when('projects', {
        is: (projects) => (projects.length > 0),
        then: Yup.string().nullable(),
        otherwise: Yup.string().required("Please Enter Working Hours")
      }),
      timesheets: Yup.array().when('projects', {
        is: (projects) => (projects.length > 0),
        then: Yup.array().min(1, "at least 1 Timesheet is").required("required"),
      }),
    }),
    onSubmit: (values, actions) => {
      dispatch(addPayroll(values, actions))
    },
  });

  useEffect(() => {
    if (modal) {
      validation.resetForm()
    }
  }, [modal])

  return (
    <React.Fragment>
      <Modal size="lg" isOpen={modal} toggle={() => dispatch(newPayrollModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(newPayrollModal())}>
          Add Payroll
        </ModalHeader>
        <ModalBody>
          <Form
            validation={validation}
            createable={true}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
