import {
  GET_LOCATIONS,
  GET_LOCATIONS_SUCCESS,
  GET_LOCATIONS_FAIL,
  DELETE_LOCATION,
  ADD_LOCATION,
  EDIT_LOCATION
} from "./actionTypes"

export const getLocations = (loading = false, params = {}) => ({
  type: GET_LOCATIONS,
  payload: { loading, params }
})

export const getLocationsSuccess = locations => ({
  type: GET_LOCATIONS_SUCCESS,
  payload: locations,
})

export const getLocationsFail = error => ({
  type: GET_LOCATIONS_FAIL,
  payload: error,
})

export const deleteLocation = location => ({
  type: DELETE_LOCATION,
  payload: { location }
})

export const addLocation = (location, actions) => {
  return {
    type: ADD_LOCATION,
    payload: { location, actions },
  }
}

export const editLocation = (id, location, actions) => {
  return {
    type: EDIT_LOCATION,
    payload: { id, location, actions },
  }
}

export const newLocationModal = () => ({
  type: 'NEW_LOCATION_MODAL'
})

export const deleteLocationModal = () => ({
  type: 'DELETE_LOCATION_MODAL'
})

export const editLocationModal = () => ({
  type: 'EDIT_LOCATION_MODAL'
})
